<template>
  <div>
    <study-report-timeline>
      <template #number>02</template>
      <template #title>Result</template>
      <div class="content-item">
        <div class="item-label">
          Precision
          <el-tooltip effect="dark" placement="right-start">
            <div slot="content">
              It measures the proportion of true positive predictions among all
              positive predictions.
              <br /><br />It indicates how accurate the positive predictions
              are.
            </div>
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>
        <div class="item-value">{{ result.precision.toFixed(3) }}</div>
      </div>
      <div class="content-item">
        <div class="item-label">
          Recall
          <el-tooltip effect="dark" placement="right-start">
            <div slot="content">
              Also known as sensitivity or true positive rate, it measures the
              proportion of true positive predictions among all actual positive
              instances. <br /><br />It indicates how well the model identifies
              positive instances.
            </div>
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>
        <div class="item-value">{{ result.recall.toFixed(3) }}</div>
      </div>
      <div class="content-item">
        <div class="item-label">
          F1 Score
          <el-tooltip effect="dark" placement="right-start">
            <div slot="content">
              It is the harmonic mean of precision and recall, providing a
              balance between the two metrics. <br /><br />It is used to
              evaluate a model's performance when there's an uneven class
              distribution.
            </div>
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>
        <div class="item-value">{{ result.f1Score.toFixed(3) }}</div>
      </div>
      <div v-if="result.matrix" class="content-item">
        <div class="item-label">
          Confusion Matrix
          <el-tooltip effect="dark" placement="right-start">
            <div slot="content">
              It is a table that summarizes the model's performance by comparing
              predicted values against actual values. <br /><br />It contains
              metrics such as true positives, true negatives, false positives,
              and false negatives.
            </div>
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>
        <div class="item-value">
          <el-table :data="result.matrix" class="result-table">
            <el-table-column prop="title" />
            <el-table-column prop="positive" label="Positive" />
            <el-table-column prop="negative" label="Negative" />
          </el-table>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label">
          Features
          <el-tooltip effect="dark" placement="right-start">
            <div slot="content">
              These are the input variables used by the Homoscedastic Logistic
              Regression model to make predictions. <br /><br />They represent
              the independent variables in the study.
            </div>
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>
        <div class="item-value">
          <el-tag v-for="tag in result.features" :key="tag" class="tag"
            >{{ tag }}
          </el-tag>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label"></div>
        <div class="item-value text-grey">
          <div>
            (a) radius (mean of distances from center to points on the
            perimeter)
          </div>
          <div>(b) texture (standard deviation of gray-scale values)</div>
          <div>(c) perimeter</div>
          <div>(d) area</div>
          <div>(e) smoothness (local variation in radius lengths)</div>
          <div>(f) compactness (perimeter^2 / area - 1.0)</div>
          <div>(g) concavity (severity of concave portions of the contour)</div>
          <div>
            (h) concave points (number of concave portions of the contour)
          </div>
          <div>(i) symmetry</div>
          <div>(j) fractal dimension ("coastline approximation" - 1)</div>
        </div>
      </div>
      <div class="content-item">
        <div class="item-label">
          ROC Chart
          <el-tooltip effect="dark" placement="right-start">
            <div slot="content">
              It is a graph that shows the model's true positive rate against
              the false positive rate at different classification thresholds. It
              helps to evaluate the model's performance across various
              thresholds.
              <br /><br />AUC represents the area under the ROC curve and is
              used as a single metric to evaluate the model's performance. A
              higher AUC value indicates better model performance.
            </div>
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>
        <div class="item-value">
          <div id="roc-chart" class="result-chart not-print"></div>
        </div>
      </div>

      <!-- <div class="item-value">
        <el-table
          :data="result.tableResult.slice((pageNo - 1) * 10, pageNo * 10)"
          class="result-table"
        >
          <el-table-column prop="name" label="变量名称"></el-table-column>
          <el-table-column prop="value" label="权重" sortable>
          </el-table-column>
        </el-table>

        <el-pagination
          background
          class="result-pagination"
          :current-page="1"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="result.tableResult.length"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div> -->
    </study-report-timeline>
  </div>
</template>

<script>
import studyReportTimeline from "../studyReport/study-report-timeline";
import echarts from "../../assets/js/echarts";

export default {
  components: {
    studyReportTimeline,
  },
  props: ["studyInfo", "result"],
  data() {
    return {
      pageNo: 1,
    };
  },
  mounted() {
    const obj = {
      id: "roc-chart",
      data: "trainingLineChartData",
    };
    this.initChart(obj);
  },
  methods: {
    initChart(obj) {
      const myChart = echarts.init(document.getElementById(obj.id));
      const option = {
        title: {
          text: `ROC Chart (AUC: ${this.result.auc.toFixed(3)}) `,
          textStyle: {
            fontSize: 18,
          },
          left: "center",
        },
        xAxis: {
          name: "FPR",
          nameTextStyle: {
            padding: [70, 0, 0, 0],
          },
          data: this.result.rocChart.x,
        },
        yAxis: {
          name: "TPR",
          nameTextStyle: {
            padding: [0, 0, 0, 0],
          },
        },
        series: [
          {
            data: this.result.rocChart.y,
            type: "line",
          },
        ],
        tooltip: {
          trigger: "axis", // 触发类型为 axis，表示在坐标轴上触发显示提示框
          formatter: function (params) {
            const dataPoint = params[0]; // 获取触发提示框的数据点
            const xValue = dataPoint.axisValue; // x 坐标值
            const yValue = dataPoint.value; // y 坐标值
            return `FPR: ${xValue}<br>TPR: ${yValue}`; // 自定义提示框内容
          },
        },
      };
      myChart.setOption(option);
      this.chartResize(myChart);
    },
    chartResize(chart) {
      function resize() {
        chart.resize();
      }
      window.addEventListener("resize", resize);
      this.$once("hook:beforeDestroy", () => {
        if (chart) {
          window.removeEventListener("resize", resize);
          chart.clear();
          chart.dispose();
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
.study-report-container {
  .content-item {
    display: flex;
    font-size: 16px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &.no-metrics {
      color: #999;
    }
    .item-label {
      flex-shrink: 0;
      margin-right: 40px;
      width: 220px;
      font-weight: 500;
      color: #999;
    }
    .item-value {
      flex: 1;
      color: #333;
    }
    .value-list-item {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
  .sub-title {
    margin-bottom: 24px;
    font-size: 18px;
    font-weight: 500;
    color: "#34E393";
  }
  .result-chart {
    width: 600px;
    height: 600px;
    margin: 50px auto;
  }
  .table-title {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
    color: #333;
    text-align: center;
  }
  .result-table {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .result-tag {
    margin: 5px;
  }
  .result-pagination {
    margin: 30px 0;
    text-align: center;

    &.no-bottom {
      margin-bottom: 0;
    }
  }
  .divide-line {
    height: 3px;
    width: 100%;
    margin: 50px 0;
    background-color: "#34E393";
  }

  @media print {
    .not-print {
      display: none;
    }
    // .page-break {
    //     page-break-before: always;
    // }
    .content-item {
      .item-value {
        color: #333;
        word-break: break-all;
      }
    }
  }
}
.value-list-item-length {
  display: inline-block;
  width: 200px;
}
.tag-container {
  width: 400px;
}
.tag {
  margin: 5px;
}
.text-grey div {
  color: #999;
}
</style>
