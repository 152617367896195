<template>
  <div
    class="nftmax-balance__current nftmax-bg__cover mg-top-30"
    :style="{
      'background-image':
        'url(' + require(`../../assets/img/current-balance__bg.png`) + ')',
    }"
  >
    <div class="nftmax-balancetop">
      <div class="nftmax-balance__wallet-box">04 <span>Wallets</span></div>
      <div class="nftmax-transaction">
        <h3 class="nftmax-transaction__title">6,572.00</h3>
        <p class="nftmax-transaction__text">Total Transactions</p>
      </div>
    </div>
    <div class="nftmax-balance__wallet-current">
      <p class="nftmax-balance__wallet-text">Current Balance</p>
      <h2 class="nftmax-balance__wallet-amount">$94734.00</h2>
      <p class="nftmax-balance__wallet-add">+324.75 <span>(11.5%)</span></p>
    </div>
    <div class="nftmax-progress">
      <!-- Progress Single -->
      <div class="nftmax-progress__single circle-count">
        <div class="percent">
          <svg>
            <circle cx="37" cy="37" r="30"></circle>
            <circle
              cx="37"
              cy="37"
              r="30"
              :style="{ '--percent': `${eth}` }"
            ></circle>
          </svg>
        </div>
        <b data-v-4b4fd68f="" class="number">90%</b>
        <strong>2.32 ETH</strong>
      </div>
      <!-- Progress Single -->
      <div class="nftmax-progress__single circle-count">
        <div class="percent">
          <svg>
            <circle cx="37" cy="37" r="30"></circle>
            <circle
              cx="37"
              cy="37"
              r="30"
              :style="{ '--percent': `${eth}` }"
            ></circle>
          </svg>
        </div>
        <b data-v-4b4fd68f="" class="number">40%</b>
        <strong>1.76 BTC</strong>
      </div>
      <!-- Progress Single -->
      <div class="nftmax-progress__single circle-count">
        <div class="percent">
          <svg>
            <circle cx="37" cy="37" r="30"></circle>
            <circle
              cx="37"
              cy="37"
              r="30"
              :style="{ '--percent': `${eth}` }"
            ></circle>
          </svg>
        </div>
        <b data-v-4b4fd68f="" class="number">80%</b>
        <strong>2.32 LTC</strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      eth: 90,
      btc: 40,
      ltc: 80,
    };
  },
};
</script>

<style scoped></style>
