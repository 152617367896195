<template>
  <Layout>
    <div class="nftmax-personals">
      <h2 class="nftmax-personals__title">
        Please describe any issue using the form and we will get back to you
        over email as soon as possible. Thanks!
      </h2>
      <div class="row">
        <div class="nftmax-personals__content">
          <el-form
            ref="form"
            :model="form"
            :rules="formRules"
            label-position="top"
            label-width="180px"
          >
            <el-form-item label="Subject" prop="subject">
              <el-input
                v-model="form.subject"
                placeholder="Please enter subject"
                maxlength="100"
              ></el-input>
            </el-form-item>
            <el-form-item label="Description" prop="description">
              <el-input
                v-model="form.description"
                placeholder="Please enter description"
                maxlength="1000"
                type="textarea"
                :rows="5"
              ></el-input>
            </el-form-item>
            <el-form-item label="Appendix" prop="appendix">
              <el-upload
                ref="upload"
                :file-list="fileList"
                :before-remove="beforeRemove"
                :on-change="saveData"
                action=""
                drag
                :auto-upload="false"
                :limit="3"
                list-type="picture"
                accept=".jpg,.jpeg,.png,.tiff,.tif,.bmp,.webp"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  Drag your file here or <em>Upload</em>
                </div>
                <div slot="tip" class="el-upload__tip">
                  Upload up to three image files, each no larger than 3MB (.jpg,
                  .jpeg, .png, .tiff, .tif, .bmp, .webp)
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">Submit</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/Partials/Layout";
import api from "../../http/api";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      form: {
        subject: "",
        description: "",
      },
      formRules: {
        subject: [
          {
            required: true,
            message: "Please enter subject",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "Please enter description",
            trigger: "blur",
          },
        ],
      },
      fileList: [],
    };
  },
  methods: {
    saveData(file, fileList) {
      const validSize = file.size / 1024 / 1024 < 3;
      if (!validSize) {
        const invalidFileIndex = fileList.findIndex(
          (item) => item.uid === file.uid
        );
        fileList.splice(invalidFileIndex, 1);
        this.$vToastify.error("Image file size should be less than 3MB");
        return false;
      } else {
        this.fileList = fileList;
      }
    },
    beforeRemove(file) {
      const fileIndex = this.fileList.findIndex(
        (item) => item.uid === file.uid
      );
      this.fileList.splice(fileIndex, 1);
    },
    async onSubmit() {
      let loading;
      try {
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            loading = this.$loading({
              lock: true,
              text: "Executing, please wait...",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.8)",
            });
            let formData = new FormData();
            this.fileList.forEach((file) => {
              formData.append("file", file.raw);
            });
            await api.uploadFeedback(formData, {
              userId: this.$store.state.userInfo.userId,
              ...this.form,
            });
            this.fileList = [];
            this.form = {
              subject: "",
              description: "",
            };
            loading.close();
            this.$alert(
              "Thanks for contacting us. We will respond back to you through email as soon as possible"
            );
          } else {
            loading.close();
          }
        });
      } catch (error) {
        console.log(error);
        loading.close();
        this.$alert("Submission failed, please try again later");
      }
    },
  },
};
</script>

<style scoped></style>
