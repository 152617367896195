<template>
  <div>
    <study-report-timeline>
      <template #number>02</template>
      <template #title>Result</template>
      <div class="content-item">
        <div class="item-label">Image Classification Result</div>
        <div class="item-value">
          <div class="zip-img-container">
            <div v-for="url in zipFilesUrlList" :key="url.name">
              <el-image
                :src="url.src"
                alt="Medical Image"
                class="zip-img"
                lazy
              ></el-image>
              <div class="demonstration">
                {{ imageClassificationList[url.index.toString()] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </study-report-timeline>
  </div>
</template>

<script>
import studyReportTimeline from "../studyReport/study-report-timeline";
import JSZip from "jszip";
import api from "../../http/api";
import imageClassificationList from "../../data/img.json";

export default {
  components: {
    studyReportTimeline,
  },
  props: ["studyInfo", "result"],
  data() {
    return {
      zipFilesUrlList: [],
      imageClassificationList: imageClassificationList,
    };
  },
  async mounted() {
    const res = await api.getImage({
      filename: "User-2-189833a9-0203-464c-897c-8c5277262304-imgset.zip",
      type: 2,
    });
    // 处理压缩图片包
    const zipData = res.data;
    const zip = new JSZip();
    zip.loadAsync(zipData).then((zip) => {
      zip.forEach((relativePath, file) => {
        file.async("base64").then((base64Data) => {
          const imageElement = new Image();
          imageElement.src = `data:image/jpeg;base64,${base64Data}`;
          this.zipFilesUrlList.push({
            name: file.name,
            src: imageElement.src,
            index: file.name.split(".")[0],
          });
        });
      });
    });
    console.log(this.zipFilesUrlList);
  },
  methods: {},
};
</script>
<style scoped lang="less">
.study-report-container {
  .content-item {
    display: flex;
    font-size: 16px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &.no-metrics {
      color: #999;
    }
    .item-label {
      flex-shrink: 0;
      margin-right: 40px;
      width: 220px;
      font-weight: 500;
      color: #999;
    }
    .item-value {
      flex: 1;
      color: #333;
    }
    .value-list-item {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
  .sub-title {
    margin-bottom: 24px;
    font-size: 18px;
    font-weight: 500;
    color: "#34E393";
  }
  .result-chart {
    width: 600px;
    height: 600px;
    margin: 50px auto;
  }
  .table-title {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
    color: #333;
    text-align: center;
  }
  .result-table {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .result-tag {
    margin: 5px;
  }
  .result-pagination {
    margin: 30px 0;
    text-align: center;

    &.no-bottom {
      margin-bottom: 0;
    }
  }
  .divide-line {
    height: 3px;
    width: 100%;
    margin: 50px 0;
    background-color: "#34E393";
  }

  @media print {
    .not-print {
      display: none;
    }
    // .page-break {
    //     page-break-before: always;
    // }
    .content-item {
      .item-value {
        color: #333;
        word-break: break-all;
      }
    }
  }
  .zip-img-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 三列等宽 */
    grid-gap: 30px;
  }
  .zip-img {
    width: 100%; /* 图片宽度占满列 */
    height: auto; /* 高度自适应，保持正方形 */
    max-width: 100%; /* 最大宽度100% */
  }
  .demonstration {
    text-align: center;
    color: #999;
  }
}
</style>
