<template>
  <div class="row nftmax-gap-30">
    <div class="col-lg-6 col-12">
      <!-- NFTMax Balance Hover -->
      <BalanceWidget />
    </div>
    <div class="col-lg-6 col-12">
      <!-- NFTMax Balance Hover -->
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="nftmax-sellbox__single nftmax-sellbox__sell mg-top-40">
            <div class="nftmax-sellbox__heading">
              <h4 class="nftmax-sellbox__heading-title">Bits this Month</h4>
            </div>
            <div class="nftmax-amount__statics">
              <h4 class="nftmax-amount__statics__title">769.44 ETH</h4>
              <p class="nftmax-amount__statics__text">
                ($949374.94)<span class="nftmax-amount__statics__debit"
                  >-224.75 (11.5%)</span
                >
              </p>
            </div>
            <div class="nftmax-sidebar__cside-one">
              <div id="myChart_bids_month" style="height: 205px">
                <SellMonthStatics set-r-g-b-color="rgba(242, 153, 74)" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="nftmax-sellbox__single nftmax-sellbox__earning mg-top-40">
            <div class="nftmax-sellbox__heading">
              <h4 class="nftmax-sellbox__heading-title">
                Sell Earing this Month
              </h4>
            </div>
            <div class="nftmax-amount__statics">
              <h4 class="nftmax-amount__statics__title">542.75 ETH</h4>
              <p class="nftmax-amount__statics__text">
                ($949374.94)<span class="nftmax-amount__statics__credit"
                  >+324.75 (11.5%)</span
                >
              </p>
            </div>
            <div class="nftmax-sidebar__cside-one">
              <div id="myChart_earning_month" style="height: 205px">
                <SellMonthStatics set-r-g-b-color="rgba(245, 57, 248, 1)" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End NFTMax Balance Hover -->
    </div>
  </div>
</template>

<script>
import BalanceWidget from "@/components/HomePage/BalanceWidget";
import SellMonthStatics from "@/components/Charts/SellMonthStatics";
export default {
  components: { SellMonthStatics, BalanceWidget },
};
</script>

<style scoped></style>
