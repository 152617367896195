import { formatUsDate, isValidDate } from "./time";

const tabTitleList = [
  {
    label: "Person",
    prop: "patient",
    icon: "el-icon-user",
  },
  {
    label: "Medication Order",
    prop: "medicationOrder",
    icon: "el-icon-data-analysis",
  },
  // {
  //   label: "Medication",
  //   prop: "medication",
  //   icon: "el-icon-takeaway-box",
  // },
  {
    label: "Diagnostic",
    prop: "Diagnostic",
    icon: "el-icon-document-checked",
  },
  {
    label: "Procedure",
    prop: "procedure",
    icon: "el-icon-suitcase",
  },
  {
    label: "Medication Request",
    prop: "MedicationRequest",
    icon: "el-icon-tickets",
  },
  {
    label: "Allergy Intolerance",
    prop: "AllergyIntolerance",
    icon: "el-icon-warning-outline",
  },
  {
    label: "Care Plan (Encounter Level)",
    prop: "CarePlanEncounterLevel",
    icon: "el-icon-date",
  },
  {
    label: "Care Plan (Longitudinal)",
    prop: "CarePlanLongitudinal",
    icon: "el-icon-date",
  },
  {
    label: "Condition (Health Concern)",
    prop: "ConditionHealthConcern",
    icon: "el-icon-monitor",
  },
  {
    label: "Condition (Problems)",
    prop: "ConditionProblems",
    icon: "el-icon-monitor",
  },
  {
    label: "Device",
    prop: "Device",
    icon: "el-icon-cpu",
  },
  {
    label: "Document Reference (Clinical Notes)",
    prop: "DocumentReferenceClinicalNotes",
    icon: "el-icon-data-line",
  },
  {
    label: "Encounter",
    prop: "Encounter",
    icon: "el-icon-office-building",
  },
  // {
  //   label: "Goal",
  //   prop: "Goal",
  //   icon: "el-icon-medal",
  // },
  {
    label: "Immunization",
    prop: "Immunization",
    icon: "el-icon-coin",
  },
  // {
  //   label: "Observation Laboratory",
  //   prop: "observationLaboratory",
  //   icon: "el-icon-document",
  // },
  // {
  //   label: "Observation (Social History)",
  //   prop: "observationSocialHistory",
  //   icon: "el-icon-document",
  // },
  // {
  //   label: "Observation (Vitals)",
  //   prop: "observationVitals",
  //   icon: "el-icon-document",
  // },
];

export function handleMetaData(details) {
  try {
    console.log(details);
    // 处理patient数据
    const patient = {
      descriptionTitle: [
        {
          label: "Name",
          prop: "name",
          uiType: "tag",
        },
        {
          label: "Birth Date",
          prop: "birthDate",
        },
        {
          label: "Gender",
          prop: "gender",
        },
        {
          label: "Managing Organization",
          prop: "managingOrganization",
        },
      ],
      descriptionData: {
        name: details.patient?.name.find((item) => item.use === "official")
          .text,
        birthDate: details.patient?.birthDate ? details.patient.birthDate : "",
        gender: details.patient?.gender,
        managingOrganization: details.patient?.managingOrganization
          ? details.patient?.managingOrganization.display
          : "",
      },
      tableTitle: [],
      tableData: [],
    };
    console.log(patient);
    // 处理medicationOrder数据
    let medicationOrderTableData = [];
    for (const item of details.medicationOrder.entry) {
      if (item.fullUrl && item.fullUrl.includes("https")) {
        const medication = details.medication.find((subItem) =>
          item.resource?.medicationReference?.reference.includes(subItem.id)
        );
        medicationOrderTableData.push({
          name: item.resource.medicationReference?.display,
          type: medication.product?.form?.text
            ? medication.product?.form?.text
            : "",
          dosageInstruction: item.resource.dosageInstruction[0]?.text
            ? item.resource.dosageInstruction[0].text
            : "",
          route: item.resource.dosageInstruction[0]?.route?.text
            ? item.resource.dosageInstruction[0]?.route?.text
            : "",
          time:
            item.resource.dispenseRequest?.validityPeriod?.start &&
            isValidDate(item.resource.dispenseRequest?.validityPeriod?.start)
              ? formatUsDate(
                  item.resource.dispenseRequest?.validityPeriod?.start
                )
              : "",
          expectedSupplyDuration: item.resource.dispenseRequest
            ?.expectedSupplyDuration?.value
            ? `${item.resource.dispenseRequest?.expectedSupplyDuration?.value}${item.resource.dispenseRequest?.expectedSupplyDuration?.unit}`
            : "",
          quantity: item.resource.dispenseRequest?.quantity?.value
            ? `${item.resource.dispenseRequest?.quantity?.value}${item.resource.dispenseRequest?.quantity?.unit}`
            : "",
          prescriber: item.resource.prescriber?.display,
          repeat: item.resource.dosageInstruction[0]?.timing?.repeat?.frequency
            ? `${item.resource.dosageInstruction[0]?.timing?.repeat?.frequency} time ${item.resource.dosageInstruction[0]?.timing?.repeat?.period} ${item.resource.dosageInstruction[0]?.timing?.repeat?.periodUnits}`
            : "",
        });
      }
    }
    const medicationOrder = {
      descriptionTitle: [],
      descriptionData: {},
      tableTitle: [
        {
          label: "Name",
          prop: "name",
        },
        {
          label: "Type",
          prop: "type",
          uiType: "tag",
        },
        {
          label: "Dosage Instruction",
          prop: "dosageInstruction",
        },
        {
          label: "Route",
          prop: "route",
          uiType: "tag",
        },
        {
          label: "Time",
          prop: "time",
        },
        {
          label: "Expected Supply Duration",
          prop: "expectedSupplyDuration",
        },
        {
          label: "Quantity",
          prop: "quantity",
        },
        {
          label: "Prescriber",
          prop: "prescriber",
        },
        {
          label: "Repeat",
          prop: "repeat",
          uiType: "tag",
        },
      ],
      tableData: medicationOrderTableData,
    };
    console.log(medicationOrder);
    // 处理medication数据
    let medicationtableData = [];
    for (const item of details.medication) {
      medicationtableData.push({
        name: item.code?.text ? item.code?.text : "",
        type: item.product?.form?.text ? item.product?.form?.text : "",
      });
    }
    const medication = {
      descriptionTitle: [],
      descriptionData: {},
      tableTitle: [
        {
          label: "Name",
          prop: "name",
        },
        {
          label: "Type",
          prop: "type",
        },
      ],
      tableData: medicationtableData,
    };
    console.log(medication);
    // 处理DiagnosticReport数据
    let diagnosticTableData = [];
    for (const item of details.diagnostic.entry) {
      if (item.fullUrl && item.fullUrl.includes("https")) {
        // 从Binary中获取数据
        let binaryDataList = [];
        if (item.resource.presentedForm?.length) {
          for (const subItem of item.resource.presentedForm) {
            const binaryData = details.binary?.find((binaryItem) =>
              subItem.url.includes(binaryItem.id)
            );
            if (binaryData) {
              binaryDataList.push(binaryData);
            }
          }
        }
        const performer = (item.resource.performer || [])
          .map((subItem) => {
            return subItem.display ? subItem.display : "";
          })
          .join(" & ");
        const resultsInterpreter = (item.resource.resultsInterpreter || [])
          .map((subItem) => {
            return subItem.display ? subItem.display : "";
          })
          .join(" & ");
        diagnosticTableData.push({
          name: item.resource?.code?.text ? item.resource?.code?.text : "",
          effectiveDateTime:
            item.resource?.effectiveDateTime &&
            isValidDate(item.resource?.effectiveDateTime)
              ? formatUsDate(item.resource?.effectiveDateTime)
              : "",
          issued:
            item.resource.issued && isValidDate(item.resource.issued)
              ? formatUsDate(item.resource.issued)
              : "",
          performer,
          resultsInterpreter,
          binaryDataList,
        });
      }
    }
    const Diagnostic = {
      descriptionTitle: [],
      descriptionData: {},
      tableTitle: [
        {
          label: "Name",
          prop: "name",
        },
        {
          label: "Effective Date Time",
          prop: "effectiveDateTime",
        },
        {
          label: "Issued Time",
          prop: "issued",
        },
        {
          label: "Performer",
          prop: "performer",
        },
        {
          label: "Results Interpreter",
          prop: "resultsInterpreter",
        },
      ],
      tableData: diagnosticTableData,
    };
    console.log(Diagnostic);
    // 处理procedure数据
    let procedureTableData = [];
    for (const item of details.procedure.entry) {
      if (item.fullUrl && item.fullUrl.includes("https")) {
        procedureTableData.push({
          name: item.resource.code?.text ? item.resource.code?.text : "",
          reason: (item.resource.reasonCode || [])
            .map((subItem) => (subItem.text ? subItem.text : ""))
            .join(" & "),
          performer: (item.resource.performer || [])
            .map((subItem) =>
              subItem.actor?.display ? subItem.actor?.display : ""
            )
            .join(" & "),
          status: item.resource.status,
        });
      }
    }
    const procedure = {
      descriptionTitle: [],
      descriptionData: {},
      tableTitle: [
        {
          label: "Name",
          prop: "name",
        },
        {
          label: "Reason",
          prop: "reason",
        },
        {
          label: "Performer",
          prop: "performer",
        },
        {
          label: "Status",
          prop: "status",
          uiType: "tag",
        },
      ],
      tableData: procedureTableData,
    };
    console.log(procedure);
    // 处理AllergyIntolerance数据
    let AllergyIntoleranceTableData = [];
    for (const item of details.AllergyIntolerance.entry) {
      if (item.fullUrl && item.fullUrl.includes("https")) {
        AllergyIntoleranceTableData.push({
          status: item.resource.code?.text ? item.resource.code?.text : "",
          recordedDate:
            item.resource.recordedDate &&
            isValidDate(item.resource.recordedDate)
              ? formatUsDate(item.resource.recordedDate)
              : "",
          recorder: item.resource.recorder
            ? item.resource.recorder.display
            : "",
        });
      }
    }
    const AllergyIntolerance = {
      descriptionTitle: [],
      descriptionData: {},
      tableTitle: [
        {
          label: "Status",
          prop: "status",
        },
        {
          label: "Recorded Date",
          prop: "recordedDate",
        },
        {
          label: "Recorder",
          prop: "recorder",
        },
      ],
      tableData: AllergyIntoleranceTableData,
    };
    console.log(AllergyIntolerance);
    // 处理CarePlanEncounterLevel数据
    let CarePlanEncounterLevelTableData = [];
    for (const item of details.CarePlanEncounterLevel.entry) {
      if (item.fullUrl && item.fullUrl.includes("https")) {
        const category = (item.resource.category || [])
          .map((subItem) => {
            return subItem.text ? subItem.text : "";
          })
          .join(" & ");
        const note = (item.resource.note || [])
          .map((subItem) => {
            return subItem.text ? subItem.text : "";
          })
          .join(" & ");
        CarePlanEncounterLevelTableData.push({
          category,
          encounter: item.resource.encounter?.display
            ? item.resource.encounter?.display
            : "",
          intent: item.resource.intent ? item.resource.intent : "",
          note,
        });
      }
    }
    const CarePlanEncounterLevel = {
      descriptionTitle: [],
      descriptionData: {},
      tableTitle: [
        {
          label: "Category",
          prop: "category",
        },
        {
          label: "Encounter",
          prop: "encounter",
        },
        {
          label: "Intent",
          prop: "intent",
        },
        {
          label: "Note",
          prop: "note",
        },
      ],
      tableData: CarePlanEncounterLevelTableData,
    };
    console.log(CarePlanEncounterLevel);
    // 处理CarePlanLongitudinal数据
    let CarePlanLongitudinalTableData = [];
    for (const item of details.CarePlanLongitudinal.entry) {
      if (item.fullUrl && item.fullUrl.includes("https")) {
        const category = (item.resource.category || [])
          .map((subItem) => {
            return subItem.text ? subItem.text : "";
          })
          .join(" & ");
        const addresses = (item.resource.addresses || [])
          .map((subItem) => {
            return subItem.display ? subItem.display : "";
          })
          .join(" & ");
        const goal = (item.resource.goal || [])
          .map((subItem) => {
            return subItem.display ? subItem.display : "";
          })
          .join(" & ");
        CarePlanLongitudinalTableData.push({
          intent: item.resource.intent ? item.resource.intent : "",
          category,
          addresses,
          goal,
        });
      }
    }
    const CarePlanLongitudinal = {
      descriptionTitle: [],
      descriptionData: {},
      tableTitle: [
        {
          label: "Intent",
          prop: "intent",
        },
        {
          label: "Category",
          prop: "category",
        },
        {
          label: "Addresses",
          prop: "addresses",
        },
        {
          label: "Goal",
          prop: "goal",
        },
      ],
      tableData: CarePlanLongitudinalTableData,
    };
    console.log(CarePlanLongitudinal);
    // 处理ConditionHealthConcern数据
    let ConditionHealthConcernTableData = [];
    for (const item of details.ConditionHealthConcern.entry) {
      if (item.fullUrl && item.fullUrl.includes("https")) {
        const clinicalStatus = item.resource.clinicalStatus?.text
          ? item.resource.clinicalStatus?.text
          : "";
        const verificationStatus = item.resource.verificationStatus?.text
          ? item.resource.verificationStatus?.text
          : "";
        const code = item.resource.code ? item.resource.code.text : "";
        const recordedDate = item.resource.recordedDate
          ? item.resource.recordedDate
          : "";
        ConditionHealthConcernTableData.push({
          clinicalStatus,
          verificationStatus,
          code,
          recordedDate,
        });
      }
    }
    const ConditionHealthConcern = {
      descriptionTitle: [],
      descriptionData: {},
      tableTitle: [
        {
          label: "Conditon Name",
          prop: "code",
        },
        {
          label: "Clinical Status",
          prop: "clinicalStatus",
          uiType: "tag",
        },
        {
          label: "Verification Status",
          prop: "verificationStatus",
          uiType: "tag",
        },
        {
          label: "Recorded Date",
          prop: "recordedDate",
        },
      ],
      tableData: ConditionHealthConcernTableData,
    };
    console.log(ConditionHealthConcern);
    // 处理ConditionProblems数据
    let ConditionProblemsTableData = [];
    for (const item of details.ConditionProblems.entry) {
      if (item.fullUrl && item.fullUrl.includes("https")) {
        const startPeriod =
          item.resource.onsetPeriod?.start &&
          isValidDate(item.resource.onsetPeriod.start)
            ? formatUsDate(item.resource.onsetPeriod.start)
            : "";
        const endPeriod =
          item.resource.onsetPeriod?.end &&
          isValidDate(item.resource.onsetPeriod.end)
            ? formatUsDate(item.resource.onsetPeriod.end)
            : "";
        ConditionProblemsTableData.push({
          clinicalStatus: item.resource.clinicalStatus?.text
            ? item.resource.clinicalStatus?.text
            : "",
          verificationStatus: item.resource.verificationStatus?.text
            ? item.resource.verificationStatus?.text
            : "",
          startPeriod,
          endPeriod,
          code: item.resource.code ? item.resource.code.text : "",
          recordedDate:
            item.resource.recordedDate &&
            isValidDate(item.resource.recordedDate)
              ? formatUsDate(item.resource.recordedDate)
              : "",
        });
      }
    }
    const ConditionProblems = {
      descriptionTitle: [],
      descriptionData: {},
      tableTitle: [
        {
          label: "Conditon Name",
          prop: "code",
        },
        {
          label: "Clinical Status",
          prop: "clinicalStatus",
          uiType: "tag",
        },
        {
          label: "Verification Status",
          prop: "verificationStatus",
          uiType: "tag",
        },
        {
          label: "Start Period",
          prop: "startPeriod",
        },
        {
          label: "End Period",
          prop: "endPeriod",
        },
        {
          label: "Recorded Date",
          prop: "recordedDate",
        },
      ],
      tableData: ConditionProblemsTableData,
    };
    console.log(ConditionProblems);
    // 处理Device数据
    let DeviceTableData = [];
    for (const item of details.Device.entry) {
      if (item.fullUrl && item.fullUrl.includes("https")) {
        DeviceTableData.push({
          name: item.resource.deviceName?.name
            ? item.resource.deviceName?.name
            : "",
          modelNumber: item.resource.modelNumber
            ? item.resource.modelNumber
            : "",
          serialNumber: item.resource.serialNumber
            ? item.resource.serialNumber
            : "",
          manufactureDate:
            item.resource.manufactureDate &&
            isValidDate(item.resource.manufactureDate)
              ? formatUsDate(item.resource.manufactureDate)
              : "",
          manufacturer: item.resource.manufacturer
            ? item.resource.manufacturer
            : "",
          expirationDate:
            item.resource.expirationDate &&
            isValidDate(item.resource.expirationDate)
              ? formatUsDate(item.resource.expirationDate)
              : "",
          location: item.resource.location ? item.resource.location : "",
          lotNumber: item.resource.lotNumber ? item.resource.lotNumber : "",
          status: item.resource.status ? item.resource.status : "",
        });
      }
    }
    const Device = {
      descriptionTitle: [],
      descriptionData: {},
      tableTitle: [
        {
          label: "Name",
          prop: "name",
        },
        {
          label: "Model Number",
          prop: "modelNumber",
        },
        {
          label: "Serial Number",
          prop: "serialNumber",
        },
        {
          label: "Manufacture Date",
          prop: "manufactureDate",
        },
        {
          label: "Manufacturer",
          prop: "manufacturer",
        },
        {
          label: "Expiration Date",
          prop: "expirationDate",
        },
        {
          label: "Location",
          prop: "location",
        },
        {
          label: "Lot Number",
          prop: "lotNumber",
        },
        {
          label: "Status",
          prop: "status",
        },
      ],
      tableData: DeviceTableData,
    };
    console.log(Device);
    // 处理DocumentReferenceClinicalNotes数据
    let DocumentReferenceClinicalNotesTableData = [];
    for (const item of details.DocumentReferenceClinicalNotes.entry) {
      if (item.fullUrl && item.fullUrl.includes("https")) {
        const startPeriod =
          item.resource.period?.start && isValidDate(item.resource.period.start)
            ? formatUsDate(item.resource.period.start)
            : "";
        const endPeriod =
          item.resource.period?.end && isValidDate(item.resource.period.end)
            ? formatUsDate(item.resource.period.end)
            : "";
        // 从Binary中获取数据
        let binaryDataList = [];
        if (item.resource.content?.length) {
          for (const subItem of item.resource.content) {
            const binaryData = details.binary?.find((binaryItem) =>
              subItem.attachment.url.includes(binaryItem.id)
            );
            if (binaryData) {
              binaryDataList.push(binaryData);
            }
          }
        }
        const author = (item.resource.author || [])
          .map((subItem) => {
            return subItem.display ? subItem.display : "";
          })
          .join(" & ");
        DocumentReferenceClinicalNotesTableData.push({
          type: item.resource.type?.text ? item.resource.type?.text : "",
          date:
            item.resource.date && isValidDate(item.resource.date)
              ? formatUsDate(item.resource.date)
              : "",
          author,
          startPeriod,
          endPeriod,
          binaryDataList,
        });
      }
    }
    const DocumentReferenceClinicalNotes = {
      descriptionTitle: [],
      descriptionData: {},
      tableTitle: [
        {
          label: "Type",
          prop: "type",
        },
        {
          label: "Date",
          prop: "date",
        },
        {
          label: "Author",
          prop: "author",
        },
        {
          label: "Start Period",
          prop: "startPeriod",
        },
        {
          label: "End Period",
          prop: "endPeriod",
        },
      ],
      tableData: DocumentReferenceClinicalNotesTableData,
    };
    console.log(DocumentReferenceClinicalNotes);
    // 处理Encounter数据
    let EncounterTableData = [];
    for (const item of details.Encounter.entry) {
      if (item.fullUrl && item.fullUrl.includes("https")) {
        const identifier = item.resource.identifier
          ? JSON.stringify(item.resource.identifier)
          : "";
        const type = (item.resource.type || [])
          .map((subItem) => {
            return subItem.text ? subItem.text : "";
          })
          .join(" & ");
        const participant = (item.resource.participant || [])
          .map((subItem) => {
            const text = subItem.individual?.display
              ? subItem.individual?.display
              : "";
            return text ? text : "";
          })
          .join(" & ");
        const location = (item.resource.location || [])
          .map((subItem) => {
            return subItem.location?.display ? subItem.location?.display : "";
          })
          .join(" & ");
        const reasonCode = (item.resource.reasonCode || [])
          .map((subItem) => {
            return subItem.text ? subItem.text : "";
          })
          .join(" & ");
        const startPeriod =
          item.resource.period?.start && isValidDate(item.resource.period.start)
            ? formatUsDate(item.resource.period.start)
            : "";
        const endPeriod =
          item.resource.period?.end && isValidDate(item.resource.period.end)
            ? formatUsDate(item.resource.period.end)
            : "";
        EncounterTableData.push({
          identifier,
          type,
          location,
          reasonCode,
          participant,
          startPeriod,
          endPeriod,
          status: item.resource.status ? item.resource.status : "",
        });
      }
    }
    const Encounter = {
      descriptionTitle: [],
      descriptionData: {},
      tableTitle: [
        {
          label: "Type",
          prop: "type",
        },
        {
          label: "Location",
          prop: "location",
        },
        {
          label: "Reason",
          prop: "reasonCode",
        },
        {
          label: "Participant",
          prop: "participant",
        },
        {
          label: "Start Period",
          prop: "startPeriod",
        },
        {
          label: "End Period",
          prop: "endPeriod",
        },
        {
          label: "Status",
          prop: "status",
          uiType: "tag",
        },
      ],
      tableData: EncounterTableData,
    };
    console.log(Encounter);
    // 处理MedicationRequest数据
    let MedicationRequestTableData = [];
    for (const item of details.MedicationRequest.entry) {
      if (item.fullUrl && item.fullUrl.includes("https")) {
        // 从Encounter中获取数据
        let encouterData = null;
        if (item.resource.encounter) {
          encouterData = Encounter.tableData.find(
            (encounterItem) =>
              encounterItem.identifier ===
              JSON.stringify([item.resource.encounter.identifier])
          );
        }
        MedicationRequestTableData.push({
          medicineName: item.resource.medicationReference?.display
            ? item.resource.medicationReference?.display
            : "",
          authoredOn:
            item.resource.authoredOn && isValidDate(item.resource.authoredOn)
              ? formatUsDate(item.resource.authoredOn)
              : "",
          courseOfTherapyType: item.resource.courseOfTherapyType?.text
            ? item.resource.courseOfTherapyType?.text
            : "",
          recorder: item.resource.recorder
            ? item.resource.recorder.display
            : "",
          requester: item.resource.subject ? item.resource.subject.display : "",
          location: encouterData ? encouterData.location : "",
        });
      }
    }
    const MedicationRequest = {
      descriptionTitle: [],
      descriptionData: {},
      tableTitle: [
        {
          label: "medicineName",
          prop: "medicineName",
        },
        {
          label: "Location",
          prop: "location",
        },
        {
          label: "Authored On",
          prop: "authoredOn",
        },
        {
          label: "Course Of Therapy Type",
          prop: "courseOfTherapyType",
        },
        {
          label: "Recorder",
          prop: "recorder",
        },
        {
          label: "Requester",
          prop: "requester",
          uiType: "tag",
        },
      ],
      tableData: MedicationRequestTableData,
    };
    console.log(MedicationRequest);
    // 处理Goal数据
    let GoalTableData = [];
    for (const item of details.Goal.entry) {
      if (item.fullUrl && item.fullUrl.includes("https")) {
        const note = (item.resource.note || [])
          .map((subItem) => {
            return subItem.text ? subItem.text : "";
          })
          .join(" & ");
        GoalTableData.push({
          issues: item.resource.addresses?.display
            ? item.resource.addresses?.display
            : "",
          description: item.resource.description?.text
            ? item.resource.description?.text
            : "",
          startDate: item.resource.startDate ? item.resource.startDate : "",
          expressedBy: item.resource.expressedBy
            ? item.resource.expressedBy
            : "",
          lifecycleStatus: item.resource.lifecycleStatus
            ? item.resource.lifecycleStatus
            : "",
          note,
        });
      }
    }
    const Goal = {
      descriptionTitle: [],
      descriptionData: {},
      tableTitle: [
        {
          label: "Issues",
          prop: "issues",
        },
        {
          label: "Description",
          prop: "description",
        },
        {
          label: "Start Date",
          prop: "startDate",
        },
        {
          label: "Expressed By",
          prop: "expressedBy",
        },
        {
          label: "Lifecycle Status",
          prop: "lifecycleStatus",
        },
      ],
      tableData: GoalTableData,
    };
    console.log(Goal);
    // 处理Immunization数据
    let ImmunizationTableData = [];
    for (const item of details.Immunization.entry) {
      if (item.fullUrl && item.fullUrl.includes("https")) {
        ImmunizationTableData.push({
          vaccineName: item.resource.vaccineCode?.text
            ? item.resource.vaccineCode?.text
            : "",
          occurrenceDateTime:
            item.resource.occurrenceDateTime &&
            isValidDate(item.resource.occurrenceDateTime)
              ? formatUsDate(item.resource.occurrenceDateTime)
              : "",
          status: item.resource.status ? item.resource.status : "",
          lotNumber: item.resource.lotNumber ? item.resource.lotNumber : "",
          expirationDate:
            item.resource.expirationDate &&
            isValidDate(item.resource.expirationDate)
              ? formatUsDate(item.resource.expirationDate)
              : "",
          site: item.resource.site?.text ? item.resource.site?.text : "",
          route: item.resource.route?.text ? item.resource.route?.text : "",
          doseQuantity:
            item.resource.doseQuantity?.value &&
            item.resource.doseQuantity?.unit
              ? `${item.resource.doseQuantity?.value} ${item.resource.doseQuantity?.unit}`
              : "",
          performer: item.resource.performer?.actor?.display
            ? item.resource.performer?.actor?.display
            : "",
        });
      }
    }
    const Immunization = {
      descriptionTitle: [],
      descriptionData: {},
      tableTitle: [
        {
          label: "Vaccine Name",
          prop: "vaccineName",
        },
        {
          label: "Occurrence Date Time",
          prop: "occurrenceDateTime",
        },
        {
          label: "Status",
          prop: "status",
          uiType: "tag",
        },
        {
          label: "Lot Number",
          prop: "lotNumber",
        },
        {
          label: "Expiration Date",
          prop: "expirationDate",
        },
        {
          label: "Site",
          prop: "site",
        },
        {
          label: "Route",
          prop: "route",
        },
        {
          label: "Dose Quantity",
          prop: "doseQuantity",
        },
        {
          label: "Performer",
          prop: "performer",
        },
      ],
      tableData: ImmunizationTableData,
    };
    console.log(Immunization);
    // 处理observationLaboratory数据
    // let observationLaboratoryTableData = [];
    // for (const item of details.observationLaboratory.entry) {
    //   if (item.fullUrl && item.fullUrl.includes("https")) {
    //     const referenceRange = (item.resource.referenceRange || [])
    //       .map((subItem) => {
    //         return subItem.text ? subItem.text : "";
    //       })
    //       .join(" & ");
    //     observationLaboratoryTableData.push({
    //       name: item.resource.code?.text ? item.resource.code?.text : "",
    //       value: item.resource.valueQuantity?.value,
    //       unit: item.resource.valueQuantity?.unit,
    //       referenceRange,
    //     });
    //   }
    // }
    // const observationLaboratory = {
    //   descriptionTitle: [],
    //   descriptionData: {},
    //   tableTitle: [
    //     {
    //       label: "Name",
    //       prop: "name",
    //     },
    //     {
    //       label: "Value",
    //       prop: "value",
    //     },
    //     {
    //       label: "Unit",
    //       prop: "unit",
    //     },
    //     {
    //       label: "Reference Range",
    //       prop: "referenceRange",
    //     },
    //   ],
    //   tableData: observationLaboratoryTableData,
    // };
    // console.log(observationLaboratory);
    // 处理observationSocialHistory数据
    // let observationSocialHistoryTableData = [];
    // for (const item of details.observationSocialHistory.entry) {
    //   if (item.fullUrl && item.fullUrl.includes("https")) {
    //     const performer = (item.resource.performer || [])
    //       .map((subItem) => {
    //         return subItem.display ? subItem.display : "";
    //       })
    //       .join(" & ");
    //     const note = (item.resource.note || [])
    //       .map((subItem) => {
    //         return subItem.text ? subItem.text : "";
    //       })
    //       .join(" & ");
    //     observationSocialHistoryTableData.push({
    //       type: item.resource.code?.text ? item.resource.code?.text : "",
    //       value: item.resource.valueCodeableConcept?.text
    //         ? item.resource.valueCodeableConcept?.text
    //         : "",
    //       startEffectivePeriod: item.resource.effectivePeriod?.start
    //         ? item.resource.effectivePeriod?.start
    //         : "",
    //       endEffectivePeriod: item.resource.effectivePeriod?.end
    //         ? item.resource.effectivePeriod?.end
    //         : "",
    //       status: item.resource.status ? item.resource.status : "",
    //       issued: item.resource.issued ? item.resource.issued : "",
    //       performer,
    //       note,
    //     });
    //   }
    // }
    // const observationSocialHistory = {
    //   descriptionTitle: [],
    //   descriptionData: {},
    //   tableTitle: [
    //     {
    //       label: "Type",
    //       prop: "type",
    //     },
    //     {
    //       label: "Value",
    //       prop: "value",
    //     },
    //     {
    //       label: "Start Effective Period",
    //       prop: "startEffectivePeriod",
    //     },
    //     {
    //       label: "End Effective Period",
    //       prop: "endEffectivePeriod",
    //     },
    //     {
    //       label: "Status",
    //       prop: "status",
    //     },
    //     {
    //       label: "Issued",
    //       prop: "issued",
    //     },
    //     {
    //       label: "Performer",
    //       prop: "performer",
    //     },
    //     {
    //       label: "Note",
    //       prop: "note",
    //     },
    //   ],
    //   tableData: observationSocialHistoryTableData,
    // };
    // console.log(observationSocialHistory);
    // 处理observationVitals数据
    // let observationVitalsTableData = [];
    // for (const item of details.observationVitals.entry) {
    //   if (item.fullUrl && item.fullUrl.includes("https")) {
    //     const performer = (item.resource.performer || [])
    //       .map((subItem) => {
    //         return subItem.display ? subItem.display : "";
    //       })
    //       .join(" & ");
    //     const component = (item.resource.component || [])
    //       .map((subItem) => {
    //         return subItem.code?.text &&
    //           subItem.valueQuantity?.value &&
    //           subItem.valueQuantity?.unit
    //           ? `${subItem.code?.text}: ${subItem.valueQuantity?.value} ${subItem.valueQuantity?.unit}`
    //           : "";
    //       })
    //       .join(" & ");
    //     const value = item.resource.valueDateTime
    //       ? item.resource.valueDateTime
    //       : item.resource.valueString
    //       ? item.resource.valueString
    //       : item.resource.valueTime
    //       ? item.resource.valueTime
    //       : item.resource.valueQuantity
    //       ? `${item.resource.valueQuantity?.value} ${item.resource.valueQuantity?.unit}`
    //       : "";
    //     observationVitalsTableData.push({
    //       name: item.resource.code?.text ? item.resource.code?.text : "",
    //       encounter: item.resource.encounter?.display
    //         ? item.resource.encounter?.display
    //         : "",
    //       performer,
    //       effectiveDateTime:
    //         item.resource.effectiveDateTime &&
    //         isValidDate(item.resource.effectiveDateTime)
    //           ? formatUsDate(item.resource.effectiveDateTime)
    //           : "",
    //       issued:
    //         item.resource.issued && isValidDate(item.resource.issued)
    //           ? formatUsDate(item.resource.issued)
    //           : "",
    //       component,
    //       value,
    //     });
    //   }
    // }
    // const observationVitals = {
    //   descriptionTitle: [],
    //   descriptionData: {},
    //   tableTitle: [
    //     {
    //       label: "Name",
    //       prop: "name",
    //     },
    //     {
    //       label: "Encounter",
    //       prop: "encounter",
    //     },
    //     {
    //       label: "Performer",
    //       prop: "performer",
    //     },
    //     {
    //       label: "Effective Date Time",
    //       prop: "effectiveDateTime",
    //     },
    //     {
    //       label: "Issued",
    //       prop: "issued",
    //     },
    //     {
    //       label: "Component",
    //       prop: "component",
    //     },
    //     {
    //       label: "Value",
    //       prop: "value",
    //     },
    //   ],
    //   tableData: observationVitalsTableData,
    // };
    // console.log(observationVitals);
    let result = {
      patient,
      medicationOrder,
      medication,
      Diagnostic,
      procedure,
      MedicationRequest,
      AllergyIntolerance,
      CarePlanEncounterLevel,
      CarePlanLongitudinal,
      ConditionHealthConcern,
      ConditionProblems,
      Device,
      DocumentReferenceClinicalNotes,
      Encounter,
      Goal,
      Immunization,
      // observationLaboratory,
      // observationSocialHistory,
      // observationVitals,
      tabTitleList,
    };
    console.log(result);
    // 遍历所有数据，如果所有数据的值都是空的，则返回标志位
    let totalDataNum = 0;
    for (const key in result) {
      if (key !== "patient" && key !== "tabTitleList") {
        // descriptionData暂时全算在totalDataNum里面
        const descriptionDataTitleList = Object.keys(
          result[key].descriptionData
        );
        totalDataNum += descriptionDataTitleList.length;
        // 如果tableData数量为0，则不算在totalDataNum里面，数量>=2则算为1
        if (result[key].tableData.length >= 2) {
          totalDataNum += 1;
        } else if (result[key].tableData.length === 1) {
          // 如果tableData数量为1，则根据规则单独计算每个tab
          switch (key) {
            case "AllergyIntolerance": {
              for (const item of result[key].tableData) {
                if (item.recordedDate !== "" || item.recorder !== "") {
                  totalDataNum += 1;
                }
              }
              break;
            }
            case "CarePlanEncounterLevel": {
              for (const item of result[key].tableData) {
                if (item.note !== "") {
                  totalDataNum += 1;
                }
              }
              break;
            }
            case "CarePlanLongitudinal": {
              for (const item of result[key].tableData) {
                if (item.addresses !== "") {
                  totalDataNum += 1;
                }
              }
              break;
            }
            case "ConditionHealthConcern": {
              for (const item of result[key].tableData) {
                if (item.code !== "") {
                  totalDataNum += 1;
                }
              }
              break;
            }
            case "ConditionProblems": {
              for (const item of result[key].tableData) {
                if (item.code !== "") {
                  totalDataNum += 1;
                }
              }
              break;
            }
            case "Device": {
              for (const item of result[key].tableData) {
                if (item.manufacturer !== "") {
                  totalDataNum += 1;
                }
              }
              break;
            }
            case "DocumentReferenceClinicalNotes": {
              for (const item of result[key].tableData) {
                if (item.date !== "") {
                  totalDataNum += 1;
                }
              }
              break;
            }
            case "Encounter": {
              for (const item of result[key].tableData) {
                if (item.location !== "") {
                  totalDataNum += 1;
                }
              }
              break;
            }
            case "Goal": {
              for (const item of result[key].tableData) {
                if (item.description !== "") {
                  totalDataNum += 1;
                }
              }
              break;
            }
            case "Immunization": {
              for (const item of result[key].tableData) {
                if (item.vaccineName !== "") {
                  totalDataNum += 1;
                }
              }
              break;
            }
            case "MedicationRequest": {
              for (const item of result[key].tableData) {
                if (item.recorder !== "") {
                  totalDataNum += 1;
                }
              }
              break;
            }
            case "Diagnostic": {
              for (const item of result[key].tableData) {
                if (item.name !== "") {
                  totalDataNum += 1;
                }
              }
              break;
            }
            case "Medication": {
              for (const item of result[key].tableData) {
                if (item.name !== "") {
                  totalDataNum += 1;
                }
              }
              break;
            }
            case "MedicationOrder": {
              for (const item of result[key].tableData) {
                if (item.prescriber !== "") {
                  totalDataNum += 1;
                }
              }
              break;
            }
            case "Procedure": {
              for (const item of result[key].tableData) {
                if (item.name !== "") {
                  totalDataNum += 1;
                }
              }
              break;
            }
            default:
              break;
          }
        }
      }
    }
    result.totalDataNum = totalDataNum;
    console.log(result);
    return result;
  } catch (error) {
    console.log(error);
  }
}
