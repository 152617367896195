<template>
  <div class="nftmax-pcats">
    <!-- Profile Menu -->
    <div class="nftmax-pcats__bar">
      <div id="list-tab" class="nftmax-pcats__list list-group" role="tablist">
        <a
          :class="[activeTab === 'sale' ? 'active' : '']"
          class="list-group-item"
          data-bs-toggle="list"
          role="tab"
          @click="tabHandler('sale')"
          >On Sale<span class="nftmax-pcats__count">16</span></a
        >
        <a
          :class="[activeTab === 'owned' ? 'active' : '']"
          class="list-group-item"
          data-bs-toggle="list"
          role="tab"
          @click="tabHandler('owned')"
          >Owned<span class="nftmax-pcats__count">09</span></a
        >
        <a
          :class="[activeTab === 'created' ? 'active' : '']"
          class="list-group-item"
          data-bs-toggle="list"
          role="tab"
          @click="tabHandler('created')"
          >Created<span class="nftmax-pcats__count">35</span></a
        >
        <a
          :class="[activeTab === 'hidden' ? 'active' : '']"
          class="list-group-item"
          data-bs-toggle="list"
          role="tab"
          @click="tabHandler('hidden')"
          >Hidden<span class="nftmax-pcats__count">14</span></a
        >
        <a
          :class="[activeTab === 'collection' ? 'active' : '']"
          class="list-group-item"
          data-bs-toggle="list"
          role="tab"
          @click="tabHandler('collection')"
          >Collections<span class="nftmax-pcats__count">21</span></a
        >
        <a
          :class="[activeTab === 'activity' ? 'active' : '']"
          class="list-group-item"
          data-bs-toggle="list"
          role="tab"
          @click="tabHandler('activity')"
          >Activity</a
        >
      </div>
      <button
        class="nftmax__item-button--single nftmax-btn nftmax-btn__bordered bg radius"
      >
        Upload Product
      </button>
    </div>
    <!-- End Profile Menu -->
    <div id="nav-tabContent" class="tab-content">
      <!-- Single Tab -->
      <div
        id="tab_1"
        class="tab-pane fade"
        :class="[activeTab === 'sale' ? 'show active' : '']"
        role="tabpanel"
        aria-labelledby="nav-home-tab"
      >
        <div class="row">
          <div class="col-12">
            <div class="nftmax-pptabs mg-btm-20">
              <div class="nftmax-pptabs__form">
                <form
                  class="nftmax-header__form-inner nftmax-header__form-profile"
                  action="#"
                >
                  <button class="search-btn" type="submit">
                    <img src="../../../assets/img/search.png" alt="#" />
                  </button>
                  <input
                    name="s"
                    value=""
                    type="text"
                    placeholder="Search items, collections..."
                  />
                </form>
              </div>
              <div class="nftmax-pptabs__main">
                <ul
                  id="nav-tab"
                  class="nav nav-tabs nftmax-dropdown__list"
                  role="tablist"
                >
                  <li class="nav-item dropdown">
                    <a
                      class="nftmax-sidebar_btn nftmax-heading__tabs nav-link dropdown-toggle"
                      >Recently Received
                      <span
                        ><svg
                          width="20"
                          height="10"
                          viewBox="0 0 13 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.7"
                            d="M12.4124 0.247421C12.3327 0.169022 12.2379 0.106794 12.1335 0.0643287C12.0291 0.0218632 11.917 0 11.8039 0C11.6908 0 11.5787 0.0218632 11.4743 0.0643287C11.3699 0.106794 11.2751 0.169022 11.1954 0.247421L7.27012 4.07837C7.19045 4.15677 7.09566 4.219 6.99122 4.26146C6.88678 4.30393 6.77476 4.32579 6.66162 4.32579C6.54848 4.32579 6.43646 4.30393 6.33202 4.26146C6.22758 4.219 6.13279 4.15677 6.05312 4.07837L2.12785 0.247421C2.04818 0.169022 1.95338 0.106794 1.84895 0.0643287C1.74451 0.0218632 1.63249 0 1.51935 0C1.40621 0 1.29419 0.0218632 1.18975 0.0643287C1.08531 0.106794 0.990517 0.169022 0.910844 0.247421C0.751218 0.404141 0.661621 0.616141 0.661621 0.837119C0.661621 1.0581 0.751218 1.2701 0.910844 1.42682L4.84468 5.26613C5.32677 5.73605 5.98027 6 6.66162 6C7.34297 6 7.99647 5.73605 8.47856 5.26613L12.4124 1.42682C12.572 1.2701 12.6616 1.0581 12.6616 0.837119C12.6616 0.616141 12.572 0.404141 12.4124 0.247421Z"
                            fill="#374557"
                            fill-opacity="0.6"
                          ></path></svg></span
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="row nftmax-gap-sq30">
          <template v-for="(item, i) in marketPlaceData">
            <div :key="i" class="col-lg-4 col-md-6 col-12">
              <!-- Marketplace Single Item -->
              <ProductCardStyleTwo :datas="item" />
              <!-- End Marketplace Item -->
            </div>
          </template>
        </div>
      </div>

      <div
        id="tab_2"
        class="tab-pane fade"
        :class="[activeTab === 'owned' ? 'show active' : '']"
        role="tabpanel"
        aria-labelledby="nav-home-tab"
      >
        <div class="row">
          <div class="col-12">
            <div class="nftmax-pptabs mg-btm-20">
              <div class="nftmax-pptabs__form">
                <form
                  class="nftmax-header__form-inner nftmax-header__form-profile"
                  action="#"
                >
                  <button class="search-btn" type="submit">
                    <img src="../../../assets/img/search.png" alt="#" />
                  </button>
                  <input
                    name="s"
                    value=""
                    type="text"
                    placeholder="Search items, collections..."
                  />
                </form>
              </div>
              <div class="nftmax-pptabs__main">
                <ul
                  id="nav-tab"
                  class="nav nav-tabs nftmax-dropdown__list"
                  role="tablist"
                >
                  <li class="nav-item dropdown">
                    <a
                      class="nftmax-sidebar_btn nftmax-heading__tabs nav-link dropdown-toggle"
                      >Recently Received
                      <span
                        ><svg
                          width="20"
                          height="10"
                          viewBox="0 0 13 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.7"
                            d="M12.4124 0.247421C12.3327 0.169022 12.2379 0.106794 12.1335 0.0643287C12.0291 0.0218632 11.917 0 11.8039 0C11.6908 0 11.5787 0.0218632 11.4743 0.0643287C11.3699 0.106794 11.2751 0.169022 11.1954 0.247421L7.27012 4.07837C7.19045 4.15677 7.09566 4.219 6.99122 4.26146C6.88678 4.30393 6.77476 4.32579 6.66162 4.32579C6.54848 4.32579 6.43646 4.30393 6.33202 4.26146C6.22758 4.219 6.13279 4.15677 6.05312 4.07837L2.12785 0.247421C2.04818 0.169022 1.95338 0.106794 1.84895 0.0643287C1.74451 0.0218632 1.63249 0 1.51935 0C1.40621 0 1.29419 0.0218632 1.18975 0.0643287C1.08531 0.106794 0.990517 0.169022 0.910844 0.247421C0.751218 0.404141 0.661621 0.616141 0.661621 0.837119C0.661621 1.0581 0.751218 1.2701 0.910844 1.42682L4.84468 5.26613C5.32677 5.73605 5.98027 6 6.66162 6C7.34297 6 7.99647 5.73605 8.47856 5.26613L12.4124 1.42682C12.572 1.2701 12.6616 1.0581 12.6616 0.837119C12.6616 0.616141 12.572 0.404141 12.4124 0.247421Z"
                            fill="#374557"
                            fill-opacity="0.6"
                          ></path></svg></span
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row nftmax-gap-sq30 trending-action__actives">
          <template v-for="(item, i) in products">
            <div :key="i" class="col-xxl-3 col-lg-3 col-md-6 col-12">
              <!-- Treadning Single -->
              <ProductCardStyleOne :datas="item" />
              <!-- End Treadning Single -->
            </div>
          </template>
        </div>
      </div>

      <div
        id="tab_3"
        class="tab-pane fade"
        :class="[activeTab === 'created' ? 'show active' : '']"
        role="tabpanel"
        aria-labelledby="nav-home-tab"
      >
        <div class="row">
          <div class="col-12">
            <div class="nftmax-pptabs mg-btm-20">
              <div class="nftmax-pptabs__form">
                <form
                  class="nftmax-header__form-inner nftmax-header__form-profile"
                  action="#"
                >
                  <button class="search-btn" type="submit">
                    <img src="../../../assets/img/search.png" alt="#" />
                  </button>
                  <input
                    name="s"
                    value=""
                    type="text"
                    placeholder="Search items, collections..."
                  />
                </form>
              </div>
              <div class="nftmax-pptabs__main">
                <ul
                  id="nav-tab"
                  class="nav nav-tabs nftmax-dropdown__list"
                  role="tablist"
                >
                  <li class="nav-item dropdown">
                    <a
                      class="nftmax-sidebar_btn nftmax-heading__tabs nav-link dropdown-toggle"
                      >Recently Received
                      <span
                        ><svg
                          width="20"
                          height="10"
                          viewBox="0 0 13 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.7"
                            d="M12.4124 0.247421C12.3327 0.169022 12.2379 0.106794 12.1335 0.0643287C12.0291 0.0218632 11.917 0 11.8039 0C11.6908 0 11.5787 0.0218632 11.4743 0.0643287C11.3699 0.106794 11.2751 0.169022 11.1954 0.247421L7.27012 4.07837C7.19045 4.15677 7.09566 4.219 6.99122 4.26146C6.88678 4.30393 6.77476 4.32579 6.66162 4.32579C6.54848 4.32579 6.43646 4.30393 6.33202 4.26146C6.22758 4.219 6.13279 4.15677 6.05312 4.07837L2.12785 0.247421C2.04818 0.169022 1.95338 0.106794 1.84895 0.0643287C1.74451 0.0218632 1.63249 0 1.51935 0C1.40621 0 1.29419 0.0218632 1.18975 0.0643287C1.08531 0.106794 0.990517 0.169022 0.910844 0.247421C0.751218 0.404141 0.661621 0.616141 0.661621 0.837119C0.661621 1.0581 0.751218 1.2701 0.910844 1.42682L4.84468 5.26613C5.32677 5.73605 5.98027 6 6.66162 6C7.34297 6 7.99647 5.73605 8.47856 5.26613L12.4124 1.42682C12.572 1.2701 12.6616 1.0581 12.6616 0.837119C12.6616 0.616141 12.572 0.404141 12.4124 0.247421Z"
                            fill="#374557"
                            fill-opacity="0.6"
                          ></path></svg></span
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="nftmax-inner__heading nftmax-pp__title mt-0">
          <h2 class="nftmax-inner__page-title">Create for Sell</h2>
        </div>
        <div class="row nftmax-gap-sq30 trending-action__actives">
          <template v-for="(item, i) in marketPlaceData.slice(0, 3)">
            <div :key="i" class="col-lg-4 col-md-6 col-12">
              <!-- Marketplace Single Item -->
              <ProductCardStyleTwo :datas="item" />
              <!-- End Marketplace Item -->
            </div>
          </template>
        </div>

        <div class="nftmax-inner__heading nftmax-pp__title">
          <h2 class="nftmax-inner__page-title">Create for Bits</h2>
        </div>

        <div class="row nftmax-gap-sq30 trending-action__actives">
          <template v-for="(item, i) in products.slice(0, 4)">
            <div :key="i" class="col-xxl-3 col-lg-3 col-md-6 col-12">
              <!-- Treadning Single -->
              <ProductCardStyleOne :datas="item" />
              <!-- End Treadning Single -->
            </div>
          </template>
        </div>
      </div>

      <div
        id="tab_4"
        class="tab-pane fade"
        :class="[activeTab === 'hidden' ? 'show active' : '']"
        role="tabpanel"
        aria-labelledby="nav-home-tab"
      >
        <div class="row">
          <div class="col-12">
            <div class="nftmax-pptabs mg-btm-20">
              <div class="nftmax-pptabs__form">
                <form
                  class="nftmax-header__form-inner nftmax-header__form-profile"
                  action="#"
                >
                  <button class="search-btn" type="submit">
                    <img src="../../../assets/img/search.png" alt="#" />
                  </button>
                  <input
                    name="s"
                    value=""
                    type="text"
                    placeholder="Search items, collections..."
                  />
                </form>
              </div>
              <div class="nftmax-pptabs__main">
                <ul
                  id="nav-tab"
                  class="nav nav-tabs nftmax-dropdown__list"
                  role="tablist"
                >
                  <li class="nav-item dropdown">
                    <a
                      class="nftmax-sidebar_btn nftmax-heading__tabs nav-link dropdown-toggle"
                      >Recently Received
                      <span
                        ><svg
                          width="20"
                          height="10"
                          viewBox="0 0 13 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.7"
                            d="M12.4124 0.247421C12.3327 0.169022 12.2379 0.106794 12.1335 0.0643287C12.0291 0.0218632 11.917 0 11.8039 0C11.6908 0 11.5787 0.0218632 11.4743 0.0643287C11.3699 0.106794 11.2751 0.169022 11.1954 0.247421L7.27012 4.07837C7.19045 4.15677 7.09566 4.219 6.99122 4.26146C6.88678 4.30393 6.77476 4.32579 6.66162 4.32579C6.54848 4.32579 6.43646 4.30393 6.33202 4.26146C6.22758 4.219 6.13279 4.15677 6.05312 4.07837L2.12785 0.247421C2.04818 0.169022 1.95338 0.106794 1.84895 0.0643287C1.74451 0.0218632 1.63249 0 1.51935 0C1.40621 0 1.29419 0.0218632 1.18975 0.0643287C1.08531 0.106794 0.990517 0.169022 0.910844 0.247421C0.751218 0.404141 0.661621 0.616141 0.661621 0.837119C0.661621 1.0581 0.751218 1.2701 0.910844 1.42682L4.84468 5.26613C5.32677 5.73605 5.98027 6 6.66162 6C7.34297 6 7.99647 5.73605 8.47856 5.26613L12.4124 1.42682C12.572 1.2701 12.6616 1.0581 12.6616 0.837119C12.6616 0.616141 12.572 0.404141 12.4124 0.247421Z"
                            fill="#374557"
                            fill-opacity="0.6"
                          ></path></svg></span
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="nftmax-inner__heading nftmax-pp__title mt-0">
          <h2 class="nftmax-inner__page-title">Create for Sell</h2>
        </div>
        <div class="row nftmax-gap-sq30 trending-action__actives">
          <div class="col-lg-4 col-md-6 col-12">
            <!-- Marketplace Single Item -->
            <div class="trending-action__single nftmax-pd-20">
              <div class="nftmax-trendmeta">
                <div class="nftmax-trendmeta__main">
                  <div class="nftmax-trendmeta__author">
                    <div class="nftmax-trendmeta__img">
                      <img
                        src="../../../assets/img/market-author-1.png"
                        alt="#"
                      />
                    </div>
                    <div class="nftmax-trendmeta__content">
                      <span class="nftmax-trendmeta__small">Owned by</span>
                      <h4 class="nftmax-trendmeta__title">Rrayak John</h4>
                    </div>
                  </div>
                  <div class="nftmax-trendmeta__author">
                    <div class="nftmax-trendmeta__content">
                      <span class="nftmax-trendmeta__small">Create by</span>
                      <h4 class="nftmax-trendmeta__title">Yuaisn Kha</h4>
                    </div>
                    <div class="nftmax-trendmeta__img">
                      <img
                        src="../../../assets/img/market-author-2.png"
                        alt="#"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- Trending Head -->
              <div class="trending-action__head">
                <div class="trending-action__badge"><span>Active</span></div>
                <div class="trending-action__button v2">
                  <a class="trending-action__btn heart-icon"
                    ><i class="fa-solid fa-heart"></i
                  ></a>
                  <a class="trending-action__btn"
                    ><i class="fa-solid fa-ellipsis-vertical"></i
                  ></a>
                </div>
                <img src="../../../assets/img/marketplace-1.png" alt="#" />
                <div class="trending-action__remove">
                  <i class="fa-solid fa-eye-slash"></i>
                </div>
              </div>
              <!-- Trending Body -->
              <div class="trending-action__body trending-marketplace__body">
                <h2 class="trending-action__title">
                  <a href="#">Interconnected Planes</a>
                </h2>
                <div class="nftmax-currency">
                  <div class="nftmax-currency__main">
                    <div class="nftmax-currency__icon">
                      <img src="../../../assets/img/eth-icon.png" alt="#" />
                    </div>
                    <div class="nftmax-currency__content">
                      <h4 class="nftmax-currency__content-title">75,320 ETH</h4>
                      <p class="nftmax-currency__content-sub">(773.69 USD)</p>
                    </div>
                  </div>
                  <a href="#" class="nftmax-btn nftmax-btn__secondary radius"
                    >On Sale</a
                  >
                </div>
              </div>
            </div>
            <!-- End Marketplace Item -->
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <!-- Marketplace Single Item -->
            <div class="trending-action__single nftmax-pd-20">
              <div class="nftmax-trendmeta">
                <div class="nftmax-trendmeta__main">
                  <div class="nftmax-trendmeta__author">
                    <div class="nftmax-trendmeta__img">
                      <img
                        src="../../../assets/img/market-author-1.png"
                        alt="#"
                      />
                    </div>
                    <div class="nftmax-trendmeta__content">
                      <span class="nftmax-trendmeta__small">Owned by</span>
                      <h4 class="nftmax-trendmeta__title">Rrayak John</h4>
                    </div>
                  </div>
                  <div class="nftmax-trendmeta__author">
                    <div class="nftmax-trendmeta__content">
                      <span class="nftmax-trendmeta__small">Create by</span>
                      <h4 class="nftmax-trendmeta__title">Yuaisn Kha</h4>
                    </div>
                    <div class="nftmax-trendmeta__img">
                      <img
                        src="../../../assets/img/market-author-2.png"
                        alt="#"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- Trending Head -->
              <div class="trending-action__head">
                <div class="trending-action__badge"><span>Active</span></div>
                <div class="trending-action__button v2">
                  <a class="trending-action__btn heart-icon"
                    ><i class="fa-solid fa-heart"></i
                  ></a>
                  <a class="trending-action__btn"
                    ><i class="fa-solid fa-ellipsis-vertical"></i
                  ></a>
                </div>
                <img src="../../../assets/img/marketplace-2.png" alt="#" />
                <div class="trending-action__remove">
                  <i class="fa-solid fa-eye-slash"></i>
                </div>
              </div>
              <!-- Trending Body -->
              <div class="trending-action__body trending-marketplace__body">
                <h2 class="trending-action__title">
                  <a href="#">Interconnected Planes</a>
                </h2>
                <div class="nftmax-currency">
                  <div class="nftmax-currency__main">
                    <div class="nftmax-currency__icon">
                      <img src="../../../assets/img/eth-icon.png" alt="#" />
                    </div>
                    <div class="nftmax-currency__content">
                      <h4 class="nftmax-currency__content-title">75,320 ETH</h4>
                      <p class="nftmax-currency__content-sub">(773.69 USD)</p>
                    </div>
                  </div>
                  <a href="#" class="nftmax-btn nftmax-btn__secondary radius"
                    >On Sale</a
                  >
                </div>
              </div>
            </div>
            <!-- End Marketplace Item -->
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <!-- Marketplace Single Item -->
            <div class="trending-action__single nftmax-pd-20">
              <div class="nftmax-trendmeta">
                <div class="nftmax-trendmeta__main">
                  <div class="nftmax-trendmeta__author">
                    <div class="nftmax-trendmeta__img">
                      <img
                        src="../../../assets/img/market-author-1.png"
                        alt="#"
                      />
                    </div>
                    <div class="nftmax-trendmeta__content">
                      <span class="nftmax-trendmeta__small">Owned by</span>
                      <h4 class="nftmax-trendmeta__title">Rrayak John</h4>
                    </div>
                  </div>
                  <div class="nftmax-trendmeta__author">
                    <div class="nftmax-trendmeta__content">
                      <span class="nftmax-trendmeta__small">Create by</span>
                      <h4 class="nftmax-trendmeta__title">Yuaisn Kha</h4>
                    </div>
                    <div class="nftmax-trendmeta__img">
                      <img
                        src="../../../assets/img/market-author-2.png"
                        alt="#"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- Trending Head -->
              <div class="trending-action__head">
                <div class="trending-action__badge"><span>Active</span></div>
                <div class="trending-action__button v2">
                  <a class="trending-action__btn heart-icon"
                    ><i class="fa-solid fa-heart"></i
                  ></a>
                  <a class="trending-action__btn"
                    ><i class="fa-solid fa-ellipsis-vertical"></i
                  ></a>
                </div>
                <img src="../../../assets/img/marketplace-3.png" alt="#" />
                <div class="trending-action__remove">
                  <i class="fa-solid fa-eye-slash"></i>
                </div>
              </div>
              <!-- Trending Body -->
              <div class="trending-action__body trending-marketplace__body">
                <h2 class="trending-action__title">
                  <a href="#">Interconnected Planes</a>
                </h2>
                <div class="nftmax-currency">
                  <div class="nftmax-currency__main">
                    <div class="nftmax-currency__icon">
                      <img src="../../../assets/img/eth-icon.png" alt="#" />
                    </div>
                    <div class="nftmax-currency__content">
                      <h4 class="nftmax-currency__content-title">75,320 ETH</h4>
                      <p class="nftmax-currency__content-sub">(773.69 USD)</p>
                    </div>
                  </div>
                  <a href="#" class="nftmax-btn nftmax-btn__secondary radius"
                    >On Sale</a
                  >
                </div>
              </div>
            </div>
            <!-- End Marketplace Item -->
          </div>
        </div>

        <div class="nftmax-inner__heading nftmax-pp__title">
          <h2 class="nftmax-inner__page-title">Create for Bits</h2>
        </div>

        <div class="row nftmax-gap-30 trending-action__actives">
          <div class="col-xxl-3 col-lg-3 col-md-6 col-12">
            <!-- Treadning Single -->
            <div class="trending-action__single nftmax-pd-20">
              <!-- Trending Head -->
              <div class="trending-action__head">
                <div class="trending-action__button">
                  <a class="trending-action__btn heart-icon"
                    ><i class="fa-solid fa-heart"></i
                  ></a>
                  <a class="trending-action__btn"
                    ><i class="fa-solid fa-ellipsis-vertical"></i
                  ></a>
                </div>
                <img src="../../../assets/img/trending-img-1.png" alt="#" />
                <div class="trending-action__remove">
                  <i class="fa-solid fa-eye-slash"></i>
                </div>
              </div>
              <!-- Trending Body -->
              <div class="trending-action__body">
                <div class="trending-action__author-meta">
                  <div class="trending-action__author-img">
                    <img src="../../../assets/img/author-pic.png" alt="#" />
                  </div>
                  <p class="trending-action__author-name">
                    Owned by <router-link to="/profile">Bilout</router-link>
                  </p>
                </div>
                <h2 class="trending-action__title">
                  <router-link to="active-bids"
                    >Interconnected Planes</router-link
                  >
                </h2>
                <div class="dashboard-banner__bid dashboard-banner__bid-v2">
                  <!-- Single Bid -->
                  <div
                    class="dashboard-banner__group dashboard-banner__group-v2"
                  >
                    <p class="dashboard-banner__group-small">Current Bid</p>
                    <h3 class="dashboard-banner__group-title">75,320 ETH</h3>
                  </div>
                  <!-- End Single Bid -->
                  <div class="dashboard-banner__middle-border"></div>
                  <!-- Single Bid -->
                  <div
                    class="dashboard-banner__group dashboard-banner__group-v2"
                  >
                    <p class="dashboard-banner__group-small">Remaing Time</p>
                    <h3
                      id="CountDown"
                      class="dashboard-banner__group-title"
                      data-countdown="2023/09/01"
                    ></h3>
                  </div>
                  <!-- End Single Bid -->
                </div>
              </div>
              <div class="dashboard-banner__button trending-action__bottom">
                <router-link
                  to="/active-bids"
                  class="nftmax-btn nftmax-btn__secondary radius"
                  >Place a Bid</router-link
                >
                <router-link to="/marketplace" class="nftmax-btn trs-white"
                  >View Art Work</router-link
                >
              </div>
            </div>
            <!-- End Treadning Single -->
          </div>
          <div class="col-xxl-3 col-lg-3 col-md-6 col-12">
            <!-- Treadning Single -->
            <div class="trending-action__single nftmax-pd-20">
              <!-- Trending Head -->
              <div class="trending-action__head">
                <div class="trending-action__button">
                  <a class="trending-action__btn heart-icon"
                    ><i class="fa-solid fa-heart"></i
                  ></a>
                  <a class="trending-action__btn"
                    ><i class="fa-solid fa-ellipsis-vertical"></i
                  ></a>
                </div>
                <img src="../../../assets/img/trending-img-2.png" alt="#" />
                <div class="trending-action__remove">
                  <i class="fa-solid fa-eye-slash"></i>
                </div>
              </div>
              <!-- Trending Body -->
              <div class="trending-action__body">
                <div class="trending-action__author-meta">
                  <div class="trending-action__author-img">
                    <img src="../../../assets/img/author-pic.png" alt="#" />
                  </div>
                  <p class="trending-action__author-name">
                    Owned by <router-link to="/profile">Bilout</router-link>
                  </p>
                </div>
                <h2 class="trending-action__title">
                  <router-link to="active-bids"
                    >Interconnected Planes</router-link
                  >
                </h2>
                <div class="dashboard-banner__bid dashboard-banner__bid-v2">
                  <!-- Single Bid -->
                  <div
                    class="dashboard-banner__group dashboard-banner__group-v2"
                  >
                    <p class="dashboard-banner__group-small">Current Bid</p>
                    <h3 class="dashboard-banner__group-title">75,320 ETH</h3>
                  </div>
                  <!-- End Single Bid -->
                  <div class="dashboard-banner__middle-border"></div>
                  <!-- Single Bid -->
                  <div
                    class="dashboard-banner__group dashboard-banner__group-v2"
                  >
                    <p class="dashboard-banner__group-small">Remaing Time</p>
                    <h3
                      id="CountDown"
                      class="dashboard-banner__group-title"
                      data-countdown="2023/09/01"
                    ></h3>
                  </div>
                  <!-- End Single Bid -->
                </div>
              </div>
              <div class="dashboard-banner__button trending-action__bottom">
                <router-link
                  to="/active-bids"
                  class="nftmax-btn nftmax-btn__secondary radius"
                  >Place a Bid</router-link
                >
                <router-link to="/marketplace" class="nftmax-btn trs-white"
                  >View Art Work</router-link
                >
              </div>
            </div>
            <!-- End Treadning Single -->
          </div>
          <div class="col-xxl-3 col-lg-3 col-md-6 col-12">
            <!-- Treadning Single -->
            <div class="trending-action__single nftmax-pd-20">
              <!-- Trending Head -->
              <div class="trending-action__head">
                <div class="trending-action__button">
                  <a class="trending-action__btn heart-icon"
                    ><i class="fa-solid fa-heart"></i
                  ></a>
                  <a class="trending-action__btn"
                    ><i class="fa-solid fa-ellipsis-vertical"></i
                  ></a>
                </div>
                <img src="../../../assets/img/trending-img-3.png" alt="#" />
                <div class="trending-action__remove">
                  <i class="fa-solid fa-eye-slash"></i>
                </div>
              </div>
              <!-- Trending Body -->
              <div class="trending-action__body">
                <div class="trending-action__author-meta">
                  <div class="trending-action__author-img">
                    <img src="../../../assets/img/author-pic.png" alt="#" />
                  </div>
                  <p class="trending-action__author-name">
                    Owned by <router-link to="/profile">Bilout</router-link>
                  </p>
                </div>
                <h2 class="trending-action__title">
                  <router-link to="active-bids"
                    >Interconnected Planes</router-link
                  >
                </h2>
                <div class="dashboard-banner__bid dashboard-banner__bid-v2">
                  <!-- Single Bid -->
                  <div
                    class="dashboard-banner__group dashboard-banner__group-v2"
                  >
                    <p class="dashboard-banner__group-small">Current Bid</p>
                    <h3 class="dashboard-banner__group-title">75,320 ETH</h3>
                  </div>
                  <!-- End Single Bid -->
                  <div class="dashboard-banner__middle-border"></div>
                  <!-- Single Bid -->
                  <div
                    class="dashboard-banner__group dashboard-banner__group-v2"
                  >
                    <p class="dashboard-banner__group-small">Remaing Time</p>
                    <h3
                      id="CountDown"
                      class="dashboard-banner__group-title"
                      data-countdown="2023/09/01"
                    ></h3>
                  </div>
                  <!-- End Single Bid -->
                </div>
              </div>
              <div class="dashboard-banner__button trending-action__bottom">
                <router-link
                  to="/active-bids"
                  class="nftmax-btn nftmax-btn__secondary radius"
                  >Place a Bid</router-link
                >
                <router-link to="/marketplace" class="nftmax-btn trs-white"
                  >View Art Work</router-link
                >
              </div>
            </div>
            <!-- End Treadning Single -->
          </div>
          <div class="col-xxl-3 col-lg-3 col-md-6 col-12">
            <!-- Treadning Single -->
            <div class="trending-action__single nftmax-pd-20">
              <!-- Trending Head -->
              <div class="trending-action__head">
                <div class="trending-action__button">
                  <a class="trending-action__btn heart-icon"
                    ><i class="fa-solid fa-heart"></i
                  ></a>
                  <a class="trending-action__btn"
                    ><i class="fa-solid fa-ellipsis-vertical"></i
                  ></a>
                </div>
                <img src="../../../assets/img/trending-img-4.png" alt="#" />
                <div class="trending-action__remove">
                  <i class="fa-solid fa-eye-slash"></i>
                </div>
              </div>
              <!-- Trending Body -->
              <div class="trending-action__body">
                <div class="trending-action__author-meta">
                  <div class="trending-action__author-img">
                    <img src="../../../assets/img/author-pic.png" alt="#" />
                  </div>
                  <p class="trending-action__author-name">
                    Owned by <router-link to="/profile">Bilout</router-link>
                  </p>
                </div>
                <h2 class="trending-action__title">
                  <router-link to="active-bids"
                    >Interconnected Planes</router-link
                  >
                </h2>
                <div class="dashboard-banner__bid dashboard-banner__bid-v2">
                  <!-- Single Bid -->
                  <div
                    class="dashboard-banner__group dashboard-banner__group-v2"
                  >
                    <p class="dashboard-banner__group-small">Current Bid</p>
                    <h3 class="dashboard-banner__group-title">75,320 ETH</h3>
                  </div>
                  <!-- End Single Bid -->
                  <div class="dashboard-banner__middle-border"></div>
                  <!-- Single Bid -->
                  <div
                    class="dashboard-banner__group dashboard-banner__group-v2"
                  >
                    <p class="dashboard-banner__group-small">Remaing Time</p>
                    <h3
                      id="CountDown"
                      class="dashboard-banner__group-title"
                      data-countdown="2023/09/01"
                    ></h3>
                  </div>
                  <!-- End Single Bid -->
                </div>
              </div>
              <div class="dashboard-banner__button trending-action__bottom">
                <router-link
                  to="/active-bids"
                  class="nftmax-btn nftmax-btn__secondary radius"
                  >Place a Bid</router-link
                >
                <router-link to="/marketplace" class="nftmax-btn trs-white"
                  >View Art Work</router-link
                >
              </div>
            </div>
            <!-- End Treadning Single -->
          </div>
        </div>
      </div>

      <div
        id="tab_5"
        class="tab-pane fade"
        :class="[activeTab === 'collection' ? 'show active' : '']"
        role="tabpanel"
        aria-labelledby="nav-home-tab"
      >
        <div class="row">
          <div class="col-12">
            <div class="nftmax-pptabs mg-btm-20">
              <div class="nftmax-pptabs__form">
                <form
                  class="nftmax-header__form-inner nftmax-header__form-profile"
                  action="#"
                >
                  <button class="search-btn" type="submit">
                    <img src="../../../assets/img/search.png" alt="#" />
                  </button>
                  <input
                    name="s"
                    value=""
                    type="text"
                    placeholder="Search items, collections..."
                  />
                </form>
              </div>
              <div class="nftmax-pptabs__main">
                <ul
                  id="nav-tab"
                  class="nav nav-tabs nftmax-dropdown__list"
                  role="tablist"
                >
                  <li class="nav-item dropdown">
                    <a
                      class="nftmax-sidebar_btn nftmax-heading__tabs nav-link dropdown-toggle"
                      >Recently Received
                      <span
                        ><svg
                          width="20"
                          height="10"
                          viewBox="0 0 13 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.7"
                            d="M12.4124 0.247421C12.3327 0.169022 12.2379 0.106794 12.1335 0.0643287C12.0291 0.0218632 11.917 0 11.8039 0C11.6908 0 11.5787 0.0218632 11.4743 0.0643287C11.3699 0.106794 11.2751 0.169022 11.1954 0.247421L7.27012 4.07837C7.19045 4.15677 7.09566 4.219 6.99122 4.26146C6.88678 4.30393 6.77476 4.32579 6.66162 4.32579C6.54848 4.32579 6.43646 4.30393 6.33202 4.26146C6.22758 4.219 6.13279 4.15677 6.05312 4.07837L2.12785 0.247421C2.04818 0.169022 1.95338 0.106794 1.84895 0.0643287C1.74451 0.0218632 1.63249 0 1.51935 0C1.40621 0 1.29419 0.0218632 1.18975 0.0643287C1.08531 0.106794 0.990517 0.169022 0.910844 0.247421C0.751218 0.404141 0.661621 0.616141 0.661621 0.837119C0.661621 1.0581 0.751218 1.2701 0.910844 1.42682L4.84468 5.26613C5.32677 5.73605 5.98027 6 6.66162 6C7.34297 6 7.99647 5.73605 8.47856 5.26613L12.4124 1.42682C12.572 1.2701 12.6616 1.0581 12.6616 0.837119C12.6616 0.616141 12.572 0.404141 12.4124 0.247421Z"
                            fill="#374557"
                            fill-opacity="0.6"
                          ></path></svg></span
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row nftmax-gap-sq30">
          <template v-for="(item, i) in collections">
            <div :key="i" class="col-lg-4 col-md-6 col-12">
              <CollectionCard :datas="item" />
            </div>
          </template>
        </div>
      </div>

      <div
        id="tab_6"
        class="tab-pane fade"
        :class="[activeTab === 'activity' ? 'show active' : '']"
        role="tabpanel"
        aria-labelledby="nav-home-tab"
      >
        <div class="row">
          <div class="col-12">
            <div class="nftmax-pptabs mg-btm-20">
              <div class="nftmax-pptabs__form">
                <form
                  class="nftmax-header__form-inner nftmax-header__form-profile"
                  action="#"
                >
                  <button class="search-btn" type="submit">
                    <img src="../../../assets/img/search.png" alt="#" />
                  </button>
                  <input
                    name="s"
                    value=""
                    type="text"
                    placeholder="Search items, collections..."
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
        <ProductHistoryTable />
      </div>
    </div>
  </div>
</template>

<script>
import ProductCardStyleTwo from "@/components/Helper/Cards/ProductCardStyleTwo";
import MarketPlaceData from "../../../data/marketplace_data.json";
import ProductData from "../../../data/product_data.json";
import CollectionData from "../../../data/Collectionplan_data.json";
import ProductCardStyleOne from "@/components/Helper/Cards/ProductCardStyleOne";
import CollectionCard from "@/components/Helper/Cards/CollectionCard";
import ProductHistoryTable from "@/components/Auth/Profile/ProductHistoryTable";
export default {
  components: {
    ProductHistoryTable,
    CollectionCard,
    ProductCardStyleOne,
    ProductCardStyleTwo,
  },
  data() {
    return {
      marketPlaceData: MarketPlaceData.data,
      activeTab: "sale",
      products: ProductData.datas,
      collections: CollectionData.data,
    };
  },
  methods: {
    tabHandler(value) {
      this.activeTab = value;
    },
  },
};
</script>

<style scoped></style>
