<template>
  <div>
    <study-report-timeline>
      <template #number>02</template>
      <template #title>Result</template>
      <div class="content-item">
        <div class="item-label">Image Segmentation Result</div>
        <div class="item-value">
          <el-table
            :data="imgTable"
            :row-style="hideTableRow"
            style="width: 100%"
          >
            <!-- <el-table-column label="* Images">
              <template slot-scope="scope">
                <div class="img-box">
                  <el-image
                    :src="scope.row.originalImg.src"
                    :preview-src-list="imgPreviewSrc"
                    @click="previewImg(scope.row.originalImg.src)"
                  >
                  </el-image>
                </div>
              </template>
            </el-table-column> -->
            <el-table-column label="Segmentation Result">
              <template slot-scope="scope">
                <div class="img-box">
                  <img
                    :src="scope.row.predictionImg.src"
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="pagination"
            :current-page="1"
            :page-size="5"
            layout="total, prev, pager, next, jumper"
            :total="imgTable.length"
            @current-change="tableChange"
          >
          </el-pagination>
          <div class="hint">
            * Images have been enhanced for clearer viewing
          </div>
          <div class="hint">
            ** If no specific areas are identified, the original and segmented
            images remain identical. Images of lower quality might be filtered
            out
          </div>
        </div>
      </div>
    </study-report-timeline>
  </div>
</template>

<script>
import studyReportTimeline from "../studyReport/study-report-timeline";
import JSZip from "jszip";
import api from "../../http/api";

export default {
  components: {
    studyReportTimeline,
  },
  props: ["studyInfo", "result"],
  data() {
    return {
      imgTable: [],
      imgPreviewSrc: [],
      tablePageNum: 1,
    };
  },
  async mounted() {
    const res = await api.getImage({
      filename: this.result.filename,
      type: this.result.type,
    });
    let promiseArray = [];
    let mappingJson = null;
    let imgList = [];
    // 处理压缩图片包
    const zipData = res.data;
    const zip = new JSZip();
    zip.loadAsync(zipData).then(async (zip) => {
      console.log(zip);
      zip.forEach((relativePath, file) => {
        if (file.name.includes("weight.json")) {
          let p = new Promise((resolve) => {
            file.async("base64").then((base64Data) => {
              const jsonString = atob(base64Data);
              const jsonData = JSON.parse(jsonString);
              resolve({
                type: "json",
                data: jsonData,
              });
            });
          });
          promiseArray.push(p);
        } else {
          let p = new Promise((resolve) => {
            file.async("base64").then((base64Data) => {
              const imageElement = new Image();
              imageElement.src = `data:image/jpeg;base64,${base64Data}`;
              resolve({
                type: "image",
                data: {
                  name: file.name,
                  src: imageElement.src,
                },
              });
            });
          });
          promiseArray.push(p);
        }
      });
      const resultList = await Promise.all(promiseArray);
      for (const result of resultList) {
        if (result.type === "json") {
          mappingJson = result.data;
        } else {
          imgList.push(result.data);
        }
      }
      console.log(mappingJson.weights);
      for (const imgName of mappingJson.weights) {
        const predictionImg = imgList.find((item) => item.name === imgName);
        this.imgTable.push({
          predictionImg,
        });
      }
      console.log(this.imgTable);
    });
  },
  methods: {
    previewImg(src) {
      this.imgPreviewSrc = [src];
    },
    tableChange(val) {
      this.tablePageNum = val;
    },
    // eslint-disable-next-line no-unused-vars
    hideTableRow({ row, rowIndex }) {
      let array = this.imgTable;
      let pageNo = this.tablePageNum;
      const pageSize = 5;
      const offset = (pageNo - 1) * pageSize;
      const isInPage =
        rowIndex >= offset &&
        rowIndex < Math.min(array.length, offset + pageSize);
      return isInPage ? {} : { display: "none" };
    },
  },
};
</script>
<style scoped lang="less">
.study-report-container {
  .content-item {
    display: flex;
    font-size: 16px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &.no-metrics {
      color: #999;
    }
    .item-label {
      flex-shrink: 0;
      margin-right: 40px;
      width: 220px;
      font-weight: 500;
      color: #999;
    }
    .item-value {
      flex: 1;
      color: #333;
    }
    .value-list-item {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
  .sub-title {
    margin-bottom: 24px;
    font-size: 18px;
    font-weight: 500;
    color: "#34E393";
  }
  .result-chart {
    width: 600px;
    height: 600px;
    margin: 50px auto;
  }
  .table-title {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
    color: #333;
    text-align: center;
  }
  .result-table {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .result-tag {
    margin: 5px;
  }
  .result-pagination {
    margin: 30px 0;
    text-align: center;

    &.no-bottom {
      margin-bottom: 0;
    }
  }
  .divide-line {
    height: 3px;
    width: 100%;
    margin: 50px 0;
    background-color: "#34E393";
  }

  @media print {
    .not-print {
      display: none;
    }
    // .page-break {
    //     page-break-before: always;
    // }
    .content-item {
      .item-value {
        color: #333;
        word-break: break-all;
      }
    }
  }
  .img-box {
    width: 400px;
    height: 400px;
    img {
      width: 400px;
      height: 400px;
    }
  }
  .pagination {
    margin: 20px 0 20px;
  }
  .hint {
    font-size: 14px;
    color: #999;
  }
}
</style>
