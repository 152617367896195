<template>
  <div class="charts-main charts-home-one mg-top-40">
    <div class="charts-main__heading">
      <h4 class="charts-main__title">Sell History</h4>
      <div class="charts-main__middle">
        <div class="charts-main__middle-single">
          <p class="charts-main__middle-text">Avg: Sell Price</p>
        </div>
        <div class="charts-main__middle-single">
          <p class="charts-main__middle-text nftmax-total__sales">Total Sell</p>
        </div>
      </div>

      <div class="nftmax-chart__dropdown">
        <span class="nftmax-current">Current Week</span>
      </div>
    </div>
    <div class="charts-main__one">
      <div id="nav-tabContent" class="tab-content">
        <div
          id="chart__sell"
          class=""
          role="tabpanel"
          aria-labelledby="chart__sell"
        >
          <div id="myChart_one" style="width: 100%">
            <SellHistoryAnalytic />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SellHistoryAnalytic from "@/components/Charts/SellHistoryAnalytic";
export default {
  components: { SellHistoryAnalytic },
};
</script>

<style scoped></style>
