<template>
  <el-dialog
    center
    v-if="resultDialogVisible"
    :visible.sync="resultDialogVisible"
    :destroy-on-close="true"
    top="5vh"
    width="1400px"
    class="study-report-dialog"
    :modal-append-to-body="false"
    :before-close="beforeClose"
  >
    <div id="study-report-container">
      <!-- 这个div不要和上面的合并，打印时样式会失效 -->
      <div class="study-report-container">
        <div class="result-header">
          <div class="result-title">
            {{ translate(studyInfo.methodName) }}
            Report
          </div>
        </div>

        <study-report-timeline :no-up="true">
          <template #number>01</template>
          <template #title>Research Summary</template>
          <div class="content-item">
            <div class="item-label">Research Name</div>
            <div class="item-value">{{ studyInfo.name }}</div>
          </div>
          <div class="content-item">
            <div class="item-label">Description</div>
            <div class="item-value">{{ studyInfo.description }}</div>
          </div>
          <div class="content-item">
            <div class="item-label">Create Time</div>
            <div class="item-value">{{ studyInfo.startDate }}</div>
          </div>
          <div class="content-item">
            <div class="item-label">Finish Time</div>
            <div class="item-value">{{ studyInfo.endDate }}</div>
          </div>
        </study-report-timeline>

        <component :is="resultType" ref="resultComponent" :result="result" />
      </div>
    </div>
  </el-dialog>
</template>

<script>
import studyReportTimeline from "./study-report-timeline";
import lRResult from "../studyResult/lrResult";
import imageClassificationResult from "../studyResult/imageClassificationResult.vue";
import imageSegmentationResult from "../studyResult/imageSegmentationResult.vue";

export default {
  components: {
    studyReportTimeline,
    lRResult,
    imageClassificationResult,
    imageSegmentationResult,
  },
  props: {
    resultDialogVisible: {
      type: Boolean,
      default: false,
    },
    studyInfo: {
      type: Object,
      default: () => {},
    },
    result: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    resultType() {
      const obj = {
        51: lRResult,
        52: imageClassificationResult,
        53: imageSegmentationResult,
      };
      return obj[this.studyInfo.analysisMethodId];
    },
  },
  methods: {
    translate(name) {
      const list = {
        Lavita_HomoLogisticRegression: "Logistic Regression",
        Lavita_ImageClassification: "Image Classification",
        Lavita_ImageSegmentation: "Image Segmentation",
      };
      return list[name];
    },
    beforeClose() {
      this.$emit("closeResultDialog");
    },
  },
};
</script>

<style lang="less" scoped>
.study-report-container {
  .content-item {
    display: flex;
    font-size: 16px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &.no-metrics {
      color: #999;
    }
    .item-label {
      flex-shrink: 0;
      margin-right: 40px;
      width: 220px;
      font-weight: 500;
      color: #999;
    }
    .item-value {
      flex: 1;
      color: #333;
    }
    .value-list-item {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
  .sub-title {
    margin-bottom: 24px;
    font-size: 18px;
    font-weight: 500;
    color: "#34E393";
  }
  .result-chart {
    width: 100%;
    height: 500px;
    margin: 50px auto;
  }
  .table-title {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
    color: #333;
    text-align: center;
  }
  .result-table {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .result-tag {
    margin: 5px;
  }
  .result-pagination {
    margin: 30px 0;
    text-align: center;

    &.no-bottom {
      margin-bottom: 0;
    }
  }
  .divide-line {
    height: 3px;
    width: 100%;
    margin: 50px 0;
    background-color: "#34E393";
  }

  @media print {
    .not-print {
      display: none;
    }
    // .page-break {
    //     page-break-before: always;
    // }
    .content-item {
      .item-value {
        color: #333;
        word-break: break-all;
      }
    }
  }
}
.study-report-dialog {
  /deep/ .el-dialog__header {
    padding: 0;
  }
  /deep/ .el-dialog__body {
    padding: 100px 120px;
  }
}
.result-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  padding-left: 78px;
  font-size: 32px;
  color: #333;

  .result-title {
    width: calc(~"100% - 100px");
  }
  .printer-wrap {
    display: flex;
    align-items: center;
  }
  .el-icon-upload,
  .el-icon-printer,
  .el-icon-download,
  .el-icon-loading {
    line-height: 1.5;
    color: #9a9a9a;
    cursor: pointer;
  }
  .el-icon-printer {
    margin-left: 15px;
  }
}
@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  @page {
    margin: 5mm 10mm 0;
  }
}
</style>
