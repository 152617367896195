<template>
  <div class="trending-action__single trending-action__single--v2">
    <div class="nftmax-trendmeta">
      <div class="nftmax-trendmeta__main">
        <div class="nftmax-trendmeta__author">
          <!-- <div class="nftmax-trendmeta__img">
            <img
              :src="require(`../../../assets/img/market-author-1.png`)"
              alt="#"
            />
          </div> -->
          <div class="nftmax-trendmeta__content">
            <span class="nftmax-trendmeta__small">Created</span>
            <h4 class="nftmax-trendmeta__title">
              {{ format(datas.createdAt) }}
            </h4>
          </div>
        </div>
        <div v-if="datas.institution" class="nftmax-trendmeta__content">
          <span class="nftmax-trendmeta__small">Institution</span>
          <h4 class="nftmax-trendmeta__title">
            {{ datas.institution }}
          </h4>
        </div>
        <!-- <div class="nftmax-trendmeta__author">
          <div class="nftmax-trendmeta__content">
            <span class="nftmax-trendmeta__small">Last Update</span>
            <h4 class="nftmax-trendmeta__title">
              {{ format(datas.updatedAt, "mm-dd-yyyy hh:MM:ss") }}
            </h4>
          </div>
        </div> -->
      </div>
    </div>
    <!-- Trending Head -->
    <div class="trending-action__head">
      <div v-if="datas.isActive" class="trending-action__badge">
        <span>Active</span>
      </div>
      <!-- <div :id="`lottie_box_${datas.id}`" class="lottie"></div> -->
      <div style="width: 170px; height: 100px; margin: 25px auto 0">
        <img
          v-if="datas.type === 0"
          :src="require(`@/assets/img/gene-pic.png`)"
          alt="#"
          style="width: 100%; height: 100%; object-fit: contain"
        />
        <img
          v-if="datas.type === 1"
          :src="require(`@/assets/img/ehr-pic.png`)"
          alt="#"
          style="width: 100%; height: 100%; object-fit: contain"
        />
        <img
          v-if="datas.type === 2"
          :src="require(`@/assets/img/medical-pic.png`)"
          alt="#"
          style="width: 100%; height: 100%; object-fit: contain"
        />
        <img
          v-if="datas.type === 3"
          :src="require(`@/assets/img/statistic-pic.png`)"
          alt="#"
          style="width: 100%; height: 100%; object-fit: contain"
        />
        <img
          v-if="datas.type === 4"
          :src="require(`@/assets/img/model-pic.png`)"
          alt="#"
          style="width: 100%; height: 100%; object-fit: contain"
        />
      </div>
    </div>
    <!-- Trending Body -->
    <div class="trending-action__body trending-marketplace__body">
      <!-- <h2 class="trending-action__title text-capitalize">
        <router-link to="/marketplace-details">{{ datas.id }}</router-link>
      </h2> -->
      <div class="nftmax-currency">
        <div class="nftmax-currency__main">
          <!-- <div class="nftmax-currency__icon">
            <img src="../../../assets/img/eth-icon.png" alt="#" />
          </div> -->
          <div class="nftmax-currency__content" style="width: 100%">
            <h4 class="nftmax-currency__content-title">
              {{ datas.subType }}
              <!-- {{
                ifShowDetails
                  ? `${$store.state.userInfo.username}-${translateType(
                      datas.type
                    )}-${datas.id}`
                  : `${translateType(datas.type)}-${datas.id}`
              }} -->
            </h4>
            <el-tag size="mini" style="font-size: 14px">{{
              translateType(datas.type)
            }}</el-tag>
            <p
              class="nftmax-currency__content-sub"
              style="display: flex; justify-content: space-between"
            >
              <el-link
                :underline="false"
                type="danger"
                style="margin-right: 10px"
                @click="removeData(datas)"
                >Forgot encryption phrase?
              </el-link>
              <el-button type="primary" size="small" @click="getDetail(datas)"
                >View Data</el-button
              >
            </p>
          </div>
          <!-- <div v-if="datas.type !== 4" class="nftmax-currency__content">
            <el-button type="primary" size="small" @click="getDetail(datas)"
              >Details</el-button
            >
          </div> -->
        </div>
      </div>
    </div>
    <el-dialog
      v-if="detailsDialog"
      title="Data Details"
      :visible.sync="detailsDialog"
      width="80%"
      center
    >
      <el-tabs tab-position="left">
        <el-tab-pane
          v-if="selectedData.basicInfo.type !== 1"
          label="Basic Info"
        >
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>Basic Info</span>
            </div>
            <el-descriptions
              border
              :column="2"
              label-class-name="table-label-title"
            >
              <el-descriptions-item label="File ID">{{
                selectedData.basicInfo.id
              }}</el-descriptions-item>
              <el-descriptions-item label="File Name">{{
                selectedData.basicInfo.filename
              }}</el-descriptions-item>
              <el-descriptions-item label="Description">
                <el-tag size="small">{{
                  selectedData.basicInfo.subType
                }}</el-tag></el-descriptions-item
              >
              <el-descriptions-item label="Created">
                <el-tag size="small">{{
                  format(selectedData.basicInfo.createdAt, "mm-dd-yyyy")
                }}</el-tag>
              </el-descriptions-item>
              <!-- <el-descriptions-item label="Last Update">
            <el-tag size="small">{{
              format(selectedData.basicInfo.updatedAt, "mm-dd-yyyy hh:MM:ss")
            }}</el-tag></el-descriptions-item
          > -->
            </el-descriptions>
          </el-card>
        </el-tab-pane>
        <el-tab-pane
          v-if="
            selectedData.basicInfo.type === 0 ||
            selectedData.basicInfo.type === 4
          "
          label="Txt File"
        >
          <el-descriptions title="Txt File" :column="1" :colon="false">
            <el-descriptions-item>
              <pre v-if="ifShowDetails">{{ selectedData.details }}</pre>
              <pre v-else>{{ mosaicText }}</pre>
            </el-descriptions-item>
          </el-descriptions>
        </el-tab-pane>
        <template v-if="selectedData.basicInfo.type === 1">
          <el-tab-pane
            v-for="tab in selectedData.details.tabTitleList"
            :key="tab.label"
          >
            <span slot="label">
              <div class="epic-data-tab">
                <i :class="tab.icon"></i> {{ tab.label }}
              </div>
            </span>
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>{{ tab.label }}</span>
              </div>
              <el-descriptions :column="2" border>
                <el-descriptions-item
                  v-for="item in selectedData.details[tab.prop]
                    .descriptionTitle"
                  :key="item.label"
                  :label="item.label"
                  >{{
                    selectedData.details[tab.prop].descriptionData[item.prop]
                  }}
                </el-descriptions-item>
              </el-descriptions>
              <br />
              <el-form
                v-if="selectedData.details[tab.prop].tableData.length"
                class="form"
              >
                <el-form-item>
                  <el-table
                    :data="selectedData.details[tab.prop].tableData"
                    cell-class-name="table-cell"
                    header-cell-class-name="epic-data-table-header"
                    class="epic-data-table"
                    stripe
                  >
                    <el-table-column
                      v-for="item in selectedData.details[
                        tab.prop
                      ].tableTitle.slice(0, 1)"
                      :key="item.label"
                      :label="item.label"
                      width="auto"
                      :min-width="300"
                      fixed
                    >
                      <template slot-scope="scope">
                        <div class="table-cell">
                          <template v-if="item.uiType === 'tag'">
                            <el-tag>{{ scope.row[item.prop] }}</el-tag>
                          </template>
                          <template v-else>{{ scope.row[item.prop] }}</template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      v-for="item in selectedData.details[
                        tab.prop
                      ].tableTitle.slice(
                        1,
                        selectedData.details[tab.prop].tableTitle.length
                      )"
                      :key="item.label"
                      :label="item.label"
                      :prop="item.prop"
                      width="auto"
                      :min-width="300"
                    >
                      <template slot-scope="scope">
                        <div class="table-cell">
                          <template
                            v-if="item.uiType === 'tag' && scope.row[item.prop]"
                          >
                            <el-tag>{{ scope.row[item.prop] }}</el-tag>
                          </template>
                          <template v-else>{{ scope.row[item.prop] }}</template>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-form-item>
              </el-form>
              <br />
              <template v-if="ifShowDetails">
                <el-divider content-position="left">Details</el-divider>
                <json-viewer
                  :value="originalJson[tab.prop]"
                  :expand-depth="0"
                ></json-viewer>
              </template>
            </el-card>
          </el-tab-pane>
        </template>
        <el-tab-pane
          v-if="selectedData.basicInfo.type === 2"
          label="Medical Image"
        >
          <el-descriptions title="Medical Image" :column="1" :colon="false">
            <template v-if="ifShowDetails">
              <el-descriptions-item v-if="ifZipFile">
                <div class="zip-img-container">
                  <el-image
                    v-for="url in zipFilesUrlList"
                    :key="url.name"
                    :src="url.src"
                    alt="Medical Image"
                    class="zip-img"
                    lazy
                  ></el-image>
                </div>
              </el-descriptions-item>
              <el-descriptions-item v-else>
                <img
                  :src="selectedData.details"
                  alt="Medical Image"
                  class="img"
                />
              </el-descriptions-item>
            </template>
            <template v-else>
              <el-descriptions-item>
                {{ mosaicText }}
              </el-descriptions-item>
            </template>
          </el-descriptions>
        </el-tab-pane>
        <el-tab-pane
          v-if="selectedData.basicInfo.type === 3"
          label="Medical Statistics"
        >
          <el-descriptions
            title="Medical Statistics"
            :column="1"
            :colon="false"
          >
            <template v-if="ifShowDetails">
              <el-descriptions-item>
                <el-table
                  :data="selectedData.details.list"
                  style="width: 100px; overflow: scroll"
                  max-height="400px"
                >
                  <el-table-column
                    v-for="title in selectedData.details.titles"
                    :key="title"
                    :prop="title"
                    :label="title"
                    fixed
                  />
                </el-table>
              </el-descriptions-item>
            </template>
            <template v-else>
              <el-descriptions-item>
                {{ mosaicText }}
              </el-descriptions-item>
            </template>
          </el-descriptions></el-tab-pane
        >
      </el-tabs>
      <br />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="detailsDialog = false"
          >Close</el-button
        >
      </span>
    </el-dialog>
    <!-- 输入加密口令和邮箱验证码 -->
    <el-dialog
      title="Enter encryption phrase and email verification code"
      :visible.sync="verifyFormDialog"
      width="60%"
    >
      <el-form
        ref="verifyForm"
        :model="verifyForm"
        :rules="verifyFormRules"
        label-width="200px"
      >
        <el-form-item
          id="view-data-driver-step-0"
          label="Encryption Phrase"
          prop="phrase"
        >
          <el-input
            v-model="verifyForm.phrase"
            placeholder="Please enter your encryption phrase"
            type="password"
            show-password
          >
            <template #append>
              <el-tooltip
                class="box-item"
                effect="dark"
                content="Please enter your encryption phrase. Be aware that if you lose this
        phrase, you will permanently lose access to your data on our website and
        will need to transfer your data again."
                placement="top-start"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="Email Address" prop="emailAddress">
          <el-input
            v-model="verifyForm.emailAddress"
            placeholder="Please enter email address"
          >
            <el-button slot="append" @click="sendCode">Send code</el-button>
          </el-input>
        </el-form-item> -->
        <el-form-item
          id="view-data-driver-step-1"
          label="Email Verification Code"
          prop="verificationCode"
        >
          <div class="form-inline">
            <el-input
              v-model="verifyForm.verificationCode"
              placeholder="Please enter email verification code"
            >
            </el-input>
            <el-button
              type="primary"
              :disabled="sendCodeDisabled"
              @click="clickSendBtn"
              >{{ ifResend ? "Resend code" : "Send code" }}
              {{ sendCodeTimer ? `(${sendCodeTimer}s)` : "" }}</el-button
            >
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="verifyFormDialog = false">Cancel</el-button>
        <el-button type="primary" @click="submitVerifyForm">Submit</el-button>
      </span>
    </el-dialog>
    <!-- 引导视频弹窗 -->
    <el-dialog
      title="Please watch this short video before proceeding"
      :visible.sync="tourDialogVisible"
      width="70%"
      :before-close="handleTourDialogClose"
      center
    >
      <div class="tour-video">
        <video
          id="dataTourVideo"
          ref="dataTourVideo"
          controls
          width="800"
          height="480"
        >
          <source src="/video/data-tour.mp4" type="video/mp4" />
          Your browser does not support the HTML5 video tag.
        </video>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleTourDialogClose"
          >Close</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../../http/api";
import { format } from "../../../lib/time";
import JSZip from "jszip";
import Tiff from "tiff.js";
import CryptoJS from "crypto-js";
import { Loading } from "element-ui";
import { handleMetaData } from "@/lib/dataProcess";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

export default {
  props: ["datas", "ifShowDetails"],
  data() {
    return {
      selectedData: {},
      detailsDialog: false,
      mosaicText: "■■■■■■■■",
      zipFilesUrlList: [],
      verifyFormDialog: false,
      verifyForm: {
        verificationCode: "",
        phrase: "",
        emailAddress: "",
      },
      verifyFormRules: {
        phrase: [
          {
            required: true,
            message: "Please enter encryption phrase ",
            trigger: "blur",
          },
        ],
        verificationCode: [
          {
            required: true,
            message: "Please enter email verification code",
            trigger: "change",
          },
        ],
        // emailAddress: [
        //   {
        //     required: true,
        //     message: "Please enter email address",
        //     trigger: "change",
        //   },
        // ],
      },
      encryptedData: "",
      originalJson: {},
      // 引导视频
      tourDialogVisible: false,
      sendCodeDisabled: false,
      sendCodeTimer: 0,
      sendCodeInterval: null,
      ifResend: false,
    };
  },
  computed: {
    ifZipFile() {
      if (
        this.selectedData.basicInfo &&
        this.selectedData.basicInfo.filename.includes(".zip")
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      const lottieFile =
        this.datas.type === 0
          ? require(`@/assets/lottie/dna.json`)
          : this.datas.type === 1
          ? require(`@/assets/lottie/health.json`)
          : this.datas.type === 2
          ? require(`@/assets/lottie/medical.json`)
          : this.datas.type === 3
          ? require(`@/assets/lottie/statistic.json`)
          : require(`@/assets/lottie/model.json`);
      this.$lottie.loadAnimation({
        container: document.getElementById(`lottie_box_${this.datas.id}`), // 包含动画的dom元素
        renderer: "svg", // 渲染出来的是什么格式
        loop: true, // 循环播放
        autoplay: true, // 自动播放
        animationData: lottieFile, // 动画json的本地路径
        // path:''//网络json地址, 选择 animationData 后，则不用path了，
      });
    });
  },
  methods: {
    clickViewData() {
      if (!sessionStorage.getItem("finishFirstViewData")) {
        sessionStorage.setItem("finishFirstViewData", true);
        this.$vToastify
          .prompt({
            title: "",
            body: "Start tour guide for data upload?",
            canTimeout: true,
            duration: 3000,
          })
          .then((value) => {
            if (value) {
              this.tourDialogVisible = true;
            }
          });
      }
    },
    startTour() {
      const driverObj = driver({
        showProgress: true,
        overlayOpacity: 0.4,
        steps: [
          {
            element: "#view-data-driver-step-0",
            popover: {
              title: "Encryption Phrase",
              description:
                "Enter the encryption phrase that you had chosen when you connected to your provider. Your data is always encrypted and only you can access it with the encryption phrase. If you don’t remember your Encryption Phrase you need to connect your provider again through My Data section",
            },
          },
          {
            element: "#view-data-driver-step-1",
            popover: {
              title: "Email Verification Code",
              description:
                "The Verification Code is sent to the email associated with your Lavita account",
            },
          },
        ],
      });
      driverObj.drive();
    },
    handleTourDialogClose() {
      this.tourDialogVisible = false;
      this.startTour();
    },
    base64ToArrayBuffer(base64) {
      const binaryString = window.atob(base64);
      const length = binaryString.length;
      const arrayBuffer = new ArrayBuffer(length);
      const uint8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
      }

      return arrayBuffer;
    },
    translateType(type) {
      switch (type) {
        case 0: {
          return "Genetic Data";
        }
        case 1: {
          return "EHR Data";
        }
        case 2: {
          return "Medical Image Data";
        }
        case 3: {
          return "Medical Statistics Data";
        }
        case 4: {
          return "Training Models";
        }
      }
    },
    format: format,
    async getDetail(datas) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
      try {
        this.selectedData = {};
        const metaRes = await api.getMetaData({
          filename: datas.filename,
          type: datas.type,
        });
        let res;
        // 获取图片类数据
        if (datas.type === 2) {
          res = await api.getImage({
            filename: datas.filename,
            type: datas.type,
          });
          // 获取非EHR的其他数据
        } else if (datas.type !== 1) {
          res = await api.getDataDetail({
            filename: datas.filename,
            type: datas.type,
          });
        } else {
          // 获取EHR数据
          // 如果id对应上，可以获取detail数据
          if (this.$store.state.userInfo.userId === datas.userId) {
            res = await api.getDataDetail({
              filename: datas.filename,
              type: datas.type,
            });
          }
        }
        this.selectedData = {
          basicInfo: datas,
          details: null,
        };
        switch (datas.type) {
          case 0: {
            this.selectedData.details = res.data;
            this.detailsDialog = true;
            break;
          }
          case 1: {
            // 查看本用户数据，可以看到details
            if (
              this.$store.state.userInfo.userId === datas.userId &&
              this.ifShowDetails
            ) {
              this.encryptedData = res.data;
              this.verifyFormDialog = true;
              this.clickViewData();
            } else {
              const ehrMetaData = JSON.parse(metaRes.data.data);
              const {
                patientMeta,
                observationMeta,
                medicationOrderMeta,
                medicationMeta,
                diagnosticMeta,
                procedureMeta,
              } = ehrMetaData;
              this.selectedData.details = {
                patientMeta,
                patient: {},
                observationMeta,
                observation: {},
                medicationOrderMeta,
                medicationOrder: {},
                medicationMeta,
                medication: {},
                diagnosticMeta,
                diagnostic: {},
                procedureMeta,
                procedure: {},
              };
              this.detailsDialog = true;
            }
            break;
          }
          case 2: {
            if (datas.filename.includes(".zip")) {
              // 处理压缩图片包
              const zipData = res.data;
              const zip = new JSZip();
              zip.loadAsync(zipData).then((zip) => {
                zip.forEach((relativePath, file) => {
                  if (file.name.includes(".tif")) {
                    file.async("base64").then((base64Data) => {
                      const tiff = new Tiff({
                        buffer: this.base64ToArrayBuffer(base64Data),
                      });
                      const imageElement = new Image();
                      imageElement.src = tiff.toDataURL("image/png");
                      this.zipFilesUrlList.push({
                        name: file.name,
                        src: imageElement.src,
                      });
                    });
                  } else {
                    file.async("base64").then((base64Data) => {
                      const imageElement = new Image();
                      imageElement.src = `data:image/jpeg;base64,${base64Data}`;
                      this.zipFilesUrlList.push({
                        name: file.name,
                        src: imageElement.src,
                      });
                    });
                  }
                });
              });
            } else {
              // 处理单张图片
              this.singleImgUrl = window.URL.createObjectURL(res.data);
            }
            this.detailsDialog = true;
            break;
          }
          case 3: {
            const data = res.data.split("\n");
            const titles = data[0].split(",");
            let list = [];
            for (let i = 1; i < data.length; i += 1) {
              let item = {};
              const array = data[i].split(",");
              for (let j = 0; j < array.length; j += 1) {
                item[titles[j]] = array[j];
              }
              list.push(item);
            }
            this.selectedData.details = {
              titles,
              list: list.length > 30 ? list.slice(0, 30) : list,
            };
            this.detailsDialog = true;
            break;
          }
          case 4: {
            this.selectedData.details = `from collections import OrderedDict

import torch
import torch.nn as nn


class UNet(nn.Module):

    def __init__(self, in_channels=3, out_channels=1, init_features=32):
        super(UNet, self).__init__()

        features = init_features
        self.encoder1 = UNet._block(in_channels, features, name="enc1")
        self.pool1 = nn.MaxPool2d(kernel_size=2, stride=2)
        self.encoder2 = UNet._block(features, features * 2, name="enc2")
        self.pool2 = nn.MaxPool2d(kernel_size=2, stride=2)
        self.encoder3 = UNet._block(features * 2, features * 4, name="enc3")
        self.pool3 = nn.MaxPool2d(kernel_size=2, stride=2)
        self.encoder4 = UNet._block(features * 4, features * 8, name="enc4")
        self.pool4 = nn.MaxPool2d(kernel_size=2, stride=2)

        self.bottleneck = UNet._block(features * 8, features * 16, name="bottleneck")

        self.upconv4 = nn.ConvTranspose2d(
            features * 16, features * 8, kernel_size=2, stride=2
        )
        self.decoder4 = UNet._block((features * 8) * 2, features * 8, name="dec4")
        self.upconv3 = nn.ConvTranspose2d(
            features * 8, features * 4, kernel_size=2, stride=2
        )
        self.decoder3 = UNet._block((features * 4) * 2, features * 4, name="dec3")
        self.upconv2 = nn.ConvTranspose2d(
            features * 4, features * 2, kernel_size=2, stride=2
        )
        self.decoder2 = UNet._block((features * 2) * 2, features * 2, name="dec2")
        self.upconv1 = nn.ConvTranspose2d(
            features * 2, features, kernel_size=2, stride=2
        )
        self.decoder1 = UNet._block(features * 2, features, name="dec1")

        self.conv = nn.Conv2d(
            in_channels=features, out_channels=out_channels, kernel_size=1
        )

    def forward(self, x):
        enc1 = self.encoder1(x)
        enc2 = self.encoder2(self.pool1(enc1))
        enc3 = self.encoder3(self.pool2(enc2))
        enc4 = self.encoder4(self.pool3(enc3))

        bottleneck = self.bottleneck(self.pool4(enc4))

        dec4 = self.upconv4(bottleneck)
        dec4 = torch.cat((dec4, enc4), dim=1)
        dec4 = self.decoder4(dec4)
        dec3 = self.upconv3(dec4)
        dec3 = torch.cat((dec3, enc3), dim=1)
        dec3 = self.decoder3(dec3)
        dec2 = self.upconv2(dec3)
        dec2 = torch.cat((dec2, enc2), dim=1)
        dec2 = self.decoder2(dec2)
        dec1 = self.upconv1(dec2)
        dec1 = torch.cat((dec1, enc1), dim=1)
        dec1 = self.decoder1(dec1)
        return torch.sigmoid(self.conv(dec1))

    @staticmethod
    def _block(in_channels, features, name):
        return nn.Sequential(
            OrderedDict(
                [
                    (
                        name + "conv1",
                        nn.Conv2d(
                            in_channels=in_channels,
                            out_channels=features,
                            kernel_size=3,
                            padding=1,
                            bias=False,
                        ),
                    ),
                    (name + "norm1", nn.BatchNorm2d(num_features=features)),
                    (name + "relu1", nn.ReLU(inplace=True)),
                    (
                        name + "conv2",
                        nn.Conv2d(
                            in_channels=features,
                            out_channels=features,
                            kernel_size=3,
                            padding=1,
                            bias=False,
                        ),
                    ),
                    (name + "norm2", nn.BatchNorm2d(num_features=features)),
                    (name + "relu2", nn.ReLU(inplace=True)),
                ]
            )
        )`;
            this.detailsDialog = true;
            break;
          }

          default:
            break;
        }
      } catch (error) {
        console.log(error);
      } finally {
        loading.close();
      }
    },
    async removeData(datas) {
      this.$confirm(
        "Losing your encryption phrase results in permanent loss of access to your data on our website. This action will lead to the deletion of the inaccessible data, you may choose to re-transfer your data after the deletion.",
        "Warning",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async () => {
          const loading = Loading.service({
            fullscreen: true,
            text: "loading...",
          });
          try {
            await api.invalidData({
              idList: [datas.id],
            });
            this.$alert("Deleted successfully", "", {
              confirmButtonText: "OK",
              callback: () => {
                window.location.reload();
              },
            });
          } catch (error) {
            console.log(error);
          } finally {
            loading.close();
          }
        })
        .catch(() => {});
    },
    clickSendBtn() {
      this.ifResend = true;
      this.sendCode();
    },
    async sendCode() {
      await api.sendCodeToEmail({
        email: this.$store.state.userInfo.username,
      });
      this.$vToastify.success(
        "Code has been sent to your email address, please check"
      );
      // 倒计时60s
      this.sendCodeDisabled = true;
      this.sendCodeTimer = 60;
      this.sendCodeInterval = setInterval(() => {
        if (this.sendCodeTimer > 0) {
          this.sendCodeTimer--;
        } else {
          this.sendCodeDisabled = false;
          clearInterval(this.sendCodeInterval);
        }
      }, 1000);
    },
    submitVerifyForm() {
      this.$refs.verifyForm.validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: "Loading...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.8)",
          });
          try {
            await api.checkVerificationCode({
              username: this.$store.state.userInfo.username,
              userId: this.$store.state.userInfo.userId,
              verificationCode: this.verifyForm.verificationCode,
            });
            // Decrypt
            const bytes = CryptoJS.AES.decrypt(
              this.encryptedData,
              this.verifyForm.phrase
            );
            const details = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            // 保存原始JSON用来展示
            this.originalJson = details;
            const result = handleMetaData(details);
            this.selectedData.details = result;
            this.verifyFormDialog = false;
            this.detailsDialog = true;
          } catch (error) {
            this.$vToastify.error("Decryption failed, please try again");
            console.log(error);
          } finally {
            loading.close();
          }
        }
      });
    },
  },
};
</script>

<style>
.table-label-title {
  width: 120px;
}
</style>
<style lang="less" scoped>
::v-deep .el-tabs__item {
  margin-right: 10px;
  font-size: 16px;
  font-weight: bold;
}
::v-deep .el-tabs__item.is-active {
  background: #eeeefa 0% 0% no-repeat padding-box;
  border-radius: 10px;
}
.lottie {
  width: 200px;
  height: 200px;
  margin: auto;
}
.img {
  width: 200px;
  height: 200px;
}
.box-card {
  margin: 20px;
}
.zip-img-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 三列等宽 */
  grid-gap: 30px;
}
.zip-img {
  width: 100%; /* 图片宽度占满列 */
  height: auto; /* 高度自适应，保持正方形 */
  max-width: 100%; /* 最大宽度100% */
}
.table-cell {
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: keep-all;
}
.epic-data-table {
  width: 100%;
  height: 400px;
  overflow-x: scroll;
  overflow-y: scroll;
  ::v-deep .epic-data-table-header {
    color: #34e393;
    font-weight: bold;
    font-size: 16px;
  }
  ::v-deep .el-table .el-table__body-wrapper {
    overflow-x: scroll;
  }

  ::v-deep .el-table .el-table__body {
    overflow-y: hidden;
  }
}
::v-deep .el-table .el-table__body-wrapper {
  overflow-x: scroll;
}

::v-deep .el-table .el-table__body {
  overflow-y: hidden;
}
.tour-video {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-inline {
  display: flex;
  align-items: center;
}
.form-inline .el-button {
  margin-left: 30px;
}
</style>
