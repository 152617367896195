<template>
  <div class="welcome-cta mg-top-40">
    <div class="welcome-cta__heading">
      <h2 class="welcome-cta__title">
        Upload your health data to earn vLAVITA
      </h2>
      <!-- <p class="welcome-cta__text nftmax-lspacing">
        Buy and sell NFTs from the world’s top artists
      </p> -->
    </div>
    <div class="welcome-cta__button">
      <el-button
        class="nftmax-btn nftmax-btn__bordered bg radius"
        @click="checkBalance"
      >
        Connect Data
      </el-button>
    </div>
    <el-dialog
      title="Upload Data - You will receive vLAVITA rewards once your data is uploaded"
      :visible.sync="uploadDialog"
      width="60%"
    >
      <el-form ref="form" :model="form" :rules="formRules" label-width="180px">
        <el-form-item id="upload-driver-step-0" label="Data Type">
          <el-select v-model="form.type" placeholder="Choose Data Type">
            <el-option label="EHR Data" :value="1"></el-option>
            <el-option label="Genetic Data" :value="0" disabled></el-option>
            <el-option
              label="Medical Image Data"
              :value="2"
              disabled
            ></el-option>
            <el-option
              label="Medical Statistics Data"
              :value="3"
              disabled
            ></el-option>
            <el-option label="Training Models" :value="4" disabled></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          id="upload-driver-step-1"
          label="Description"
          prop="subType"
        >
          <el-input
            v-model="form.subType"
            placeholder="Please provide details of the data"
          ></el-input>
        </el-form-item>
        <el-form-item id="upload-driver-step-2">
          <span slot="label">Data Contribution</span>
          <div>
            <el-radio v-model="form.privacy" :label="0" border>
              I want to be notified to participate in health studies</el-radio
            >
          </div>
          <div>
            <el-radio
              v-model="form.privacy"
              :label="1"
              border
              style="position: relative; top: 10px"
              >I want to use Lavita platform only to view my health
              data</el-radio
            >
          </div>
        </el-form-item>
        <el-form-item
          v-show="form.type === 1"
          id="upload-driver-step-3"
          label="Provider Organization"
        >
          <el-select
            v-model="form.selectedOrg"
            filterable
            placeholder="Please select organization"
            style="width: 400px"
            value-key="Hospital"
          >
            <el-option
              v-for="item in orgList"
              :key="item.Hospital"
              :label="item.Hospital"
              :value="item"
            />
          </el-select>
          <div>
            If you don't have an online account with your provider,
            <el-button id="upload-driver-step-4" type="text" @click="signUpOrg"
              >sign up here</el-button
            >
          </div>
        </el-form-item>
        <el-form-item v-show="form.type !== 1" label="Data Select" prop="file">
          <el-upload
            ref="upload"
            :on-change="saveData"
            action=""
            :auto-upload="false"
            :limit="1"
          >
            <el-button slot="trigger" type="primary">Select File</el-button>
            <!-- <div slot="tip" class="el-upload__tip">Only accept csv file</div> -->
          </el-upload>
        </el-form-item>
        <el-form-item prop="policyAgreed">
          <el-checkbox v-model="form.policyAgreed"
            >I Agree to
            <el-button
              id="upload-driver-step-5"
              type="text"
              @click="consentFormDialog = true"
              >Lavita Data Consent And Privacy Policy</el-button
            >
          </el-checkbox>
        </el-form-item>
        <!-- <el-form-item prop="agreed">
          <el-checkbox v-model="form.agreed" class="multi-text">
            I understand that my data will be encrypted and stored on Theta<br />
            EdgeNode Network. I understand that Lavita will never store my data
          </el-checkbox>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="uploadDialog = false">Cancel</el-button>
        <el-button v-show="form.type !== 1" type="primary" @click="uploadData"
          >Upload</el-button
        >
        <el-button
          v-show="form.type === 1"
          id="upload-driver-step-6"
          type="primary"
          @click="epicAuth"
          >Connect through your provider</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="Terms and Condition – User Consent for Accessing Lavita Platform"
      :visible.sync="consentFormDialog"
      center
    >
      <div class="no-wrap">
        <p>
          Lavita's mission is to help individuals take full control of their
          health data. Users will benefit by unlocking the value of their health
          data, to receive personalized health and wellness information, and to
          participate in paying research and studies.
        </p>

        <p>
          By agreeing to this consent, you give permission to Lavita to retrieve
          and encrypt your health data from Epic and other EHR providers. Your
          data will be immediately encrypted on your device browser, using a
          private encryption key that is generated from your chosen password.
          Lavita never stores your private encryption key. Lavita never stores
          your unencrypted data. Only users themselves can access their data by
          login into Lavita platform and using their private encryption key,
          generated by your password. No entity, including Lavita itself, can
          decrypt your data without your own explicit consent.
        </p>

        <p>
          If you choose to contribute your data to Lavita platform, Lavita will
          collect certain de-identified meta data about you to allow researchers
          to ask for your participation, with your permission, in paid studies
          through Lavita platform.
        </p>

        <p>For further information please contact privacy@lavita.ai</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="consentFormDialog = false"
          >Close</el-button
        >
      </span>
    </el-dialog>
    <!-- 输入加密口令和邮箱验证码 -->
    <el-dialog
      title="Enter encryption phrase and email verification code"
      :visible.sync="verifyFormDialog"
      width="60%"
    >
      <el-form
        ref="verifyForm"
        :model="verifyForm"
        :rules="verifyFormRules"
        label-width="220px"
      >
        <el-form-item
          label="Encryption Phrase"
          prop="phrase"
          :inline-message="true"
        >
          <el-input
            v-model="verifyForm.phrase"
            placeholder="Please enter your encryption phrase"
            type="password"
            show-password
          >
            <template #append>
              <el-tooltip
                class="box-item"
                effect="dark"
                content="Please enter your encryption phrase. Be aware that if you lose this
        phrase, you will permanently lose access to your data on our website and
        will need to transfer your data again."
                placement="top-start"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="Re-enter Encryption Phrase" prop="reEnterPhrase">
          <el-input
            v-model="verifyForm.reEnterPhrase"
            placeholder="Please re-enter your encryption phrase"
            type="password"
            show-password
          >
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="Email Address" prop="emailAddress">
          <el-input
            v-model="verifyForm.emailAddress"
            placeholder="Please enter email address"
          >
            <el-button slot="append" @click="sendCode">Send code</el-button>
          </el-input>
        </el-form-item> -->
        <el-form-item label="Email Verification Code" prop="verificationCode">
          <div class="form-inline">
            <el-input
              v-model="verifyForm.verificationCode"
              placeholder="Please enter email verification code"
            >
            </el-input>
            <el-button
              type="primary"
              :disabled="sendCodeDisabled"
              @click="clickSendBtn"
              >{{ ifResend ? "Resend code" : "Send code" }}
              {{ sendCodeTimer ? `(${sendCodeTimer}s)` : "" }}</el-button
            >
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="verifyFormDialog = false">Cancel</el-button>
        <el-button type="primary" @click="submitVerifyForm">Submit</el-button>
      </span>
    </el-dialog>
    <!-- 引导视频弹窗 -->
    <el-dialog
      title="Please watch this short video before proceeding"
      :visible.sync="tourDialogVisible"
      width="70%"
      :before-close="handleTourDialogClose"
      center
    >
      <div class="tour-video">
        <video
          id="uploadTourVideo"
          ref="uploadTourVideo"
          controls
          width="800"
          height="480"
        >
          <source src="/video/upload-tour.mp4" type="video/mp4" />
          Your browser does not support the HTML5 video tag.
        </video>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleTourDialogClose"
          >Close</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../http/api";
import orgListR4 from "../../lib/orgListR4";
import axios from "axios";
import CryptoJS from "crypto-js";
import detectEthereumProvider from "@metamask/detect-provider";
import { handleMetaData } from "../../lib/dataProcess";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

export default {
  data() {
    const validatePhrase = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please enter your encryption phrase"));
      } else if (
        !/^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/.test(
          value
        )
      ) {
        callback(
          new Error(
            "The encryption phrase must contain at least 6 digits, including at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."
          )
        );
      } else {
        if (this.verifyForm.reEnterPhrase !== "") {
          this.$refs.verifyForm.validateField("reEnterPhrase");
        }
        callback();
      }
    };
    const validateReEnterPhrase = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please re-enter your encryption phrase"));
      } else if (value !== this.verifyForm.phrase) {
        callback(new Error("Encryption phrase doesn't match"));
      } else {
        callback();
      }
    };
    return {
      consentFormDialog: false,
      uploadDialog: false,
      fileList: [],
      form: {
        type: 1,
        subType: "",
        edgeNodeId: "",
        privacy: 0,
        policyAgreed: false,
        agreed: false,
        selectedOrg: "",
      },
      formRules: {
        subType: [
          {
            required: true,
            message: "Please enter descriptiion",
            trigger: "blur",
          },
        ],
        policyAgreed: [
          {
            validator: this.consentValidator,
            required: true,
            trigger: "change",
          },
        ],
      },
      patientData: null,
      orgList: orgListR4.filter(
        (item) => item["Include in Platform"] === "YES"
      ),
      ifShowDetails: true,
      verifyFormDialog: false,
      verifyForm: {
        verificationCode: "",
        phrase: "",
        reEnterPhrase: "",
        // emailAddress: "",
      },
      verifyFormRules: {
        phrase: [
          {
            required: true,
            validator: validatePhrase,
            trigger: "blur",
          },
        ],
        reEnterPhrase: [
          {
            required: true,
            validator: validateReEnterPhrase,
            trigger: "blur",
          },
        ],
        verificationCode: [
          {
            required: true,
            message: "Please enter email verification code",
            trigger: "change",
          },
        ],
        // emailAddress: [
        //   {
        //     required: true,
        //     message: "Please enter email address",
        //     trigger: "change",
        //   },
        // ],
      },
      tourDialogVisible: false,
      sendCodeDisabled: false,
      sendCodeTimer: 0,
      sendCodeInterval: null,
      ifResend: false,
    };
  },
  async mounted() {
    let loading = null;
    try {
      if (!sessionStorage.getItem("finishFirstUpload")) {
        sessionStorage.setItem("finishFirstUpload", true);
        this.$vToastify
          .prompt({
            title: "",
            body: "Start tour guide for data view?",
            canTimeout: true,
            duration: 3000,
          })
          .then((value) => {
            if (value) {
              this.uploadDialog = true;
              this.tourDialogVisible = true;
            }
          });
      }
      if (this.$route.query.code) {
        loading = this.$loading({
          lock: true,
          text: "Your data is being securely imported, Please wait…",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.8)",
        });
        const res = await api.epicAuth({
          grant_type: "authorization_code",
          code: this.$route.query.code,
          redirect_uri: `${process.env.VUE_APP_LAVITA_URL}/my-contributions`,
          client_id: process.env.VUE_APP_EPIC_ID,
        });
        this.$store.commit("saveEpicInfo", res.data);
        const patientRes = await api.r4EpicInfoGet({
          endpoint: `Patient/${res.data.patient}`,
        });
        const medicationOrderRes = await api.DSTU2EpicInfoGet({
          endpoint: `MedicationOrder`,
          params: {
            patient: patientRes.data.id,
          },
        });
        const medicationOrderUrlList = [];
        for (const order of medicationOrderRes.data.entry) {
          if (order.fullUrl && order.fullUrl.includes("https")) {
            medicationOrderUrlList.push(
              order.resource.medicationReference.reference
            );
          }
        }
        let medicationOrderRequests = [];
        for (const url of medicationOrderUrlList) {
          medicationOrderRequests.push(axios.get(url));
        }
        const medicationResponses = await Promise.all(medicationOrderRequests);
        const procedureRes = await api.r4EpicInfoGet({
          endpoint: `Procedure`,
          params: {
            patient: patientRes.data.id,
          },
        });
        const DiagnosticReportRes = await api.r4EpicInfoGet({
          endpoint: `DiagnosticReport`,
          params: {
            patient: patientRes.data.id,
          },
        });
        const DocumentReferenceClinicalNotesRes = await api.r4EpicInfoGet({
          endpoint: `DocumentReference`,
          params: {
            patient: patientRes.data.id,
            category: "clinical-note",
          },
        });
        // 保存DiagnosticReport中可能出现的binary数据
        let binaryUrlList = [];
        for (const item of DiagnosticReportRes.data.entry) {
          if (item.fullUrl && item.fullUrl.includes("https")) {
            const list = (item.resource.presentedForm || []).map(
              (subItem) => subItem.url
            );
            binaryUrlList = [...binaryUrlList, ...list];
          }
        }
        console.log(binaryUrlList);
        // 保存DocumentReferenceClinicalNotes中可能出现的binary数据
        for (const item of DocumentReferenceClinicalNotesRes.data.entry) {
          if (item.fullUrl && item.fullUrl.includes("https")) {
            const list = (item.resource.content || []).map(
              (subItem) => subItem.attachment.url
            );
            binaryUrlList = [...binaryUrlList, ...list];
          }
        }
        console.log(binaryUrlList);
        // let binaryRequests = [];
        // for (const url of binaryUrlList) {
        //   binaryRequests.push(
        //     api.r4EpicInfoGet({
        //       endpoint: url,
        //     })
        //   );
        // }
        // let binaryResponses = [];
        // binaryResponses = await Promise.all(binaryRequests).catch((err) => {
        //   // Promise.all 中有一个或多个 Promise 失败时执行
        //   console.error("One or more promises failed:", err);
        // });
        // console.log(binaryResponses);
        // 新api
        const MedicationRequestRes = await api.r4EpicInfoGet({
          endpoint: `MedicationRequest`,
          params: {
            patient: patientRes.data.id,
          },
        });
        const AllergyIntoleranceRes = await api.r4EpicInfoGet({
          endpoint: `AllergyIntolerance`,
          params: {
            patient: patientRes.data.id,
          },
        });
        const CarePlanEncounterLevelRes = await api.r4EpicInfoGet({
          endpoint: `CarePlan`,
          params: {
            patient: patientRes.data.id,
            category: "734163000",
          },
        });
        const CarePlanLongitudinalRes = await api.r4EpicInfoGet({
          endpoint: `CarePlan`,
          params: {
            patient: patientRes.data.id,
            category: "38717003",
          },
        });
        // const ConditionCarePlanProblemRes = await api.r4EpicInfoGet({
        //   endpoint: `Condition`,
        //   params: {
        //     _id: patientRes.data.id,
        //   },
        // });
        // console.log(ConditionCarePlanProblemRes.data);
        const ConditionHealthConcernRes = await api.r4EpicInfoGet({
          endpoint: `Condition`,
          params: {
            patient: patientRes.data.id,
            category: "health-concern",
          },
        });
        const ConditionProblemsRes = await api.r4EpicInfoGet({
          endpoint: `Condition`,
          params: {
            patient: patientRes.data.id,
            category: "problem-list-item",
          },
        });
        const DeviceRes = await api.r4EpicInfoGet({
          endpoint: `Device`,
          params: {
            patient: patientRes.data.id,
          },
        });
        const EncounterRes = await api.r4EpicInfoGet({
          endpoint: `Encounter`,
          params: {
            patient: patientRes.data.id,
          },
        });
        // 从encounter中获取具体的location信息
        let locationUrlList = [];
        for (const encounter of EncounterRes.data.entry) {
          if (encounter.fullUrl && encounter.fullUrl.includes("https")) {
            const list = (encounter.resource.location || []).map((subItem) => {
              return {
                reference: subItem.location.reference,
                encounterId: encounter.resource.id,
              };
            });
            locationUrlList = [...locationUrlList, ...list];
          }
        }
        let locationRequests = [];
        for (const url of locationUrlList) {
          locationRequests.push(
            api.r4EpicInfoGet({
              endpoint: url.reference,
            })
          );
        }
        const locationResponses = await Promise.all(locationRequests);
        const GoalRes = await api.r4EpicInfoGet({
          endpoint: `Goal`,
          params: {
            patient: patientRes.data.id,
          },
        });
        const ImmunizationRes = await api.r4EpicInfoGet({
          endpoint: `Immunization`,
          params: {
            patient: patientRes.data.id,
          },
        });
        // const LocationRes = await api.r4EpicInfoGet({
        //   endpoint: `Location`,
        //   params: {
        //     _id: patientRes.data.id,
        //   },
        // });
        // console.log(LocationRes.data);
        // const observationLaboratoryRes = await api.r4EpicInfoGet({
        //   endpoint: `Observation`,
        //   params: {
        //     patient: patientRes.data.id,
        //     category: "laboratory",
        //   },
        // });
        // const observationSocialHistoryRes = await api.r4EpicInfoGet({
        //   endpoint: `Observation`,
        //   params: {
        //     patient: patientRes.data.id,
        //     category: "social-history",
        //   },
        // });
        // const observationVitalsRes = await api.r4EpicInfoGet({
        //   endpoint: `Observation`,
        //   params: {
        //     patient: patientRes.data.id,
        //     category: "vital-signs",
        //   },
        // });
        // const OrganizationRes = await api.r4EpicInfoGet({
        //   endpoint: `Organization`,
        //   params: {
        //     _id: patientRes.data.id,
        //   },
        // });
        // console.log(OrganizationRes.data);
        this.patientData = {
          patient: patientRes.data,
          // observationLaboratory: observationLaboratoryRes.data,
          medicationOrder: medicationOrderRes.data,
          medication: medicationResponses.map((item) => item.data),
          procedure: procedureRes.data,
          diagnostic: DiagnosticReportRes.data,
          // binary: binaryResponses.map((item) => item.data),
          binary: [],
          MedicationRequest: MedicationRequestRes.data,
          AllergyIntolerance: AllergyIntoleranceRes.data,
          CarePlanEncounterLevel: CarePlanEncounterLevelRes.data,
          CarePlanLongitudinal: CarePlanLongitudinalRes.data,
          ConditionHealthConcern: ConditionHealthConcernRes.data,
          ConditionProblems: ConditionProblemsRes.data,
          Device: DeviceRes.data,
          DocumentReferenceClinicalNotes:
            DocumentReferenceClinicalNotesRes.data,
          Encounter: EncounterRes.data,
          location: locationResponses.map((item) => item.data),
          locationUrlList,
          Goal: GoalRes.data,
          Immunization: ImmunizationRes.data,
          // observationSocialHistory: observationSocialHistoryRes.data,
          // observationVitals: observationVitalsRes.data,
        };
        const result = handleMetaData(this.patientData);
        if (result.totalDataNum === 0) {
          this.$alert(
            "Your account does not contain any valid medical data, please try again"
          );
          loading.close();
          return;
        }
        this.form = JSON.parse(sessionStorage.getItem("userDataInfo"));
        this.uploadDialog = true;
        this.$nextTick(() => {
          loading.close();
          this.verifyFormDialog = true;
        });
      }
    } catch (error) {
      loading.close();
      console.log(error);
    }
  },
  methods: {
    // 检查主钱包是否有足够的余额
    async checkBalance() {
      try {
        if (typeof window.ethereum === "undefined") {
          this.$alert("MetaMask is not installed or connected, please check");
          return;
        }
        const res = await api.enoughBalance();
        if (res.data.data === "true") {
          this.uploadDialog = true;
        } else {
          this.$alert(
            "Connect data service is unavailable right now, please try later"
          );
        }
      } catch (error) {
        console.log(error);
        this.$alert(
          "Connect data service is unavailable right now, please try later"
        );
      }
    },
    startTour() {
      const driverObj = driver({
        showProgress: true,
        overlayOpacity: 0.4,
        steps: [
          {
            element: "#upload-driver-step-0",
            popover: {
              title: "Data Type",
              description:
                "Choose Electronic Health Record (EHR) as Data Type. Other data types will become available in future releases of the platform soon",
            },
          },
          {
            element: "#upload-driver-step-1",
            popover: {
              title: "Data Description",
              description:
                "Write a short description for your health data, for example, “Data from my Epic EHR",
            },
          },
          {
            element: "#upload-driver-step-2",
            popover: {
              title: "Data Contribution",
              description:
                "Choose one of the two options for data contribution",
            },
          },
          {
            element: "#upload-driver-step-3",
            popover: {
              title: "Provider Organization",
              description:
                "Select the provider organization, for example the clinic or hospital, from which you receive medical care. If you can’t find your provider, they are not on the Lavita platform yet. We are adding new providers soon",
            },
          },
          {
            element: "#upload-driver-step-4",
            popover: {
              title: "Provider Signup",
              description:
                "Click if you don't or are not sure whether you have an online account with your provider",
            },
          },
          {
            element: "#upload-driver-step-5",
            popover: {
              title: "Privacy Policy",
              description:
                "Please read Lavita Data Consent and Privacy Policy before proceeding",
            },
          },
          {
            element: "#upload-driver-step-6",
            popover: {
              title: "Connect Data",
              description:
                "Click here to connect your Lavita account to your provider",
            },
          },
        ],
      });
      driverObj.drive();
    },
    handleTourDialogClose() {
      this.tourDialogVisible = false;
      this.startTour();
    },
    generateUUID() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    agreeValidator(rule, value, callback) {
      if (this.form.agreed) {
        callback();
      } else {
        callback(new Error("Please agree the terms"));
      }
    },
    consentValidator(rule, value, callback) {
      if (this.form.policyAgreed) {
        callback();
      } else {
        callback(new Error("Please check the box"));
      }
    },
    async epicAuth() {
      const loading = this.$loading({
        lock: true,
        text: "Connecting to 3rd party MyChart provider, please wait...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
      try {
        if (this.form.type === 1 && !this.form.selectedOrg) {
          this.$alert("Please select your organization");
          loading.close();
          return;
        }
        if (typeof window.ethereum === "undefined") {
          this.$alert("MetaMask is not installed or connected, please check");
          loading.close();
          return;
        }
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            sessionStorage.setItem("userDataInfo", JSON.stringify(this.form));
            const configRes = await api.getOauthMeta({
              url: `${this.form.selectedOrg["Epic Endpiont"]}.well-known/smart-configuration`,
              headers: {
                epicClientId: process.env.VUE_APP_EPIC_ID,
                accept: "application/fhir+json",
              },
            });
            let configData;
            try {
              configData = JSON.parse(configRes.data.data);
            } catch (error) {
              console.log(error);
              this.$alert(
                "Your organization is unavailable right now, please try again later"
              );
              loading.close();
              return;
            }
            sessionStorage.setItem("tokenEndpoint", configData.token_endpoint);
            const baseUrl = configData.authorization_endpoint;
            const params = {
              response_type: "code",
              client_id: process.env.VUE_APP_EPIC_ID,
              redirect_uri: `${process.env.VUE_APP_LAVITA_URL}/my-contributions`,
              scope: "fhirUser",
              aud: this.form.selectedOrg["Epic Endpiont"],
            };
            const urlParams = new URLSearchParams(params);
            const paramString = urlParams.toString();
            const newUrl = `${baseUrl}?${paramString}`;
            window.location.href = newUrl;
          } else {
            loading.close();
          }
        });
      } catch (error) {
        this.$alert(
          "Your organization is unavailable right now, please try again later"
        );
        loading.close();
        console.log(error);
      }
    },
    saveData(file, fileList) {
      this.fileList = fileList;
    },
    async uploadData() {
      const loading = this.$loading({
        lock: true,
        text: "Your data is being securely imported, Please wait…",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
      try {
        if (typeof window.ethereum === "undefined") {
          this.$alert("MetaMask is not installed or connected, please check");
          loading.close();
          return;
        }
        if (!this.patientData && !this.fileList.length) {
          this.$alert(
            "Please select a file first or connect with your EPIC account"
          );
          loading.close();
          return;
        }
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            this.uploadDialog = false;
            loading.setText("Your data is being retrieved");
            setTimeout(function () {
              loading.setText("Your EHR data is being encrypted and stored");
            }, 5000);
            setTimeout(async () => {
              try {
                this.form.edgeNodeId = "lavita_edgenode_2";
                // 绑定用户钱包
                const provider = await detectEthereumProvider();
                const accounts = await provider.request({
                  method: "eth_requestAccounts",
                });
                let toAddress = "";
                if (accounts.length > 0) {
                  toAddress = window.ethereum.selectedAddress;
                }
                await api.registerWallet({
                  userId: this.$store.state.userInfo.userId,
                  address: toAddress,
                });
                const res = await this.uploadFile(loading);
                if (res.data.data?.reImport) {
                  this.$alert(
                    "Your data has been imported securely",
                    "Congratulations!",
                    {
                      confirmButtonText: "OK",
                      type: "success",
                      callback: () => {
                        // this.$store.commit("refrenshBalance");
                        window.location.reload();
                      },
                    }
                  );
                } else {
                  this.$alert(
                    "You've earned 1000 vLAVITA! It may take some time (usually less than 2 minutes) to appear in your MetaMask wallet",
                    "Congratulations!",
                    {
                      confirmButtonText: "OK",
                      type: "success",
                      callback: () => {
                        // this.$store.commit("refrenshBalance");
                        window.location.reload();
                      },
                    }
                  );
                }
                loading.close();
              } catch (error) {
                loading.close();
                console.log(error);
                this.$alert(
                  error.data?.message
                    ? error.data.message
                    : "Something wrong, please try again",
                  "Alert"
                );
              }
            }, 15000);
          }
        });
      } catch (error) {
        loading.close();
        console.log(error);
      }
    },
    async uploadFile() {
      let res;
      if (this.patientData) {
        const details = this.patientData;
        const result = handleMetaData(details);
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(this.patientData),
          this.verifyForm.phrase
        ).toString();
        const hash = CryptoJS.SHA256(
          JSON.stringify(this.patientData)
        ).toString();
        const params = {
          encryptedData,
          hash,
          userId: this.$store.state.userInfo.userId,
          institution: this.form.selectedOrg.Hospital,
          institutionUserId: this.patientData.patient.id,
          verificationCode: this.verifyForm.verificationCode,
          // metaData: JSON.stringify(result),
          metaData: "",
          ...this.form,
        };
        res = await api.uploadEHRData(params);
      } else {
        let formData = new FormData();
        formData.append("file", this.fileList[0].raw);
        res = await api.uploadFile(formData, {
          userId: this.$store.state.userInfo.userId,
          ...this.form,
        });
      }
      return Promise.resolve(res);
    },
    clickSendBtn() {
      this.ifResend = true;
      this.sendCode();
    },
    async sendCode() {
      await api.sendCodeToEmail({
        email: this.$store.state.userInfo.username,
      });
      this.$vToastify.success(
        "Code has been sent to your email address, please check"
      );
      // 倒计时60s
      this.sendCodeDisabled = true;
      this.sendCodeTimer = 60;
      this.sendCodeInterval = setInterval(() => {
        if (this.sendCodeTimer > 0) {
          this.sendCodeTimer--;
        } else {
          this.sendCodeDisabled = false;
          clearInterval(this.sendCodeInterval);
        }
      }, 1000);
    },
    submitVerifyForm() {
      this.$refs.verifyForm.validate(async (valid) => {
        if (valid) {
          this.uploadData();
        }
      });
    },
    signUpOrg() {
      if (!this.form.selectedOrg.Hospital) {
        this.$vToastify.error(
          "Please select an orgnization that you want to sign up"
        );
        return;
      }
      if (!this.form.selectedOrg["Sign-up URL"]) {
        this.$vToastify.error(
          `The sign-up link for ${this.form.selectedOrg.Hospital} is currently not available. Please check their official website directly for updates.`
        );
        return;
      }
      window.open(this.form.selectedOrg["Sign-up URL"], "_blank");
    },
  },
};
</script>

<style scoped>
.multi-text >>> .el-checkbox__label {
  position: relative;
  top: 20px;
}
.no-wrap {
  word-break: keep-all;
}
.tour-video {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-inline {
  display: flex;
  align-items: center;
}
.form-inline .el-button {
  margin-left: 30px;
}
</style>
