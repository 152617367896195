<template>
  <div>
    <!-- 使用 :is 动态渲染子页面 -->
    <component :is="pageComponent"></component>
  </div>
</template>

<script>
import lr from "./lr.vue";
import imageClassification from "./imageClassification.vue";
import imageSegmentation from "./imageSegmentation.vue";

export default {
  data() {
    return {
      studiesList: {
        51: lr,
        52: imageClassification,
        53: imageSegmentation,
      },
    };
  },
  computed: {
    pageInfo() {
      return this.$route.params.studyInfo;
    },
    pageComponent() {
      return this.studiesList[this.pageInfo.analysisMethodId];
    },
  },
};
</script>
