<template>
  <div>
    <div id="app">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style></style>
