<template>
  <section class="nftmax-wc nftmax-wc__full">
    <div class="container-fluid">
      <div class="row g-0">
        <div class="col-xxl-6 col-lg-6 col-12 nftmax-hidden-rp">
          <div class="nftmax-wc__inner nft-gr-primary">
            <!-- Logo -->
            <div class="nftmax-wc__logo">
              <a href="/"
                ><img src="../../assets/img/lavita-logo.png" alt="#"
              /></a>
            </div>
            <!-- Middle Image -->
            <div id="lottie_box" class="nftmax-wc__middle lottie"></div>
            <!-- Welcome Heading -->
            <!-- <div class="nftmax-wc__heading">
              <h2 class="nftmax-wc__title">Welcome to Lavita</h2>
            </div> -->
          </div>
        </div>
        <div class="col-xxl-6 col-lg-6 col-12">
          <!-- Welcome Form -->
          <slot></slot>
          <!-- End Welcome Form -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  mounted() {
    this.$nextTick(() => {
      this.$lottie.loadAnimation({
        container: document.getElementById(`lottie_box`), // 包含动画的dom元素
        renderer: "svg", // 渲染出来的是什么格式
        loop: true, // 循环播放
        autoplay: true, // 自动播放
        animationData: require(`@/assets/lottie/logIn.json`), // 动画json的本地路径
        // path:''//网络json地址, 选择 animationData 后，则不用path了，
      });
    });
  },
};
</script>

<style scoped></style>
