<template>
  <div class="row nftmax-gap-30">
    <div class="col-lg-6 col-12 nftmax-sixth-one">
      <!-- Charts One -->
      <SellHistoryWidget />
      <!-- End Charts One -->
    </div>
    <div class="col-lg-6 col-12 nftmax-sixth-two">
      <!-- NFTMax Balance Hover -->
      <TopSellCountryWidget />
    </div>
  </div>
</template>

<script>
import SellHistoryWidget from "@/components/HomePage/SellHistoryWidget";
import TopSellCountryWidget from "./TopSellCountryWidget";
export default {
  components: { SellHistoryWidget, TopSellCountryWidget },
};
</script>

<style scoped></style>
