<template>
  <Layout>
    <UploadProductSectionWidget />
    <MainSection />
  </Layout>
</template>

<script>
import Layout from "@/components/Partials/Layout";
import UploadProductSectionWidget from "@/components/HomePage/UploadProductSectionWidget";
import MainSection from "./MainSection";
export default {
  components: { Layout, MainSection, UploadProductSectionWidget },
};
</script>

<style scoped></style>
