<template>
  <div>
    <div>
      <div class="nftmax-smenu" :class="[drawer ? '' : 'nftmax-close']">
        <div class="admin-menu">
          <div class="logo">
            <router-link to="/my-contributions">
              <img
                class="nftmax-logo__main"
                src="../../../assets/img/lavita-logo.png"
                alt="#"
              />
            </router-link>
            <div class="nftmax__sicon close-icon" @click="catchDrawerHandler">
              <img
                v-show="!this.$store.state.layoutDrawer"
                src="../../../assets/img/menu-toggle.svg"
                alt="#"
              />
              <img
                v-show="this.$store.state.layoutDrawer"
                src="../../../assets/img/menu-toggle-reverse.svg"
                alt="#"
              />
            </div>
          </div>

          <div class="admin-menu__one">
            <!-- <h4 class="admin-menu__title nftmax-scolor">Menu</h4> -->

            <div class="menu-bar">
              <ul class="menu-bar__one">
                <el-tooltip
                  content="This feature will be available in future releases of the Lavita platform"
                  placement="right-start"
                >
                  <li
                    id="login-driver-step-4"
                    :class="[
                      this.$route.name === 'Marketplace' ? 'active' : '',
                    ]"
                  >
                    <router-link to="" style="cursor: default"
                      ><span class="menu-bar__text"
                        ><span>
                          <svg
                            v-show="this.$route.name === 'Marketplace'"
                            id="Layer_1"
                            fill="#1cd480"
                            width="21"
                            height="21"
                            viewBox="0 0 200 200"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <title />
                            <path
                              d="M181.75,42.5c-3-13-15-22.5-29-22.5h-105a30.6,30.6,0,0,0-29.5,22.5l-10.5,40c-2,7,1,14,7,18l2,1.5c3,1.5,5.5,3,8.5,3.5V160a20.06,20.06,0,0,0,20,20h23.5a20.06,20.06,0,0,0,20-20V140h27.5v20a20.06,20.06,0,0,0,20,20h19a20.06,20.06,0,0,0,20-20V105.5a22.09,22.09,0,0,0,8-3l2-1.5a16.87,16.87,0,0,0,7-18.5ZM156.25,160h-20V140a20.06,20.06,0,0,0-20-20H88.75a20.06,20.06,0,0,0-20,20v20H45.25V100c6.5-2.5,13.5-1.5,19.5,2a21.07,21.07,0,0,0,24,0,21.07,21.07,0,0,1,24,0,21.07,21.07,0,0,0,24,0,20.87,20.87,0,0,1,19.5-2Zm14.5-74.5a40.69,40.69,0,0,0-46,0,.94.94,0,0,1-1.5,0,40.69,40.69,0,0,0-46,0,.94.94,0,0,1-1.5,0,40.69,40.69,0,0,0-46,0,.94.94,0,0,1-1.5,0l9.5-38c1-4.5,5-7.5,10-7.5h105.5a9.64,9.64,0,0,1,9.5,7.5l9.5,38S171.25,86,170.75,85.5Z"
                            />
                          </svg>
                          <svg
                            v-show="this.$route.name !== 'Marketplace'"
                            id="Layer_1"
                            fill="#000000"
                            width="21"
                            height="21"
                            viewBox="0 0 200 200"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <title />
                            <path
                              d="M181.75,42.5c-3-13-15-22.5-29-22.5h-105a30.6,30.6,0,0,0-29.5,22.5l-10.5,40c-2,7,1,14,7,18l2,1.5c3,1.5,5.5,3,8.5,3.5V160a20.06,20.06,0,0,0,20,20h23.5a20.06,20.06,0,0,0,20-20V140h27.5v20a20.06,20.06,0,0,0,20,20h19a20.06,20.06,0,0,0,20-20V105.5a22.09,22.09,0,0,0,8-3l2-1.5a16.87,16.87,0,0,0,7-18.5ZM156.25,160h-20V140a20.06,20.06,0,0,0-20-20H88.75a20.06,20.06,0,0,0-20,20v20H45.25V100c6.5-2.5,13.5-1.5,19.5,2a21.07,21.07,0,0,0,24,0,21.07,21.07,0,0,1,24,0,21.07,21.07,0,0,0,24,0,20.87,20.87,0,0,1,19.5-2Zm14.5-74.5a40.69,40.69,0,0,0-46,0,.94.94,0,0,1-1.5,0,40.69,40.69,0,0,0-46,0,.94.94,0,0,1-1.5,0,40.69,40.69,0,0,0-46,0,.94.94,0,0,1-1.5,0l9.5-38c1-4.5,5-7.5,10-7.5h105.5a9.64,9.64,0,0,1,9.5,7.5l9.5,38S171.25,86,170.75,85.5Z"
                            />
                          </svg>
                        </span>
                        <span
                          class="menu-bar__name"
                          style="color: #c0c4cc; margin-left: 10px"
                          >Marketplace</span
                        >
                      </span></router-link
                    >
                  </li>
                </el-tooltip>
                <li
                  id="login-driver-step-2"
                  :class="[
                    this.$route.name === 'MyContributions' ? 'active' : '',
                  ]"
                >
                  <router-link to="/my-contributions">
                    <span class="menu-bar__text">
                      <svg
                        v-show="this.$route.name === 'MyContributions'"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g
                          id="Group_16"
                          data-name="Group 16"
                          transform="translate(-9853.645 -873.549)"
                        >
                          <rect
                            id="Rectangle_14"
                            data-name="Rectangle 14"
                            width="24"
                            height="24"
                            transform="translate(9853.645 873.549)"
                            fill="none"
                          />
                          <g id="Group_15" data-name="Group 15">
                            <g id="Group_14" data-name="Group 14">
                              <rect
                                id="Rectangle_15"
                                data-name="Rectangle 15"
                                width="2.715"
                                height="6.534"
                                rx="0.849"
                                transform="translate(9857.493 886.807)"
                                fill="#1cd480"
                              />
                              <rect
                                id="Rectangle_16"
                                data-name="Rectangle 16"
                                width="2.715"
                                height="11.718"
                                rx="0.849"
                                transform="translate(9862.022 881.623)"
                                fill="#1cd480"
                              />
                              <rect
                                id="Rectangle_17"
                                data-name="Rectangle 17"
                                width="2.715"
                                height="14.925"
                                rx="0.849"
                                transform="translate(9866.553 878.415)"
                                fill="#1cd480"
                              />
                              <rect
                                id="Rectangle_18"
                                data-name="Rectangle 18"
                                width="2.715"
                                height="9.198"
                                rx="0.849"
                                transform="translate(9871.082 884.143)"
                                fill="#1cd480"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                      <svg
                        v-show="this.$route.name !== 'MyContributions'"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g
                          id="Group_7"
                          data-name="Group 7"
                          transform="translate(-9813.645 -873.549)"
                        >
                          <rect
                            id="Rectangle_5"
                            data-name="Rectangle 5"
                            width="24"
                            height="24"
                            transform="translate(9813.645 873.549)"
                            fill="none"
                          />
                          <g id="Group_6" data-name="Group 6">
                            <rect
                              id="Rectangle_6"
                              data-name="Rectangle 6"
                              width="2.715"
                              height="6.534"
                              rx="0.849"
                              transform="translate(9817.493 886.807)"
                              fill="#202224"
                            />
                            <rect
                              id="Rectangle_7"
                              data-name="Rectangle 7"
                              width="2.715"
                              height="11.718"
                              rx="0.849"
                              transform="translate(9822.022 881.623)"
                              fill="#202224"
                            />
                            <rect
                              id="Rectangle_8"
                              data-name="Rectangle 8"
                              width="2.715"
                              height="14.925"
                              rx="0.849"
                              transform="translate(9826.553 878.415)"
                              fill="#202224"
                            />
                            <rect
                              id="Rectangle_9"
                              data-name="Rectangle 9"
                              width="2.715"
                              height="9.198"
                              rx="0.849"
                              transform="translate(9831.082 884.143)"
                              fill="#202224"
                            />
                          </g>
                        </g>
                      </svg>
                      <span class="menu-bar__name">My Data</span>
                    </span>
                  </router-link>
                </li>
                <li :class="[this.$route.name === 'MyWallet' ? 'active' : '']">
                  <router-link to="/my-wallet">
                    <span class="menu-bar__text">
                      <svg
                        v-show="this.$route.name === 'MyWallet'"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g
                          id="Group_10"
                          data-name="Group 10"
                          transform="translate(-9853.645 -903.549)"
                        >
                          <rect
                            id="Rectangle_12"
                            data-name="Rectangle 12"
                            width="24"
                            height="24"
                            transform="translate(9853.645 903.549)"
                            fill="none"
                          />
                          <path
                            id="Path_8"
                            data-name="Path 8"
                            d="M9871.893,911.513h-11.025a.919.919,0,1,1,0-1.47h12.5a1.1,1.1,0,0,0,0-2.205h-12.5a4.048,4.048,0,0,0-4.043,4.043v8.086a4.047,4.047,0,0,0,4.043,4.042h11.025a2.576,2.576,0,0,0,2.573-2.572v-7.351A2.576,2.576,0,0,0,9871.893,911.513Zm.367,9.924a.367.367,0,0,1-.367.367h-11.025a1.839,1.839,0,0,1-1.838-1.837v-6.692a4.047,4.047,0,0,0,1.838.444h11.025a.367.367,0,0,1,.367.367Zm-1.47-3.676a1.1,1.1,0,0,1-2.205,0A1.1,1.1,0,0,1,9870.79,917.761Z"
                            fill="#1cd480"
                          />
                        </g>
                      </svg>
                      <svg
                        v-show="this.$route.name !== 'MyWallet'"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g
                          id="Group_3"
                          data-name="Group 3"
                          transform="translate(-9813.645 -903.549)"
                        >
                          <rect
                            id="Rectangle_3"
                            data-name="Rectangle 3"
                            width="24"
                            height="24"
                            transform="translate(9813.645 903.549)"
                            fill="none"
                          />
                          <path
                            id="Path_3"
                            data-name="Path 3"
                            d="M9831.893,911.513h-11.025a.919.919,0,1,1,0-1.47h12.5a1.1,1.1,0,0,0,0-2.205h-12.5a4.048,4.048,0,0,0-4.043,4.043v8.086a4.047,4.047,0,0,0,4.043,4.042h11.025a2.576,2.576,0,0,0,2.573-2.572v-7.351A2.576,2.576,0,0,0,9831.893,911.513Zm.367,9.924a.367.367,0,0,1-.367.367h-11.025a1.839,1.839,0,0,1-1.838-1.837v-6.692a4.047,4.047,0,0,0,1.838.444h11.025a.367.367,0,0,1,.367.367Zm-1.47-3.676a1.1,1.1,0,0,1-2.205,0A1.1,1.1,0,0,1,9830.79,917.761Z"
                            fill="#202224"
                          />
                        </g>
                      </svg>
                      <span class="menu-bar__name">My Wallet</span>
                    </span>
                  </router-link>
                </li>
                <el-tooltip
                  content="This feature will be available in future releases of the Lavita platform"
                  placement="right-start"
                >
                  <li
                    id="login-driver-step-3"
                    :class="[
                      this.$route.name === 'MyResearches' ? 'active' : '',
                    ]"
                  >
                    <router-link to="" style="cursor: default"
                      ><span class="menu-bar__text"
                        ><span>
                          <svg
                            v-show="this.$route.name === 'MyResearches'"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <g
                              id="Group_8"
                              data-name="Group 8"
                              transform="translate(-9853.645 -933.549)"
                            >
                              <rect
                                id="Rectangle_10"
                                data-name="Rectangle 10"
                                width="24"
                                height="24"
                                transform="translate(9853.645 933.549)"
                                fill="none"
                              />
                              <path
                                id="Path_6"
                                data-name="Path 6"
                                d="M9868.08,941.4a1.1,1.1,0,1,1,0,2.208h-5.322a1.1,1.1,0,1,1,0-2.208Zm6.129,12.924a1.168,1.168,0,0,1-1.613,0h0l-1.528-1.481a3.458,3.458,0,0,1-1.467.332,3.313,3.313,0,1,1,3.423-3.311,3.2,3.2,0,0,1-.344,1.419l1.53,1.48a1.079,1.079,0,0,1,0,1.561Zm-4.609-3.356a1.1,1.1,0,1,0-1.14-1.1A1.124,1.124,0,0,0,9869.6,950.963Zm-4.562,1.471H9862a1.871,1.871,0,0,1-1.9-1.839v-9.568a1.871,1.871,0,0,1,1.9-1.839h8.364a.374.374,0,0,1,.38.368v5.151a1.141,1.141,0,0,0,2.281,0v-5.151a2.62,2.62,0,0,0-2.661-2.576H9862a4.12,4.12,0,0,0-4.181,4.047v9.568a4.12,4.12,0,0,0,4.181,4.047h3.041a1.1,1.1,0,1,0,0-2.208Z"
                                fill="#1cd480"
                              />
                            </g>
                          </svg>
                          <svg
                            v-show="this.$route.name !== 'MyResearches'"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <g
                              id="Group_1"
                              data-name="Group 1"
                              transform="translate(-9813.645 -933.549)"
                            >
                              <rect
                                id="Rectangle_1"
                                data-name="Rectangle 1"
                                width="24"
                                height="24"
                                transform="translate(9813.645 933.549)"
                                fill="none"
                              />
                              <path
                                id="Path_1"
                                data-name="Path 1"
                                d="M9828.08,941.4a1.1,1.1,0,1,1,0,2.208h-5.322a1.1,1.1,0,1,1,0-2.208Zm6.129,12.924a1.168,1.168,0,0,1-1.613,0h0l-1.528-1.481a3.458,3.458,0,0,1-1.467.332,3.313,3.313,0,1,1,3.423-3.311,3.2,3.2,0,0,1-.344,1.419l1.53,1.48a1.079,1.079,0,0,1,0,1.561Zm-4.609-3.356a1.1,1.1,0,1,0-1.14-1.1A1.124,1.124,0,0,0,9829.6,950.963Zm-4.562,1.471H9822a1.871,1.871,0,0,1-1.9-1.839v-9.568a1.871,1.871,0,0,1,1.9-1.839h8.364a.374.374,0,0,1,.38.368v5.151a1.141,1.141,0,0,0,2.281,0v-5.151a2.62,2.62,0,0,0-2.661-2.576H9822a4.12,4.12,0,0,0-4.181,4.047v9.568a4.12,4.12,0,0,0,4.181,4.047h3.041a1.1,1.1,0,1,0,0-2.208Z"
                                fill="#202224"
                              />
                            </g>
                          </svg>
                        </span>
                        <span
                          class="menu-bar__name"
                          style="color: #c0c4cc; margin-left: 10px"
                          >My Research</span
                        >
                      </span></router-link
                    >
                  </li>
                </el-tooltip>
                <li :class="[this.$route.name === 'Settings' ? 'active' : '']">
                  <router-link to="/settings"
                    ><span class="menu-bar__text"
                      ><span>
                        <svg
                          v-show="this.$route.name === 'Settings'"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <g
                            id="Group_13"
                            data-name="Group 13"
                            transform="translate(-9853.645 -963.549)"
                          >
                            <rect
                              id="Rectangle_13"
                              data-name="Rectangle 13"
                              width="24"
                              height="24"
                              transform="translate(9853.645 963.549)"
                              fill="none"
                            />
                            <g id="Group_12" data-name="Group 12">
                              <g id="Group_11" data-name="Group 11">
                                <path
                                  id="Path_9"
                                  data-name="Path 9"
                                  d="M9865.645,972.225a3.325,3.325,0,1,0,3.325,3.324A3.324,3.324,0,0,0,9865.645,972.225Zm0,4.987a1.662,1.662,0,1,1,1.662-1.663A1.662,1.662,0,0,1,9865.645,977.212Z"
                                  fill="#1cd480"
                                />
                                <path
                                  id="Path_10"
                                  data-name="Path 10"
                                  d="M9873.37,977.128l-.369-.212a7.572,7.572,0,0,0,0-2.735l.369-.213a2.5,2.5,0,0,0-2.494-4.322l-.37.214a7.446,7.446,0,0,0-2.368-1.365v-.426a2.493,2.493,0,1,0-4.986,0v.426a7.48,7.48,0,0,0-2.367,1.368l-.371-.215a2.495,2.495,0,1,0-2.494,4.322h0l.369.213a7.566,7.566,0,0,0,0,2.734l-.369.213a2.5,2.5,0,1,0,2.494,4.322h0l.37-.213a7.455,7.455,0,0,0,2.368,1.364v.427a2.493,2.493,0,0,0,4.986,0V982.6a7.454,7.454,0,0,0,2.368-1.368l.371.215a2.5,2.5,0,0,0,2.494-4.322h0Zm-2.118-3.138a5.863,5.863,0,0,1,0,3.117.831.831,0,0,0,.386.942l.9.52a.832.832,0,0,1-.832,1.44h0l-.9-.522a.832.832,0,0,0-1.01.137,5.8,5.8,0,0,1-2.7,1.559.832.832,0,0,0-.624.8v1.042a.831.831,0,1,1-1.661,0v-1.041a.832.832,0,0,0-.624-.8,5.812,5.812,0,0,1-2.7-1.562.831.831,0,0,0-1.01-.137l-.9.521a.831.831,0,0,1-.84-1.434l.009-.006.9-.52a.832.832,0,0,0,.386-.942,5.84,5.84,0,0,1,0-3.116.834.834,0,0,0-.387-.939l-.9-.52a.832.832,0,0,1,.831-1.441l.9.522a.831.831,0,0,0,1.009-.133,5.806,5.806,0,0,1,2.7-1.559.834.834,0,0,0,.624-.809v-1.04a.831.831,0,1,1,1.661,0v1.04a.833.833,0,0,0,.624.806,5.8,5.8,0,0,1,2.7,1.562.831.831,0,0,0,1.01.137l.9-.521a.831.831,0,1,1,.84,1.434l-.009.005-.9.52a.831.831,0,0,0-.385.939Z"
                                  fill="#1cd480"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                        <svg
                          v-show="this.$route.name !== 'Settings'"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <g
                            id="Group_5"
                            data-name="Group 5"
                            transform="translate(-9813.645 -963.549)"
                          >
                            <rect
                              id="Rectangle_4"
                              data-name="Rectangle 4"
                              width="24"
                              height="24"
                              transform="translate(9813.645 963.549)"
                              fill="none"
                            />
                            <g id="Group_4" data-name="Group 4">
                              <path
                                id="Path_4"
                                data-name="Path 4"
                                d="M9825.645,972.225a3.325,3.325,0,1,0,3.325,3.324A3.324,3.324,0,0,0,9825.645,972.225Zm0,4.987a1.662,1.662,0,1,1,1.662-1.663A1.662,1.662,0,0,1,9825.645,977.212Z"
                                fill="#202224"
                              />
                              <path
                                id="Path_5"
                                data-name="Path 5"
                                d="M9833.37,977.128l-.369-.212a7.572,7.572,0,0,0,0-2.735l.369-.213a2.5,2.5,0,0,0-2.494-4.322l-.37.214a7.446,7.446,0,0,0-2.368-1.365v-.426a2.493,2.493,0,1,0-4.986,0v.426a7.48,7.48,0,0,0-2.367,1.368l-.371-.215a2.495,2.495,0,1,0-2.494,4.322h0l.369.213a7.566,7.566,0,0,0,0,2.734l-.369.213a2.5,2.5,0,1,0,2.494,4.322h0l.37-.213a7.455,7.455,0,0,0,2.368,1.364v.427a2.493,2.493,0,0,0,4.986,0V982.6a7.454,7.454,0,0,0,2.368-1.368l.371.215a2.5,2.5,0,0,0,2.494-4.322h0Zm-2.118-3.138a5.863,5.863,0,0,1,0,3.117.831.831,0,0,0,.386.942l.9.52a.832.832,0,0,1-.832,1.44h0l-.9-.522a.832.832,0,0,0-1.01.137,5.8,5.8,0,0,1-2.7,1.559.832.832,0,0,0-.624.8v1.042a.831.831,0,1,1-1.661,0v-1.041a.832.832,0,0,0-.624-.8,5.812,5.812,0,0,1-2.7-1.562.831.831,0,0,0-1.01-.137l-.9.521a.831.831,0,0,1-.84-1.434l.009-.006.9-.52a.832.832,0,0,0,.386-.942,5.84,5.84,0,0,1,0-3.116.834.834,0,0,0-.387-.939l-.9-.52a.832.832,0,0,1,.831-1.441l.9.522a.831.831,0,0,0,1.009-.133,5.806,5.806,0,0,1,2.7-1.559.834.834,0,0,0,.624-.809v-1.04a.831.831,0,1,1,1.661,0v1.04a.833.833,0,0,0,.624.806,5.8,5.8,0,0,1,2.7,1.562.831.831,0,0,0,1.01.137l.9-.521a.831.831,0,1,1,.84,1.434l-.009.005-.9.52a.831.831,0,0,0-.385.939Z"
                                fill="#202224"
                              />
                            </g>
                          </g>
                        </svg>
                      </span>
                      <span class="menu-bar__name">Settings</span></span
                    ></router-link
                  >
                </li>
                <li :class="[this.$route.name === 'Feedback' ? 'active' : '']">
                  <router-link to="/feedback"
                    ><span class="menu-bar__text"
                      ><span>
                        <svg
                          v-show="this.$route.name === 'Feedback'"
                          fill="#1cd480"
                          width="21"
                          height="21"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.1907 18.5V17.1736H8.13445C7.29051 17.1052 6.48827 16.7892 5.83501 16.2677C5.18175 15.7462 4.70862 15.0441 4.47891 14.2554L4.28471 13.9016H3.88489C3.71759 13.8803 3.56365 13.8019 3.45079 13.6806C3.34793 13.5546 3.29163 13.399 3.29086 13.2384C3.29126 13.1221 3.32274 13.0079 3.38225 12.9068C3.44157 12.8051 3.52847 12.721 3.63357 12.6636L3.97627 12.4757V11.2156C3.99031 11.1228 4.0252 11.0342 4.0785 10.9559C4.13179 10.8776 4.20218 10.8115 4.28471 10.7624C4.34794 10.702 4.42288 10.6543 4.50522 10.6221C4.58756 10.5898 4.67567 10.5737 4.7645 10.5745H11.6186C11.7095 10.5714 11.8001 10.5864 11.8847 10.6187C11.9693 10.6509 12.0461 10.6996 12.1104 10.7619C12.1747 10.8241 12.2251 10.8984 12.2584 10.9803C12.2917 11.0622 12.3072 11.1498 12.304 11.2377V14.5538H9.44815C9.38334 14.385 9.27287 14.2361 9.12829 14.1227C8.98921 14.0059 8.819 13.9293 8.63708 13.9016C8.45145 13.88 8.26316 13.9028 8.08875 13.968C7.91684 14.035 7.76685 14.1458 7.65466 14.2885C7.54539 14.4305 7.47824 14.5987 7.46046 14.7749C7.43553 14.9519 7.46327 15.1321 7.54042 15.2944C7.61569 15.4607 7.73858 15.6026 7.89455 15.7034C8.0455 15.8076 8.22356 15.8689 8.40861 15.8803H13.6292C13.9918 15.8774 14.3388 15.7367 14.5952 15.4886C14.8516 15.2404 14.997 14.9047 15 14.5538V13.2274C15.0002 13.0521 14.9639 12.8785 14.8933 12.7171C14.8226 12.5556 14.719 12.4096 14.5888 12.2878C14.464 12.1641 14.3147 12.066 14.1498 11.9995C13.9849 11.933 13.8078 11.8995 13.6292 11.9009V10.5745C13.5647 9.20775 12.9583 7.91766 11.9359 6.97253C10.9136 6.02741 9.55409 5.5 8.14016 5.5C6.72623 5.5 5.36672 6.02741 4.34439 6.97253C3.32206 7.91766 2.7156 9.20775 2.65114 10.5745V11.8678C2.26166 12.214 2.02791 12.6942 2 13.2053C2.00247 13.6047 2.13876 13.9925 2.3884 14.3106C2.62582 14.6192 2.95148 14.8536 3.32513 14.9849C3.68436 15.9613 4.33125 16.8145 5.18592 17.4392C6.04059 18.0638 7.06562 18.4326 8.13445 18.5H10.1907Z"
                          />
                          <path
                            d="M16 2H8C7.46957 2 6.96086 2.21071 6.58579 2.58579C6.21071 2.96086 6 3.46957 6 4H15V9H20V20H6C6 20.5304 6.21071 21.0391 6.58579 21.4142C6.96086 21.7893 7.46957 22 8 22H20C20.5304 22 21.0391 21.7893 21.4142 21.4142C21.7893 21.0391 22 20.5304 22 20V8L16 2Z"
                          />
                        </svg>
                        <svg
                          v-show="this.$route.name !== 'Feedback'"
                          fill="#000000"
                          width="21"
                          height="21"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.1907 18.5V17.1736H8.13445C7.29051 17.1052 6.48827 16.7892 5.83501 16.2677C5.18175 15.7462 4.70862 15.0441 4.47891 14.2554L4.28471 13.9016H3.88489C3.71759 13.8803 3.56365 13.8019 3.45079 13.6806C3.34793 13.5546 3.29163 13.399 3.29086 13.2384C3.29126 13.1221 3.32274 13.0079 3.38225 12.9068C3.44157 12.8051 3.52847 12.721 3.63357 12.6636L3.97627 12.4757V11.2156C3.99031 11.1228 4.0252 11.0342 4.0785 10.9559C4.13179 10.8776 4.20218 10.8115 4.28471 10.7624C4.34794 10.702 4.42288 10.6543 4.50522 10.6221C4.58756 10.5898 4.67567 10.5737 4.7645 10.5745H11.6186C11.7095 10.5714 11.8001 10.5864 11.8847 10.6187C11.9693 10.6509 12.0461 10.6996 12.1104 10.7619C12.1747 10.8241 12.2251 10.8984 12.2584 10.9803C12.2917 11.0622 12.3072 11.1498 12.304 11.2377V14.5538H9.44815C9.38334 14.385 9.27287 14.2361 9.12829 14.1227C8.98921 14.0059 8.819 13.9293 8.63708 13.9016C8.45145 13.88 8.26316 13.9028 8.08875 13.968C7.91684 14.035 7.76685 14.1458 7.65466 14.2885C7.54539 14.4305 7.47824 14.5987 7.46046 14.7749C7.43553 14.9519 7.46327 15.1321 7.54042 15.2944C7.61569 15.4607 7.73858 15.6026 7.89455 15.7034C8.0455 15.8076 8.22356 15.8689 8.40861 15.8803H13.6292C13.9918 15.8774 14.3388 15.7367 14.5952 15.4886C14.8516 15.2404 14.997 14.9047 15 14.5538V13.2274C15.0002 13.0521 14.9639 12.8785 14.8933 12.7171C14.8226 12.5556 14.719 12.4096 14.5888 12.2878C14.464 12.1641 14.3147 12.066 14.1498 11.9995C13.9849 11.933 13.8078 11.8995 13.6292 11.9009V10.5745C13.5647 9.20775 12.9583 7.91766 11.9359 6.97253C10.9136 6.02741 9.55409 5.5 8.14016 5.5C6.72623 5.5 5.36672 6.02741 4.34439 6.97253C3.32206 7.91766 2.7156 9.20775 2.65114 10.5745V11.8678C2.26166 12.214 2.02791 12.6942 2 13.2053C2.00247 13.6047 2.13876 13.9925 2.3884 14.3106C2.62582 14.6192 2.95148 14.8536 3.32513 14.9849C3.68436 15.9613 4.33125 16.8145 5.18592 17.4392C6.04059 18.0638 7.06562 18.4326 8.13445 18.5H10.1907Z"
                          />
                          <path
                            d="M16 2H8C7.46957 2 6.96086 2.21071 6.58579 2.58579C6.21071 2.96086 6 3.46957 6 4H15V9H20V20H6C6 20.5304 6.21071 21.0391 6.58579 21.4142C6.96086 21.7893 7.46957 22 8 22H20C20.5304 22 21.0391 21.7893 21.4142 21.4142C21.7893 21.0391 22 20.5304 22 20V8L16 2Z"
                          />
                        </svg>
                      </span>
                      <span class="menu-bar__name">Feedback</span></span
                    ></router-link
                  >
                </li>
                <li :class="[this.$route.name === 'FAQ' ? 'active' : '']">
                  <router-link to="/faq"
                    ><span class="menu-bar__text"
                      ><span>
                        <svg
                          v-show="this.$route.name === 'FAQ'"
                          width="21"
                          height="21"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.29289 1.29289C9.48043 1.10536 9.73478 1 10 1H18C19.6569 1 21 2.34315 21 4V20C21 21.6569 19.6569 23 18 23H6C4.34315 23 3 21.6569 3 20V8C3 7.73478 3.10536 7.48043 3.29289 7.29289L9.29289 1.29289ZM18 3H11V8C11 8.55228 10.5523 9 10 9H5V20C5 20.5523 5.44772 21 6 21H18C18.5523 21 19 20.5523 19 20V4C19 3.44772 18.5523 3 18 3ZM6.41421 7H9V4.41421L6.41421 7ZM7 13C7 12.4477 7.44772 12 8 12H16C16.5523 12 17 12.4477 17 13C17 13.5523 16.5523 14 16 14H8C7.44772 14 7 13.5523 7 13ZM7 17C7 16.4477 7.44772 16 8 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H8C7.44772 18 7 17.5523 7 17Z"
                            fill="#1cd480"
                          />
                        </svg>
                        <svg
                          v-show="this.$route.name !== 'FAQ'"
                          width="21"
                          height="21"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.29289 1.29289C9.48043 1.10536 9.73478 1 10 1H18C19.6569 1 21 2.34315 21 4V20C21 21.6569 19.6569 23 18 23H6C4.34315 23 3 21.6569 3 20V8C3 7.73478 3.10536 7.48043 3.29289 7.29289L9.29289 1.29289ZM18 3H11V8C11 8.55228 10.5523 9 10 9H5V20C5 20.5523 5.44772 21 6 21H18C18.5523 21 19 20.5523 19 20V4C19 3.44772 18.5523 3 18 3ZM6.41421 7H9V4.41421L6.41421 7ZM7 13C7 12.4477 7.44772 12 8 12H16C16.5523 12 17 12.4477 17 13C17 13.5523 16.5523 14 16 14H8C7.44772 14 7 13.5523 7 13ZM7 17C7 16.4477 7.44772 16 8 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H8C7.44772 18 7 17.5523 7 17Z"
                            fill="#000000"
                          />
                        </svg>
                      </span>
                      <span class="menu-bar__name">FAQ</span></span
                    ></router-link
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="admin-menu__two mg-top-50">
            <!-- <h4 class="admin-menu__title nftmax-scolor">Account</h4> -->

            <div class="menu-bar">
              <ul class="menu-bar__one"></ul>
            </div>
          </div>
          <img
            class="logout-button"
            :class="{ 'full-sidebar': !this.$store.state.layoutDrawer }"
            src="../../../assets/img/exit.svg"
            alt="#"
            @click="() => $emit('logoutModalHandler')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    drawer: Boolean,
  },
  methods: {
    catchDrawerHandler() {
      this.$store.dispatch("layoutHandler");
    },
  },
};
</script>

<style scoped>
.nftmax-logo__main {
  width: 150px;
}
.full-sidebar {
  left: 28px;
}
</style>
