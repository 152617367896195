import Vue from "vue";
import App from "./App.vue";
import { store } from "./store/index";
import router from "./router";
import AOS from "aos";
import "aos/dist/aos.css";
import VueToastify from "vue-toastify";
import ElementUI from "element-ui";
import "./theme/index.css";
import lottie from "lottie-web";
import JsonViewer from "vue-json-viewer";

Vue.prototype.$lottie = lottie;
Vue.use(VueToastify);
Vue.use(ElementUI, { locale });
Vue.use(JsonViewer);

// css
import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/font-awesome.min.css";
import "../src/assets/css/reset.css";
import "../src/assets/css/style.css";
import "../src/assets/css/overwritten.css";
import locale from "element-ui/lib/locale/lang/en";
import "vue-json-viewer/style.css";

Vue.config.productionTip = false;
new Vue({
  store,
  router,
  mounted() {
    AOS.init();
  },
  render: (h) => h(App),
}).$mount("#app");
