<template>
  <div class="dashboard-banner dashboard-banner__active-bids mg-top-40">
    <div class="row">
      <div class="col-12">
        <div class="dashboard-banner__main">
          <div
            class="dashboard-banner__column dashboard-banner__column--two order-top"
          >
            <div class="dashboard-banner__single-slider order-top">
              <img src="../../assets/img/active-bids-img.png" alt="#" />
            </div>
          </div>
          <div class="dashboard-banner__column dashboard-banner__column--one">
            <!-- Dashboard Content -->
            <div class="dashboard-banner__content">
              <h2 class="dashboard-banner__title">
                Lock and Lob x Fiesta Spurs
              </h2>
              <p class="dashboard-banner__text">ID : 2320382</p>
            </div>

            <div class="nftmax-header__author nftmax-header__author-two">
              <div class="nftmax-header__author-img">
                <img src="../../assets/img/profile-pic-2.png" alt="#" />
              </div>
              <div class="nftmax-header__author-content">
                <h4
                  class="nftmax-header__author-title nftmax-header__author-title--two"
                >
                  Brokln Simons
                </h4>
                <p
                  class="nftmax-header__author-text nftmax-header__author-text--two"
                >
                  <a href="#">@broklinslam_75</a>
                </p>
              </div>
            </div>

            <div class="dashboard-banner__bids">
              <div class="dashboard-banner__bid">
                <!-- Single Bid -->
                <div class="dashboard-banner__group">
                  <p class="dashboard-banner__group-small">Current Bid</p>
                  <h3 class="dashboard-banner__group-title">75,320 ETH</h3>
                  <p class="dashboard-banner__group-small">
                    773.69 <span>USD</span>
                  </p>
                </div>
                <!-- End Single Bid -->
                <div class="dashboard-banner__middle-border"></div>
                <!-- Single Bid -->
                <div class="dashboard-banner__group">
                  <p class="dashboard-banner__group-small">Remaing Time</p>
                  <CountDown last-date="2023-03-04 4:00:00" />
                  <p class="dashboard-banner__group-small nftmax-timing">
                    <span>Hrs</span> <span>Min</span> <span>Sec</span>
                  </p>
                </div>
                <!-- End Single Bid -->
              </div>
            </div>

            <!-- Dashboard Banner Button -->
            <div class="dashboard-banner__button">
              <div class="dashboard-banner__single-btn">
                <a class="dashboard-banner__heart"
                  ><i class="fa-solid fa-heart"></i
                ></a>
              </div>
              <div class="dashboard-banner__single-btn">
                <a class="dashboard-banner__btn"
                  ><router-link
                    to="/active-bids"
                    class="nftmax-btn nftmax-btn__secondary radius"
                    >Place a Bid</router-link
                  ></a
                >
              </div>
              <div class="dashboard-banner__single-btn">
                <a class="dashboard-banner__btn"
                  ><router-link to="/marketplace" class="nftmax-btn trs-white"
                    >View Atwork</router-link
                  ></a
                >
              </div>
            </div>
            <!-- End Dashboard Banner Button -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountDown from "@/components/Helper/CountDown";
export default {
  components: { CountDown },
};
</script>

<style scoped></style>
