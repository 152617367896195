<template>
  <div class="row nftmax-gap-30">
    <div class="col-lg-6 col-12 nftmax-sixth-one">
      <div class="seller-list mg-top-40">
        <h3 class="seller-list__heading">Top Seller</h3>
        <div class="seller-list__slider position-relative">
          <button class="Prev slick-arrow" style="" @click="sellerPrev()">
            <svg
              width="11"
              height="19"
              viewBox="0 0 11 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.34766 17.0801L9.31224 9.11549L1.34766 1.15091"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </button>
          <VueSlickCarousel ref="sellerSlider" v-bind="settings">
            <!-- Seller Single List -->
            <div class="seller-list__single-slider">
              <div class="seller-list__single">
                <!-- Seller Head -->
                <div class="seller-list__head">
                  <div class="verfify-sign">
                    <img src="../../assets/img/verfify-sign.png" alt="#" />
                  </div>
                  <img src="../../assets/img/seller-1.png" alt="#" />
                </div>
                <!-- Seller Body -->
                <div class="seller-list__body">
                  <h4 class="sell-list__title">
                    <a href="#">Brokln Simons</a>
                  </h4>
                  <p class="sell-list__author">
                    <a href="#">@broklinslam_75</a>
                  </p>
                  <div class="sell-list__item nftmax-scolor">
                    <img src="../../assets/img/diamond-icon.png" alt="#" />3435
                    Items
                  </div>
                </div>
              </div>
            </div>
            <!-- End Seller Single List -->
            <!-- Seller Single List -->
            <div class="seller-list__single-slider">
              <div class="seller-list__single">
                <!-- Seller Head -->
                <div class="seller-list__head">
                  <div class="verfify-sign">
                    <img src="../../assets/img/verfify-sign.png" alt="#" />
                  </div>
                  <img src="../../assets/img/seller-2.png" alt="#" />
                </div>
                <!-- Seller Body -->
                <div class="seller-list__body">
                  <h4 class="sell-list__title">
                    <a href="#">Brokln Simons</a>
                  </h4>
                  <p class="sell-list__author">
                    <a href="#">@broklinslam_75</a>
                  </p>
                  <div class="sell-list__item nftmax-scolor">
                    <img src="../../assets/img/diamond-icon.png" alt="#" />3435
                    Items
                  </div>
                </div>
              </div>
            </div>
            <!-- End Seller Single List -->
            <!-- Seller Single List -->
            <div class="seller-list__single-slider">
              <div class="seller-list__single">
                <!-- Seller Head -->
                <div class="seller-list__head">
                  <div class="verfify-sign">
                    <img src="../../assets/img/verfify-sign.png" alt="#" />
                  </div>
                  <img src="../../assets/img/seller-3.png" alt="#" />
                </div>
                <!-- Seller Body -->
                <div class="seller-list__body">
                  <h4 class="sell-list__title">
                    <a href="#">Brokln Simons</a>
                  </h4>
                  <p class="sell-list__author">
                    <a href="#">@broklinslam_75</a>
                  </p>
                  <div class="sell-list__item nftmax-scolor">
                    <img src="../../assets/img/diamond-icon.png" alt="#" />3435
                    Items
                  </div>
                </div>
              </div>
            </div>
            <!-- End Seller Single List -->
            <!-- Seller Single List -->
            <div class="seller-list__single-slider">
              <div class="seller-list__single">
                <!-- Seller Head -->
                <div class="seller-list__head">
                  <div class="verfify-sign">
                    <img src="../../assets/img/verfify-sign.png" alt="#" />
                  </div>
                  <img src="../../assets/img/seller-4.png" alt="#" />
                </div>
                <!-- Seller Body -->
                <div class="seller-list__body">
                  <h4 class="sell-list__title">
                    <a href="#">Brokln Simons</a>
                  </h4>
                  <p class="sell-list__author">
                    <a href="#">@broklinslam_75</a>
                  </p>
                  <div class="sell-list__item nftmax-scolor">
                    <img src="../../assets/img/diamond-icon.png" alt="#" />3435
                    Items
                  </div>
                </div>
              </div>
            </div>
            <!-- End Seller Single List -->
            <!-- Seller Single List -->
            <div class="seller-list__single-slider">
              <div class="seller-list__single">
                <!-- Seller Head -->
                <div class="seller-list__head">
                  <div class="verfify-sign">
                    <img src="../../assets/img/verfify-sign.png" alt="#" />
                  </div>
                  <img src="../../assets/img/seller-1.png" alt="#" />
                </div>
                <!-- Seller Body -->
                <div class="seller-list__body">
                  <h4 class="sell-list__title">
                    <a href="#">Brokln Simons</a>
                  </h4>
                  <p class="sell-list__author">
                    <a href="#">@broklinslam_75</a>
                  </p>
                  <div class="sell-list__item nftmax-scolor">
                    <img src="../../assets/img/diamond-icon.png" alt="#" />3435
                    Items
                  </div>
                </div>
              </div>
            </div>
            <!-- End Seller Single List -->
          </VueSlickCarousel>
          <button class="Next slick-arrow" style="" @click="sellerNext()">
            <svg
              width="11"
              height="19"
              viewBox="0 0 11 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.09766 1.1499L1.13307 9.11449L9.09766 17.0791"
                stroke="url(#paint0_linear_220_23410)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <defs>
                <linearGradient
                  id="paint0_linear_220_23410"
                  x1="9.09766"
                  y1="1.1499"
                  x2="-4.2474"
                  y2="7.96749"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F539F8"></stop>
                  <stop offset="0.416763" stop-color="#C342F9"></stop>
                  <stop offset="1" stop-color="#34E393"></stop>
                </linearGradient>
              </defs>
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-12 nftmax-sixth-two">
      <div class="seller-list mg-top-40">
        <h3 class="seller-list__heading">Top Buyer</h3>
        <div class="seller-list__slider position-relative">
          <button class="Prev slick-arrow" style="" @click="buyerPrev()">
            <svg
              width="11"
              height="19"
              viewBox="0 0 11 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.34766 17.0801L9.31224 9.11549L1.34766 1.15091"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </button>
          <VueSlickCarousel ref="buyerSlider" v-bind="settings">
            <!-- Seller Single List -->
            <div class="seller-list__single-slider">
              <div class="seller-list__single">
                <!-- Seller Head -->
                <div class="seller-list__head">
                  <div class="verfify-sign">
                    <img src="../../assets/img/verfify-sign.png" alt="#" />
                  </div>
                  <img src="../../assets/img/seller-1.png" alt="#" />
                </div>
                <!-- Seller Body -->
                <div class="seller-list__body">
                  <h4 class="sell-list__title">
                    <a href="#">Brokln Simons</a>
                  </h4>
                  <p class="sell-list__author">
                    <a href="#">@broklinslam_75</a>
                  </p>
                  <div class="sell-list__item sell-list__v2 nftmax-pcolor">
                    <img src="../../assets/img/order-icon.png" alt="#" />$78 M
                  </div>
                </div>
              </div>
            </div>
            <!-- End Seller Single List -->
            <!-- Seller Single List -->
            <div class="seller-list__single-slider">
              <div class="seller-list__single">
                <!-- Seller Head -->
                <div class="seller-list__head">
                  <div class="verfify-sign">
                    <img src="../../assets/img/verfify-sign.png" alt="#" />
                  </div>
                  <img src="../../assets/img/seller-2.png" alt="#" />
                </div>
                <!-- Seller Body -->
                <div class="seller-list__body">
                  <h4 class="sell-list__title">
                    <a href="#">Brokln Simons</a>
                  </h4>
                  <p class="sell-list__author">
                    <a href="#">@broklinslam_75</a>
                  </p>
                  <div class="sell-list__item sell-list__v2 nftmax-pcolor">
                    <img src="../../assets/img/order-icon.png" alt="#" />$78 M
                  </div>
                </div>
              </div>
            </div>
            <!-- End Seller Single List -->
            <!-- Seller Single List -->
            <div class="seller-list__single-slider">
              <div class="seller-list__single">
                <!-- Seller Head -->
                <div class="seller-list__head">
                  <div class="verfify-sign">
                    <img src="../../assets/img/verfify-sign.png" alt="#" />
                  </div>
                  <img src="../../assets/img/seller-3.png" alt="#" />
                </div>
                <!-- Seller Body -->
                <div class="seller-list__body">
                  <h4 class="sell-list__title">
                    <a href="#">Brokln Simons</a>
                  </h4>
                  <p class="sell-list__author">
                    <a href="#">@broklinslam_75</a>
                  </p>
                  <div class="sell-list__item sell-list__v2 nftmax-pcolor">
                    <img src="../../assets/img/order-icon.png" alt="#" />$78 M
                  </div>
                </div>
              </div>
            </div>
            <!-- End Seller Single List -->
            <!-- Seller Single List -->
            <div class="seller-list__single-slider">
              <div class="seller-list__single">
                <!-- Seller Head -->
                <div class="seller-list__head">
                  <div class="verfify-sign">
                    <img src="../../assets/img/verfify-sign.png" alt="#" />
                  </div>
                  <img src="../../assets/img/seller-4.png" alt="#" />
                </div>
                <!-- Seller Body -->
                <div class="seller-list__body">
                  <h4 class="sell-list__title">
                    <a href="#">Brokln Simons</a>
                  </h4>
                  <p class="sell-list__author">
                    <a href="#">@broklinslam_75</a>
                  </p>
                  <div class="sell-list__item sell-list__v2 nftmax-pcolor">
                    <img src="../../assets/img/order-icon.png" alt="#" />$78 M
                  </div>
                </div>
              </div>
            </div>
            <!-- End Seller Single List -->
            <!-- Seller Single List -->
            <div class="seller-list__single-slider">
              <div class="seller-list__single">
                <!-- Seller Head -->
                <div class="seller-list__head">
                  <div class="verfify-sign">
                    <img src="../../assets/img/verfify-sign.png" alt="#" />
                  </div>
                  <img src="../../assets/img/seller-1.png" alt="#" />
                </div>
                <!-- Seller Body -->
                <div class="seller-list__body">
                  <h4 class="sell-list__title">
                    <a href="#">Brokln Simons</a>
                  </h4>
                  <p class="sell-list__author">
                    <a href="#">@broklinslam_75</a>
                  </p>
                  <div class="sell-list__item sell-list__v2 nftmax-pcolor">
                    <img src="../../assets/img/order-icon.png" alt="#" />$78 M
                  </div>
                </div>
              </div>
            </div>
            <!-- End Seller Single List -->
          </VueSlickCarousel>
          <button class="Next slick-arrow" style="" @click="buyerNext()">
            <svg
              width="11"
              height="19"
              viewBox="0 0 11 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.09766 1.1499L1.13307 9.11449L9.09766 17.0791"
                stroke="url(#paint0_linear_220_23410)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <defs>
                <linearGradient
                  id="paint0_linear_220_23410"
                  x1="9.09766"
                  y1="1.1499"
                  x2="-4.2474"
                  y2="7.96749"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F539F8"></stop>
                  <stop offset="0.416763" stop-color="#C342F9"></stop>
                  <stop offset="1" stop-color="#34E393"></stop>
                </linearGradient>
              </defs>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        autoplay: false,
        autoplaySpeed: 3500,
        slidesToShow: 4,
        pauseOnHover: true,
        centerMode: true,
        infinite: true,
        centerPadding: "0px",
        dots: false,
        arrows: false,
        cssEase: "ease",
        speed: 700,
        draggable: true,
        responsive: [
          {
            breakpoint: 1500,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      },
    };
  },
  methods: {
    sellerPrev() {
      this.$refs.sellerSlider.prev();
    },
    sellerNext() {
      this.$refs.sellerSlider.next();
    },
    buyerPrev() {
      this.$refs.buyerSlider.prev();
    },
    buyerNext() {
      this.$refs.buyerSlider.next();
    },
  },
};
</script>

<style scoped></style>
