<template>
  <div class="nftmax-ptabs__page">
    <h3 class="nftmax-ptabs__page-title">1. Definitions</h3>
    <p>
      What you do own when you buy an NFT are the keys to a non-fungible –
      perhaps unique – token. That token is yours to trade or hold or display in
      Decentraland. But the digital file associated with an NFT is just as easy
      to copy and paste and download as any other – the Finally, players lose
      their NFTs sometimes according to the rules and regulations of the NFT
      game.
    </p>

    <h3 class="nftmax-ptabs__page-title">2. Acceptance</h3>
    <p>
      Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
      Velit officia consequat duis enim velit mollit. Exercitation veniam
      consequat sunt nostrud amet.Capacity. You confirm that you have the legal
      capacity to receive and hold and make use of the NFT under French
      jurisdiction and any other relevant jurisdiction.Acceptance. By
      participating in the Sale, You accept and agree to these Terms and
      Conditions without any condition or restriction.If You do not agree to
      this Contract, You shall not participate in the Sale made by the Company
      Exercitation veniam consequat sunt nostrud amet.Capacity. You confirm that
      you have the legal capacity to receive and hold find to end.Contract, You
      shall not participate in the Sale made by the Company Exercitation venia
    </p>

    <blockquote>
      These Terms and Conditions are related to the sale of NFTs by the Company
      (the “Company”) on its Website. It solely governs the contractual
      relationship between You and the Company regarding the Sale and any
      related contract.
    </blockquote>

    <h3 class="nftmax-ptabs__page-title">3. The Sale</h3>
    <p>
      The Company offers NFTs featuring the Betonyou universe. The holders of
      one or more NFTs will be able to win cryptos while playing video games. In
      the future, the Company plans to develop its own games and Metaverse
      around the Betonyou universe (“Project”).
    </p>
    <p>
      To release the NFTs and fund the project, the Company offers NFTs from a
      dedicated website("Sale"). The web address of this website will be given
      at the time of the mint. The NFT acquisition does not confer any rights on
      the Company or in the future development.
    </p>

    <h3 class="nftmax-ptabs__page-title">4. Purchaser’s obligations</h3>
    <p>
      To the fullest extent permitted by applicable law, You undertake to
      indemnify, defend and hold harmless the Company from and against all
      claims, demands, actions, damages, losses, costs and expenses (including
      attorneys’ fees) that arise from or relate to (i) your Subscription or use
      of the NFTs; (ii) your responsibilities or obligations under this
      Contract; and (iii) your breach of this Contract.
    </p>

    <p>
      Company undertakes to act with the care normally expected from a
      professional in his field and to comply with the best practice in force.
      The best endeavor obligation only binds the Company.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
