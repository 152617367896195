<template>
  <div>
    <h3 class="dashboard-banner__group-title">
      {{ showHour }} : {{ showMinute }} : {{ showSecound }}
    </h3>
  </div>
</template>

<script>
export default {
  props: ["lastDate"],
  data() {
    return {
      showDate: 0,
      showHour: 0,
      showMinute: 0,
      showSecound: 0,
    };
  },
  mounted() {
    this.startInterval();
  },
  methods: {
    startInterval() {
      // count Down
      const provideDate = new Date(this.lastDate);
      // format date
      const year = provideDate.getFullYear();
      const month = provideDate.getMonth();
      // console.log(month);
      const date = provideDate.getDate();
      // console.log(date);
      const hours = provideDate.getHours();
      // console.log(hours);
      const minutes = provideDate.getMinutes();
      // console.log(minutes);
      const seconds = provideDate.getSeconds();
      // console.log(seconds)
      // date calculation logic
      const _seconds = 1000;
      const _minutes = _seconds * 60;
      const _hours = _minutes * 60;
      const _date = _hours * 24;
      const timer = setInterval(() => {
        const now = new Date();
        const distance =
          new Date(year, month, date, hours, minutes, seconds).getTime() -
          now.getTime();
        if (distance < 0) {
          clearInterval(timer);
          return;
        }
        this.showDate = Math.floor(distance / _date);
        this.showMinute = Math.floor((distance % _hours) / _minutes);
        this.showHour = Math.floor((distance % _date) / _hours);
        this.showSecound = Math.floor((distance % _minutes) / _seconds);
      }, 1000);
    },
  },
};
</script>

<style scoped></style>
