<template>
  <div class="timeline-wrap">
    <div v-if="!noUp" class="line up"></div>
    <div v-if="!noDown" class="line down"></div>
    <div class="circle"></div>
    <div class="timeline-content-wrap" :class="{ 'no-down': noDown }">
      <div class="title-wrap">
        <slot name="number"></slot>
        <div class="title">
          <slot name="title"></slot>
        </div>
      </div>
      <div class="timeline-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    noUp: { type: Boolean, default: false },
    noDown: { type: Boolean, default: false },
  },
};
</script>

<style lang="less" scoped>
.timeline-wrap {
  position: relative;
}
.line {
  position: absolute;
  left: 9px;
  width: 2px;
  background: fade(#34E393, 54%);
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;

  &.up {
    top: 0;
    height: 45px;
  }
  &.down {
    top: 65px;
    bottom: 0;
  }
}
.circle {
  position: absolute;
  top: 45px;
  width: 20px;
  height: 20px;
  border: 2px solid #34E393;
  border-radius: 100%;
}
.timeline-content-wrap {
  padding: 0 0 40px 70px;

  &.no-down {
    padding-bottom: 0;
  }
}
.title-wrap {
  position: relative;
  margin-bottom: 30px;
  font-size: 50px;
  font-weight: 500;
  color: #ecedf0;

  .title {
    position: absolute;
    top: 30px;
    left: 8px;
    font-size: 22px;
    font-weight: 500;
    color: "#34E393";
  }
}
.timeline-content {
  margin-left: 8px;
}
</style>
