<template>
  <div class="trending-action mg-top-40">
    <h2 class="trending-action__heading mg-btm-20">All Bids</h2>
    <div class="row nftmax-gap-sq30 trending-action__actives">
      <template v-for="(product, index) in products">
        <div :key="index" class="col-xxl-3 col-lg-3 col-md-6 col-12">
          <!-- Treadning Single -->
          <ProductCardStyleOne :datas="product" />
          <!-- End Treadning Single -->
        </div>
      </template>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="nftmax-view__all">
          <a href="#" class="nftmax-btn trs-white bl-color">View Atwork</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import products from "../../data/product_data.json";
import ProductCardStyleOne from "@/components/Helper/Cards/ProductCardStyleOne";
export default {
  components: { ProductCardStyleOne },
  data() {
    return {
      products: products.datas,
    };
  },
};
</script>

<style scoped></style>
