<template>
  <Layout>
    <FunFact />
    <hero-section />
    <products-section />
    <ProductTable />
  </Layout>
</template>

<script>
import Layout from "@/components/Partials/Layout";
import FunFact from "@/components/ActiveBids/FunFact";
import HeroSection from "@/components/ActiveBids/HeroSection";
import ProductsSection from "@/components/ActiveBids/ProductsSection";
import ProductTable from "@/components/HomePage/ProductTable";
export default {
  components: { ProductTable, ProductsSection, HeroSection, FunFact, Layout },
};
</script>

<style scoped></style>
