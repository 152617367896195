import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { thetaTokenBalanceOf, lavitaTokenBalanceOf } from "../lib/thetaUtil";

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    layoutDrawer: true,
    userInfo: {},
    epicInfo: {},
    walletInfo: null,
    lavitaBalance: null,
    tFuelBalance: null,
  };
};
export const store = new Vuex.Store({
  state: {
    layoutDrawer: true,
    userInfo: {},
    epicInfo: {},
    walletInfo: null,
    lavitaBalance: null,
    tFuelBalance: null,
  },
  mutations: {
    RESET_STATE: (state) => {
      Object.assign(state, getDefaultState());
    },
    SET_DRAWER: (state, value) => {
      state.layoutDrawer = value;
    },
    saveUserInfo: (state, value) => {
      state.userInfo = value;
    },
    saveEpicInfo: (state, value) => {
      state.epicInfo = value;
    },
    updateBalance: (state, value) => {
      state.lavitaBalance = value.lavitaBalance;
      state.tFuelBalance = value.tFuelBalance;
    },
    saveWalletInfo: async (state, value) => {
      state.walletInfo = value;
      const thetaBalance = await thetaTokenBalanceOf(value.address);
      state.thetaWalletBalance = thetaBalance;
      const lavitaBalance = await lavitaTokenBalanceOf(value.address);
      state.lavitaWalletBalance = lavitaBalance;
    },
    clearWalletInfo: (state) => {
      state.walletInfo = null;
      state.thetaWalletBalance = null;
      state.lavitaWalletBalance = null;
    },
    refrenshBalance: async (state) => {
      const thetaBalance = await thetaTokenBalanceOf(state.walletInfo.address);
      state.thetaWalletBalance = thetaBalance;
      const lavitaBalance = await lavitaTokenBalanceOf(
        state.walletInfo.address
      );
      state.lavitaWalletBalance = lavitaBalance;
    },
  },
  actions: {
    layoutHandler({ state, commit }) {
      commit("SET_DRAWER", !state.layoutDrawer);
    },
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })],
});
