<template>
  <div id="mycounter" class="nftmax-funfact mg-top-40">
    <!-- NFTMax Single -->
    <div class="nftmax-funfact__single">
      <div class="nftmax-funfact__icon">
        <img
          class="nftmax-funfact__img"
          src="../../assets/img/funfact-icon.png"
          alt="#"
        />
      </div>
      <div class="nftmax-funfact__content">
        <h3 class="nftmax-funfact__title">
          <b class="number"
            ><CounterUpCom selector="mycounter" :end-val="42" /></b
          ><span>K</span>
        </h3>
        <p class="nftmax-funfact__text">Artworks</p>
      </div>
    </div>
    <!-- End NFTMax Single -->
    <!-- NFTMax Single -->
    <div class="nftmax-funfact__single">
      <div class="nftmax-funfact__icon">
        <img
          class="nftmax-funfact__img nftmax-funfact__img--v2"
          src="../../assets/img/funfact-icon-2.png"
          alt="#"
        />
      </div>
      <div class="nftmax-funfact__content">
        <h3 class="nftmax-funfact__title">
          <b class="number"
            ><CounterUpCom selector="mycounter" :end-val="42" /></b
          ><span>K</span>
        </h3>
        <p class="nftmax-funfact__text">Artworks</p>
      </div>
    </div>
    <!-- End NFTMax Single -->
    <!-- NFTMax Single -->
    <div class="nftmax-funfact__single">
      <div class="nftmax-funfact__icon">
        <img
          class="nftmax-funfact__img nftmax-funfact__img--v3"
          src="../../assets/img/funfact-icon-3.png"
          alt="#"
        />
      </div>
      <div class="nftmax-funfact__content">
        <h3 class="nftmax-funfact__title">
          <b class="number"
            ><CounterUpCom selector="mycounter" :end-val="42" /></b
          ><span>K</span>
        </h3>
        <p class="nftmax-funfact__text">Artworks</p>
      </div>
    </div>
    <!-- End NFTMax Single -->
    <!-- NFTMax Single -->
    <div class="nftmax-funfact__single nftmax-funfact__single--v4">
      <div class="nftmax-funfact__icon">
        <img
          class="nftmax-funfact__img nftmax-funfact__img--v4"
          src="../../assets/img/funfact-icon-4.png"
          alt="#"
        />
      </div>
      <div class="nftmax-funfact__content">
        <h3 class="nftmax-funfact__title">
          <b class="number"
            ><CounterUpCom selector="mycounter" :end-val="42" /></b
          ><span>K</span>
        </h3>
        <p class="nftmax-funfact__text">Artworks</p>
      </div>
    </div>
    <!-- End NFTMax Single -->
  </div>
</template>

<script>
import CounterUpCom from "@/components/Helper/CounterUpCom";
export default {
  components: { CounterUpCom },
};
</script>

<style scoped></style>
