<template>
  <div class="row nftmax-gap-30">
    <div class="col-lg-3 col-md-6 col-12">
      <HistoryCard
        title="Total Sells"
        :number-of-count="71"
        :number-of-analyse="324.75"
        primary-color="#34E393"
        icon-bg="rgba(83, 86, 251, 0.16)"
        icon-src="history-icon-1.png"
      />
    </div>
    <div class="col-lg-3 col-md-6 col-12">
      <HistoryCard
        title="Active Customer"
        :number-of-count="7583"
        :number-of-analyse="324.75"
        primary-color="#F539F8"
        icon-bg="#FEEBFE"
        icon-src="history-icon-2.png"
      />
    </div>
    <div class="col-lg-3 col-md-6 col-12">
      <HistoryCard
        title="Total Prodcuts"
        :number-of-count="6753"
        :number-of-analyse="324.75"
        primary-color="#27AE60"
        icon-bg="#E9F7EF"
        icon-src="history-icon-3.png"
      />
    </div>
    <div class="col-lg-3 col-md-6 col-12">
      <HistoryCard
        title="Close Offer"
        :number-of-count="745"
        :number-of-analyse="-324.75"
        primary-color="#EB5757"
        icon-bg="#FDEEEE"
        icon-src="history-icon-4.png"
      />
    </div>
  </div>
</template>

<script>
import HistoryCard from "@/components/Helper/Cards/HistoryCard";
export default {
  components: { HistoryCard },
};
</script>

<style scoped></style>
