<template>
  <AuthLayout>
    <div class="nftmax-wc__form">
      <div class="nftmax-wc__form-inner">
        <div class="nftmax-wc__heading">
          <h3
            class="nftmax-wc__form-title nftmax-wc__form-title__one"
            style="background-image: url('../../assets/img/heading-vector.png')"
          >
            Log In
          </h3>
        </div>
        <!-- Sign in Form -->
        <form class="nftmax-wc__form-main" action="/" method="post">
          <div class="form-group">
            <label class="nftmax-wc__form-label">Email Address</label>
            <div class="form-group__input">
              <el-input v-model="userName" placeholder=""></el-input>
            </div>
          </div>
          <div class="form-group">
            <label class="nftmax-wc__form-label">Password</label>
            <div class="form-group__input">
              <el-input
                v-model="password"
                placeholder=""
                type="password"
                show-password
              ></el-input>
            </div>
          </div>
          <div class="form-group" style="text-align: right">
            <!-- <label class="nftmax-wc__form-label">Image Verification Code</label>
            <div class="form-group__input">
              <el-input v-model="imageCode" placeholder="">
                <template slot="append">
                  <img
                    ref="logInImage"
                    alt="Verification Code"
                    class="image-code"
                    @click="getImage()"
                  />
                </template>
              </el-input>
            </div> -->
            <div
              id="recaptcha-main"
              class="g-recaptcha"
              data-sitekey="6LfCDqYoAAAAAMp6KRCONh-m2ruSgU6eXJJe2_lk"
              style="display: inline-block"
            ></div>
          </div>
          <div class="form-group">
            <div class="form-group">
              <div class="nftmax-wc__check-inline">
                <!-- <div class="nftmax-wc__checkbox">
                  <input
                    id="checkbox"
                    class="nftmax-wc__form-check"
                    name="checkbox"
                    type="checkbox"
                  />
                  <label for="checkbox">Remember Me</label>
                </div> -->
                <div class="nftmax-wc__forgot">
                  <router-link
                    :to="{
                      name: 'ForgotPassword',
                      params: { previousPageName: 'Login' },
                    }"
                    class="forgot-pass"
                    >Forgot Password?</router-link
                  >
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="nftmax-wc__button">
                <button class="ntfmax-wc__btn" type="button" @click="login">
                  Log In
                </button>
                <!-- <button class="ntfmax-wc__btn ntfmax-wc__btn-google " type="submit"><div class="ntfmax-wc__btn-icon"><img src="../../assets/img/google-logo.png" alt="logo"></div>Sign In with Google</button> -->
              </div>
            </div>
            <div class="nftmax-wc__bottom">
              <p class="nftmax-wc__text" style="margin-top: 15px">
                Lavita accounts are currently by invitation only.
              </p>
              <p class="nftmax-wc__text">
                If you have an inviation code, you can sign up
                <router-link to="/signup" style="color: #34e393"
                  >here</router-link
                >.
              </p>
            </div>
          </div>
        </form>
        <!-- End Sign in Form -->
      </div>
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from "@/components/Auth/AuthLayout";
import api from "../../http/api";

export default {
  components: { AuthLayout },
  data() {
    return {
      userName: "",
      password: "",
      imageCode: "",
      token: "",
    };
  },
  computed: {
    isMobile() {
      const userAgent = navigator.userAgent;
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          userAgent
        );
      return isMobile;
    },
  },
  async mounted() {
    this.checkIfMobile();
    this.$nextTick(function () {
      window.grecaptcha.render("recaptcha-main");
    });
    // this.getImage();
  },
  methods: {
    async login() {
      const res = window.grecaptcha.getResponse();
      if (!res.length) {
        this.$vToastify.error("Please check the reCAPTCHA");
        return;
      }
      try {
        if (
          this.userName !== "" &&
          this.userName !== ""
          // && this.imageCode !== ""
        ) {
          const res = await api.logIn({
            username: this.userName,
            password: this.password.split(""),
            // imageCode: this.imageCode,
            token: this.token,
            loginSource: this.isMobile ? 1 : 0,
          });
          this.$store.commit("saveUserInfo", res.data.data);
          this.$router.push("/my-contributions");
        } else {
          this.$vToastify.error("Please fill all fields");
        }
      } catch (error) {
        console.log(error);
        if (error.data.message === "Unverified account ") {
          this.$router.push({
            name: "SignUpVerify",
            params: { userName: this.userName, password: this.password },
          });
        }
      }
    },
    // 图形验证码
    getImage() {
      api
        .createImageCode()
        .then((res) => {
          this.token = res.headers.imagetoken;
          let el;
          el = this.$refs.logInImage;
          if (!el) {
            return;
          }
          el.src = `data:image/png;base64,${btoa(
            new Uint8Array(res.data).reduce(
              (data, byte) => `${data}${String.fromCharCode(byte)}`,
              ""
            )
          )}`;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkIfMobile() {
      if (this.isMobile) {
        this.$router.push({
          name: "Signup",
        });
      }
    },
  },
};
</script>

<style scoped>
.form-group__input .el-input-group >>> .el-input-group__append {
  width: 125px;
  height: 43px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.image-code {
  width: 125px;
  height: 43px;
}
</style>
