<template>
  <div class="row nftmax-gap-sq30">
    <template v-for="(product, index) in products">
      <div :key="index" class="col-xxl-3 col-lg-3 col-md-6 col-12">
        <!-- Treadning Single -->
        <ProductCardStyleOne :datas="product" />
        <!-- End Treadning Single -->
      </div>
    </template>
  </div>
</template>

<script>
import products from "../../data/product_data.json";
import ProductCardStyleOne from "@/components/Helper/Cards/ProductCardStyleOne";
export default {
  components: { ProductCardStyleOne },
  data() {
    return {
      products: products.datas,
    };
  },
};
</script>

<style scoped></style>
