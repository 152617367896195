<template>
  <AuthLayout>
    <div v-if="finishUpdate" class="mobile-view">
      Thank you for registering on the Lavita platform! The sign-up has been
      completed successfully. <br /><br />
      Please note that the Lavita platform website is optimized for use on
      desktop browsers. Please login with your new Lavita account from a desktop
      browser to explore our platform
    </div>
    <div v-else class="nftmax-wc__form">
      <div class="nftmax-wc__form-inner">
        <div class="nftmax-wc__heading">
          <h3
            class="nftmax-wc__form-title nftmax-wc__form-title__three"
            style="
              background-image: url('../../assets/img/heading-vector-3.png');
            "
          >
            Sign Up Verification
          </h3>
        </div>
        <!-- Sign in Form -->
        <form class="nftmax-wc__form-main" action="#" method="post">
          <div class="form-group">
            <label class="nftmax-wc__form-label">Verification Code</label>
            <div class="form-group__input">
              <el-input v-model="code" placeholder=""></el-input>
            </div>
            Enter the verification code that was sent to your email address
          </div>
          <div class="form-group">
            <div class="nftmax-wc__button">
              <el-button
                class="ntfmax-wc__btn"
                type="submit"
                @click="finishUpdateHandler"
              >
                Verify
              </el-button>
            </div>
          </div>
        </form>
        <!-- End Sign in Form -->
      </div>
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from "@/components/Auth/AuthLayout";
import api from "../../http/api";

export default {
  components: { AuthLayout },
  data() {
    return {
      code: "",
      finishUpdate: false,
    };
  },
  computed: {
    isMobile() {
      const userAgent = navigator.userAgent;
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          userAgent
        );
      return isMobile;
    },
  },
  mounted() {
    this.$vToastify.success(
      "Verification code has been sent to the email address"
    );
  },
  methods: {
    async finishUpdateHandler() {
      try {
        if (this.code !== "") {
          await api.signUpVerify({
            username: this.$route.params.userName,
            code: this.code,
          });
          if (this.isMobile) {
            this.finishUpdate = true;
          } else {
            const res = await api.logIn({
              username: this.$route.params.userName,
              password: this.$route.params.password.split(""),
              token: "",
            });
            this.$store.commit("saveUserInfo", res.data.data);
            this.$router.push("/my-contributions");
          }
        } else {
          this.$vToastify.error("Please fill all fields");
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.mobile-view {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 25px;
  line-height: 35px;
  margin: 0 30px;
  text-align: center;
}
</style>
