<template>
  <Layout>
    <div class="nftmax-inner__heading">
      <h2 class="nftmax-inner__page-title">Fill Study Info</h2>
    </div>
    <div class="nftmax__item">
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        label-position="top"
      >
        <el-form-item label="Select Image Dataset">
          <el-table
            :data="imgList"
            :row-style="hideTrainRow"
            @selection-change="trainFileSelect"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column property="id" label="File ID"></el-table-column>
            <el-table-column
              property="filename"
              label="File Name"
            ></el-table-column>
            <el-table-column
              property="subType"
              label="Description"
            ></el-table-column>
            <el-table-column
              property="edgeNodeId"
              label="EdgeNode ID"
            ></el-table-column>
            <el-table-column label="Data Preview">
              <template slot-scope="scope">
                <el-button type="text" @click="showDetail(scope.row)"
                  >Preview</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="pagination"
            :current-page="1"
            :page-size="5"
            layout="total, prev, pager, next, jumper"
            :total="imgList.length"
            @current-change="trainTableChange"
          >
          </el-pagination>
        </el-form-item>

        <el-form-item label="Select Model File">
          <el-table
            :data="modelList"
            :row-style="hideTestRow"
            @selection-change="testFileSelect"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column property="id" label="Dataset ID"></el-table-column>
            <el-table-column
              property="filename"
              label="File Name"
            ></el-table-column>
            <el-table-column
              property="subType"
              label="Description"
            ></el-table-column>
            <el-table-column
              property="edgeNodeId"
              label="EdgeNode ID"
            ></el-table-column>
          </el-table>
          <el-pagination
            class="pagination"
            :current-page="1"
            :page-size="5"
            layout="total, prev, pager, next, jumper"
            :total="modelList.length"
            @current-change="testTableChange"
          >
          </el-pagination>
        </el-form-item>

        <el-form-item class="right-btn">
          <el-button type="primary" @click="startStudy">Start Study</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog title="Data Preview" :visible.sync="detailsDialog">
      <el-descriptions :column="1" :colon="false">
        <el-descriptions-item v-if="!singleImgUrl">
          <div class="zip-img-container">
            <el-image
              v-for="url in zipFilesUrlList"
              :key="url.name"
              :src="url.src"
              alt="Medical Image"
              class="zip-img"
              lazy
            ></el-image>
          </div>
        </el-descriptions-item>
        <el-descriptions-item v-else>
          <img :src="singleImgUrl" alt="Medical Image" class="img" />
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </Layout>
</template>

<script>
import Layout from "@/components/Partials/Layout";
import api from "../../http/api";
import { Loading } from "element-ui";
import JSZip from "jszip";

export default {
  components: { Layout },
  data() {
    return {
      form: {
        studyName: "",
        description: "",
        privacy: "Public",
        sgx: "",
        analysisMethodId: 51,
      },
      sgxServerList: [
        {
          value: "US",
          label: "Los Angeles",
        },
        {
          value: "US",
          label: "New York",
        },
        {
          value: "Canada",
          label: "Toronto",
        },
        {
          value: "Japan",
          label: "Tokyo",
        },
        {
          value: "Australia",
          label: "Sydney",
        },
        {
          value: "France",
          label: "Paris",
        },
      ],
      imgList: [],
      modelList: [],
      selectedTrainFiles: [],
      selectedTestFiles: [],
      split_ratio: 0.6,
      trainPageNum: 1,
      testPageNum: 1,
      zipFilesUrlList: [],
      singleImgUrl: null,
      detailsDialog: false,
    };
  },
  computed: {
    computedId() {
      const testID = this.selectedTestFiles.map((item) => {
        return item.parameters.id;
      });
      const trainID = this.selectedTrainFiles.map((item) => {
        return item.parameters.id;
      });
      if (!testID.length && !trainID.length) {
        return "";
      }
      const idList = Array.from(new Set([...testID, ...trainID]));
      return idList.length ? idList[0] : "";
    },
    computedY() {
      const testY = this.selectedTestFiles.map((item) => {
        return item.parameters.y;
      });
      const trainY = this.selectedTrainFiles.map((item) => {
        return item.parameters.y;
      });
      if (!testY.length && !trainY.length) {
        return "";
      }
      const yList = Array.from(new Set([...testY, ...trainY]));
      return yList.length ? yList[0] : "";
    },
  },
  async mounted() {
    let loading = Loading.service({
      fullscreen: true,
      text: "loading data...",
    });
    try {
      const res = await api.getFilesList({
        type: null,
        edgeNodeId: null,
        filename: "",
        privacy: null,
        startTime: null,
        endTime: null,
        page: 1,
        perPage: 10000,
        userId: this.$store.state.userInfo.userId,
      });
      this.imgList = res.data.data.list
        .map((item) => {
          return {
            ...item,
          };
        })
        .filter((item) => item.type === 2);
      this.modelList = res.data.data.list
        .map((item) => {
          return {
            ...item,
          };
        })
        .filter((item) => item.type === 4);
      console.log(this.imgList);
      console.log(this.modelList);
    } catch (error) {
      console.log(error);
    } finally {
      loading.close();
    }
  },
  methods: {
    async showDetail(row) {
      console.log(row);
      // 清空之前的图片数据
      this.singleImgUrl = null;
      this.zipFilesUrlList = [];
      const res = await api.getImage({
        filename: row.filename,
        type: row.type,
      });
      if (row.filename.includes(".zip")) {
        // 处理压缩图片包
        const zipData = res.data;
        const zip = new JSZip();
        zip.loadAsync(zipData).then((zip) => {
          zip.forEach((relativePath, file) => {
            file.async("base64").then((base64Data) => {
              const imageElement = new Image();
              imageElement.src = `data:image/jpeg;base64,${base64Data}`;
              this.zipFilesUrlList.push({
                name: file.name,
                src: imageElement.src,
              });
            });
          });
        });
      } else {
        // 处理单张图片
        this.singleImgUrl = window.URL.createObjectURL(res.data);
      }
      this.detailsDialog = true;
    },
    // eslint-disable-next-line no-unused-vars
    hideTrainRow({ row, rowIndex }) {
      let array = this.imgList;
      let pageNo = this.trainPageNum;
      const pageSize = 5;
      const offset = (pageNo - 1) * pageSize;
      const isInPage =
        rowIndex >= offset &&
        rowIndex < Math.min(array.length, offset + pageSize);
      return isInPage ? {} : { display: "none" };
    },
    // eslint-disable-next-line no-unused-vars
    hideTestRow({ row, rowIndex }) {
      let array = this.modelList;
      let pageNo = this.testPageNum;
      const pageSize = 5;
      const offset = (pageNo - 1) * pageSize;
      const isInPage =
        rowIndex >= offset &&
        rowIndex < Math.min(array.length, offset + pageSize);
      return isInPage ? {} : { display: "none" };
    },
    trainTableChange(val) {
      this.trainPageNum = val;
    },
    testTableChange(val) {
      this.testPageNum = val;
    },
    trainFileSelect(val) {
      this.selectedTrainFiles = val;
    },
    testFileSelect(val) {
      try {
        this.selectedTestFiles = val;
      } catch (error) {
        console.log(error);
      }
    },
    // 求数组交集
    getIntersection(arr) {
      const features = arr.reduce((data, item) => {
        return data.filter((i) => {
          return item.some((j) => {
            return i === j;
          });
        });
      });
      return features;
    },
    async startStudy() {
      let loading = Loading.service({
        fullscreen: true,
        text: "Connecting to server...",
      });
      setTimeout(function () {
        loading.setText("EdgeNode data-coordination in progress ...");
      }, 3000);
      setTimeout(function () {
        loading.setText("Remote attestation in progress ...");
      }, 7000);
      setTimeout(function () {
        loading.setText("Encrypted data uploading to SGX in progress ...");
      }, 11000);
      setTimeout(async () => {
        try {
          const {
            id,
            analysisMethodId,
            name,
            privacy,
            description,
            startDate,
            endDate,
          } = this.$route.params.studyInfo;
          const params = {
            analysisMethodId,
          };
          const body = {
            studyId: id,
            analysisMethodId: analysisMethodId,
            compositeStudyId: null,
            parameters: params,
            server: "0.0.0.0",
            model: null,
            dataSources: [],
            userId: this.$store.state.userInfo.userId,
            studyInfo: {
              name,
              privacy,
              startDate,
              endDate,
              description,
              userId: this.$store.state.userInfo.userId,
            },
            trainingStudyId: null,
            l1Token: "",
            l2Token: "",
          };
          await api.startStudy(body);
          loading.close();
          this.$alert("Sucessful - Study Started", "", {
            confirmButtonText: "OK",
            callback: () => {
              this.$router.push("/my-researches");
            },
          });
        } catch (error) {
          this.$alert("Start Study Failed", "Error");
        } finally {
          loading.close();
        }
      }, 15000);
    },
  },
};
</script>

<style scoped>
.method-tooltip {
  width: 300px;
  font-size: 16px;
}
.tag-container {
  width: 400px;
}
.tag {
  margin: 5px;
}
.right-btn {
  text-align: right;
}
.pagination {
  margin-top: 20px;
  margin-bottom: 40px;
}
.zip-img-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 三列等宽 */
  grid-gap: 30px;
}
.zip-img {
  width: 100%; /* 图片宽度占满列 */
  height: auto; /* 高度自适应，保持正方形 */
  max-width: 100%; /* 最大宽度100% */
}
</style>
