<template>
  <div class="nftmax-wallet__dashboard">
    <div class="row nftmax-gap-40">
      <!-- <div class="col-lg-6 col-6 col-12 nftmax-wallet__one">
        NFTMax Balance Hover
        <BalanceWidget />
      </div> -->
      <div>
        <!-- NFTMax Balance Hover -->
        <div id="login-driver-step-1" class="nftmax-balance__all mg-top-30">
          <div class="inline">
            <h3 class="nftmax-balance__all-title">My Wallet</h3>
          </div>
          <!-- NFTMax Balance List -->
          <ul class="nftmax-balance_list">
            <li class="nftmax-balance__all-single">
              <div class="nftmax-balance-info">
                <div class="nftmax-balance__img">
                  <img src="../../assets/img/lavita.png" alt="#" />
                </div>
                <h4 class="nftmax-balance-name">vLAVITA</h4>
              </div>
              <div class="nftmax-balance-desc">
                <div class="nftmax-balance-desc__main">
                  <h4 class="nftmax-balance-amount">
                    {{
                      $store.state.lavitaBalance !== null
                        ? parseFloat($store.state.lavitaBalance).toFixed(4)
                        : "-"
                    }}
                  </h4>
                </div>
              </div>
            </li>
            <li class="nftmax-balance__all-single">
              <div class="nftmax-balance-info">
                <div class="nftmax-balance__img">
                  <img src="../../assets/img/tfuel-logo.png" alt="#" />
                </div>
                <h4 class="nftmax-balance-name">vTFUEL</h4>
              </div>
              <div class="nftmax-balance-desc">
                <div class="nftmax-balance-desc__main">
                  <h4 class="nftmax-balance-amount">
                    {{
                      $store.state.tFuelBalance !== null
                        ? parseFloat($store.state.tFuelBalance).toFixed(4)
                        : "-"
                    }}
                  </h4>
                </div>
              </div>
            </li>
          </ul>
          <div id="login-driver-step-0" class="update-btn">
            <el-button
              class="nftmax-btn nftmax-btn__bordered bg radius"
              @click="clickUpdateBalanceBtn"
              >Update Balance</el-button
            >
          </div>
        </div>
        <!-- End NFTMax Balance Hover -->
      </div>
    </div>
    <div class="meatamask-link inline">
      <el-button
        type="info"
        class="nftmax-btn nftmax-btn__bordered grey-bg radius"
        @click="addToken"
        >Add vLAVITA to Metamask</el-button
      >
      <i
        class="el-icon-question inline-icon"
        @click="hintDialogVisible = true"
      ></i>
    </div>
    <el-dialog
      title="Connect to your wallet"
      :visible.sync="walletDialog"
      width="40%"
    >
      <el-form ref="form">
        <el-form-item label="Private key">
          <el-input v-model="privateKey"></el-input>
        </el-form-item>
      </el-form>
      <div class="nftmax__item-button--group">
        <el-button @click="createNewWallet"> Create new wallet </el-button>
        <el-button type="primary" @click="connectWallet">
          Connect to your wallet
        </el-button>
      </div>
    </el-dialog>
    <!-- End Logout Modal -->
    <el-dialog
      title="Connet wallet success"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <div class="">Wallet created successfully!</div>
      <div>
        Here's your private key, it's very important, please keep it safely
      </div>
      <br />
      <div>
        {{ $store.state.walletInfo ? $store.state.walletInfo.privateKey : "" }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">OK</el-button>
      </span>
    </el-dialog>

    <!-- 引导视频弹窗 -->
    <el-dialog
      title="Please watch this short video before proceeding"
      :visible.sync="tourDialogVisible"
      width="70%"
      :before-close="handleTourDialogClose"
      center
    >
      <div class="tour-video">
        <video
          id="loginTourVideo"
          ref="loginTourVideo"
          controls
          width="800"
          height="480"
        >
          <source src="/video/login-tour.mp4" type="video/mp4" />
          Your browser does not support the HTML5 video tag.
        </video>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleTourDialogClose"
          >Close</el-button
        >
      </span>
    </el-dialog>
    <!-- add token按钮提示弹窗 -->
    <el-dialog
      title="Hint"
      :visible.sync="hintDialogVisible"
      width="40%"
      center
    >
      <div class="hint-text">
        The "Add vLAVITA to Metamask" button allows you to include the vLAVITA
        token in your MetaMask plugin for balance display. Alternatively, you
        can manually input the vLAVITA token address into MetaMask:
      </div>
      <br />
      <div class="hint-text">0x7c1aae7bd03c577af08f51b536bfeeba516db877</div>
      <br />
      <div class="hint-text">
        Please note that the process might take longer than a minute to
        complete. We recommend performing this operation directly in the
        MetaMask plugin first
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="hintDialogVisible = false"
          >Close</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import BalanceWidget from "@/components/HomePage/BalanceWidget";
import api from "../../http/api";
import { lavitaTokenBalanceOf } from "../../lib/thetaUtil";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import detectEthereumProvider from "@metamask/detect-provider";

export default {
  components: {},
  data() {
    return {
      walletDialog: false,
      privateKey: "",
      dialogVisible: false,
      tourDialogVisible: false,
      hintDialogVisible: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!sessionStorage.getItem("finishFirstLogin")) {
        sessionStorage.setItem("finishFirstLogin", true);
        this.$vToastify
          .prompt({
            title: "",
            body: "Start tour guide for digital wallet?",
            canTimeout: true,
            duration: 3000,
          })
          .then((value) => {
            if (value) {
              this.tourDialogVisible = true;
            }
          });
      }
      this.updateBalance();
    },
    startTour() {
      const driverObj = driver({
        showProgress: true,
        overlayOpacity: 0.4,
        steps: [
          {
            element: "#login-driver-step-0",
            popover: {
              title: "Connect Wallet",
              description: "Click here to connect to your MetaMask wallet",
            },
          },
          {
            element: "#login-driver-step-1",
            popover: {
              title: "Wallet Information",
              description: "You can check your token balances here",
            },
          },
          {
            element: "#login-driver-step-2",
            popover: {
              title: "My Data",
              description:
                "Use this section to access your health data on Lavita platform",
            },
          },
          {
            element: "#login-driver-step-3",
            popover: {
              title: "My Research",
              description:
                "This section will become available in future releases of the platform soon.",
            },
          },
          {
            element: "#login-driver-step-4",
            popover: {
              title: "The Marketplace",
              description:
                "This section will become available in future releases of the platform soon.",
            },
          },
        ],
      });
      driverObj.drive();
    },
    handleTourDialogClose() {
      this.tourDialogVisible = false;
      this.startTour();
    },
    async addToken() {
      if (window.ethereum) {
        const loading = this.$loading({
          lock: true,
          text: "Connecting to MetaMask...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.8)",
        });
        try {
          loading.setText("Requesting Accounts...");
          await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          const networkId = 360890;
          loading.setText("Adding Chain...");
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: `0x${networkId.toString(16)}`,
                chainName: "Lavita Network", // 网络名称
                rpcUrls: [process.env.VUE_APP_RPC_URL], // RPC URL
                nativeCurrency: {
                  name: "VTFUEL",
                  symbol: "VTFUEL",
                  decimals: 18,
                },
              },
            ],
          });
          loading.setText("Switching Chain...");
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: `0x${networkId.toString(16)}` }],
          });
          this.$alert(
            "We are adding vLAVITA token in MetaMask, this may take more than one minute with several pop-ups <br/><br/> Also you can click the MetaMask browser extension in the top right corner to check if there are any pop-ups that haven't been displayed <br/><br/> Thank you for waiting",
            "Hint",
            {
              dangerouslyUseHTMLString: true,
            }
          );
          loading.setText(
            "Adding vLAVITA token, this may take more than one minute with several steps, please wait..."
          );
          await window.ethereum.request({
            method: "wallet_watchAsset",
            params: {
              type: "ERC20",
              options: {
                address: process.env.VUE_APP_TOKEN_ADDRESS,
                symbol: "vLAVITA",
                decimals: 18,
                image: "https://i.ibb.co/BsQgbym/lavita.png",
              },
            },
          });
          // 获取货币余额
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          if (accounts.length === 0) {
            this.$alert("There's no MetaMask account connected, please check");
            loading.close();
            return;
          }
          const balance = await window.ethereum.request({
            method: "eth_getBalance",
            params: [accounts[0]],
          });
          const { Web3 } = require("web3");
          const etherBalance = Web3.utils.fromWei(balance, "ether");
          const res = await lavitaTokenBalanceOf(accounts[0]);
          this.$store.commit("updateBalance", {
            lavitaBalance: res,
            tFuelBalance: etherBalance,
          });
          loading.close();
          this.$alert("MetaMask is connected and you can see token balances");
        } catch (error) {
          loading.close();
          // this.$alert("Finished");
          console.log(error);
        }
      } else {
        this.$alert("MetaMask is not installed, please check");
      }
    },
    async clickUpdateBalanceBtn() {
      // 绑定用户钱包
      const provider = await detectEthereumProvider();
      const accounts = await provider.request({
        method: "eth_requestAccounts",
      });
      let toAddress = "";
      if (accounts.length > 0) {
        toAddress = window.ethereum.selectedAddress;
      }
      await api.registerWallet({
        userId: this.$store.state.userInfo.userId,
        address: toAddress,
      });
      this.updateBalance();
    },
    async updateBalance() {
      if (window.ethereum) {
        const loading = this.$loading({
          lock: true,
          text: "Updating balance...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.8)",
        });
        try {
          await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          const networkId = 360890;
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: `0x${networkId.toString(16)}`,
                chainName: "Lavita Network", // 网络名称
                rpcUrls: [process.env.VUE_APP_RPC_URL], // RPC URL
                nativeCurrency: {
                  name: "VTFUEL",
                  symbol: "VTFUEL",
                  decimals: 18,
                },
              },
            ],
          });
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: `0x${networkId.toString(16)}` }],
          });
          // 获取货币余额
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          if (accounts.length === 0) {
            this.$alert("There's no MetaMask account connected, please check");
            loading.close();
            return;
          }
          const balance = await window.ethereum.request({
            method: "eth_getBalance",
            params: [accounts[0]],
          });
          const { Web3 } = require("web3");
          const etherBalance = Web3.utils.fromWei(balance, "ether");
          const res = await lavitaTokenBalanceOf(accounts[0]);
          this.$store.commit("updateBalance", {
            lavitaBalance: res,
            tFuelBalance: etherBalance,
          });
          loading.close();
          this.$vToastify.success("Balance updated successfully");
        } catch (error) {
          loading.close();
          this.$alert("Update balance failed, please try again");
          console.log(error);
        }
      } else {
        this.$alert("MetaMask is not installed, please check");
      }
    },
    async createNewWallet() {
      // try {
      //   const newWallet = await createThetaWallet();
      //   this.$store.commit("saveWalletInfo", newWallet);
      //   await api.registerWallet({
      //     userId: this.$store.state.userInfo.userId,
      //     address: newWallet.address,
      //   });
      //   this.dialogVisible = true;
      //   this.walletDialog = false;
      // } catch (error) {
      //   console.log(error);
      //   this.$alert("Create failed");
      // }
    },
    async connectWallet() {
      // try {
      //   if (this.privateKey === "") {
      //     this.$alert("Please enter private key");
      //     return;
      //   }
      //   const newWallet = await getThetaWallet(this.privateKey);
      //   await api.registerWallet({
      //     userId: this.$store.state.userInfo.userId,
      //     address: newWallet.address,
      //   });
      //   this.$store.commit("saveWalletInfo", newWallet);
      //   this.$alert("Connect successfully!");
      //   this.walletDialog = false;
      // } catch (error) {
      //   console.log(error);
      //   this.$alert("Connect failed");
      // }
    },
    async disconnectWallet() {
      try {
        this.$store.commit("clearWalletInfo");
        await api.revokeWallet({
          userId: this.$store.state.userInfo.userId,
        });
      } catch (error) {
        console.log(error);
        this.$alert("Disconnect failed");
      }
    },
  },
};
</script>

<style scoped>
.tour-video {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.update-btn {
  display: flex;
  justify-content: center;
}
.inline {
  display: flex;
  justify-content: space-between;
}
.inline-icon {
  margin-left: 5px;
  cursor: pointer;
  position: relative;
  top: 8px;
}
.grey-bg {
  background-color: #909399 !important;
  color: #fff !important;
  font-size: 14px;
}
.custom-hint {
  width: 800px;
}
.hint-text {
  font-size: 18px;
  font-weight: bold;
  word-break: keep-all;
}
.meatamask-link {
  display: flex;
  justify-content: flex-end;
  padding-right: 7px;
  margin-top: 50px;
}
</style>
