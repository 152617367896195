<template>
  <Layout>
    <div class="nftmax-personals">
      <h2 class="nftmax-personals__title">Frequently Asked Questions</h2>
      <el-collapse v-model="activeNames" accordion>
        <el-collapse-item
          title="Can you explain what the Lavita Platform is and what it does?"
          name="1"
        >
          <div class="collapse-item">
            Lavita aims to provide an all-in-one platform that enables users to
            (1) access all their health data in a unified portal, (2) utilize
            their health data to receive personalized and relevant health and
            wellness advice, and (3) monetize their health data, if they choose
            to, by participating in health research and studies, while ensuring
            their data remains private. Lavita incentivizes individuals and
            healthcare institutions to share healthcare data, and be rewarded
            through "vLAVITA" digital tokens, built on the Theta blockchain
            ecosystem. Furthermore, the digital token is utilized to reward
            users not only from their health data, but also for sharing their
            storage and computation resources.
            <br />
            <br />
            Lavita's privacy-preserving computing primitives ensure that users'
            data remain private during the computation phase. This
            privacy-preserving computing platform enables sharing encrypted data
            and analyses without exposing raw data to potential privacy threats.
          </div>
        </el-collapse-item>
        <el-collapse-item
          title="How do I link my MyChart account with Lavita?"
          name="2"
        >
          <div class="collapse-item">
            Through “My Data” section of the platform, users can authenticate
            their local device to access their health records from Epic's
            MyChart (other EHR platforms are currently being integrated and will
            be rolled out in future). The EHR data is immediately encrypted upon
            retrieval from MyChart, with private key only accessible to the
            users themselves. Thus, only users can view and access their health
            data by using their unique private key.
          </div>
        </el-collapse-item>
        <el-collapse-item
          title="What advantages does Lavita offer over traditional health data management systems?"
          name="3"
        >
          <div class="collapse-item">
            <ul class="circle-list">
              <li>
                Lavita platform is an all-in-one data platform to bring various
                health data in one easy to view and manage.
              </li>
              <li>
                Lavita ensures data privacy through encryption and
                privacy-preserving technologies.
              </li>
              <li>
                Lavita enables individuals to monetize their data, through
                informed consent, by participating in health research and
                studies.
              </li>
            </ul>
          </div>
        </el-collapse-item>
        <el-collapse-item
          title="What security measures does Lavita use to protect my personal health information?"
          name="4"
        >
          <div class="collapse-item">
            <ul class="circle-list">
              <li>
                All users' data is encrypted and accessible only through private
                keys held by users themselves. Lavita never stores users'
                private keys,
              </li>
              <li>
                Users will utilize their local device to run small AI models
                that will provide personalized and useful health and data
                insights, without having to send their data to an outside server
                or third-party service provider.
              </li>
              <li>
                Data contributed to health research and studies are transmitted
                through an end-to-end encrypted channel, which connects to
                Lavita's secure computation enclaves powered by Intel SGX
                Trusted Execution Environment.
              </li>
            </ul>
          </div>
        </el-collapse-item>
        <el-collapse-item
          title="How can I manage permissions and access to my health data on Lavita?"
          name="5"
        >
          <div class="collapse-item">
            After connecting their Lavita account with their EHR accounts, users
            need to authenticate access to their data through with their private
            key (which only users have access to, i.e., Lavita never stores
            private keys), as well as 2FA through one-time-passcode sent to
            users registered email address. In future, Lavita platform will add
            features for users to share their data securely with their health
            providers and trusted family members.
          </div>
        </el-collapse-item>
        <el-collapse-item
          title="What does it mean to tokenize my health data and how can I benefit from this?"
          name="6"
        >
          <div class="collapse-item">
            Lavita never stores personal data on-chain, and never tokenizes
            health data for use on the blockchain. However, Lavita enables users
            to monetize their data and earn token rewards by securely
            participating in health research and studies.
          </div>
        </el-collapse-item>
        <el-collapse-item
          title="What types of health insights can I expect to receive from Lavita's AI analysis?"
          name="7"
        >
          <div class="collapse-item">
            Lavita Medical AI Engine is empowered by state-of-the-art Large
            Language Models (LLMs) that continually fine-tuned and improved for
            health and medical applications, using data from published research
            as well as input from medical experts. Lavita AI aims to bring the
            most personalized health advice for users, while ensuring their data
            privacy.
          </div>
        </el-collapse-item>
        <el-collapse-item
          title="Can you detail the technologies Lavita employs to keep my data private while being used for research?"
          name="8"
        >
          <div class="collapse-item">
            Lavita uses secure computation technologies based on Intel-SGX
            Trusted Execution Environment (TEE) architecture to provide
            end-to-end encrypted communication channel and secure computation
            enclave. This technology is augmented with additional data privacy
            techniques such as multi-party computation and differential privacy,
            to ensure maximum security for users' data. In additional, Lavita AI
            will publish local AI models which users can run locally on their
            device to obtain AI-powered personalized health information, without
            having to share their health data outside their local device.
          </div>
        </el-collapse-item>
        <el-collapse-item
          title="Are there any fees for using Lavita, or is it free for all users?"
          name="9"
        >
          <div class="collapse-item">
            There are no fees for using Lavita AI platform.
          </div>
        </el-collapse-item>
        <el-collapse-item
          title="How can I provide feedback or get technical support for issues?"
          name="10"
        >
          <div class="collapse-item">
            Users can utilize the “Feedback” section of the navigation menu to
            provide feedback about the platform and receive help with any
            technical questions.
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/Partials/Layout";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      activeNames: "",
    };
  },
  methods: {},
};
</script>

<style scoped>
.collapse-item {
  padding-left: 20px;
}
.circle-list {
  list-style-type: disc;
}
</style>
