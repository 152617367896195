<template>
  <div class="row">
    <div>
      <!-- Sign in Form -->
      <form class="nftmax-wc__form-main" action="#" method="post">
        <div
          v-if="!$route.params.email && !$route.params.verificationCode"
          class="form-group"
        >
          <label class="nftmax-wc__form-label">Old</label>
          <el-input
            v-model="oldPassword"
            placeholder=""
            show-password
          ></el-input>
        </div>
        <div class="form-group">
          <label class="nftmax-wc__form-label">New</label>
          <el-input v-model="password" placeholder="" show-password></el-input>
        </div>
        <div class="form-group">
          <label class="nftmax-wc__form-label">Re-enter</label>
          <el-input
            v-model="renterPassword"
            placeholder=""
            show-password
          ></el-input>
        </div>
        <!-- <div class="form-group">
          <div class="nftmax-wc__button">
            <el-button
              class="ntfmax-wc__btn"
              type="submit"
              @click="finishUpdateHandler"
            >
              Continue
            </el-button>
          </div>
        </div>
        <div class="form-group">
          <div class="nftmax-wc__button">
            <button
              class="ntfmax-wc__btn ntfmax-wc__btn-grey"
              type="submit"
              @click="back"
            >
              Back
            </button>
          </div>
        </div> -->
      </form>
      <!-- End Sign in Form -->
      <div class="nftmax__item-button--group nftmax__ptabs-bottom">
        <!-- <button class="nftmax__item-button--single nftmax__item-button--cancel">
          Cancel
        </button> -->
        <el-button
          class="nftmax__item-button--single nftmax-btn nftmax-btn__bordered bg radius"
          @click="finishUpdateHandler"
        >
          Update Password
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../../http/api";

export default {
  data() {
    return {
      oldPassword: "",
      password: "",
      renterPassword: "",
      finishUpdate: false,
    };
  },
  methods: {
    async finishUpdateHandler() {
      try {
        if (this.password !== "" && this.renterPassword !== "") {
          if (this.password === this.renterPassword) {
            const passwordRegex =
              /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/;
            if (!passwordRegex.test(this.password)) {
              this.$vToastify.warning(
                "The password must contain at least 6 digits, including at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."
              );
              return;
            }
            // 根据this.$route.params中有没有值判断用户是在登录前后进入更新密码页面的
            if (
              this.$route.params &&
              this.$route.params.email &&
              this.$route.params.verificationCode
            ) {
              await api.forgotPassword({
                email: this.$route.params.email,
                verificationCode: this.$route.params.verificationCode,
                newPassword: this.password.split(""),
              });
              this.finishUpdate = !this.finishUpdate;
            } else {
              if (this.oldPassword === "") {
                this.$vToastify.error("Please fill all fields");
                return;
              }
              console.log({
                username: this.$store.state.userInfo.username,
                password: this.oldPassword,
                newPassword: this.password.split(""),
              });
              await api.resetPassword({
                username: this.$store.state.userInfo.username,
                password: this.oldPassword,
                newPassword: this.password.split(""),
              });
              this.$vToastify.success("Password updated");
            }
          } else {
            this.$vToastify.error("Password doesn't match");
          }
        } else {
          this.$vToastify.error("Please fill all fields");
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped></style>
