<template>
  <div class="trending-action__single">
    <!-- Trending Head -->
    <div class="trending-action__head">
      <div class="trending-action__button">
        <a class="trending-action__btn heart-icon"
          ><i class="fa-solid fa-heart"></i
        ></a>
        <a class="trending-action__btn"
          ><i class="fa-solid fa-ellipsis-vertical"></i
        ></a>
      </div>
      <img :src="require(`@/assets/img/${product.thumbnil}`)" alt="#" />
    </div>
    <!-- Trending Body -->
    <div class="trending-action__body">
      <div class="trending-action__author-meta">
        <div class="trending-action__author-img">
          <img src="../../../assets/img/author-pic.png" alt="#" />
        </div>
        <p class="trending-action__author-name">
          Owned by
          <router-link to="/profile">{{ product.username }}</router-link>
        </p>
      </div>
      <h2 class="trending-action__title">
        <router-link to="active-bids">{{ product.title }}</router-link>
      </h2>
      <div class="dashboard-banner__bid dashboard-banner__bid-v2">
        <!-- Single Bid -->
        <div class="dashboard-banner__group dashboard-banner__group-v2">
          <p class="dashboard-banner__group-small">Current Bid</p>
          <h3 class="dashboard-banner__group-title">{{ product.price }}</h3>
        </div>
        <!-- End Single Bid -->
        <div class="dashboard-banner__middle-border"></div>
        <!-- Single Bid -->
        <div class="dashboard-banner__group dashboard-banner__group-v2">
          <p class="dashboard-banner__group-small">Remaing Time</p>
          <CountDown :last-date="product.remaing" />
        </div>
        <!-- End Single Bid -->
      </div>
    </div>
    <div class="dashboard-banner__button trending-action__bottom">
      <router-link
        to="active-bids"
        class="nftmax-btn nftmax-btn__secondary radius"
        >Place a Bid</router-link
      >
      <router-link to="/marketplace" class="nftmax-btn trs-white"
        >View Art Work</router-link
      >
    </div>
  </div>
</template>

<script>
import CountDown from "@/components/Helper/CountDown";
export default {
  components: { CountDown },
  props: ["datas"],
  data() {
    return {
      product: this.datas,
    };
  },
};
</script>

<style scoped></style>
