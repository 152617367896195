const orgListR4 = [
  {
    Hospital: "Access Community Health Network",
    "Epic Endpiont":
      "https://eprescribing.accesscommunityhealth.net/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://www.myaccesshealth.net/myaccesshealth/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Adult & Pediatric Ear, Nose & Throat – Kalamazoo",
    "Epic Endpiont": "https://hygieia.bronsonhg.org/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.bronsonhealth.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "AdvantageCare Physicians",
    "Epic Endpiont": "https://epwebapps.acpny.com/FHIRproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://my.acpny.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "AdventHealth",
    "Epic Endpiont": "https://mobile.adventhealth.com/oauth2-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://account.adventhealth.com/register",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Akron Children's Hospital",
    "Epic Endpiont":
      "https://haiku-canto-prod.chmca.org/ARR-FHIR-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.akronchildrens.org/mychart/Authentication/Login?mode=stdfile&option=proxylandingpage",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Alameda Health System",
    "Epic Endpiont":
      "https://epicproxy.et1075.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://my.alamedahealthsystem.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Allegheny Health Network",
    "Epic Endpiont": "https://epicprisfd.ahn.org/PRD-FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.ahn.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Allina Health System",
    "Epic Endpiont": "https://webproxy.allina.com/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://account.allinahealth.org/registration",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Altais",
    "Epic Endpiont":
      "https://epicproxy.et1138.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.altais.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "AltaMed",
    "Epic Endpiont":
      "https://epicproxy.et1123.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.myaltamed.net/MyAltaMed/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Altru Health System",
    "Epic Endpiont": "https://epicsoap.altru.org/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.altru.org/myhealth/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "AnMed Health",
    "Epic Endpiont":
      "https://epicproxy.et0971.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.anmedhealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Ann & Robert H. Lurie Children's Hospital of Chicago",
    "Epic Endpiont":
      "https://epicmobile.luriechildrens.org/Interconnect-FHIRPRD/api/FHIR/R4/",
    "Endpoint Access Issue": "TRUE",
    "Sign-up URL": "",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "",
  },
  {
    Hospital: "Ardent",
    "Epic Endpiont": "https://epicproxy.ardenthealth.com/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.ardenthealth.com/PRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Arizona Community Physicians",
    "Epic Endpiont":
      "https://interconnect.azacp.com/interconnect-oauth2-prd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.azacp.com/MyChartPRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Arkansas Children's",
    "Epic Endpiont": "https://fhir.archildrens.org/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://go.archildrens.org/WR-2019-08-MyChartOnlineSignUp_LP-MyChart-Online-Sign-Up.html",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Arrowhead Regional Medical Center",
    "Epic Endpiont":
      "https://epicproxy.et1152.epichosted.com/fhirproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.arrowheadregional.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Asante Health Systems",
    "Epic Endpiont": "https://epicmobile.asante.org/FHIR-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.asante.org/MyChart-AHS/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Ascension Illinois",
    "Epic Endpiont":
      "https://epicmobile.presencehealth.org/fhirPRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.presencehealth.org/mychart/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Ascension Providence",
    "Epic Endpiont": "https://stofo.providence-waco.org/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychartwa.providence.org/mychart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Ascension Wisconsin",
    "Epic Endpiont": "https://eprescribe.wfhc.org/FHIRproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "",
    "Online Sign-up Access Issue": "No Online Sign Up",
    "Include in Platform": "",
  },
  {
    Hospital: "Aspen Valley Hospital",
    "Epic Endpiont":
      "https://epicproxy.et1254.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.aspenhospital.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Aspirus",
    "Epic Endpiont": "https://erx.aspirus.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://myaspirus.org/MyChart/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Atlantic Health",
    "Epic Endpiont":
      "https://soapproxy.atlantichealth.org/FHIRPrd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.atlantichealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Atrium – Morehouse Healthcare",
    "Epic Endpiont":
      "https://epicproxy.et0905.epichosted.com/FHIRproxy/MHC/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://my.atriumhealth.org/myatriumhealth/publicforms.asp?mode=showform&formname=SignUpOne",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Atrium Health",
    "Epic Endpiont":
      "https://epicproxy.et0905.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://my.atriumhealth.org/myatriumhealth/publicforms.asp?mode=showform&formname=SignUpOne",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Atrium Health Wake Forest Baptist",
    "Epic Endpiont": "https://w1soap.wakehealth.edu/fhirproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://my.atriumhealth.org/myatriumhealth/publicforms.asp?mode=showform&formname=SignUpOne",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Atrius Health",
    "Epic Endpiont": "https://iatrius.atriushealth.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://myhealth.atriushealth.org/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Aurora Health Care - LiveWell",
    "Epic Endpiont": "https://EpicFHIR.aurora.org/FHIR/LiveWell/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://livewell.aah.org/Chart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Austin Regional Clinic",
    "Epic Endpiont": "https://mobileprod.arcmd.com/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.austinregionalclinic.com/mychart/Authentication/Login?",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Ballad Health",
    "Epic Endpiont": "https://soap.wellmont.org/FHIRPRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.balladhealth.org/mychart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Baptist Health - Northeast Florida",
    "Epic Endpiont":
      "https://epicproxy.et1206.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://my.baptistchart.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Baptist Health – KY & IN",
    "Epic Endpiont": "https://epicproxy.bhsi.com/PRD-FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.baptisthealth.com/MyChart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Baptist Health (Arkansas)",
    "Epic Endpiont":
      "https://api.baptist-health.org/Interconnect-FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.baptist-health.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Baptist Memorial Health Care",
    "Epic Endpiont": "https://rxedi.bmhcc.org/prd-fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.baptistonecare.org/PRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Bassett Healthcare",
    "Epic Endpiont": "https://soap.bassett.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://www.mybassetthealthconnection.org/MyChart-PRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "BayCare Clinic - myBayCare",
    "Epic Endpiont":
      "https://EpicFHIR.aurora.org/fhirproxy/MYBAYCARE/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://my.baycare.net/BaycareClinicsMyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Bayhealth Medical Center",
    "Epic Endpiont": "https://epproxy.bayhealth.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://epmychart.bayhealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Baylor College of Medicine",
    "Epic Endpiont": "https://fhir.clinical.bcm.edu/stage1fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.bcm.edu/MyChartPRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Baylor Scott & White",
    "Epic Endpiont":
      "https://epicproxy.bswhealth.org/FHIR-PRD/BSW/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://my.bswhealth.com/login",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Beaumont Health",
    "Epic Endpiont": "https://moc.beaumont.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mybeaumontchart.com/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Bellin Health",
    "Epic Endpiont": "https://arr.thedacare.org/FHIR/BLN/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.mybellin.org/MyChart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Beth Israel Lahey Health",
    "Epic Endpiont": "https://fhir.laheyhealth.org/proxy-prd-fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://bilh.org/patient-portal",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "BJC & Washington University",
    "Epic Endpiont":
      "https://epicproxy.et0965.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.mypatientchart.org/MyChart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Bon Secours Health System",
    "Epic Endpiont": "https://haiku.bshsi.org/fhir/BSHSI_OAUTH/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.bonsecours.com/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Boston Medical Center",
    "Epic Endpiont": "https://emerge-soap1.bmc.org/FHIR-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.bmc.org/MyChart/Authentication/Login?",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Boulder Community Health",
    "Epic Endpiont": "https://prevprox.bch.org/FHIRproxyPRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://my.bch.org/MyChartPRD/Authentication/Login?",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Bozeman Health",
    "Epic Endpiont":
      "https://revproxy.bh.bozemanhealth.org/Interconnect-Oauth2-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mc.bh.bozemanhealth.org/MyChartPRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Bronson Healthcare Group",
    "Epic Endpiont": "https://hygieia.bronsonhg.org/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.bronsonhealth.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Brookwood Baptist Health",
    "Epic Endpiont": "https://epicproxy.bhsala.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.bhsala.com/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Bryan Health",
    "Epic Endpiont":
      "https://epicproxy.et1031.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.bryanhealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Buffalo Medical Group",
    "Epic Endpiont":
      "https://fhir.buffalomedicalgroup.com/fhir-arr/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.mybmgchart.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Caldera Family Medicine",
    "Epic Endpiont":
      "https://epicproxy.et4001.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://caldera.epicmychart.com/cfm/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Cambridge Health Alliance",
    "Epic Endpiont":
      "https://epicmobile.challiance.org/Interconnect-oauth2/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.challiance.org/Mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Cape Cod Healthcare",
    "Epic Endpiont":
      "https://epicproxy.et1149.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.capecodhealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Cape Fear Valley Health",
    "Epic Endpiont":
      "https://epicproxy.et1094.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.capefearvalley.com/mychart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Care New England",
    "Epic Endpiont": "https://cnesp001.carene.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.carene.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Carilion Clinic",
    "Epic Endpiont": "https://fhir.carilionclinic.org/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.carilionclinic.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Carle Foundation Hospital & Physician Group",
    "Epic Endpiont":
      "https://epicproxy.et0316.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mycarle.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Caromont Health",
    "Epic Endpiont": "https://spp.caromonthealth.org/FhirProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.caromonthealth.org/MyChart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Cascade Internal Medicine",
    "Epic Endpiont":
      "https://epicproxy.et4001.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://cascadeinternal.epicmychart.com/cim/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Catholic Health (Long Island NY)",
    "Epic Endpiont": "https://epx1.chsli.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.chsli.org/MyChartProd/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Catholic Health System (Buffalo)",
    "Epic Endpiont":
      "https://epicproxy.et1144.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.chsbuffalo.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Cedars-Sinai Health System",
    "Epic Endpiont": "https://cslinkmobile.csmc.edu/fhirproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mycslink.cedars-sinai.org/mycslink/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "CentraCare Health and Affiliates",
    "Epic Endpiont": "https://epicmobile.centracare.com/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.centracare.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Central Ohio Primary Care Physicians",
    "Epic Endpiont": "https://rp-prd.copcp.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.copcp.com/MyChart/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Centura Health",
    "Epic Endpiont": "https://epic-p-mobile.centura.org/prd-fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://epic.mycenturahealth.org/MCH/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Charlotte Eye Ear Nose & Throat Associates",
    "Epic Endpiont": "https://fhirprd.ceenta.com/proxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://myceentachart.com/MyChartPRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Chesapeake Regional Medical Center",
    "Epic Endpiont": "https://haiku.bshsi.org/fhir/CRMC_OAUTH/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.chesapeakeregional.com/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Cheyenne Regional Medical Center",
    "Epic Endpiont": "https://soap.crmcwy.org/fhirproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.crmcwy.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "CHI Health",
    "Epic Endpiont": "https://rp.chihealth.com/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.chihealth.com/PRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "CHI Memorial",
    "Epic Endpiont":
      "https://rpsouth.catholichealth.net/fhir/FHIRMCT/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.memorial.org/TN/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "CHI Saint Joseph Health",
    "Epic Endpiont":
      "https://rpsouth.catholichealth.net/fhir/FHIRKY/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.chisaintjosephhealth.org/KY/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "CHI St. Alexius Health",
    "Epic Endpiont": "https://rp.chihealth.com/fhir/FHIRSTA/api/FHIR/R4",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychartsta.chihealth.com/STAPRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "CHI St. Luke's Health",
    "Epic Endpiont": "https://rpsouth.catholichealth.net/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.sleh.com/TX/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "CHI St. Vincent",
    "Epic Endpiont": "https://epic-fhir.mercy.net/PRDFHIRSGF/CHI/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "",
    "Online Sign-up Access Issue": "No Online Sign Up",
    "Include in Platform": "",
  },
  {
    Hospital: "Children's Health System of Texas",
    "Epic Endpiont": "https://fhir.childrens.com/prd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.childrens.com/MyChart/publicforms.asp?mode=showform&formname=ProxyAccessRequest",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Children's Hospital and Medical Center, Omaha Nebraska",
    "Epic Endpiont": "https://EPROXY1.chsomaha.org/FHIRPROXY/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://connect.childrensomaha.org/MyChart/publicforms.asp?mode=showForm&formname=ActivationReq",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Children's Hospital Colorado",
    "Epic Endpiont": "https://fhir.childrenscolorado.org/fhirprd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.childrenscolorado.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Children's Hospital of Philadelphia",
    "Epic Endpiont": "https://epicnsproxy.chop.edu/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychop.chop.edu/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Children's of Alabama",
    "Epic Endpiont":
      "https://epicproxy.et1246.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.childrensal.org/MyChart/Authentication/Login?mode=stdfile&option=ProxyAccessReqLandingPg",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Children's Wisconsin",
    "Epic Endpiont":
      "https://epicproxy.et0815.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.chw.org/MyChart/publicforms.asp?mode=showForm&formname=AccessCodeRequest",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Childrens's Healthcare of Atlanta",
    "Epic Endpiont": "https://wpprod.choa.org/FHIR_PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.choa.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "CHRISTUS Health",
    "Epic Endpiont": "https://proxy.christushealth.org/FHIRPRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.christushealth.org/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Cigna Medical Group",
    "Epic Endpiont":
      "https://epicarr.healthcare.cigna.com/FHIR-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "TRUE",
    "Sign-up URL": "",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "",
  },
  {
    Hospital: "Cincinnati Children's Hospital Medical Center",
    "Epic Endpiont": "https://boomer.cchmc.org/fhir/api/fhir/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.cincinnatichildrens.org/MyChart/cchmc-online-enroll.html",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "City of Hope",
    "Epic Endpiont":
      "https://epic-rproxyprod.coh.org/Interconnect-FHIR-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.mycityofhope.org/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Cleveland Clinic",
    "Epic Endpiont": "https://api.ccf.org/mu/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.clevelandclinic.org/public/signup/",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Columbia Physicians",
    "Epic Endpiont":
      "https://epicproxy-pub.et1089.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.columbiadoctors.org/patients/access-connect",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Columbus Regional Health",
    "Epic Endpiont": "https://epicprdproxy.crh.org/FHIRPRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.crh.org/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Community Health Network",
    "Epic Endpiont": "https://esp.ecommunity.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.ecommunity.com/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Community Healthcare System",
    "Epic Endpiont": "https://webproxy.comhs.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.comhs.org/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Community Medical Centers",
    "Epic Endpiont":
      "https://epicsoapprd.communitymedical.org/arr_fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.communitymedical.org/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Community Technology Cooperative",
    "Epic Endpiont":
      "https://epicproxy.et1290.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychartepic.c3ctc.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "CommUnityCare Health Centers",
    "Epic Endpiont":
      "https://epicproxy.et1164.epichosted.com/FHIRproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.ochin.org/MyChartSeattleroots/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Cone Health",
    "Epic Endpiont": "https://epsoap.conehealth.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.conehealth.com/MyChart//signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Conemaugh Health System",
    "Epic Endpiont": "https://fhir.conemaugh.org/arr-fhir-prd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.conemaugh.org/MyChart-2017-PRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Confluence Health",
    "Epic Endpiont":
      "https://epicproxy.et0764.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.confluencehealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Connecticut Children's Medical Center",
    "Epic Endpiont":
      "https://epicproxy.connecticutchildrens.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.connecticutchildrens.org/MYC-PRD/publicforms.asp?mode=showform&formname=signupform",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Contra Costa",
    "Epic Endpiont": "https://icproxy.mycclink.org/proxy-FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.cchealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Cook Children’s Health Care System",
    "Epic Endpiont": "https://cookicfg.cookchildrens.org/CookFHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.cookchildrens.org/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Cooper University Health Care",
    "Epic Endpiont":
      "https://epicproxy.et0578.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://my.cooperhealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Cottage Health",
    "Epic Endpiont": "https://eparp.sbch.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.sbch.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Covenant Health",
    "Epic Endpiont": "https://haiku.bshsi.org/fhir/COV_OAUTH/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.covh.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Covenant HealthCare",
    "Epic Endpiont": "https://epichaiku.chs-mi.com/FHIRPROXY/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.chs-mi.com/MyChartPRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "CVS Health & Minute Clinic",
    "Epic Endpiont":
      "https://retailepicfhir.cvshealth.com/FhirProxy/api/fhir/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.cvshealth.com/MyChartPRD/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Dartmouth-Hitchcock",
    "Epic Endpiont":
      "https://edhwebportal.hitchcock.org/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://portal.mydh.org/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "DaVita Physician Solutions",
    "Epic Endpiont":
      "https://epicproxy.et1087.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.davitaphysiciansolutions.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Dayton Children's Hospital",
    "Epic Endpiont":
      "https://appprd.childrensdayton.org/interconnect-prd-fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mykidschart.childrensdayton.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Deaconess Health System",
    "Epic Endpiont": "https://eprp.deaconess.com/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.viewmychart.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Denver Health",
    "Epic Endpiont": "https://webservices.dhha.org/PRD-FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.denverhealth.org/MyChart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Dr. Bruce W Moskowitz, MD",
    "Epic Endpiont":
      "https://epicproxy.et4001.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "",
    "Online Sign-up Access Issue": "No Online Sign Up",
    "Include in Platform": "",
  },
  {
    Hospital: "Dr. Burket's Office",
    "Epic Endpiont":
      "https://epicproxy.et4001.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "",
    "Online Sign-up Access Issue": "No Online Sign Up",
    "Include in Platform": "",
  },
  {
    Hospital: "Drexel Medicine",
    "Epic Endpiont":
      "https://epicproxy.et4001.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://drexel.epicmychart.com/drx/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Driscoll Children's Hospital",
    "Epic Endpiont": "https://fhir.dchstx.org/FHIR-External/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.dchstx.org/MyChart/publicforms.asp?mode=showForm&formname=ActivationReqSelf",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Duke Health",
    "Epic Endpiont": "https://health-apis.duke.edu/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.dukemychart.org/Home/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Duly Health and Care",
    "Epic Endpiont":
      "https://epicproxy.et1296.epichosted.com/APIProxyPRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.dupagemd.net/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "East Boston Neighborhood Health Center",
    "Epic Endpiont": "https://ebmobile14.ebnhc.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.ebnhc.org/mychart/accesscheck.asp?lang=english",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "East Cascades Women's Group",
    "Epic Endpiont":
      "https://epicproxy.et4001.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://eastcascadewomens.epicmychart.com/ecwg/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Edward-Elmhurst Healthcare",
    "Epic Endpiont": "https://fhirprd.edward.org/fhirprd/EEHOAUTH/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.eehealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Eisenhower Medical Center",
    "Epic Endpiont": "https://epicarr.emc.org/EMC_FHIR_PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.eisenhowerhealth.org/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "El Camino Hospital",
    "Epic Endpiont": "https://rwebproxy.elcaminohospital.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mycare.elcaminohospital.org/MyChart/Authentication/Login?mode=stdfile&option=signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "El Rio Health",
    "Epic Endpiont":
      "https://epicproxy.et1240.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://elriomychart.org/MyChart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Emory Healthcare",
    "Epic Endpiont": "https://epicrp-prd.eushc.org/OAUTH2-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.emoryhealthcare.org/MyChart-prd/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Englewood Hospital and Medical Center",
    "Epic Endpiont":
      "https://epicproxy.et1073.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.englewoodhealth.org/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Enloe Health",
    "Epic Endpiont":
      "https://epicproxy.et1034.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.enloe.org/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "EPIC Management (Beaver Medical Group)",
    "Epic Endpiont":
      "https://epicproxy.et1038.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.mybeaverchart.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Erlanger Health System",
    "Epic Endpiont":
      "https://epicproxy.et0967.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.erlanger.org/mychart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Eskenazi Health",
    "Epic Endpiont": "https://proxy.eskenazihealth.edu/FHIR-Proxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.eskenazihealth.edu/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Essentia Health",
    "Epic Endpiont": "https://m.essentiahealth.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://communityconnect.essentiahealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "EvergreenHealth",
    "Epic Endpiont":
      "https://epicproxy.et1270.epichosted.com/apiproxyprd/api/FHIR/R4",
    "Endpoint Access Issue": "TRUE",
    "Sign-up URL":
      "https://mychart.evergreenhealth.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "",
  },
  {
    Hospital: "Evernorth",
    "Epic Endpiont":
      "https://epicarr.healthcare.cigna.com/FHIR-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "TRUE",
    "Sign-up URL":
      "https://mychart.evernorthcaregroup.com/Chart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "",
  },
  {
    Hospital: "Fairview Health Services",
    "Epic Endpiont": "https://sfd.fairview.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.fairview.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Fall Creek Internal Medicine",
    "Epic Endpiont":
      "https://epicproxy.et4001.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://fallcreek.epicmychart.com/fcim/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Family Care Network",
    "Epic Endpiont": "https://epicproxy.fcn.net/Fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.fcn.net/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Family Health Center (Michigan)",
    "Epic Endpiont": "https://hygieia.bronsonhg.org/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.fhckzoo.com/MyChartFHC/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "FastMed",
    "Epic Endpiont": "https://external.fastmed.com/FHIRproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.fastmed.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "FirstHealth of the Carolinas",
    "Epic Endpiont": "https://epicrp.firsthealth.org/FHIR-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.firsthealthmychart.org/mychartprd/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Foothill Family Clinic",
    "Epic Endpiont":
      "https://epicproxy.et4001.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://foothill.epicmychart.com/ffc/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Franciscan Alliance",
    "Epic Endpiont":
      "https://ema.franciscanalliance.org/FHIR_PROXY/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.franciscanmychart.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Franciscan Missionaries of Our Lady Health System",
    "Epic Endpiont":
      "https://epicproxy.et0830.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.fmolhs.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Fresenius Medical Care North America",
    "Epic Endpiont":
      "https://epicproxy.et1041.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://patientlogin.fmcna.com/auth/realms/patienthub/login-actions/registration?client_id=https%3A%2F%2Ffmcna-healthcloud.my.salesforce.com&tab_id=bkTrM37n6R8",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Froedtert Health",
    "Epic Endpiont":
      "https://epicserviceGW.froedtert.com/FHIRProxyPRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.my.froedtert.com/MyChartFS/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Garnet Health",
    "Epic Endpiont": "https://epic.garnethealth.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.garnethealth.org/GH/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Geisinger",
    "Epic Endpiont": "https://geisapi.geisinger.edu/FHIR_PROD/api/FHIR/R4/",
    "Endpoint Access Issue": "TRUE",
    "Sign-up URL": "https://apps.mycarecompass.org/MyChart/Registration",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "",
  },
  {
    Hospital: "Genesis Healthcare System",
    "Epic Endpiont": "https://fhir.genesishcs.org/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.genesishcs.org/MyChart/Authentication/Login?",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "George Washington University Medical Faculty Associates",
    "Epic Endpiont":
      "https://epicproxy.et1222.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.gwdocs.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Golden Valley Health Centers",
    "Epic Endpiont": "https://ep-rps.gvhc.org/FHIR-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.gvhc.org/MyChartPRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Grady Health System",
    "Epic Endpiont": "https://surescripts.gmh.edu/OAuth2PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.gradyhealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Greater Baltimore Medical Center",
    "Epic Endpiont": "https://eportal.gbmc.org/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://eportal.gbmc.org/MyChart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Group Health Cooperative - South Central Wisconsin",
    "Epic Endpiont":
      "https://linkpoint.ghcscw.com/Interconnect-prd-fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://ghcmychart.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Gundersen Health System",
    "Epic Endpiont": "https://scproxy.gundersenhealth.org/FHIRARR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.gundersenhealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Hackensack Meridian Health",
    "Epic Endpiont": "https://mepic.hmhn.org/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.hmhn.org/MyChart/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Hamilton Health Care System",
    "Epic Endpiont":
      "https://epicproxy.et1336.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.hamiltonhealth.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Harris Health System",
    "Epic Endpiont": "https://fhir.harrishealth.org/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://myhealth.harrishealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Hartford HealthCare",
    "Epic Endpiont": "https://epicproxy.hhchealth.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "TRUE",
    "Sign-up URL": "https://mychartplus.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "",
  },
  {
    Hospital: "Hattiesburg Clinic and Forrest General Hospital",
    "Epic Endpiont": "https://soapprod.hattiesburgclinic.com/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://iris.forrestgeneral.com/Iris/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Hawaii Pacific Health",
    "Epic Endpiont":
      "https://webservices.hawaiipacifichealth.org/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.hawaiipacifichealth.org/web/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "HCA Central and West Texas",
    "Epic Endpiont":
      "https://stdavidsfhirprd.app.medcity.net/fhir-proxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://myhealthone.com/mh1/public/mh1/Login/#/signup-form",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "HCA Mountain",
    "Epic Endpiont":
      "https://mountainstarhealthfhirprd.app.medcity.net/fhir-proxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.mountainstarhealth.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "HCA South Atlantic",
    "Epic Endpiont":
      "https://memorialhealthfhirprd.app.medcity.net/fhir-proxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://myhealthone.com/mh1/public/mh1/Login/#/signup-form",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Health Choice Network",
    "Epic Endpiont":
      "https://epicproxy.et1256.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.hcnetwork.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "HealthPartners",
    "Epic Endpiont": "https://proxy.healthpartners.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.healthpartners.com/account/create/start",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Hendricks Regional Health",
    "Epic Endpiont":
      "https://sehproxy.stelizabeth.com/arr-fhir/HRH/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.hendricks.org/mychart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Hennepin Healthcare",
    "Epic Endpiont": "https://hie.hcmed.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.hcmed.org/MyChart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Henry Ford Health System",
    "Epic Endpiont": "https://fhir.hfhs.org/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.hfhs.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Highland District Hospital",
    "Epic Endpiont": "https://epicscripts.trihealth.com/fhirproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.hdh.org/MyChartConnect//signup/",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Hill Physicians",
    "Epic Endpiont":
      "https://epicproxy.et1013.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.myhillchart.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Hoag Memorial Hospital Presbyterian",
    "Epic Endpiont":
      "https://epicproxy.et1197.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.hoagconnect.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "HonorHealth",
    "Epic Endpiont":
      "https://interconnect.honorhealth.com/Interconnect-FHIR-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.honorhealth.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Hospital for Special Surgery",
    "Epic Endpiont":
      "https://epicproxy.et0927.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://myhss.hss.edu/MyHSS/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Hospital Sisters Health System (HSHS)",
    "Epic Endpiont": "https://scripts.prevea.com/FHIR-ARR-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.myhshs.org/MyHSHS/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Houston Methodist",
    "Epic Endpiont":
      "https://epicproxy.et0922.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.houstonmethodist.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Hurley Medical Center",
    "Epic Endpiont": "https://fhir.hurleymc.com/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.hurleymc.com/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Illinois Bone & Joint Institute",
    "Epic Endpiont":
      "https://epicproxy.et1195.epichosted.com/fhirproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.ibji.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Infirmary Health",
    "Epic Endpiont":
      "https://ssproxyprod.infirmaryhealth.org/epicFHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.myihchart.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Innovage",
    "Epic Endpiont": "https://rp.innovage.com/OAUTH2-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.innovage.com/mychartprd/Authentication/Login?",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Inova Health System",
    "Epic Endpiont": "https://epicrpprd.inova.org/fhirrp/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.inova.org/mychart-signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Institute for Family Health",
    "Epic Endpiont": "https://epicproxy.institute.org/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.institute.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "INTEGRIS Health",
    "Epic Endpiont":
      "https://FHIR.Integrisok.com/Interconnect-FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://integrisandme.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Intermountain Healthcare",
    "Epic Endpiont": "https://sclprdproxy.sclhs.net/FHIRPRD-2017/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://myhealthplus.intermountainhealthcare.org/landing",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Jefferson Health",
    "Epic Endpiont": "https://fhir.jefferson.edu/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://my.jeffersonhealth.org/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "John Muir Health",
    "Epic Endpiont": "https://fhir.johnmuirhealth.com/fhir-prd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.johnmuirhealth.com/mychartmcmprd/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Johns Hopkins Medicine",
    "Epic Endpiont":
      "https://epicmobile.johnshopkins.edu/FHIR_PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.hopkinsmedicine.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "JPS Health Network",
    "Epic Endpiont":
      "https://oauth2.revprxprd.jpshealth.org/epoauth2/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://jpsmychart.jpshealth.org/jpsmychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Kaiser Permanente - California - Northern",
    "Epic Endpiont":
      "https://FHIR.KP.ORG/service/ptnt_care/EpicEdiFhirRoutingSvc/v2014/esb-envlbl/312/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://healthy.kaiserpermanente.org/register",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Kaiser Permanente - California - Southern",
    "Epic Endpiont":
      "https://fhir.kp.org/service/ptnt_care/EpicEdiFhirRoutingSvc/v2014/esb-envlbl/212/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://healthy.kaiserpermanente.org/register",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Kaiser Permanente - Colorado",
    "Epic Endpiont":
      "https://fhir.kp.org/service/ptnt_care/EpicEdiFhirRoutingSvc/v2014/esb-envlbl/140/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://healthy.kaiserpermanente.org/register",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Kaiser Permanente - Maryland/Virginia/Washington D.C.",
    "Epic Endpiont":
      "https://fhir.kp.org/service/ptnt_care/EpicEdiFhirRoutingSvc/v2014/esb-envlbl/170/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://healthy.kaiserpermanente.org/register",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Kaiser Permanente - Washington",
    "Epic Endpiont": "https://fhir.kp.org/Interconnect-FHIR-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://healthy.kaiserpermanente.org/register",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Kaiser Permanente – Georgia",
    "Epic Endpiont":
      "https://fhir.kp.org/service/ptnt_care/EpicEdiFhirRoutingSvc/v2014/esb-envlbl/200/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://healthy.kaiserpermanente.org/register",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Kaiser Permanente – Oregon – SW Washington",
    "Epic Endpiont":
      "https://FHIR.KP.ORG/service/ptnt_care/EpicEdiFhirRoutingSvc/v2014/esb-envlbl/190/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://healthy.kaiserpermanente.org/register",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Kaiser Permanente Hawaii / Maui Health System",
    "Epic Endpiont":
      "https://fhir.kp.org/service/ptnt_care/EpicEdiFhirRoutingSvc/v2014/esb-envlbl/130/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://healthy.kaiserpermanente.org/register",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Kalamazoo College Student Health Center",
    "Epic Endpiont": "https://hygieia.bronsonhg.org/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.kzoo.edu/MyChartKzoo/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Kalamazoo Foot Surgery",
    "Epic Endpiont": "https://hygieia.bronsonhg.org/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.bronsonhealth.com/MyChartKFS/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Kelsey-Seybold Clinic",
    "Epic Endpiont": "https://ssrx.ksnet.com/FhirProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.mykelseyonline.com/Mychart/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Kennedy Krieger Institute",
    "Epic Endpiont":
      "https://epicproxy.et1095.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.kennedykrieger.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Kettering Health Network",
    "Epic Endpiont": "https://khnarr.ketthealth.com/FHIR-PROD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.ketteringhealth.org/MyChartPRD/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "KeyCare",
    "Epic Endpiont":
      "https://epicproxy.et1284.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.keycare.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "King's Daughters Medical Center",
    "Epic Endpiont": "https://arrprd.kdmc.net/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.kdmc.net/MyChartProduction/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Kootenai Health",
    "Epic Endpiont": "https://soapprod.multicare.org/FHIRProxy/KH/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://kh.ccmychart.org/KH/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Lacy C Kessler, MD, PA",
    "Epic Endpiont":
      "https://epicproxy.bswhealth.org/FHIR-PRD/CONNECT/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "",
    "Online Sign-up Access Issue": "No Online Sign Up",
    "Include in Platform": "",
  },
  {
    Hospital: "Lakeland Regional Health (FL)",
    "Epic Endpiont": "https://epicapp.mylrh.org/OAuth2/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.mylrh.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Lancaster General Health",
    "Epic Endpiont": "https://epicproxy.lghealth.org/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "TRUE",
    "Sign-up URL": "",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "",
  },
  {
    Hospital: "LCMC Health",
    "Epic Endpiont": "https://interconnect.lcmchealth.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://patientportal.lcmchealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Lee Health",
    "Epic Endpiont": "https://epicedi.leememorial.org/FHIR-prd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.leememorial.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Legacy Health",
    "Epic Endpiont": "https://lhspdxfhirprd.lhs.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://myhealth.lhs.org/MyHealth/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Lehigh Valley Health Network",
    "Epic Endpiont": "https://proxy.lvh.com/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.mylvhn.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Leon Medical Centers",
    "Epic Endpiont":
      "https://prodinterconnect.leonmedicalcenters.com/FHIR-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://leonmediconnect.com/MyChartLeonMediConnect/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Lexington Medical Center",
    "Epic Endpiont": "https://lmcrcs.lexmed.com/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.lexmed.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Licking Memorial Health Systems",
    "Epic Endpiont":
      "https://epicproxy.et1168.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.lmhealth.org/MyChart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Lifespan",
    "Epic Endpiont": "https://lsepprdsoap.lifespan.org/fhirproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://my.lifespan.org/MyChartPRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Loma Linda University Health and CareConnect Partners",
    "Epic Endpiont": "https://fhir.lluh.org/interconnect-fhir-prd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mylluhealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Loyola Medicine",
    "Epic Endpiont": "https://rxhub.luhs.org/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://myloyola.luhs.org/mychart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Luminis Health",
    "Epic Endpiont": "https://epicarr.aahs.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.aahs.org/MyChartAccess/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Main Line Health",
    "Epic Endpiont":
      "https://epicproxy.et1007.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://my.mainlinehealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "MaineHealth",
    "Epic Endpiont": "https://fhir.mainehealth.org/FHIRPRD/api/FHIR/R4/",
    "Endpoint Access Issue": "TRUE",
    "Sign-up URL": "",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "",
  },
  {
    Hospital: "Mary Greeley Medical Center (Iowa)",
    "Epic Endpiont":
      "https://emrproxy.mcfarlandclinic.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://www.mychartiowa.com/MyChartPRD/Authentication/Login?",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Mary Washington Healthcare",
    "Epic Endpiont":
      "https://epicproxy.et1055.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.mwhc.com/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Mass General Brigham",
    "Epic Endpiont":
      "https://ws-interconnect-fhir.partners.org/Interconnect-FHIR-MU-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://patientgateway.massgeneralbrigham.org/MyChart-PRD/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Mayo Clinic",
    "Epic Endpiont": "https://mcc.api.mayo.edu/epic-fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://signup.mayoclinic.org/?ApplicationID=24e83c70-19b1-4a2f-9412-fd5838c56be7",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "McFarland Clinic (Iowa)",
    "Epic Endpiont":
      "https://emrproxy.mcfarlandclinic.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.mychartiowa.com/MyChartPRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "McLeod Health",
    "Epic Endpiont":
      "https://epicproxy.et1267.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.mcleodmychart.org/MyChart/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Medical University of South Carolina",
    "Epic Endpiont": "https://fhirprod.musc.edu/fhirprod/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.musc.edu/mychart/Signup/",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "MediSys Health Network",
    "Epic Endpiont": "https://eprescribe-p.medisys.org/fhir-prd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.medisys.org/MyChart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Memorial Healthcare System",
    "Epic Endpiont": "https://mhssp.mhs.net/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.mhs.net/MyChart/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Memorial Hospital and Health Care Center",
    "Epic Endpiont": "https://arrprd.mhhcc.org/OAuth2/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.mhs.net/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "MemorialCare",
    "Epic Endpiont": "https://fhir.memorialcare.org/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mymemorialcare.memorialcare.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Mercy Health - OH, KY",
    "Epic Endpiont":
      "https://chperx.health-partners.org/Proxy-FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://chpepiceweb.health-partners.org/mychart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Mercy Health (Arkansas, Louisiana, Mississippi and Texas)",
    "Epic Endpiont": "https://epic-fhir.mercy.net/prdfhirstl/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.mercy.net/app/registration",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Mercy Health Services (MD)",
    "Epic Endpiont": "https://surescripts.mdmercy.com/fhir-prd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.mdmercy.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Mercy Health System - WI",
    "Epic Endpiont": "https://epicproxy.mhsjvl.org/FHIRproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.mercyhealthsystem.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Mercy Medical Center",
    "Epic Endpiont": "https://eproxy.mercycare.org/oauth2/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.mdmercy.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Meritus",
    "Epic Endpiont":
      "https://epicproxy.et1062.epichosted.com/FHIRProxyPRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.meritushealth.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Methodist Health System",
    "Epic Endpiont": "https://epcapp.mhd.com/arr-prd-fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.methodisthealthsystem.org/MyChart/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Methodist Hospitals",
    "Epic Endpiont":
      "https://mychart.methodisthospitals.org/FHIR-ARR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.methodisthospitals.org/MyChart/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Metro Health - Michigan",
    "Epic Endpiont": "https://arrprd.metrohealth.net/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.metrohealth.net/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "MetroHealth - OH",
    "Epic Endpiont": "https://fhir.metrohealth.org/fhir_prd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.metrohealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Michigan Medicine",
    "Epic Endpiont": "https://mcproxyprd.med.umich.edu/FHIR-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.myuofmhealth.org/MyChart-PRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Middlesex Hospital",
    "Epic Endpiont":
      "https://epicproxy.et1124.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.middlesexhealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Midwestern University",
    "Epic Endpiont":
      "https://epicproxy.et1329.epichosted.com/APIProxyPRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.mwuclinics.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Mohawk Valley Health System",
    "Epic Endpiont": "https://fhir.mvhealthsystem.org/FHIRproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.mvhealthsystem.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Molina Care Connections",
    "Epic Endpiont":
      "https://epicproxy.et1057.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.molinahealthcare.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Montage Health",
    "Epic Endpiont":
      "https://epicproxy.et1058.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.montagehealth.org/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Montefiore Medical Center",
    "Epic Endpiont":
      "https://soapepic.montefiore.org/FhirProxyPrd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.montefiore.org/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Monument Health",
    "Epic Endpiont":
      "https://ehrmobile.monument.health/interconnect-prd-fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.monument.health/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Mosaic Life Care",
    "Epic Endpiont":
      "https://epicproxy.et1274.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.mymlc.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Mount Auburn Hospital",
    "Epic Endpiont": "https://fhir.mah.org/prd-fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.mah.org/mychart/Authentication/Login?",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Mount Sinai Health System",
    "Epic Endpiont":
      "https://epicsoapproxyprd.mountsinai.org/FHIR-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.mountsinai.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Mount Sinai Medical Center",
    "Epic Endpiont": "https://epicfhir.msmc.com/proxysite-prd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.msmc.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "MultiCare Health System",
    "Epic Endpiont": "https://soapprod.multicare.org/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.multicare.org/MyMultiCare/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Muscogee - Creek Nation Department of Health",
    "Epic Endpiont":
      "https://epicproxy.et1221.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.creekhealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "MY DR NOW",
    "Epic Endpiont":
      "https://epicproxy.et4001.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mydrnow.epicmychart.com/mdn/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "MyMichigan Health",
    "Epic Endpiont": "https://arrprod.midmichigan.net/ProdFHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://my.midmichigan.net/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Nationwide Children's Hospital",
    "Epic Endpiont": "https://hkc.nationwidechildrens.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.nationwidechildrens.org/MyChart/publicforms.asp?mode=showForm&formname=ActivationReq",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "NCH Healthcare System",
    "Epic Endpiont":
      "https://epicproxy.et1233.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.nch.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Nebraska Medicine",
    "Epic Endpiont": "https://ocsoapprd.nebraskamed.com/FHIR-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.onechartpatient.com/mychart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Nemours",
    "Epic Endpiont": "https://iconnect.nemours.org/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://app.nemours.org/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Nephrology Center - Southwest Michigan",
    "Epic Endpiont": "https://hygieia.bronsonhg.org/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "",
    "Online Sign-up Access Issue": "No Online Sign Up",
    "Include in Platform": "",
  },
  {
    Hospital: "New Hanover Regional Medical Center",
    "Epic Endpiont": "https://epicmobile.nhrmc.org/OAuth2-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.nhrmc.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "New Jersey Urology",
    "Epic Endpiont":
      "https://epicproxy.et1153.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.njurology.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "New York-Presbyterian",
    "Epic Endpiont":
      "https://epicproxy-pub.et1089.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.myconnectnyc.org/mychart/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "NOMS Healthcare",
    "Epic Endpiont":
      "https://prdproxy.nomshealthcare.com/PRD-OAuth2/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.nomshealthcare.com/MyChart-Prod/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "North East Medical Services",
    "Epic Endpiont":
      "https://epicproxy.et1332.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.nems.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "North Memorial Health",
    "Epic Endpiont": "https://minerva.northmemorial.com/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.northmemorial.com/NorthMemorial/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "North Mississippi Health Services",
    "Epic Endpiont":
      "https://eiclbext.nmhs.net/interconnect-fhir-prd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://myconnection.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "North Oaks",
    "Epic Endpiont": "https://soapproxyprd.northoaks.org/nohsfhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.northoaks.org/MyChartPRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Northeast Georgia Health System",
    "Epic Endpiont": "https://wpprod.nghs.com/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "TRUE",
    "Sign-up URL": "",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "",
  },
  {
    Hospital: "NorthShore University Health System",
    "Epic Endpiont":
      "https://haiku.northshore.org/Interconnect-FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "TRUE",
    "Sign-up URL": "",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "",
  },
  {
    Hospital: "Northwest Community Hospital",
    "Epic Endpiont": "https://epicprdproxy.nch.org/prd-fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.nch.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Northwestern Medicine",
    "Epic Endpiont": "https://nmepicproxy.nm.org/FHIR-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mynm.nm.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Norton Healthcare",
    "Epic Endpiont":
      "https://epicsoap.nortonhealthcare.org/FHIRPRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.nortonhealthcare.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Novant Health",
    "Epic Endpiont":
      "https://epicproxy.et0798.epichosted.com/APIProxyPRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.novantmychart.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "NYC Health + Hospitals",
    "Epic Endpiont": "https://epicproxypda.nychhc.org/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://epicmychart.nychhc.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "NYU Langone Medical Center",
    "Epic Endpiont": "https://epicfhir.nyumc.org/FHIRPRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://activation.nyulmc.org",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "OB/GYN Associates of Waco - Dr. Rister, Dr. Koeritz",
    "Epic Endpiont":
      "https://epicproxy.bswhealth.org/FHIR-PRD/CONNECT/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "",
    "Online Sign-up Access Issue": "No Online Sign Up",
    "Include in Platform": "",
  },
  {
    Hospital: "OCHIN",
    "Epic Endpiont": "https://webprd.ochin.org/prd-fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.ochin.org/mychartchap/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Ochsner Health System",
    "Epic Endpiont": "https://myc.ochsner.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://my.ochsner.org/PRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "OhioHealth",
    "Epic Endpiont":
      "https://ccpintconfg.ohiohealth.com/Interconnect-PRD-MUAPI/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.ohiohealth.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Olmsted Medical Center",
    "Epic Endpiont":
      "https://epicproxy.et1077.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.olmmed.org/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "One Brooklyn Health System",
    "Epic Endpiont":
      "https://epicproxy.et0883.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://www.myonebrooklynhealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "OptumCare East",
    "Epic Endpiont": "https://epicarr.optum.com/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://epicmychart.optum.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "OptumCare West",
    "Epic Endpiont": "https://epicpnwarr.optum.com/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://epicmychart.optum.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Oregon Health & Science University",
    "Epic Endpiont": "https://epicmobile.ohsu.edu/FHIRPRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychartweb.ohsu.edu/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Orlando Health",
    "Epic Endpiont":
      "https://epicproxy.et1131.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.orlandohealth.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "OrthoCarolina",
    "Epic Endpiont":
      "https://epwebapps.orthocarolina.com/fhir-prd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://my.orthocarolina.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "OrthoVirginia",
    "Epic Endpiont":
      "https://epicproxy.et1015.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.orthovirginia.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "OSF HealthCare",
    "Epic Endpiont":
      "https://ssproxy.osfhealthcare.org/fhir-proxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.osfmychart.org/OSFMyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "OU Health",
    "Epic Endpiont":
      "https://epicproxy.et1181.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.ouhealth.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Overlake Hospital Medical Center",
    "Epic Endpiont": "https://sfd.overlakehospital.org/FHIRproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.overlakehospital.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Owensboro Health",
    "Epic Endpiont": "https://fhir.omhs.org/rp-prd-fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.omhs.org/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Pacific Dental Services",
    "Epic Endpiont":
      "https://epicproxy.et1079.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.smilegenerationmychart.com/MyChart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Palos Health",
    "Epic Endpiont": "https://nmepicproxy.nm.org/PalosFHIR-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.paloshealth.com/MyChart/Authentication/Login?",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Parkland",
    "Epic Endpiont": "https://pmh-vmhaiku-01.pmh.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.parklandhealth.org/MyChart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Parkview Health",
    "Epic Endpiont": "https://epicprod-mobile.parkview.com/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://parkviewhealth.medbridgego.com/access_token",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "PeaceHealth",
    "Epic Endpiont": "https://soapproxy.peacehealth.org/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://my.peacehealth.org/MyPeaceHealth/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Pediatric Physicians Organization at Children's",
    "Epic Endpiont": "https://fhir.chppoc.org/Fhir-External/api/FHIR/R4/",
    "Endpoint Access Issue": "TRUE",
    "Sign-up URL": "",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "",
  },
  {
    Hospital: "Penn Medicine",
    "Epic Endpiont": "https://ssproxy.pennhealth.com/PRD-FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "TRUE",
    "Sign-up URL": "",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "",
  },
  {
    Hospital: "Phelps Health",
    "Epic Endpiont":
      "https://epicproxy.et1134.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.phelpshealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Piedmont Healthcare",
    "Epic Endpiont": "https://webproxy.piedmont.org/ARR-FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.piedmont.org/prd/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Pikeville Medical Center",
    "Epic Endpiont":
      "https://epicproxy.et1225.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.pikevillehospital.org/MyChart/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Pine Rest Christian Mental Health Services",
    "Epic Endpiont": "https://wecare.pinerest.org/fhirproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.pinerest.org/mychartPRD/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Pine Springs Health Internal Medicine",
    "Epic Endpiont":
      "https://epicproxy.et4001.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://pinesprings.epicmychart.com/psh/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Planned Parenthood",
    "Epic Endpiont":
      "https://epicproxy.et1154.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://www.plannedparenthood.org/planned-parenthood-south-atlantic/patients/mychart-patient-portal/mychart-signup-and-login",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Premier Health",
    "Epic Endpiont": "https://rx.premierhealthpartners.org/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.premierhealthpartners.org/mychart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Premise Health",
    "Epic Endpiont":
      "https://epicproxy.et1052.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.mypremisehealth.com/MyChart/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Presbyterian Healthcare Services",
    "Epic Endpiont": "https://epicFHIR.phs.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "TRUE",
    "Sign-up URL": "https://mypres.phs.org/Pages/registration.aspx",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "",
  },
  {
    Hospital: "Prime Healthcare",
    "Epic Endpiont":
      "https://phsfhir.primehealthcare.com/PHS-PRD-FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.primehealthcare.com/MyChart/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Prisma Health",
    "Epic Endpiont":
      "https://epicproxy.et0915.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.prismahealth.org/mychart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "ProHealth Care",
    "Epic Endpiont": "https://soap.phci.org/Interconnect-FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.prohealthcare.org/MyChart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "ProMedica Health System",
    "Epic Endpiont": "https://fhir.promedica.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.promedica.org/MyChart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Providence Health & Services - Alaska",
    "Epic Endpiont": "https://haikuak.providence.org/fhirproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychartor.providence.org/mychart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Providence Health & Services - Oregon/California",
    "Epic Endpiont": "https://haikuor.providence.org/fhirproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychartor.providence.org/mychart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Providence Health & Services - Washington/Montana",
    "Epic Endpiont": "https://haikuwa.providence.org/fhirproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychartor.providence.org/mychart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "QuadMed",
    "Epic Endpiont": "https://epicicfore.quadmedical.com/fhirprd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.myquadmedical.com/MyChart/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Rady Children's",
    "Epic Endpiont": "https://epcppxl1.rchsd.org/fhirprd/api/FHIR/R4/",
    "Endpoint Access Issue": "TRUE",
    "Sign-up URL":
      "https://www.mychartatradychildrens.org/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "",
  },
  {
    Hospital: "Reid Health",
    "Epic Endpiont":
      "https://epicproxy.et1220.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://myreidhealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Reliant Medical Group",
    "Epic Endpiont":
      "https://fhirprd.reliantmedicalgroup.org/FHIRPRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.reliantmedicalgroup.org/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Renown, Barton, CVMC",
    "Epic Endpiont": "https://fhir.renown.org/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.renown.org/mychart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Ritual Medical",
    "Epic Endpiont":
      "https://epicproxy.et4001.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://ritualmedical.com/new-patients/",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Riverside Health System (Newport News, VA)",
    "Epic Endpiont":
      "https://ep-rpfg.rivhs.com/Interconnect-FHIR-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.riversideonline.com/mychart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Riverside Medical Center",
    "Epic Endpiont":
      "https://rpprod.riversidehealthcare.net/FHIRPRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://riversidemychart.org/MyChart-PRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Riverside Medical Clinic",
    "Epic Endpiont": "https://sf1.rmcps.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://myhealthyconnection.riversidemedicalclinic.com/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Riverview Health",
    "Epic Endpiont": "https://epic-fhir.mercy.net/PRDFHIRSTL/RVH/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.riverview.org/app/registration",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Rochester Regional Health",
    "Epic Endpiont": "https://epicarr.rochesterregional.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mycare.rochesterregional.org/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Rush University Medical Center",
    "Epic Endpiont": "https://epicproxy.rush.edu/fhir-prd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.rush.edu/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "RWJBarnabas Health",
    "Epic Endpiont":
      "https://epicproxy.et1157.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.rwjbh.org/MyChart/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Saint Francis Health System",
    "Epic Endpiont":
      "https://eprdsoap000.saintfrancis.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.saintfrancis.com/MyChart/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Saint Francis Healthcare System (Manual)",
    "Epic Endpiont": "https://reverseproxy.sfmc.net/fhirproxyprd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.sfmc.net/MyChart/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Saint Luke's Health System",
    "Epic Endpiont":
      "https://epicmobile.corp.saint-lukes.org/FHIRPROXY/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mysaintlukes.corp.saint-lukes.org/MyChartPRD/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Salem Health",
    "Epic Endpiont": "https://prd.salemhealth.org/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.salemhealth.org/mychart/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Salinas Valley Memorial Healthcare Systems",
    "Epic Endpiont":
      "https://epicproxy.et1146.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.svmh.com/MyChart/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Samaritan Health Services",
    "Epic Endpiont": "https://fhir.samhealth.org/fhir-arr/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.samhealth.org/MyChart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "San Francisco Department of Public Health",
    "Epic Endpiont":
      "https://epicproxy.et1082.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.sfdph.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "San Ysidro Health",
    "Epic Endpiont":
      "https://epicproxy.et1327.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.syhealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Sanford Health",
    "Epic Endpiont": "https://eprescribe.sanfordhealth.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.mysanfordchart.org/MyChart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Sansum Clinic",
    "Epic Endpiont":
      "https://wavesurescripts.sansumclinic.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.sansumclinic.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Santa Clara Valley Medical Center Hospitals and Clinics",
    "Epic Endpiont":
      "https://scvhhsfhir.sccgov.org/interconnect-fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://myhealthonline.sccgov.org/MyChartPRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "SBH Health System",
    "Epic Endpiont":
      "https://sbhepicrevprox.barnabas.network/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://sbhmychart.barnabas.network/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Scotland Health Care System",
    "Epic Endpiont":
      "https://epicproxy.et0905.epichosted.com/FHIRproxy/SCOTMYCHART/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://www.myscotlandhealth.org/myscotlandhealth/Authentication/Login?mode=stdfile&option=signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Scottish Rite for Children",
    "Epic Endpiont":
      "https://epicproxy.et0970.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.scottishriteforchildren.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Scripps Health",
    "Epic Endpiont":
      "https://haiku.scrippshealth.org/ARR-PRD-FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://myscripps.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Seattle Children's Hospital",
    "Epic Endpiont": "https://fhir.seattlechildrens.org/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.seattlechildrens.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Select Medical",
    "Epic Endpiont":
      "https://epicproxy.et0948.epichosted.com/FhirProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.selectmedical.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Self Regional Healthcare",
    "Epic Endpiont":
      "https://epicproxy.et1235.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.selfregional.org/MySRH/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Sentara Healthcare",
    "Epic Endpiont": "https://epicfhir.sentara.com/ARR-FHIR-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://myhealth.sentara.com/account/sign-up",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Shannon Health",
    "Epic Endpiont": "https://epicrpx.shannonhealth.org/FHIR_ARR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.shannonhealth.org/mychart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Sharp HealthCare",
    "Epic Endpiont":
      "https://epicproxy.et1275.epichosted.com/fhirproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.sharp.com/app",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Shriners Children’s",
    "Epic Endpiont":
      "https://epicproxy.et1283.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.shrinerschildrens.org/MyChart/Authentication/Login",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Singing River Health System",
    "Epic Endpiont": "https://arr.mysrhs.com/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.mysrhs.com/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Skagit Regional Health",
    "Epic Endpiont":
      "https://epicproxy.et1005.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.skagitregionalhealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "SolutionHealth",
    "Epic Endpiont":
      "https://epicproxyprd.solutionhealth.org/FHIR_PROD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.solutionhealth.org/MYCHARTPRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "South Georgia Medical Center",
    "Epic Endpiont":
      "https://epicproxy.et1024.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.sgmc.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "South Shore Health System",
    "Epic Endpiont": "https://SSHIC.southshorehealth.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.southshorehealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Southcoast Health",
    "Epic Endpiont": "https://epicpproxy.southcoast.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.southcoast.org/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Southeast Health",
    "Epic Endpiont": "https://arrprd.southeasthealth.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.southeasthealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Southern Illinois Healthcare",
    "Epic Endpiont":
      "https://epicproxy.et0986.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.sih.net/MyChartPRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Sparrow Health System",
    "Epic Endpiont": "https://haiku.sparrow.org/fhir-prd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.sparrow.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Spartanburg Regional Health Systems",
    "Epic Endpiont":
      "https://epicproxy.et0939.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.spartanburgregional.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Spectrum Health",
    "Epic Endpiont":
      "https://epicarr02.spectrumhealth.org/EpicFHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.spectrumhealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Spectrum Health Lakeland",
    "Epic Endpiont": "https://fhir.lakelandregional.org/fhirproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.lakelandregional.org/mychartprd/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "SSM Health",
    "Epic Endpiont": "https://fhir.ssmhc.com/Fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.ssmhc.com/MyChart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "St. Charles Health System",
    "Epic Endpiont":
      "https://epicproxy.et1030.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.stcharleshealthcare.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "St. Elizabeth Healthcare",
    "Epic Endpiont":
      "https://sehproxy.stelizabeth.com/arr-fhir/SEH/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.stelizabeth.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "St. Joseph Hospital Health Center",
    "Epic Endpiont": "https://mobileproxy.sjhsyr.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.covh.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "St. Jude Children's Research Hospital",
    "Epic Endpiont": "https://rp.stjude.org/oauth2-prd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.stjude.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "St. Luke’s Health System (Idaho & Eastern Oregon)",
    "Epic Endpiont": "https://epmobile.slhs.org/Interconnect-FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.slhs.org/MyChart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "St. Luke's Hospital (North Carolina)",
    "Epic Endpiont":
      "https://epicproxy.et0905.epichosted.com/FHIRproxy/STLUMYCHART/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://www.mystlukeschart.org/mystlukeschart/Authentication/Login?mode=stdfile&option=signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "St. Luke's University Health Network",
    "Epic Endpiont": "https://fhir.slhn.org/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.slhn.org/mychart/index",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Stanford Children's Health",
    "Epic Endpiont":
      "https://epicproxy.et0857.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.stanfordchildrens.org/Mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Stanford Health Care",
    "Epic Endpiont": "https://sfd.stanfordmed.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://stanfordhealthcare.org/for-patients-visitors/myhealth.html",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Stormont Vail Health",
    "Epic Endpiont": "https://epicsoap.stormontvail.org/FHIRproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.stormontvail.org/MyChart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Summa Health",
    "Epic Endpiont":
      "https://epicproxy.et1289.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.summahealth.org/MyChart/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Summit Health",
    "Epic Endpiont": "https://epicsoap.bmctotalcare.com/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.bmctotalcare.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "SUNY Upstate Medical University",
    "Epic Endpiont": "https://epicedge.upstate.edu/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.upstate.edu/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Sutter Health",
    "Epic Endpiont": "https://apiservices.sutterhealth.org/ifs/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://myhealthonline.sutterhealth.org/MHO/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Tahoe Forest Health System",
    "Epic Endpiont": "https://epic-fhir.mercy.net/PRDFHIRAOK2/TAO/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.tfhd.com/app/registration",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Tampa General Hospital",
    "Epic Endpiont":
      "https://epicproxy.et0761.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.tgh.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Tanner Health System",
    "Epic Endpiont":
      "https://epicproxy.et1098.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.tannermychart.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "TempleHealth",
    "Epic Endpiont":
      "https://epicaccess.templehealth.org/FhirProxyPrd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://my.templehealth.org/MyChartPRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Texas Children's",
    "Epic Endpiont": "https://mobileapps.texaschildrens.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.texaschildrens.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Texas Health Resources",
    "Epic Endpiont": "https://epproxy.texashealth.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "TRUE",
    "Sign-up URL": "",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "",
  },
  {
    Hospital: "The Brooklyn Hospital Center",
    "Epic Endpiont":
      "https://epicproxy.et1043.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.tbh.org/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "The Center",
    "Epic Endpiont":
      "https://epicproxy.et4006.epichosted.com/FHIRProxyPRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://thecenter.epicmychart.com/ctr/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "The Christ Hospital",
    "Epic Endpiont":
      "https://soapproxyprod.thechristhospital.com/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://www.thechristhospitalmychart.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "The Everett Clinic",
    "Epic Endpiont": "https://fhir.myeverettclinic.com/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://myhealthchart.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "The Guthrie Clinic",
    "Epic Endpiont": "https://fhir.guthrie.org/FHIR-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://e.guthrie.org/Mychart//signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "The Ohio State University Wexner Medical Center",
    "Epic Endpiont": "https://ihismufhir.osumc.edu/fhir-prd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.osu.edu/osumc/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "The Portland Clinic",
    "Epic Endpiont": "https://tpc-shield.tpcllp.com/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.tpcllp.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "The Queen's Health Systems",
    "Epic Endpiont": "https://mobileapps.queens.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.queens.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "The University of Texas Health Science Center at Houston",
    "Epic Endpiont":
      "https://epicproxy.et1178.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.myuthealthhouston.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "The University of Texas MD Anderson Cancer Center",
    "Epic Endpiont": "https://fhir.mdanderson.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://my.mdanderson.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "The University of Vermont Health Network",
    "Epic Endpiont": "https://epicproxy.uvmhealth.org/FHIR-ARR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.uvmhealth.org/MyChart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "The Vancouver Clinic",
    "Epic Endpiont": "https://soapprod.tvc.org/ARR-FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.tvc.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "ThedaCare",
    "Epic Endpiont": "https://arr.thedacare.org/FHIR/TC/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://my.thedacare.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "TidalHealth",
    "Epic Endpiont": "https://eweb.peninsula.org/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.tidalhealth.org/MyChart/Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "TMC HealthCare",
    "Epic Endpiont": "https://fhir.tmcaz.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.tmcaz.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Tower Health",
    "Epic Endpiont":
      "https://epicsoap.readinghospital.org/FHIR-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://www.mytowerhealth.org/mytowerhealth/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "TriHealth",
    "Epic Endpiont": "https://epicscripts.trihealth.com/fhirproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.trihealth.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Trinity Health",
    "Epic Endpiont": "https://epic-ext.trinity-health.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.trinity-health.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Trinity Health of New England",
    "Epic Endpiont":
      "https://epicprdext.stfranciscare.org/FhirProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mycare.stfranciscare.org/MyCare/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Trinity Health of New England Medical Group Springfield",
    "Epic Endpiont":
      "https://rmgpxy.riverbendmedical.com/fhir_proxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.trinity-health.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Tufts Medicine",
    "Epic Endpiont": "https://intconfg-p.well-net.org/PRD-OAUTH2/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart-p.well-net.org/MyChartPRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "UC Davis",
    "Epic Endpiont": "https://emrrp.ucdmc.ucdavis.edu/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.ucdavis.edu/MyChart/Authentication/Login?mode=stdfile&option=Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "UC Davis - MMC",
    "Epic Endpiont": "https://emrrp.ucdmc.ucdavis.edu/FHIR/MMC/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.ucdavis.edu/MyChart/Authentication/Login?mode=stdfile&option=Signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "UC Health",
    "Epic Endpiont": "https://epic-soap.uchealth.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://my.uchealth.com/myuchealth/Authentication/Login?",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "UChicago Medicine",
    "Epic Endpiont":
      "https://interconapps.uchospitals.edu/PRD-FHIR-Proxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.uchospitals.edu/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "UCLA Medical Center",
    "Epic Endpiont": "https://arrprox.mednet.ucla.edu/FHIRPRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://my.uclahealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "UConn Health",
    "Epic Endpiont":
      "https://epicproxy.et0996.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.uconn.edu/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "UCSF Benioff Children's Hospital",
    "Epic Endpiont": "https://unified-api.ucsf.edu/clinical/apex/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.ucsfhealth.org/mychart",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "UCSF Health",
    "Epic Endpiont": "https://unified-api.ucsf.edu/clinical/apex/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.ucsfhealth.org/mychart",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "UF Health",
    "Epic Endpiont": "https://epicsoap.shands.ufl.edu/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.shands.org/MyChartPRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "UHS San Antonio",
    "Epic Endpiont":
      "https://epicproxy.et1130.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.universityhealthsystem.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "UI Health",
    "Epic Endpiont":
      "https://epicproxy.et1085.epichosted.com/FHIRPROXY/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.uillinois.edu/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "UK HealthCare",
    "Epic Endpiont":
      "https://ukepicproxy.mc.uky.edu/Interconnect-PRD-OAuth2/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.uky.edu/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "UMass Memorial Health Care",
    "Epic Endpiont":
      "https://epicproxy.et0978.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychartonline.umassmemorial.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "UMC Southern Nevada",
    "Epic Endpiont":
      "https://epicproxy.et1023.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://umconnect.umcsn.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "UNC Health Care",
    "Epic Endpiont": "https://epicfe.unch.unc.edu/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.myuncchart.org/mychart/Authentication/Login?",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "United Health Services New York (NYUHS)",
    "Epic Endpiont":
      "https://epicproxy.et1127.epichosted.com/FHIRproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.nyuhs.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "United Regional Health Care System",
    "Epic Endpiont":
      "https://epicproxy.et1096.epichosted.com/FHIRProxy/api/fhir/r4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.unitedregional.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "UnityPoint Health",
    "Epic Endpiont": "https://epicfhir.unitypoint.org/ProdFHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.chart.myunitypoint.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "University Health Care System",
    "Epic Endpiont": "https://soap.uhcs.org/IC-FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "TRUE",
    "Sign-up URL": "",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "",
  },
  {
    Hospital: "University Hospital (New Jersey)",
    "Epic Endpiont": "https://epicarrprod.uhnj.org/FHIR-Proxy/api/FHIR/R4/",
    "Endpoint Access Issue": "TRUE",
    "Sign-up URL": "",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "",
  },
  {
    Hospital: "University Hospitals Cleveland",
    "Epic Endpiont":
      "https://uhhsportal.uhhospitals.org/oauth2-prd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.uhhospitals.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "University of Arkansas for Medical Sciences",
    "Epic Endpiont": "https://ucsoap.uams.edu/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.uamshealth.com/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "University of California Irvine",
    "Epic Endpiont":
      "https://epicproxy.et0502.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://my.ucihealth.org/UCI/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "University of California San Diego",
    "Epic Endpiont":
      "https://epicproxy.et0502.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.ucsd.edu/UCSD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "University of Colorado Health",
    "Epic Endpiont": "https://ss.uch.edu/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.uchealth.org/access-my-health-connection/",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "University of Iowa Health Care",
    "Epic Endpiont":
      "https://myepicapps.uihealthcare.org/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.uihealthcare.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "University of Kansas Health System",
    "Epic Endpiont":
      "https://fhir.kansashealthsystem.com/interconnect-PRD_FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.kansashealthsystem.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "University of Louisville Physicians",
    "Epic Endpiont":
      "https://epicproxy.et1193.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.uoflhealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "University of Maryland Medical System",
    "Epic Endpiont": "https://fhir.umm.edu/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://myportfolio.umm.edu/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "University of Miami (UHealth)",
    "Epic Endpiont": "https://uhealthsoap.med.miami.edu/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://myuhealthchart.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "University of Mississippi Medical Center",
    "Epic Endpiont": "https://soapproxy.umc.edu/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://www.umc.edu/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "University of Pittsburgh Medical Center (UPMC)",
    "Epic Endpiont": "https://epic-fhir-prd.upmc.com/FHIR-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://upmccentralpaportal.com/mychart/Authentication/Login",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "University of Rochester Medical Center",
    "Epic Endpiont": "https://ercd-sproxy.urmc.rochester.edu/MIPS/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.urmc.rochester.edu/mychart/home.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "University of Texas Medical Branch",
    "Epic Endpiont": "https://epic-arr.utmb.edu/fhir-prd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.utmb.edu/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "University of Texas Southwestern Medical Center",
    "Epic Endpiont": "https://EpicIntprxyPRD.swmed.edu/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.utsouthwestern.edu/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "University of Toledo",
    "Epic Endpiont": "https://icon.utoledo.edu/ic-oa2-prd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.utoledo.edu/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "University of Utah Healthcare",
    "Epic Endpiont":
      "https://webproxyprd.med.utah.edu/FHIRMyChart/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.med.utah.edu/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "UPMC Central PA",
    "Epic Endpiont":
      "https://Epic-Arr.pinnaclehealth.org/PRD-FHIR-ARR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://upmccentralpaportal.com/mychart/Authentication/Login",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "UT Health San Antonio",
    "Epic Endpiont":
      "https://epicproxy.et0582.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.utmedicinesa.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "UVA Health System",
    "Epic Endpiont":
      "https://hscsesoap.hscs.virginia.edu/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.healthsystem.virginia.edu/Mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "UW Health And Affiliates - Wisconsin",
    "Epic Endpiont": "https://epicproxy.hosp.wisc.edu/FhirProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.uwhealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "UW Medicine (Washington)",
    "Epic Endpiont":
      "https://fhir.epic.medical.washington.edu/FHIR-Proxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.uwmedicine.org/prod01/Authentication/Login?",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Valley Children's Healthcare",
    "Epic Endpiont": "https://ic.valleychildrens.org/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://www.valleychildrens.org/about-us/mychart-patient-portal",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Valley Health System (VA)",
    "Epic Endpiont":
      "https://epicproxy.et1320.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.valleyhealth.org/PRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Valley Health Systems",
    "Epic Endpiont": "https://api.valleyhealth.org/fhirproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.valleyhealth.org/PRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Valley Medical Center",
    "Epic Endpiont": "https://FHIR.valleymed.org/FHIR-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.valleymed.org/MyChart_PRD/Authentication/Login",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Valleywise Health",
    "Epic Endpiont": "https://esoap.mihs.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.valleywisehealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Vanderbilt",
    "Epic Endpiont": "https://arr01.service.vumc.org/FHIR-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://myhealthatvanderbilt.com/Authentication/Login?",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "VCU Health",
    "Epic Endpiont":
      "https://epicproxy.et1200.epichosted.com/OAuth2-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.vcuhealth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Vidant Health",
    "Epic Endpiont": "https://prd-proxy.vidanthealth.com/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.vidanthealth.com/mychart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Virginia Hospital Center",
    "Epic Endpiont":
      "https://common.virginiahospitalcenter.com/FHIRPRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://myvhc.virginiahospitalcenter.com/MyChartPRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Virginia Mason Franciscan Health",
    "Epic Endpiont": "https://rp.catholichealth.net/fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.catholichealth.net/PRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Virtua Health",
    "Epic Endpiont":
      "https://epicproxy.et1017.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://secure.myvirtua.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Waco Family Medicine (Heart of Texas Community Health Center)",
    "Epic Endpiont": "https://haiku.wacofhc.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.wacofhc.org/mychartwacoprod/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "WakeMed Health and Hospitals",
    "Epic Endpiont": "https://epic-soap.wakemed.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.wakemed.org/MyChart-PRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Washington Hospital Healthcare System",
    "Epic Endpiont":
      "https://psacesoap.whhs.com/interconnect-fhir-prd/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.whhs.com/MyChart/Authentication/Login",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Watson Clinic",
    "Epic Endpiont":
      "https://epic-arr.watsonclinicad.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.watsonclinic.com/PRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Weill Cornell Medicine",
    "Epic Endpiont":
      "https://epicproxy-pub.et1089.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL":
      "https://mychart.weill.cornell.edu/mychart/Authentication/Login",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "WellSpan Health",
    "Epic Endpiont":
      "https://interconnect.wellspan.org/interconnect-prd-fhir/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://my.wellspan.org/MyWellSpan/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "WellStar",
    "Epic Endpiont": "https://epicsoap.wellstar.org/fhirproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.wellstar.org/MyChart/signup",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "West Bend Family Medicine",
    "Epic Endpiont":
      "https://epicproxy.et4001.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://westbend.epicmychart.com/wbfm/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "West Tennessee Healthcare",
    "Epic Endpiont":
      "https://epicproxy.et1243.epichosted.com/OAuth2-PRD/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.wth.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "West Virginia University Medicine",
    "Epic Endpiont": "https://apps.mywvuchart.com/fhirproxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mywvuchart.com/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Western Michigan University School of Medicine",
    "Epic Endpiont": "https://hygieia.bronsonhg.org/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.wmed.edu/MyChartWMED/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Woman's",
    "Epic Endpiont":
      "https://epicproxy.et1339.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.womans.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Yakima Valley Farm Workers Clinic",
    "Epic Endpiont":
      "https://epicproxy.et0943.epichosted.com/FHIRProxy/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.yvfwc.org/MyChart/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Yale New Haven Health System",
    "Epic Endpiont": "https://patientfhirapis.ynhh.org/pff/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mychart.ynhhs.org/MyChart-PRD/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
  {
    Hospital: "Yuma Regional Medical Center",
    "Epic Endpiont": "https://yrmccare1.yumaregional.org/FHIR/api/FHIR/R4/",
    "Endpoint Access Issue": "",
    "Sign-up URL": "https://mycare.yumaregional.org/MyCare/accesscheck.asp",
    "Online Sign-up Access Issue": "",
    "Include in Platform": "YES",
  },
];

export default orgListR4;
