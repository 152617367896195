<template>
  <div id="nftmax-accordion" class="nftmax-accordion accordion accordion-flush">
    <!-- Single Accordion -->
    <div class="accordion-item nftmax-accordion__single">
      <h2 id="nftac-1" class="accordion-header">
        <button
          :class="[acc === 'q1' ? 'collapsed' : '']"
          class="accordion-button collapsed nftmax-accordion__heading"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#ac-collapseOne"
          aria-expanded="false"
          aria-controls="ac-collapseOne"
          @click="accHandler('q1')"
        >
          Can I resell an NFT?
        </button>
      </h2>
      <div
        id="ac-collapseOne"
        :class="[acc === 'q1' ? 'show' : '']"
        class="accordion-collapse collapse"
        aria-labelledby="nftac-1"
        data-bs-parent="#nftmax-accordion"
      >
        <div class="accordion-body nftmax-accordion__body">
          What you do own when you buy an NFT are the keys to a non-fungible –
          perhaps unique – token. That token is yours to trade or hold or
          display in Decentraland. But the digital file associated with an NFT
          is just as easy to copy and paste and download as any other – the
          Finally, players lose their NFTs sometimes according to the rules and
          regulations of the NFT game.
        </div>
      </div>
    </div>
    <!-- End Single Accordion -->
    <!-- Single Accordion -->
    <div class="accordion-item nftmax-accordion__single">
      <h2 id="nftac-2" class="accordion-header">
        <button
          :class="[acc === 'q2' ? 'collapsed' : '']"
          class="accordion-button collapsed nftmax-accordion__heading"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#ac-collapseTwo"
          aria-expanded="false"
          aria-controls="ac-collapseTwo"
          @click="accHandler('q2')"
        >
          What can you do with an NFT after buying it?
        </button>
      </h2>
      <div
        id="ac-collapseTwo"
        :class="[acc === 'q2' ? 'show' : '']"
        class="accordion-collapse collapse"
        aria-labelledby="nftac-2"
        data-bs-parent="#nftmax-accordion"
      >
        <div class="accordion-body nftmax-accordion__body">
          What you do own when you buy an NFT are the keys to a non-fungible –
          perhaps unique – token. That token is yours to trade or hold or
          display in Decentraland. But the digital file associated with an NFT
          is just as easy to copy and paste and download as any other – the
          Finally, players lose their NFTs sometimes according to the rules and
          regulations of the NFT game.
        </div>
      </div>
    </div>
    <!-- End Single Accordion -->
    <!-- Single Accordion -->
    <div class="accordion-item nftmax-accordion__single">
      <h2 id="nftac-3" class="accordion-header">
        <button
          :class="[acc === 'q3' ? 'collapsed' : '']"
          class="accordion-button collapsed nftmax-accordion__heading"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#ac-collapseThree"
          aria-expanded="false"
          aria-controls="ac-collapseThree"
          @click="accHandler('q3')"
        >
          Can you lose money on NFT?
        </button>
      </h2>
      <div
        id="ac-collapseThree"
        :class="[acc === 'q3' ? 'show' : '']"
        class="accordion-collapse collapse"
        aria-labelledby="nftac-3"
        data-bs-parent="#nftmax-accordion"
      >
        <div class="accordion-body nftmax-accordion__body">
          What you do own when you buy an NFT are the keys to a non-fungible –
          perhaps unique – token. That token is yours to trade or hold or
          display in Decentraland. But the digital file associated with an NFT
          is just as easy to copy and paste and download as any other – the
          Finally, players lose their NFTs sometimes according to the rules and
          regulations of the NFT game.
        </div>
      </div>
    </div>
    <!-- End Single Accordion -->
    <!-- Single Accordion -->
    <div class="accordion-item nftmax-accordion__single">
      <h2 id="nftac-4" class="accordion-header">
        <button
          :class="[acc === 'q4' ? 'collapsed' : '']"
          class="accordion-button collapsed nftmax-accordion__heading"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#ac-collapseFour"
          aria-expanded="false"
          aria-controls="ac-collapseFour"
          @click="accHandler('q4')"
        >
          How does NFT prove ownership?
        </button>
      </h2>
      <div
        id="ac-collapseFour"
        :class="[acc === 'q4' ? 'show' : '']"
        class="accordion-collapse collapse"
        aria-labelledby="nftac-4"
        data-bs-parent="#nftmax-accordion"
      >
        <div class="accordion-body nftmax-accordion__body">
          What you do own when you buy an NFT are the keys to a non-fungible –
          perhaps unique – token. That token is yours to trade or hold or
          display in Decentraland. But the digital file associated with an NFT
          is just as easy to copy and paste and download as any other – the
          Finally, players lose their NFTs sometimes according to the rules and
          regulations of the NFT game.
        </div>
      </div>
    </div>
    <!-- End Single Accordion -->
    <!-- Single Accordion -->
    <div class="accordion-item nftmax-accordion__single">
      <h2 id="nftac-5" class="accordion-header">
        <button
          :class="[acc === 'q5' ? 'collapsed' : '']"
          class="accordion-button collapsed nftmax-accordion__heading"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#ac-collapseFive"
          aria-expanded="false"
          aria-controls="ac-collapseFive"
          @click="accHandler('q5')"
        >
          How can you tell if someone owns an NFT?
        </button>
      </h2>
      <div
        id="ac-collapseFive"
        :class="[acc === 'q5' ? 'show' : '']"
        class="accordion-collapse collapse"
        aria-labelledby="nftac-5"
        data-bs-parent="#nftmax-accordion"
      >
        <div class="accordion-body nftmax-accordion__body">
          What you do own when you buy an NFT are the keys to a non-fungible –
          perhaps unique – token. That token is yours to trade or hold or
          display in Decentraland. But the digital file associated with an NFT
          is just as easy to copy and paste and download as any other – the
          Finally, players lose their NFTs sometimes according to the rules and
          regulations of the NFT game.
        </div>
      </div>
    </div>
    <!-- End Single Accordion -->
    <!-- Single Accordion -->
    <div class="accordion-item nftmax-accordion__single">
      <h2 id="nftac-6" class="accordion-header">
        <button
          :class="[acc === 'q6' ? 'collapsed' : '']"
          class="accordion-button collapsed nftmax-accordion__heading"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#ac-collapseSix"
          aria-expanded="false"
          aria-controls="ac-collapseSix"
          @click="accHandler('q6')"
        >
          Can you lose more than you invest in NFTs?
        </button>
      </h2>
      <div
        id="ac-collapseSix"
        :class="[acc === 'q6' ? 'show' : '']"
        class="accordion-collapse collapse"
        aria-labelledby="nftac-6"
        data-bs-parent="#nftmax-accordion"
      >
        <div class="accordion-body nftmax-accordion__body">
          What you do own when you buy an NFT are the keys to a non-fungible –
          perhaps unique – token. That token is yours to trade or hold or
          display in Decentraland. But the digital file associated with an NFT
          is just as easy to copy and paste and download as any other – the
          Finally, players lose their NFTs sometimes according to the rules and
          regulations of the NFT game.
        </div>
      </div>
    </div>
    <!-- End Single Accordion -->
    <!-- Single Accordion -->
    <div class="accordion-item nftmax-accordion__single">
      <h2 id="nftac-7" class="accordion-header">
        <button
          :class="[acc === 'q7' ? 'collapsed' : '']"
          class="accordion-button collapsed nftmax-accordion__heading"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#ac-collapseSeven"
          aria-expanded="false"
          aria-controls="ac-collapseSeven"
          @click="accHandler('q7')"
        >
          What can you do with an NFT after buying it?
        </button>
      </h2>
      <div
        id="ac-collapseSeven"
        :class="[acc === 'q7' ? 'show' : '']"
        class="accordion-collapse collapse"
        aria-labelledby="nftac-7"
        data-bs-parent="#nftmax-accordion"
      >
        <div class="accordion-body nftmax-accordion__body">
          What you do own when you buy an NFT are the keys to a non-fungible –
          perhaps unique – token. That token is yours to trade or hold or
          display in Decentraland. But the digital file associated with an NFT
          is just as easy to copy and paste and download as any other – the
          Finally, players lose their NFTs sometimes according to the rules and
          regulations of the NFT game.
        </div>
      </div>
    </div>
    <!-- End Single Accordion -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      acc: "q1",
    };
  },
  methods: {
    accHandler(value) {
      this.acc = value;
    },
  },
};
</script>

<style scoped></style>
