<template>
  <div>
    <form action="#">
      <div class="nftmax-paymentm">
        <ul class="nftmax-paymentm__list">
          <li class="nftmax-paymentm__single">
            <div class="nftmax-paymentm__name">
              <div class="nftmax-paymentm__icon">
                <img src="../../../assets/img/payment-1.png" alt="#" />
              </div>
              <div class="nftmax-paymentm__content">
                <h4 class="nftmax-paymentm__title">Datch Bangla Bank Lmd</h4>
                <p class="nftmax-paymentm__text">Bank **********5535</p>
                <span class="nftmax-paymentm__badge nftmax__ok">Verified</span>
              </div>
            </div>
            <div class="nftmax-paymentm__manage-btn">
              <button class="nftmax-btn nftmax-btn__bordered bg radius">
                Manage
              </button>
            </div>
          </li>
          <li class="nftmax-paymentm__single">
            <div class="nftmax-paymentm__name">
              <div class="nftmax-paymentm__icon">
                <img src="../../../assets/img/payment-2.png" alt="#" />
              </div>
              <div class="nftmax-paymentm__content">
                <h4 class="nftmax-paymentm__title">Master Card</h4>
                <p class="nftmax-paymentm__text">Bank **********5535</p>
                <span class="nftmax-paymentm__badge nftmax__ok">Verified</span>
              </div>
            </div>
            <div class="nftmax-paymentm__manage-btn">
              <button class="nftmax-btn nftmax-btn__bordered bg radius">
                Manage
              </button>
            </div>
          </li>
          <li class="nftmax-paymentm__single">
            <div class="nftmax-paymentm__name">
              <div class="nftmax-paymentm__icon">
                <img src="../../../assets/img/payment-3.png" alt="#" />
              </div>
              <div class="nftmax-paymentm__content">
                <h4 class="nftmax-paymentm__title">Paypal Account</h4>
                <p class="nftmax-paymentm__text">Bank **********5535</p>
                <span class="nftmax-paymentm__badge nftmax__ok">Verified</span>
              </div>
            </div>
            <div class="nftmax-paymentm__manage-btn">
              <button class="nftmax-btn nftmax-btn__bordered bg radius">
                Manage
              </button>
            </div>
          </li>
          <li class="nftmax-paymentm__single">
            <div class="nftmax-paymentm__name">
              <div class="nftmax-paymentm__icon">
                <img src="../../../assets/img/payment-4.png" alt="#" />
              </div>
              <div class="nftmax-paymentm__content">
                <h4 class="nftmax-paymentm__title">Visa Card</h4>
                <p class="nftmax-paymentm__text">Bank **********5535</p>
                <span class="nftmax-paymentm__badge nftmax__error"
                  >Unverified</span
                >
              </div>
            </div>
            <div class="nftmax-paymentm__manage-btn">
              <button class="nftmax-btn nftmax-btn__bordered bg radius">
                Manage
              </button>
            </div>
          </li>
        </ul>
      </div>
      <div class="nftmax__item-button--group nftmax__ptabs-bottom v2">
        <button class="nftmax-btn nftmax-primary radius">Add Bank</button>
        <button class="nftmax-btn nftmax-gbcolor radius" type="submit">
          Add Card
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
e
