<template>
  <Layout>
    <div class="nftmax-inner__heading">
      <h2 class="nftmax-inner__page-title">Create New Research</h2>
    </div>
    <div class="nftmax__item">
      <form class="form" action="#">
        <div class="row">
          <div class="col-12">
            <div class="row nftmax-pcolumn">
              <div class="nftmax-pcolumn__two">
                <div class="nftmax__item-form--main col-xxl-7 col-lg-7 col-12">
                  <div class="nftmax__item-form--group">
                    <label class="nftmax__item-label">Analysis Method</label>
                    <el-select v-model="form.analysisMethodId">
                      <el-option
                        v-for="item in analysisMethodList"
                        :key="item.id"
                        :value="item.id"
                        :label="item.name"
                        :disabled="item.disabled"
                      >
                        <el-tooltip
                          class="item"
                          effect="dark"
                          placement="right"
                        >
                          <div slot="content">
                            <div class="method-tooltip">
                              {{ item.description }}
                            </div>
                          </div>
                          <span>{{ item.name }}</span>
                        </el-tooltip>
                      </el-option>
                    </el-select>
                  </div>

                  <!-- <div class="nftmax__item-form--group">
                    <label class="nftmax__item-label">SGX Center </label>
                    <el-select v-model="form.sgx">
                      <el-option
                        v-for="item in sgxServerList"
                        :key="item.label"
                        :value="item.value"
                      >
                        <span>{{ item.label }}</span>
                      </el-option>
                    </el-select>
                  </div> -->

                  <div class="nftmax__item-form--group">
                    <label class="nftmax__item-label">Research Name</label>
                    <el-input
                      v-model="form.studyName"
                      type="text"
                      placeholder="Research Name"
                      required="required"
                    />
                  </div>

                  <div class="nftmax__item-form--group">
                    <label class="nftmax__item-label">Description</label>
                    <el-input
                      v-model="form.description"
                      placeholder="Research Description"
                      required="required"
                      type="textarea"
                      :rows="5"
                    />
                  </div>

                  <!-- <div class="nftmax__item-form--group">
                    <label class="nftmax__item-label">Privacy</label>
                    <el-radio-group v-model="form.privacy" class="round">
                      <el-radio-button label="Private">Private</el-radio-button>
                      <el-radio-button label="Public">Public</el-radio-button>
                    </el-radio-group>
                  </div> -->
                </div>
                <div class="nftmax__item-button--group">
                  <el-button
                    class="nftmax__item-button--single nftmax-btn nftmax-btn__bordered bg radius nftmax-item__btn right"
                    @click="createStudy"
                  >
                    Create
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/Partials/Layout";
import api from "../../http/api";

export default {
  components: { Layout },
  data() {
    return {
      form: {
        studyName: "",
        description: "",
        privacy: "Private",
        sgx: "Lavita-SGX-1",
        analysisMethodId: 51,
      },
      sgxServerList: [
        {
          value: "Lavita-SGX-1",
          label: "Lavita-SGX-1",
        },
        {
          value: "Lavita-SGX-2",
          label: "Lavita-SGX-2",
        },
        {
          value: "Lavita-SGX-3",
          label: "Lavita-SGX-3",
        },
        {
          value: "Lavita-SGX-4",
          label: "Lavita-SGX-4",
        },
        {
          value: "Lavita-SGX-5",
          label: "Lavita-SGX-5",
        },
      ],
      analysisMethodList: [
        {
          id: 1,
          disabled: true,
          nameDB: "Chi-Square",
          name: "Chi-Square",
          description: `Chi-Square, often written as χ² or chi-squared, is a statistical test and a measure used to determine the association between categorical variables in a dataset. It assesses whether there is a significant relationship or dependence between two categorical variables by comparing observed data to expected data under the assumption of independence.`,
        },
        {
          id: 2,
          disabled: true,
          nameDB: "Xgboost",
          name: "Xgboost",
          description: `XGBoost, which stands for eXtreme Gradient Boosting, is a powerful and widely used machine learning algorithm known for its exceptional performance in various data science and machine learning tasks.`,
        },
        {
          id: 51,
          disabled: false,
          nameDB: "Logistic_Regression",
          name: "Logistic Regression",
          description: `Logistic regression model is a generalized linear regression analysis model, 
            commonly used in data mining, automatic diagnosis of diseases, and other fields.`,
        },
        {
          id: 52,
          disabled: false,
          nameDB: "Lavita_ImageClassification",
          name: "Medical Image Classificaiton",
          description: `Medical Image Classification is a crucial task in the field of medical imaging and healthcare. It involves the process of categorizing medical images into predefined classes or categories based on their content and features.`,
        },
        {
          id: 3,
          disabled: true,
          nameDB: "Random Forest",
          name: "Random Forest",
          description: `Random Forest is a popular machine learning algorithm that is widely used for both classification and regression tasks. It belongs to the ensemble learning family of algorithms, which means it combines the predictions of multiple individual models to make more accurate and robust predictions.`,
        },
        {
          id: 53,
          disabled: false,
          nameDB: "Medical Image Segmentation",
          name: "Medical Image Segmentation",
          description: `Medical Image Segmentation is a crucial task in the field of medical imaging and computer vision. It involves the process of partitioning or dividing a medical image into distinct, meaningful regions or segments.`,
        },
      ],
      datasetList: [],
      selectedDataset: [],
    };
  },
  methods: {
    handleSelectionChange(val) {
      this.selectedDataset = val;
    },
    async createStudy() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
      try {
        const date = new Date();
        const formattedDate = `${
          date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
        }/${
          date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
        }/${date.getFullYear()}`;
        const body = {
          analysisMethodId: this.form.analysisMethodId,
          compositeStudyId: null,
          parameters: null,
          server: "0.0.0.0",
          model: null,
          dataSources: [],
          userId: this.$store.state.userInfo.userId,
          studyInfo: {
            name: this.form.studyName,
            privacy: this.form.privacy,
            startDate: formattedDate,
            endDate: formattedDate,
            description: this.form.description,
            userId: this.$store.state.userInfo.userId,
          },
          trainingStudyId: null,
          l1Token: "",
          l2Token: "",
          // mainStudyId: this.studyId,
        };
        await api.injectStudy(body);
        loading.close();
        this.$alert(
          "Research is created, please specify parameters in the Research List table",
          "",
          {
            confirmButtonText: "OK",
            callback: () => {
              this.$router.push("/my-researches");
            },
          }
        );
      } catch (error) {
        console.log(error);
        this.$alert("Create Research Failed", "Warning");
      } finally {
        loading.close();
      }
    },
  },
};
</script>

<style scoped>
.method-tooltip {
  width: 300px;
  font-size: 16px;
}
</style>
