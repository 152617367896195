<template>
  <Layout>
    <ResearchList />
  </Layout>
</template>

<script>
import Layout from "@/components/Partials/Layout";
import ResearchList from "./ResearchList";
export default {
  components: { Layout, ResearchList },
};
</script>

<style scoped></style>
