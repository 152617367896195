<template>
  <Layout>
    <DashboardSlider />
    <UploadProductSectionWidget />
    <TrendingSection />
    <SellHistoryAnalytics />
    <TopSellerTopBuyerSliderSection />
    <ProductTable />
  </Layout>
</template>

<script>
import Layout from "@/components/Partials/Layout";
import DashboardSlider from "@/components/HomePage/DashboardSlider";
import UploadProductSectionWidget from "@/components/HomePage/UploadProductSectionWidget";
import ProductTable from "@/components/HomePage/ProductTable";
import TrendingSection from "@/components/HomePage/TrendingSection";
import SellHistoryAnalytics from "@/components/HomePage/SellHistoryAnalytics";
import TopSellerTopBuyerSliderSection from "@/components/HomePage/TopSellerTopBuyerSliderSection";
export default {
  components: {
    TopSellerTopBuyerSliderSection,
    SellHistoryAnalytics,
    TrendingSection,
    ProductTable,
    UploadProductSectionWidget,
    DashboardSlider,
    Layout,
  },
};
</script>

<style scoped></style>
