const { Web3 } = require("web3");

const thetaRpcURL = "https://eth-rpc-api-testnet.thetatoken.org/rpc"; // Your PRC URL goes here
const thetaWeb3 = new Web3(thetaRpcURL);
const lavitaRpcURL = process.env.VUE_APP_RPC_URL; // Your PRC URL goes here
const lavitaWeb3 = new Web3(lavitaRpcURL);

const mainchainAddress = "0xc74c9a64d243bd2bc14c561e4d6b7daae19c73ea";
const subchainAddress = process.env.VUE_APP_TOKEN_ADDRESS;

lavitaWeb3.eth.defaultCommon = {
  customChain: { chainId: 360890, networkId: 360890 },
};
const tnt20TokenABI = [
  {
    inputs: [
      { internalType: "string", name: "name_", type: "string" },
      { internalType: "string", name: "symbol_", type: "string" },
      { internalType: "uint8", name: "decimals_", type: "uint8" },
      { internalType: "uint256", name: "initialSupply_", type: "uint256" },
      { internalType: "bool", name: "mintable_", type: "bool" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "spender", type: "address" },
    ],
    name: "allowance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
    name: "mint",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "mintable",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "sender", type: "address" },
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
];

// const sendThetaToken = async (senderPrivateKey, to, value) => {
//   const thetaRpcURL = "https://eth-rpc-api-testnet.thetatoken.org/rpc"; // Your PRC URL goes here
//   const thetaWeb3 = new Web3(thetaRpcURL);
//   const mainchainAddress = "0xc74c9a64d243bd2bc14c561e4d6b7daae19c73ea";

//   thetaWeb3.eth.accounts.wallet.clear();
//   const wallet = await thetaWeb3.eth.accounts.wallet.add(senderPrivateKey);
//   console.log(wallet);
//   const contract = new thetaWeb3.eth.Contract(tnt20TokenABI, mainchainAddress);
//   const sender = wallet[0];
//   const senderAddress = sender.address;
//   const count = await thetaWeb3.eth.getTransactionCount(senderAddress);
//   const gasPrice = await thetaWeb3.eth.getGasPrice();
//   const createTransaction = await thetaWeb3.eth.accounts.signTransaction(
//     {
//       from: senderAddress,
//       nonce: thetaWeb3.utils.toHex(count),
//       gas: thetaWeb3.utils.toHex(150000),
//       gasPrice,
//       to: mainchainAddress,
//       data: contract.methods.transfer(to, value).encodeABI(),
//     },
//     senderPrivateKey
//   );

//   // Deploy transaction
//   const createReceipt = await thetaWeb3.eth.sendSignedTransaction(
//     createTransaction.rawTransaction
//   );
//   console.log(createReceipt);
//   return createReceipt;
// };

const createThetaWallet = async () => {
  const account = thetaWeb3.eth.accounts.create();
  return account;
};
const createLavitaWallet = async () => {
  const account = lavitaWeb3.eth.accounts.create();
  return account;
};
const thetaTokenBalanceOf = async (address) => {
  const contract = new thetaWeb3.eth.Contract(tnt20TokenABI, mainchainAddress);
  const balance = await contract.methods.balanceOf(address).call();
  return Web3.utils.fromWei(balance, "ether");
};
const lavitaTokenBalanceOf = async (address) => {
  const contract = new lavitaWeb3.eth.Contract(tnt20TokenABI, subchainAddress);
  const balance = await contract.methods.balanceOf(address).call();
  return Web3.utils.fromWei(balance, "ether");
};
const fuelBalanceOf = async (address) => {
  const balance = await thetaWeb3.eth.getBalance(address);
  return balance;
};
const getThetaWallet = async (privateKey) => {
  thetaWeb3.eth.accounts.wallet.clear();
  const wallet = await thetaWeb3.eth.accounts.wallet.add(privateKey);
  return wallet[0];
};
const getLavitaWallet = async (privateKey) => {
  lavitaWeb3.eth.accounts.wallet.clear();
  const wallet = await lavitaWeb3.eth.accounts.wallet.add(privateKey);
  return wallet[0];
};
const sendThetaToken = async (senderPrivateKey, to, valueWei) => {
  const value = Web3.utils.toWei(valueWei, "ether");
  const contract = new thetaWeb3.eth.Contract(tnt20TokenABI, mainchainAddress);
  const sender = await getThetaWallet(senderPrivateKey);
  const senderAddress = sender.address;
  const count = await thetaWeb3.eth.getTransactionCount(senderAddress);
  const gasPrice = await thetaWeb3.eth.getGasPrice();
  const createTransaction = await thetaWeb3.eth.accounts.signTransaction(
    {
      from: senderAddress,
      nonce: thetaWeb3.utils.toHex(count),
      gas: thetaWeb3.utils.toHex(150000),
      gasPrice,
      to: mainchainAddress,
      data: contract.methods.transfer(to, value).encodeABI(),
    },
    senderPrivateKey
  );

  // Deploy transaction
  const createReceipt = await thetaWeb3.eth.sendSignedTransaction(
    createTransaction.rawTransaction
  );
  console.log(createReceipt);
  return createReceipt;
};
const sendLavitaToken = async (senderPrivateKey, to, valueWei) => {
  const value = Web3.utils.toWei(valueWei, "ether");
  const contract = new lavitaWeb3.eth.Contract(tnt20TokenABI, subchainAddress);
  const sender = await getLavitaWallet(senderPrivateKey);
  const senderAddress = sender.address;
  const count = await lavitaWeb3.eth.getTransactionCount(senderAddress);
  const gasPrice = await lavitaWeb3.eth.getGasPrice();
  const createTransaction = await lavitaWeb3.eth.accounts.signTransaction(
    {
      from: senderAddress,
      nonce: lavitaWeb3.utils.toHex(count),
      gas: lavitaWeb3.utils.toHex(150000),
      gasPrice,
      to: subchainAddress,
      data: contract.methods.transfer(to, value).encodeABI(),
    },
    senderPrivateKey
  );

  // Deploy transaction
  const createReceipt = await lavitaWeb3.eth.sendSignedTransaction(
    createTransaction.rawTransaction
  );
  return createReceipt;
};
const sendFuel = async (from, to, value) => {
  const receipt = await thetaWeb3.eth.sendTransaction({
    from,
    to,
    value,
    gas: thetaWeb3.utils.toHex(150000),
  });
  console.log(receipt);
};

// const sendTfuel = async params => {
//   const { senderPrivateKey, receiverAddress, tfuelAmount } = params;
//   const ten18 = new BigNumber(10).pow(18);
//   const tfuelWeiToSend = new BigNumber(tfuelAmount).multipliedBy(ten18);
//   const senderWallet = new thetajs.Wallet(senderPrivateKey, provider);
//   const senderWalletAddress = senderWallet.address;
//   const txData = {
//     from: senderWalletAddress,
//     outputs: [
//       {
//         address: receiverAddress,
//         thetaWei: 0,
//         tfuelWei: tfuelWeiToSend,
//       },
//     ],
//   };
//   const tx = new thetajs.transactions.SendTransaction(txData);
//   const txResult = await senderWallet.sendTransaction(tx);
//   return txResult;
// };

// const sendThetaToken = async (params, gasLimit = 0) => {
//   try {
//     let result;
//     const { senderPrivateKey, receiverAddress, tokenAmount } = params;
//     const senderWallet = new thetajs.Wallet(senderPrivateKey, provider);
//     const senderContract = new thetajs.Contract(
//       tnt20TokenContractAddress,
//       tnt20TokenABI,
//       senderWallet,
//     );
//     const ten18 = new BigNumber(10).pow(18);
//     if (gasLimit) {
//       const overRides = {
//         gasLimit: Math.ceil(gasLimit.multipliedBy(ten18).toNumber()),
//       };
//       result = await senderContract.transfer(
//         receiverAddress,
//         tokenAmount,
//         overRides,
//       );
//     } else {
//       result = await senderContract.transfer(receiverAddress, tokenAmount);
//       return result;
//     }
//     return result;
//   } catch (error) {
//     console.log(error);
//   }
// };

// const estimatedGasOf = async params => {
//   const { senderPrivateKey, receiverAddress, tokenAmount } = params;
//   const senderWallet = new thetajs.Wallet(senderPrivateKey, provider);
//   const senderContract = new thetajs.Contract(
//     tnt20TokenContractAddress,
//     tnt20TokenABI,
//     senderWallet,
//   );
//   const estimatedGas = await senderContract.estimateGas.transfer(
//     receiverAddress,
//     tokenAmount.toString(),
//   );
//   const ten18 = new BigNumber(10).pow(18);
//   const value = estimatedGas.dividedBy(ten18);
//   return value.toString();
// };

export {
  createThetaWallet,
  thetaTokenBalanceOf,
  createLavitaWallet,
  lavitaTokenBalanceOf,
  getThetaWallet,
  getLavitaWallet,
  sendThetaToken,
  sendLavitaToken,
  fuelBalanceOf,
  sendFuel,
  //   tfuelBalanceOf,
  //   estimatedGasOf,
};
