<template>
  <header class="nftmax-header" :class="[drawer ? '' : 'nftmax-close']">
    <div class="container">
      <div class="row g-50">
        <div class="col-12">
          <!-- Dashboard Header -->
          <div class="nftmax-header__inner">
            <div
              class="nftmax__sicon close-icon d-xl-none"
              @click="() => this.$store.dispatch('layoutHandler')"
            >
              <img
                v-show="!this.$store.state.layoutDrawer"
                src="../../../assets/img/menu-toggle.svg"
                alt="#"
              />
              <img
                v-show="this.$store.state.layoutDrawer"
                src="../../../assets/img/menu-toggle-reverse.svg"
                alt="#"
              />
            </div>
            <div class="nftmax-header__left">
              <!-- Search Form -->
              <div class="text">
                <!-- <form class="nftmax-header__form-inner" action="#">
                  <button class="search-btn" type="submit">
                    <img src="../../../assets/img/search.png" alt="#" />
                  </button>
                  <input
                    name="s"
                    value=""
                    type="text"
                    placeholder="Search items, collections..."
                  />
                </form> -->
                Next generation health data marketplace
              </div>
              <!-- End Search Form -->
            </div>
            <div class="nftmax-header__right">
              <div class="nftmax-header__group">
                <!-- Search Form -->
                <!-- <div class="nftmax-header__amount">
                  <div class="nftmax-amount__icon">
                    <img src="../../../assets/img/bag-icon.svg" alt="#" />
                  </div>
                  <div class="nftmax-header__plus">
                    <a href="#"
                      ><img src="../../../assets/img/plus-icon.svg" alt="#"
                    /></a>
                  </div>
                  <div class="nftmax-balance">
                    <h3 class="nftmax-balance__title">Your Balance</h3>
                    <ul class="nftmax-balance_list">
                      <li>
                        <div class="nftmax-balance-info">
                          <div class="nftmax-balance__img">
                            <img
                              src="../../../assets/img/Theta_logo.png"
                              alt="#"
                            />
                          </div>
                          <h4 class="nftmax-balance-name balance-container">
                            <span>Theta</span>
                            <span
                              v-if="$store.state.thetaWalletBalance !== null"
                              >{{ $store.state.thetaWalletBalance }}</span
                            >
                          </h4>
                        </div>
                      </li>
                      <li>
                        <div class="nftmax-balance-info">
                          <div class="nftmax-balance__img">
                            <img src="../../../assets/img/lavita.png" alt="#" />
                          </div>
                          <h4 class="nftmax-balance-name balance-container">
                            <span>Lavita</span>
                            <span
                              v-if="$store.state.lavitaWalletBalance !== null"
                              >{{ $store.state.lavitaWalletBalance }}</span
                            >
                          </h4>
                        </div>
                      </li>
                    </ul>
                    <img
                      src="../../../assets/img/metamask-logo.svg"
                      alt="#"
                      class="metamask-logo"
                    />
                    <div
                      v-if="$store.state.walletInfo === null"
                      class="nftmax-balance__button"
                      @click="() => $emit('connectWallet')"
                    >
                      <a class="nftmax-btn nftmax-btn__bordered bg radius"
                        >Connect to MetaMask</a
                      >
                    </div>
                    <div
                      v-if="$store.state.walletInfo !== null"
                      class="nftmax-balance__button"
                      @click="() => $emit('disconnectWallet')"
                    >
                      <a class="nftmax-btn nftmax-btn__bordered bg radius"
                        >Disconnect</a
                      >
                    </div>
                  </div>
                </div> -->
                <!-- End Search Form -->
                <div class="nftmax-header__group-two">
                  <div class="nftmax-header__right">
                    <div class="nftmax-header__author">
                      <!-- <div class="nftmax-header__author-img">
                        <img
                          src="../../../assets/img/profile-pic.png"
                          alt="#"
                        />
                      </div> -->
                      <div class="nftmax-header__author-content">
                        <h4 class="nftmax-header__author-title">
                          {{ $store.state.userInfo.username }}
                        </h4>
                        <!-- <p class="nftmax-header__author-text v1">
                          <a href="#">@broklinslam_75</a>
                        </p> -->
                      </div>

                      <!-- NFTMax Profile Hover -->
                      <div class="nftmax-balance nftmax-profile__hover">
                        <h3 class="nftmax-balance__title">My Profile</h3>
                        <ul class="nftmax-balance_list">
                          <!-- <li>
                            <div class="nftmax-balance-info">
                              <div
                                class="nftmax-balance__img nftmax-profile__img-one"
                              >
                                <img
                                  src="../../../assets/img/profile-1.png"
                                  alt="#"
                                />
                              </div>
                              <h4 class="nftmax-balance-name">
                                <router-link to="/profile"
                                  >My Profile</router-link
                                >
                              </h4>
                            </div>
                          </li>
                          <li>
                            <div class="nftmax-balance-info">
                              <div
                                class="nftmax-balance__img nftmax-profile__img-two"
                              >
                                <img
                                  src="../../../assets/img/profile-2.png"
                                  alt="#"
                                />
                              </div>
                              <h4 class="nftmax-balance-name">
                                <router-link to="/messages">Message</router-link
                                ><span class="nftmax-profile__count">19</span>
                              </h4>
                            </div>
                          </li>
                          <li>
                            <div class="nftmax-balance-info">
                              <div
                                class="nftmax-balance__img nftmax-profile__img-three"
                              >
                                <img
                                  src="../../../assets/img/profile-3.png"
                                  alt="#"
                                />
                              </div>
                              <h4 class="nftmax-balance-name">
                                <router-link to="/history">History</router-link>
                              </h4>
                            </div>
                          </li> -->
                          <li>
                            <div class="nftmax-balance-info">
                              <div
                                class="nftmax-balance__img nftmax-profile__img-four"
                              >
                                <img
                                  src="../../../assets/img/profile-4.png"
                                  alt="#"
                                />
                              </div>
                              <h4 class="nftmax-balance-name">
                                <router-link to="/settings"
                                  >Setting</router-link
                                >
                              </h4>
                            </div>
                          </li>
                          <li @click="() => $emit('logoutModalHandler')">
                            <div class="nftmax-balance-info">
                              <div
                                class="nftmax-balance__img nftmax-profile__img-five"
                              >
                                <img
                                  src="../../../assets/img/profile-5.png"
                                  alt="#"
                                />
                              </div>
                              <h4 class="nftmax-balance-name">
                                <a>Sign Out</a>
                              </h4>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <!-- End NFTMax Balance Hover -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    drawer: Boolean,
  },
  data() {
    return {
      wallerModal: false,
    };
  },
  methods: {
    walletModalHandler() {
      this.wallerModal = !this.wallerModal;
    },
  },
};
</script>

<style scoped>
.metamask-logo {
  margin-left: 100px;
  width: 200px;
}
.inline {
  width: 160px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.balance-container {
  width: 260px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.text {
  letter-spacing: 0.2rem;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  background: -webkit-linear-gradient(
    left,
    #34e393,
    #5356fb 25%,
    #34e393 50%,
    #5356fb 75%,
    #34e393 100%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-size: 200% 100%;
  -webkit-background-size: 200% 100%;
  -webkit-animation: maskedAnimation 8s infinite linear;
  animation: maskedAnimation 8s infinite linear;
}
@keyframes maskedAnimation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -100% 0;
  }
}
</style>
