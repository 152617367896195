<template>
  <Layout>
    <div class="nftmax-inner__heading">
      <h2 class="nftmax-inner__page-title">Fill Study Info</h2>
    </div>
    <div class="nftmax__item">
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        label-position="top"
      >
        <el-form-item label="Select Train File" prop="trainFile">
          <el-table
            :data="datasetList"
            :row-style="hideTrainRow"
            @selection-change="trainFileSelect"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column property="id" label="File Id"></el-table-column>
            <el-table-column
              property="filename"
              label="File Name"
            ></el-table-column>
            <el-table-column label="Description">
              <template> Description... </template>
            </el-table-column>
            <el-table-column
              property="edgeNodeId"
              label="EdgeNode Id"
            ></el-table-column>
            <!-- <el-table-column label="ID">
              <template slot-scope="scope">
                {{ scope.row.parameters.id }}
              </template>
            </el-table-column>
            <el-table-column label="Y">
              <template slot-scope="scope">
                {{ scope.row.parameters.y }}
              </template>
            </el-table-column> -->
            <el-table-column label="Data Details">
              <template slot-scope="scope">
                <el-tooltip class="item" placement="right" effect="light">
                  <div slot="content">
                    <div class="tag-container">
                      <el-descriptions
                        title="Data Details"
                        :column="1"
                        direction="vertical"
                      >
                        <el-descriptions-item label="ID">
                          <el-tag class="tag">{{
                            scope.row.parameters.id
                          }}</el-tag>
                        </el-descriptions-item>
                        <el-descriptions-item label="Features">
                          <el-tag
                            v-for="tag in scope.row.parameters.features"
                            :key="tag"
                            class="tag"
                            >{{ tag }}</el-tag
                          ></el-descriptions-item
                        >
                      </el-descriptions>
                    </div>
                  </div>
                  <i class="el-icon-s-data"></i>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="pagination"
            :current-page="1"
            :page-size="5"
            layout="total, prev, pager, next, jumper"
            :total="datasetList.length"
            @current-change="trainTableChange"
          >
          </el-pagination>
        </el-form-item>

        <el-form-item label="Select Test File" prop="testFile">
          <el-table
            :data="datasetList"
            :row-style="hideTestRow"
            @selection-change="testFileSelect"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column property="id" label="Dataset Id"></el-table-column>
            <el-table-column
              property="filename"
              label="File Name"
            ></el-table-column>
            <el-table-column label="Description">
              <template> Description... </template>
            </el-table-column>
            <el-table-column
              property="edgeNodeId"
              label="EdgeNode Id"
            ></el-table-column>
            <!-- <el-table-column label="ID">
              <template slot-scope="scope">
                {{ scope.row.parameters.id }}
              </template>
            </el-table-column>
            <el-table-column label="Y">
              <template slot-scope="scope">
                {{ scope.row.parameters.y }}
              </template>
            </el-table-column> -->
            <el-table-column label="Data Details">
              <template slot-scope="scope">
                <el-tooltip class="item" placement="right" effect="light">
                  <div slot="content">
                    <div class="tag-container">
                      <el-descriptions
                        title="Data Details"
                        :column="1"
                        direction="vertical"
                      >
                        <el-descriptions-item label="ID">
                          <el-tag class="tag">{{
                            scope.row.parameters.id
                          }}</el-tag>
                        </el-descriptions-item>
                        <el-descriptions-item label="Features">
                          <el-tag
                            v-for="tag in scope.row.parameters.features"
                            :key="tag"
                            class="tag"
                            >{{ tag }}</el-tag
                          ></el-descriptions-item
                        >
                      </el-descriptions>
                    </div>
                  </div>
                  <i class="el-icon-s-data"></i>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="pagination"
            :current-page="1"
            :page-size="5"
            layout="total, prev, pager, next, jumper"
            :total="datasetList.length"
            @current-change="testTableChange"
          >
          </el-pagination>
        </el-form-item>

        <el-form-item label="ID">
          <el-tag v-show="computedId">{{ computedId }}</el-tag>
        </el-form-item>

        <el-form-item label="Y">
          <el-select v-model="y" placeholder="please select Y">
            <el-option
              v-for="item in yList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="Split Ratio">
          <el-input-number
            v-model="split_ratio"
            :controls="false"
          ></el-input-number>
        </el-form-item> -->
        <el-form-item class="right-btn">
          <el-button type="primary" @click="startStudy">Start Study</el-button>
        </el-form-item>
      </el-form>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/Partials/Layout";
import api from "../../http/api";
import { Loading } from "element-ui";

export default {
  components: { Layout },
  data() {
    return {
      form: {
        studyName: "",
        description: "",
        privacy: "Public",
        sgx: "",
        analysisMethodId: 51,
      },
      y: "",
      sgxServerList: [
        {
          value: "US",
          label: "Los Angeles",
        },
        {
          value: "US",
          label: "New York",
        },
        {
          value: "Canada",
          label: "Toronto",
        },
        {
          value: "Japan",
          label: "Tokyo",
        },
        {
          value: "Australia",
          label: "Sydney",
        },
        {
          value: "France",
          label: "Paris",
        },
      ],
      datasetList: [],
      selectedTrainFiles: [],
      selectedTestFiles: [],
      split_ratio: 0.6,
      trainPageNum: 1,
      testPageNum: 1,
    };
  },
  computed: {
    computedId() {
      const testID = this.selectedTestFiles.map((item) => {
        return item.parameters.id;
      });
      const trainID = this.selectedTrainFiles.map((item) => {
        return item.parameters.id;
      });
      if (!testID.length && !trainID.length) {
        return "";
      }
      const idList = Array.from(new Set([...testID, ...trainID]));
      return idList.length ? idList[0] : "";
    },
    yList() {
      const testY = this.selectedTestFiles.map((item) => {
        return item.parameters.features;
      });
      const trainY = this.selectedTrainFiles.map((item) => {
        return item.parameters.features;
      });
      if (!testY.length && !trainY.length) {
        return [];
      }
      const yList = Array.from(new Set([...testY, ...trainY].flat()));
      return yList.length ? yList : [];
    },
  },
  async mounted() {
    let loading = Loading.service({
      fullscreen: true,
      text: "loading data...",
    });
    try {
      const res = await api.getFilesList({
        type: 3,
        edgeNodeId: null,
        filename: "",
        privacy: null,
        startTime: null,
        endTime: null,
        page: 1,
        perPage: 10000,
        userId: this.$store.state.userInfo.userId,
      });
      this.datasetList = res.data.data.list.map((item) => {
        return {
          ...item,
          parameters: JSON.parse(item.dataDetails),
        };
      });
      console.log(this.datasetList);
    } catch (error) {
      console.log(error);
    } finally {
      loading.close();
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    hideTrainRow({ row, rowIndex }) {
      let array = this.datasetList;
      let pageNo = this.trainPageNum;
      const pageSize = 5;
      const offset = (pageNo - 1) * pageSize;
      const isInPage =
        rowIndex >= offset &&
        rowIndex < Math.min(array.length, offset + pageSize);
      return isInPage ? {} : { display: "none" };
    },
    // eslint-disable-next-line no-unused-vars
    hideTestRow({ row, rowIndex }) {
      let array = this.datasetList;
      let pageNo = this.testPageNum;
      const pageSize = 5;
      const offset = (pageNo - 1) * pageSize;
      const isInPage =
        rowIndex >= offset &&
        rowIndex < Math.min(array.length, offset + pageSize);
      return isInPage ? {} : { display: "none" };
    },
    trainTableChange(val) {
      this.trainPageNum = val;
    },
    testTableChange(val) {
      this.testPageNum = val;
    },
    trainFileSelect(val) {
      this.selectedTrainFiles = val;
    },
    testFileSelect(val) {
      try {
        this.selectedTestFiles = val;
      } catch (error) {
        console.log(error);
      }
    },
    // 求数组交集
    getIntersection(arr) {
      const features = arr.reduce((data, item) => {
        return data.filter((i) => {
          return item.some((j) => {
            return i === j;
          });
        });
      });
      return features;
    },
    async startStudy() {
      let loading = Loading.service({
        fullscreen: true,
        text: "Connecting to server...",
      });
      setTimeout(function () {
        loading.setText("EdgeNode data-coordination in progress ...");
      }, 3000);
      setTimeout(function () {
        loading.setText("Remote attestation in progress ...");
      }, 7000);
      setTimeout(function () {
        loading.setText("Encrypted data uploading to SGX in progress ...");
      }, 11000);
      setTimeout(async () => {
        try {
          // 求多个数组的交集
          const testFeatures = this.selectedTestFiles.map((item) => {
            return item.parameters.features;
          });
          const trainFeatures = this.selectedTrainFiles.map((item) => {
            return item.parameters.features;
          });
          const features = this.getIntersection([
            ...testFeatures,
            ...trainFeatures,
          ]).filter((item) => item !== this.y);
          // const id = this.getIntersection([testID, trainID]);
          // const y = this.getIntersection([testY, trainY]);
          const trainFilesList = this.selectedTrainFiles.map(
            (item) => item.filename
          );
          const testFilesList = this.selectedTestFiles.map(
            (item) => item.filename
          );

          const {
            id,
            analysisMethodId,
            name,
            privacy,
            description,
            startDate,
            endDate,
          } = this.$route.params.studyInfo;
          const params = {
            analysisMethodId,
            features,
            id: this.computedId,
            y: this.y,
            split_ratio: this.split_ratio,
            filenames: {
              train: trainFilesList,
              test: testFilesList,
            },
          };
          const body = {
            studyId: id,
            analysisMethodId: analysisMethodId,
            compositeStudyId: null,
            parameters: params,
            server: "0.0.0.0",
            model: null,
            dataSources: [],
            userId: this.$store.state.userInfo.userId,
            studyInfo: {
              name,
              privacy,
              startDate,
              endDate,
              description,
              userId: this.$store.state.userInfo.userId,
            },
            trainingStudyId: null,
            l1Token: "",
            l2Token: "",
          };
          await api.startStudy(body);
          loading.close();
          this.$alert("Sucessful - Study Started", "", {
            confirmButtonText: "OK",
            callback: () => {
              this.$router.push("/my-researches");
            },
          });
        } catch (error) {
          this.$alert("Start Study Failed", "Error");
        } finally {
          loading.close();
        }
      }, 15000);
    },
  },
};
</script>

<style scoped>
.method-tooltip {
  width: 300px;
  font-size: 16px;
}
.tag-container {
  width: 400px;
}
.tag {
  margin: 5px;
}
.right-btn {
  text-align: right;
}
.pagination {
  margin-top: 20px;
  margin-bottom: 40px;
}
</style>
