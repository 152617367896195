<template>
  <LineCart id="chart" :options="options" :data="data" />
</template>

<script>
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line as LineCart } from "vue-chartjs";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  {
    id: "lineChart",
  }
);
export default {
  components: {
    LineCart,
  },
  data() {
    return {
      options: {
        type: "line",
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
            gridLines: {
              zeroLineColor: "transparent",
            },
          },

          y: {
            beginAtZero: true,
            ticks: {
              display: false,
            },
            grid: {
              display: false,
              drawBorder: false,
            },
            gridLines: {
              zeroLineColor: "transparent",
            },
          },
        },
        maintainAspectRatio: false,
        lineTension: 0.5,
      },

      data: {
        labels: ["Jan 1", "Jan 2", "Jan 3", "Jan 4", "Jan 5", "Jan 6", "Jan 7"],
        datasets: [
          {
            label: "First dataset",
            data: [50, 30, 100, 20, 50, 30, 100],
            // data: [50, 30, 100, 20, 50, 30, 100, 20],
            fill: true,
            backgroundColor: "rgb(250, 236, 254)",
            borderColor: "#D040F9",
            pointBorderColor: "#AE8FF7",
            pointBackgroundColor: "#ffffff",
            pointRadius: 5,
            pointHoverRadius: 5,
            borderWidth: 5,
            pointBorderWidth: 3,
            pointHoverBorderWidth: 5,
          },
          // {
          //   label: 'Data One',
          //   backgroundColor: '#f87979',
          //   data: [this.getRandomInt(), this.getRandomInt()]
          // }
        ],
      },
    };
  },
};
</script>

<style scoped></style>
