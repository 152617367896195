<template>
  <AuthLayout>
    <div class="nftmax-wc__form">
      <div class="nftmax-wc__form-inner nftmax-wc__form-new">
        <div class="nftmax-wc__heading">
          <h3
            class="nftmax-wc__form-title nftmax-wc__form-title__two"
            style="
              background-image: url('../../assets/img/heading-vector-2.png');
            "
          >
            Create Account
          </h3>
        </div>
        <!-- Sign in Form -->
        <form class="nftmax-wc__form-main" action="/" method="post">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="nftmax-wc__form-label">Email Address</label>
                <div class="form-group__input">
                  <el-input v-model="email" placeholder=""></el-input>
                </div>
              </div>
            </div>
            <!-- <div class="col-12">
              <div class="form-group">
                <label class="nftmax-wc__form-label">User Name</label>
                <div class="form-group__input">
                  <el-input v-model="userName" placeholder=""></el-input>
                </div>
              </div>
            </div> -->
            <div class="col-12">
              <div class="form-group">
                <label class="nftmax-wc__form-label">Password</label>
                <div class="form-group__input">
                  <el-input
                    v-model="password"
                    placeholder=""
                    type="password"
                    show-password
                  ></el-input>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label class="nftmax-wc__form-label">Re-enter Password</label>
                <div class="form-group__input">
                  <el-input
                    v-model="reEnterPassword"
                    placeholder=""
                    type="password"
                    show-password
                  ></el-input>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label class="nftmax-wc__form-label">Invitation Code</label>
                <div class="form-group__input">
                  <el-input v-model="invitationCode" placeholder=""></el-input>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <div>
                  <div class="nftmax-wc__checkbox">
                    <input
                      id="checkbox"
                      class="nftmax-wc__form-check"
                      name="checkbox"
                      type="checkbox"
                    />
                    <label for="checkbox"
                      >I agree to all
                      <el-button type="text" @click="consentFormDialog = true"
                        >Terms and Conditions</el-button
                      >
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group nftmax-wc__new-bottom">
                <div class="nftmax-wc__button">
                  <button class="ntfmax-wc__btn" type="button" @click="signUp">
                    Sign Up
                  </button>
                </div>
              </div>
              <div v-if="!isMobile" class="form-group">
                <div class="nftmax-wc__bottom">
                  <p class="nftmax-wc__text">
                    Already have an account?
                    <router-link to="/login" style="color: #34e393"
                      >Login</router-link
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- End Sign in Form -->
      </div>
    </div>
    <el-dialog
      title="Terms and Condition – User Consent for Accessing Lavita Platform"
      :visible.sync="consentFormDialog"
      center
    >
      <div class="no-wrap">
        <p>
          Lavita's mission is to help individuals take full control of their
          health data. Users will benefit by unlocking the value of their health
          data, to receive personalized health and wellness information, and to
          participate in paying research and studies.
        </p>

        <p>
          By agreeing to this consent, you give permission to Lavita to retrieve
          and encrypt your health data from Epic and other EHR providers. Your
          data will be immediately encrypted on your device browser, using a
          private encryption key that is generated from your chosen password.
          Lavita never stores your private encryption key. Lavita never stores
          your unencrypted data. Only users themselves can access their data by
          login into Lavita platform and using their private encryption key,
          generated by your password. No entity, including Lavita itself, can
          decrypt your data without your own explicit consent.
        </p>

        <p>
          If you choose to contribute your data to Lavita platform, Lavita will
          collect certain de-identified meta data about you to allow researchers
          to ask for your participation, with your permission, in paid studies
          through Lavita platform.
        </p>

        <p>For further information please contact privacy@lavita.ai</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="consentFormDialog = false"
          >Close</el-button
        >
      </span>
    </el-dialog>
  </AuthLayout>
</template>

<script>
import AuthLayout from "@/components/Auth/AuthLayout";
import api from "../../http/api";
export default {
  components: { AuthLayout },
  data() {
    return {
      email: "",
      userName: "",
      password: "",
      reEnterPassword: "",
      invitationCode: "",
      consentFormDialog: false,
    };
  },
  computed: {
    isMobile() {
      const userAgent = navigator.userAgent;
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          userAgent
        );
      return isMobile;
    },
  },
  methods: {
    async signUp() {
      try {
        if (
          this.email !== "" &&
          this.password !== "" &&
          this.reEnterPassword !== "" &&
          this.invitationCode !== ""
        ) {
          if (this.password === this.reEnterPassword) {
            const passwordRegex =
              /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/;
            if (!passwordRegex.test(this.password)) {
              this.$vToastify.warning(
                "The password must contain at least 6 digits, including at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."
              );
              return;
            }
            const signUpSource = this.isMobile ? 1 : 0;
            await api.signUp({
              // username: this.userName,
              password: this.password,
              email: this.email,
              invitationCode: this.invitationCode,
              signUpSource,
            });
            this.$router.push({
              name: "SignUpVerify",
              params: { userName: this.email, password: this.password },
            });
          } else {
            this.$vToastify.error("Password doesn't match");
          }
        } else {
          this.$vToastify.error("Please fill all fields");
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.no-wrap {
  word-break: keep-all;
}
</style>
