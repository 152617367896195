import axios from "axios";
import { Message } from "element-ui";
import router from "../router";
import { store } from "../store/index";

const serverUrl = "https://gateway.lavita.ai";
const userService = "/user-service";
const dataMinerService = "/dataminer-service";
let r4EpicUrl = "";
let DSTU2EpicUrl = "";
const axiosList = {};

axios.defaults.withCredentials = false;

axios.interceptors.request.use(
  async (request) => {
    if (sessionStorage.getItem("userDataInfo")) {
      const userDataInfo = JSON.parse(sessionStorage.getItem("userDataInfo"));
      r4EpicUrl = userDataInfo.selectedOrg["Epic Endpiont"];
      DSTU2EpicUrl = r4EpicUrl.replace("FHIR/R4", "FHIR/DSTU2");
    }
    if (request.url.includes("lavita.ai")) {
      const LicenseToken = sessionStorage.getItem("LicenseToken");
      const RefreshTokenLicense = sessionStorage.getItem("RefreshTokenLicense");
      const RefreshBearerToken = sessionStorage.getItem("RefreshBearerToken");
      const BearerToken = sessionStorage.getItem("BearerToken");
      if (LicenseToken && RefreshTokenLicense) {
        request.headers.LicenseToken = LicenseToken;
        request.headers.RefreshTokenLicense = RefreshTokenLicense;
      }
      if (BearerToken && RefreshBearerToken) {
        request.headers.Authorization = `${BearerToken}`;
        request.headers.RefreshBearerToken = `${RefreshBearerToken}`;
      }
    } else if (
      request.url.includes("FHIR/R4") ||
      request.url.includes("FHIR/DSTU2")
    ) {
      request.headers.Authorization = `Bearer ${store.state.epicInfo.access_token}`;
    }
    //3. 全局防抖拦截，请根据实际情况自行修改
    // if (request.url.includes("gateway.lavita.ai")) {
    //   const urlKey = `${request.url}-${JSON.stringify(request.data || {})}`;
    //   const lastTime = axiosList[urlKey];
    //   if (lastTime) {
    //     const diffTime = new Date().getTime() - lastTime;
    //     if (diffTime < 5000) {
    //       //间隔时间，小于此时间视为重复提交
    //       return Promise.reject({ code: 1, msg: "数据正在处理，请勿重复提交" });
    //     }
    //   }
    //   axiosList[urlKey] = new Date().getTime(); // 保持或更新请求记录
    //   // 添加记录，然后自动清除
    //   setTimeout(() => {
    //     delete axiosList[urlKey];
    //   }, 5000);
    // }
    return request;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  async (response) => {
    if (response.headers["X-CSRF-Token"]) {
      sessionStorage.setItem("csrfToken", response.headers["X-CSRF-Token"]);
    }
    if (response.headers.licensetoken) {
      sessionStorage.setItem("LicenseToken", response.headers.licensetoken);
    }
    if (response.headers.refreshtokenlicense) {
      sessionStorage.setItem(
        "RefreshTokenLicense",
        response.headers.refreshtokenlicense
      );
    }
    if (response.headers.bearertoken) {
      sessionStorage.setItem("BearerToken", response.headers.bearertoken);
    }
    if (response.headers.refreshbearertoken) {
      sessionStorage.setItem(
        "RefreshBearerToken",
        response.headers.refreshbearertoken
      );
    }
    if (
      response.request.responseURL ===
      "https://gateway.lavita.ai/dataminer-service/download"
    ) {
      return response;
    }
    if (response.request.responseURL.includes(serverUrl)) {
      switch (response.data.code) {
        // 返回响应内容
        case 0:
          return response;
        case 102:
          Message.error(
            "You've been logged out; please log in again to access the Lavita platform"
          );
          store.commit("clearWalletInfo");
          router.push({ name: "Login" });
          break;
        // 提示错误信息
        default:
          Message.error(response.data.message || "Network Error");
          return Promise.reject(response);
      }
    }
    return response;
  },
  (err) => {
    console.log(err);
    // 根据message过滤错误
    if (
      err.response.data &&
      err.response.data.message.includes("JWT expired")
    ) {
      Message.error(
        "You've been logged out; please log in again to access the Lavita platform"
      );
      router.push({ name: "Login" });
    }
    if (!err.response) {
      console.log(1);
      Message.error("Network Error");
    }
    return Promise.reject(err);
  }
);

const api = {
  // 注册账号
  signUp: (signUpForm) =>
    axios.post(`${serverUrl}${userService}/signup`, signUpForm),
  // 生成验证码
  createImageCode: () =>
    axios.post(
      `${serverUrl}${userService}/createImageCode`,
      {},
      { responseType: "arraybuffer" }
    ),
  // 注册
  logIn: (logInForm) =>
    axios.post(`${serverUrl}${userService}/login`, logInForm),
  // 忘记密码
  forgotPassword: (form) =>
    axios.post(`${serverUrl}${userService}/forgotPassword`, form),
  // 重设密码
  resetPassword: (form) =>
    axios.post(`${serverUrl}${userService}/resetPassword`, form),
  // 发送验证码到邮箱
  sendCodeToEmail: (form) =>
    axios.post(`${serverUrl}${userService}/sendCodeToEmail`, form),
  // 注册验证
  signUpVerify: (form) =>
    axios.post(`${serverUrl}${userService}/verifySignUp`, form),
  // 获取文件列表
  getFilesList: (form) =>
    axios.post(`${serverUrl}${dataMinerService}/listOfFiles`, form),
  // 绑定钱包
  registerWallet: (form) =>
    axios.post(`${serverUrl}${userService}/wallet/register`, form),
  // 解绑钱包
  revokeWallet: (form) =>
    axios.post(`${serverUrl}${userService}/wallet/revoke`, form),
  // 上传文件
  uploadFile: (data, params) =>
    axios({
      url: `${serverUrl}${dataMinerService}/upload`,
      method: "post",
      data,
      params,
    }),
  // 注册研究
  injectStudy: (parameter) =>
    axios.post(`${serverUrl}${dataMinerService}/studies/inject`, parameter),
  // 创建研究
  startStudy: (data) => {
    return axios({
      method: "post",
      url: `${serverUrl}${dataMinerService}/studies/start`,
      data,
    });
  },
  // 获取研究项目列表
  getStudyProjectList: async (data) => {
    return axios({
      url: `${serverUrl}${dataMinerService}/studies/list`,
      method: "post",
      data,
    });
  },
  // 获取研究结果
  getResult: (data) =>
    axios({
      url: `${serverUrl}${dataMinerService}/studies/result/get`,
      method: "post",
      data,
    }),
  // 获取研究信息
  getStudyInfo: (body) =>
    axios.post(`${serverUrl}${dataMinerService}/studies/getInfo`, body),
  // 获取研究信息
  studyPaid: (body) =>
    axios.post(`${serverUrl}${dataMinerService}/studyPaid`, body),
  epicAuth: (params) => {
    const endpoint = sessionStorage.getItem("tokenEndpoint");
    return axios.post(endpoint, params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded", // 例如设置为 JSON 类型
        // 如果需要其他类型，可以根据需求进行修改
      },
    });
  },

  r4EpicInfoGet: (params) => {
    return axios.get(`${r4EpicUrl}${params.endpoint}`, {
      params: params.params ? params.params : null,
    });
  },
  DSTU2EpicInfoGet: (params) => {
    return axios.get(`${DSTU2EpicUrl}${params.endpoint}`, {
      params: params.params ? params.params : null,
    });
  },
  uploadEHRData: (body) =>
    axios.post(`${serverUrl}${dataMinerService}/ehr`, body),
  getDataDetail: (body) =>
    axios.post(`${serverUrl}${dataMinerService}/download`, body),
  getImage: (body) =>
    axios.post(`${serverUrl}${dataMinerService}/download`, body, {
      responseType: "blob",
      emulateJSON: true,
    }),
  // 获取epic机构oauth相关参数
  getOauthMeta: (body) => {
    return axios.post(`${serverUrl}${dataMinerService}/epic/config`, body);
  },
  // 获取上传EHR数据metadata
  getMetaData: (body) =>
    axios.post(`${serverUrl}${dataMinerService}/metaData`, body),
  // 获得奖励token
  earnToken: (body) => axios.post(`${serverUrl}${dataMinerService}/pay`, body),
  // 删除(屏蔽)用户数据
  invalidData: (body) =>
    axios.post(`${serverUrl}${dataMinerService}/invalid`, body),
  // 检查验证码
  checkVerificationCode: (body) =>
    axios.post(`${serverUrl}${userService}/checkVerificationCode`, body),
  // 更新用户信息
  updateUserInfo: (body) =>
    axios.post(`${serverUrl}${userService}/modify`, body),
  uploadFeedback: (data, params) =>
    axios({
      url: `${serverUrl}${dataMinerService}/feedback`,
      method: "post",
      data,
      params,
    }),

  // 检查主钱包余额
  enoughBalance: () => axios.post(`${serverUrl}${dataMinerService}/check`),
};

export const request = axios;
export default api;
