<template>
  <div>
    <side-bar
      :drawer="this.$store.state.layoutDrawer"
      @logoutModalHandler="logoutModalHandler"
    />
    <header-com
      :drawer="this.$store.state.layoutDrawer"
      @logoutModalHandler="logoutModalHandler"
      @connectWallet="openWalletModal"
      @disconnectWallet="disconnectWallet"
    />
    <section
      class="nftmax-adashboard nftmax-show"
      :class="[this.$store.state.layoutDrawer ? '' : 'nftmax-close']"
    >
      <div class="container">
        <div class="row">
          <div class="col-xxl-9 col-12 nftmax-main__column">
            <div class="nftmax-body">
              <!-- Dashboard Inner -->
              <div class="nftmax-dsinner">
                <slot></slot>
              </div>
              <!-- End Dashboard Inner -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <div
      id="logout_modal"
      :style="{ display: logoutModal ? 'block' : 'none' }"
      :class="[logoutModal ? 'show' : '']"
      class="nftmax-preview__modal modal fade"
      tabindex="-1"
      aria-labelledby="logoutmodal"
      aria-hidden="true"
    >
      <div class="logout-model-shade"></div>
      <div class="modal-dialog modal-dialog-centered nftmax-close__modal-close">
        <div class="modal-content nftmax-preview__modal-content">
          <!-- <div class="modal-header nftmax__modal__header">
            <h4
              id="logoutmodal"
              class="modal-title nftmax-preview__modal-title"
            >
              Confirm
            </h4>
            <button
              type="button"
              class="nftmax-preview__modal--close btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="logoutModalHandler"
            >
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M36 16.16C36 17.4399 36 18.7199 36 20.0001C35.7911 20.0709 35.8636 20.2554 35.8385 20.4001C34.5321 27.9453 30.246 32.9248 22.9603 35.2822C21.9006 35.6251 20.7753 35.7657 19.6802 35.9997C18.4003 35.9997 17.1204 35.9997 15.8401 35.9997C15.5896 35.7086 15.2189 35.7732 14.9034 35.7093C7.77231 34.2621 3.08728 30.0725 0.769671 23.187C0.435002 22.1926 0.445997 21.1199 0 20.1599C0 18.7198 0 17.2798 0 15.8398C0.291376 15.6195 0.214408 15.2656 0.270759 14.9808C1.71321 7.69774 6.02611 2.99691 13.0428 0.700951C14.0118 0.383805 15.0509 0.386897 15.9999 0C17.2265 0 18.4532 0 19.6799 0C19.7156 0.124041 19.8125 0.136067 19.9225 0.146719C27.3 0.868973 33.5322 6.21922 35.3801 13.427C35.6121 14.3313 35.7945 15.2484 36 16.16ZM33.011 18.0787C33.0433 9.77105 26.3423 3.00309 18.077 2.9945C9.78479 2.98626 3.00344 9.658 2.98523 17.8426C2.96667 26.1633 9.58859 32.9601 17.7602 33.0079C26.197 33.0577 32.9787 26.4186 33.011 18.0787Z"
                  fill="#374557"
                  fill-opacity="0.6"
                ></path>
                <path
                  d="M15.9309 18.023C13.9329 16.037 12.007 14.1207 10.0787 12.2072C9.60071 11.733 9.26398 11.2162 9.51996 10.506C9.945 9.32677 11.1954 9.0811 12.1437 10.0174C13.9067 11.7585 15.6766 13.494 17.385 15.2879C17.9108 15.8401 18.1633 15.7487 18.6375 15.258C20.3586 13.4761 22.1199 11.7327 23.8822 9.99096C24.8175 9.06632 26.1095 9.33639 26.4967 10.517C26.7286 11.2241 26.3919 11.7413 25.9133 12.2178C24.1757 13.9472 22.4477 15.6855 20.7104 17.4148C20.5228 17.6018 20.2964 17.7495 20.0466 17.9485C22.0831 19.974 24.0372 21.8992 25.9689 23.8468C26.9262 24.8119 26.6489 26.1101 25.4336 26.4987C24.712 26.7292 24.2131 26.3441 23.7455 25.8757C21.9945 24.1227 20.2232 22.3892 18.5045 20.6049C18.0698 20.1534 17.8716 20.2269 17.4802 20.6282C15.732 22.4215 13.9493 24.1807 12.1777 25.951C11.7022 26.4262 11.193 26.7471 10.4738 26.4537C9.31345 25.9798 9.06881 24.8398 9.98589 23.8952C11.285 22.5576 12.6138 21.2484 13.9387 19.9355C14.5792 19.3005 15.2399 18.6852 15.9309 18.023Z"
                  fill="#374557"
                  fill-opacity="0.6"
                ></path>
              </svg>
            </button>
          </div> -->
          <div
            class="modal-body nftmax-modal__body modal-body nftmax-close__body"
          >
            <div class="nftmax-preview__close">
              <!-- <div class="nftmax-preview__close-img">
                <img src="../../assets/img/close.png" alt="#" />
              </div> -->
              <h2 class="nftmax-preview__close-title">
                Please Confirm Sign Out
              </h2>
              <div class="nftmax__item-button--group">
                <button
                  class="nftmax__item-button--single nftmax-btn nftmax-btn__bordered bg radius"
                  type="submit"
                  @click="doLogout"
                >
                  Sign Out
                </button>
                <button
                  class="nftmax__item-button--single nftmax-btn nftmax-btn__bordered--plus radius"
                  data-bs-dismiss="modal"
                  @click="logoutModalHandler"
                >
                  <span class="ntfmax__btn-textgr">Not Now</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="Connect to your wallet"
      :visible.sync="walletDialog"
      width="40%"
    >
      <el-form ref="form">
        <el-form-item label="Private key">
          <el-input v-model="privateKey"></el-input>
        </el-form-item>
      </el-form>
      <div class="nftmax__item-button--group">
        <el-button @click="createNewWallet"> Create new wallet </el-button>
        <el-button type="primary" @click="connectWallet">
          Connect to your wallet
        </el-button>
      </div>
    </el-dialog>
    <!-- End Logout Modal -->
    <el-dialog
      title="Connet wallet success"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <div class="">Wallet created successfully!</div>
      <div>
        Here's your private key, it's very important, please keep it safely
      </div>
      <br />
      <div>
        {{ $store.state.walletInfo ? $store.state.walletInfo.privateKey : "" }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../http/api";
import HeaderCom from "./Header/HeaderCom.vue";
import SideBar from "./SideBar/SideBar.vue";
import { lavitaTokenBalanceOf } from "../../lib/thetaUtil";
// import RightSideBarCom from "./RightSideBar/RightSideBarCom.vue";

export default {
  components: { SideBar, HeaderCom },
  data() {
    return {
      logoutModal: false,
      walletDialog: false,
      privateKey: "",
      dialogVisible: false,
      account: {},
    };
  },
  mounted() {},
  methods: {
    async createNewWallet() {
      // try {
      //   const newWallet = await createThetaWallet();
      //   this.$store.commit("saveWalletInfo", newWallet);
      //   await api.registerWallet({
      //     userId: this.$store.state.userInfo.userId,
      //     address: newWallet.address,
      //   });
      //   this.dialogVisible = true;
      //   this.walletDialog = false;
      // } catch (error) {
      //   console.log(error);
      //   this.$alert("Create failed");
      // }
    },
    async connectWallet() {
      // try {
      //   if (this.privateKey === "") {
      //     this.$alert("Please enter private key");
      //     return;
      //   }
      //   const newWallet = await getThetaWallet(this.privateKey);
      //   await api.registerWallet({
      //     userId: this.$store.state.userInfo.userId,
      //     address: newWallet.address,
      //   });
      //   this.$store.commit("saveWalletInfo", newWallet);
      //   this.$alert("Connect successfully!");
      //   this.walletDialog = false;
      // } catch (error) {
      //   console.log(error);
      //   this.$alert("Connect failed");
      // }
    },
    logoutModalHandler() {
      this.logoutModal = !this.logoutModal;
    },
    doLogout() {
      this.logoutModal = false;
      this.$store.commit("RESET_STATE");
      localStorage.removeItem("email");
      localStorage.clear();
      this.$router.push("/login");
      this.$vToastify.success("🙂 Come back soon");
    },
    async openWalletModal() {
      if (window.ethereum) {
        const loading = this.$loading({
          lock: true,
          text: "Connecting to MetaMask...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.8)",
        });
        try {
          loading.setText("Requesting Accounts...");
          await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          const networkId = 360890;
          loading.setText("Adding Chain...");
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: `0x${networkId.toString(16)}`,
                chainName: "Lavita Network", // 网络名称
                rpcUrls: [process.env.VUE_APP_RPC_URL], // RPC URL
                nativeCurrency: {
                  name: "VTFUEL",
                  symbol: "VTFUEL",
                  decimals: 18,
                },
              },
            ],
          });
          loading.setText("Switching Chain...");
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: `0x${networkId.toString(16)}` }],
          });
          this.$alert(
            "We are adding vLAVITA token in MetaMask, this may take more than one minute with several pop-ups <br/><br/> Also you can click the MetaMask browser extension in the top right corner to check if there are any pop-ups that haven't been displayed <br/><br/> Thank you for waiting",
            "Hint",
            {
              dangerouslyUseHTMLString: true,
            }
          );
          loading.setText(
            "Adding vLAVITA token, this may take more than one minute with several steps \nyou can click the browser extension in the top right corner to check if there are any pop-ups that haven't been displayed"
          );
          await window.ethereum.request({
            method: "wallet_watchAsset",
            params: {
              type: "ERC20",
              options: {
                address: process.env.VUE_APP_TOKEN_ADDRESS,
                symbol: "vLAVITA",
                decimals: 18,
                image: "https://i.ibb.co/BsQgbym/lavita.png",
              },
            },
          });
          // 获取货币余额
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          if (accounts.length === 0) {
            this.$alert("There's no MetaMask account connected, please check");
            loading.close();
            return;
          }
          const balance = await window.ethereum.request({
            method: "eth_getBalance",
            params: [accounts[0]],
          });
          const { Web3 } = require("web3");
          const etherBalance = Web3.utils.fromWei(balance, "ether");
          const res = await lavitaTokenBalanceOf(accounts[0]);
          this.$store.commit("updateBalance", {
            lavitaBalance: res,
            tFuelBalance: etherBalance,
          });
          loading.close();
          this.$alert("MetaMask wallet is connected");
        } catch (error) {
          loading.close();
          // this.$alert("Finished");
          console.log(error);
        }
      } else {
        this.$alert("MetaMask is not installed, please check");
      }
    },
    async disconnectWallet() {
      try {
        this.$store.commit("clearWalletInfo");
        await api.revokeWallet({
          userId: this.$store.state.userInfo.userId,
        });
      } catch (error) {
        console.log(error);
        this.$alert("Disconnect failed");
      }
    },
  },
};
</script>

<style scoped>
.logout-model-shade {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000000b5;
}
.wallet-model-shade {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000000b5;
}
</style>
