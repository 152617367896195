<template>
  <AuthLayout>
    <div class="nftmax-wc__form">
      <div class="nftmax-wc__form-inner">
        <div class="nftmax-wc__heading">
          <h3
            class="nftmax-wc__form-title nftmax-wc__form-title__three"
            style="
              background-image: url('../../assets/img/heading-vector-3.png');
            "
          >
            Forgot Password
          </h3>
        </div>
        <!-- Sign in Form -->
        <form class="nftmax-wc__form-main" action="#" method="post">
          <div class="form-group">
            <label class="nftmax-wc__form-label">Email Address</label>
            <div class="form-group__input">
              <el-input v-model="email" placeholder="" type="email"></el-input>
            </div>
          </div>
          <div class="form-group">
            <div class="nftmax-wc__button">
              <el-button
                class="ntfmax-wc__btn"
                type="submit"
                @click="sendEmail"
              >
                Send Code
              </el-button>
            </div>
          </div>
          <div class="form-group">
            <div class="nftmax-wc__button">
              <button
                class="ntfmax-wc__btn ntfmax-wc__btn-grey"
                type="submit"
                @click="back"
              >
                Back
              </button>
            </div>
          </div>
        </form>
        <!-- End Sign in Form -->
      </div>
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from "@/components/Auth/AuthLayout";
import api from "../../http/api";

export default {
  components: { AuthLayout },
  data() {
    return {
      email: "",
    };
  },
  methods: {
    async sendEmail() {
      try {
        if (this.email !== "") {
          await api.sendCodeToEmail({
            email: this.email,
          });
          this.$router.push({
            name: "VerifyYou",
            params: { email: this.email },
          });
        } else {
          this.$vToastify.error("Please fill all fields");
        }
      } catch (error) {
        console.log(error);
      }
    },
    back() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped></style>
