import { render, staticRenderFns } from "./SideBar.vue?vue&type=template&id=66db73a0&scoped=true"
import script from "./SideBar.vue?vue&type=script&lang=js"
export * from "./SideBar.vue?vue&type=script&lang=js"
import style0 from "./SideBar.vue?vue&type=style&index=0&id=66db73a0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.10.0_prettier@2.8.8_react-dom@18.2.0_react@18.2.0_vue-templat_fws7el2nf3g7al3qvrb4aflm4e/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66db73a0",
  null
  
)

export default component.exports