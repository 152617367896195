<template>
  <div>
    <div class="nftmax-marketplace__bar mg-top-50 mg-btm-40">
      <div class="nftmax-marketplace__bar-inner">
        <!-- Marketplace Tab List -->
        <div
          id="list-tab"
          class="list-group nftmax-marketplace__bar-list"
          role="tablist"
        >
          <a
            :class="[tabActive === null ? 'active' : '']"
            class="list-group-item"
            data-bs-toggle="list"
            role="tab"
            @click="tabhandler(null)"
            >All</a
          >
          <a
            :class="[tabActive === 0 ? 'active' : '']"
            class="list-group-item"
            data-bs-toggle="list"
            role="tab"
            @click="tabhandler(0)"
            >Genetic Data</a
          >
          <a
            :class="[tabActive === 1 ? 'active' : '']"
            class="list-group-item"
            data-bs-toggle="list"
            role="tab"
            @click="tabhandler(1)"
            >EHR Data</a
          >
          <a
            :class="[tabActive === 2 ? 'active' : '']"
            class="list-group-item"
            data-bs-toggle="list"
            role="tab"
            @click="tabhandler(2)"
            >Medical Image Data</a
          >
          <a
            :class="[tabActive === 3 ? 'active' : '']"
            class="list-group-item"
            data-bs-toggle="list"
            role="tab"
            @click="tabhandler(3)"
            >Medical Statistics Data</a
          >
          <a
            :class="[tabActive === 4 ? 'active' : '']"
            class="list-group-item"
            data-bs-toggle="list"
            role="tab"
            @click="tabhandler(4)"
            >Training Models</a
          >
        </div>
        <!-- End Marketplace Tab List -->

        <div class="nftmax-marketplace__bar-right">
          <div class="nftmax-marketplace__bar-one">
            <form class="nftmax-marketplace__bar-search" action="#">
              <button class="search-btn" type="submit">
                <img src="../../assets/img/search.png" alt="#" />
              </button>
              <input
                v-model="filterParams.dataName"
                type="text"
                placeholder="Search data by name"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="trending-action">
      <div class="row">
        <div class="col-12">
          <div id="nav-tabContent" class="tab-content">
            <!-- Single Tab -->
            <div
              id="id1"
              v-loading="listLoading"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <div class="row nftmax-gap-sq30">
                <template v-for="(product, index) in filteredDataList">
                  <div :key="index" class="col-lg-4 col-md-6 col-12">
                    <!-- Marketplace Single Item -->
                    <ProductCardStyleTwo
                      :datas="product"
                      :if-show-details="false"
                    />
                    <!-- End Marketplace Item -->
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCardStyleTwo from "@/components/Helper/Cards/ProductCardStyleTwo";
import api from "../../http/api";

export default {
  components: { ProductCardStyleTwo },
  data() {
    return {
      tabActive: null,
      dataList: [],
      filterParams: {
        dataName: "",
      },
      searchParams: {
        type: null,
        edgeNodeId: null,
        filename: "",
        privacy: null,
        startTime: null,
        endTime: null,
        page: 1,
        perPage: 10000,
      },
      listLoading: false,
    };
  },
  computed: {
    filteredDataList() {
      if (this.filterParams.dataName) {
        return this.dataList.filter((item) =>
          item.filename
            .toLowerCase()
            .includes(this.filterParams.dataName.toLowerCase())
        );
      } else {
        return this.dataList;
      }
    },
  },
  mounted() {
    this.getFilesList();
  },
  methods: {
    tabhandler(value) {
      this.tabActive = value;
      this.searchParams.type = value;
      this.getFilesList();
    },
    async getFilesList() {
      try {
        this.listLoading = true;
        this.dataList = [];
        const res = await api.getFilesList(this.searchParams);
        this.dataList = res.data.data.list;
      } catch (error) {
        console.log(error);
      } finally {
        this.listLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
