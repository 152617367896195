<template>
  <div>
    <div class="nftmax-dsinner">
      <div class="nftmax-inner__heading mg-btm-20">
        <h2 class="nftmax-inner__page-title mb-0">Research List</h2>
        <div>
          <el-button type="primary" icon="el-icon-refresh" @click="refreshList">
            Refresh
          </el-button>
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="$router.push('/create-study')"
          >
            Create New Research
          </el-button>
        </div>
      </div>
      <div class="table-container">
        <el-table v-loading="tableLoading" :data="tableData">
          <el-table-column prop="id" label="ID" width="100"> </el-table-column>
          <el-table-column
            prop="name"
            label="Name"
            width="200"
          ></el-table-column>
          <el-table-column label="Method" width="250">
            <template slot-scope="scope">
              {{ translate(scope.row.analysisMethodName) }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="privacy" label="Privacy"> </el-table-column> -->
          <el-table-column label="Description">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" placement="right">
                <div slot="content">
                  {{ scope.row.description }}
                </div>
                <i class="el-icon-s-data"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Status">
            <template slot-scope="scope">
              <div :style="statusColor(scope.row.status)">
                {{ scope.row.status }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Payment">
            <template slot-scope="scope">
              <div :style="paymentStatusColor(scope.row.isPaid)">
                {{ scope.row.isPaid ? "Paid" : "Not Paid" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="Operation" min-width="150">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.status === 'Completed'"
                style="width: 150px"
                type="primary"
                size="medium"
                @click="getResultDialog(scope.row)"
                >Check Result</el-button
              >
              <el-button
                v-if="scope.row.status === 'Created'"
                style="width: 150px"
                type="primary"
                size="medium"
                @click="switchToParameterPage(scope.row)"
                >Fill Parameters</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <studyReport
      :result-dialog-visible="resultDialogVisible"
      :study-info="studyInfo"
      :result="result"
      @closeResultDialog="closeResultDialog"
    />
  </div>
</template>

<script>
import api from "../../http/api";
import studyReport from "../studyReport/study-report";
// import { sendToken } from "../../lib/MetaMaskService";
import { Loading } from "element-ui";

export default {
  components: {
    studyReport,
  },
  data() {
    return {
      tableLoading: false,
      tableData: [],
      studyInfo: {},
      resultDialogVisible: false,
      result: {},
    };
  },
  async mounted() {
    this.refreshList();
  },
  methods: {
    translate(name) {
      const list = {
        Lavita_HomoLogisticRegression: "Logistic Regression",
        Lavita_ImageClassification: "Image Classification",
        Lavita_ImageSegmentation: "Image Segmentation",
      };
      return list[name];
    },
    closeResultDialog() {
      this.resultDialogVisible = false;
    },
    async refreshList() {
      this.tableLoading = true;
      try {
        const params = {
          userId: this.$store.state.userInfo.userId,
          l1Token: "",
          analysisMethodId: "",
          creator: "",
          endTime: null,
          name: "",
          page: 1,
          perPage: 100,
          privacy: "",
          startTime: null,
          status: "",
          userName: this.$store.state.userInfo.username,
        };
        const res = await api.getStudyProjectList(params);
        this.tableData = res.data.data.list;
      } catch (error) {
        console.log(error);
      } finally {
        this.tableLoading = false;
      }
    },
    statusColor(status) {
      switch (status) {
        case "Failed":
          return "color: #A4413D";
        case "Not Started":
          return "color: #D8D8D8";
        case "In Process":
          return "color: #F0CD62";
        case "Completed":
          return "color: #97C565";
        case "Datasets Not Ready":
          return "color: #F0CD62";
        case "Approval Pending":
          return "color: #F0CD62";
        case "Created":
          return "color: #F0CD62";
        case "File Pending":
          return "color: #F0CD62";
        default:
          return "";
      }
    },
    paymentStatusColor(status) {
      switch (status) {
        case false:
          return "color: #D8D8D8";
        case true:
          return "color: #97C565";
        default:
          return "";
      }
    },
    async payForResearch(row) {
      if (typeof window.ethereum === "undefined") {
        this.$alert("MetaMask is not installed or connected, please check");
        return;
      }
      let loading = Loading.service({
        fullscreen: true,
        text: "Transferring vLAVITA...",
      });
      try {
        // await sendToken(10n);
        loading.setText("Getting result...");
        const res = await api.studyPaid({
          studyId: row.id,
        });
        this.refreshList();
        this.getResult(row);
        return res;
      } catch (error) {
        console.log(error);
        this.$alert(
          "Transaction failed, Please check if you have connected MetaMask service to our website, or do you have enough vLAVITA and vTFUEL to finish this transaction.",
          "Blockchain Service Unavailable"
        );
      } finally {
        loading.close();
      }
    },
    async getResult(row) {
      let loading = Loading.service({
        fullscreen: true,
        text: "Getting results...",
      });
      try {
        const res1 = await api.getStudyInfo({
          studyId: row.id,
          userId: this.$store.state.userInfo.userId,
          isPipelineStudy: false,
        });
        const res = await api.getResult({
          studyId: row.id,
          userId: this.$store.state.userInfo.userId,
        });
        this.result = {};
        this.studyInfo = {
          ...res1.data.studyRequestInfo.studyInfo,
          methodName: res.data.method,
          analysisMethodId: res1.data.studyRequestInfo.analysisMethodId,
        };
        switch (this.studyInfo.analysisMethodId) {
          case 51: {
            this.result = {
              f1Score: res.data.results.metrics["F1_secore"],
              auc: res.data.results.metrics.auc,
              precision: res.data.results.metrics.precision,
              recall: res.data.results.metrics.recall,
              rocChart: {
                x: res.data.results.metrics.roc[0],
                y: res.data.results.metrics.roc[1],
              },
              matrix: [
                {
                  title: "Positive",
                  positive: `${res.data.results.metrics["confusion matrix"]["true pos"]} (TP)`,
                  negative: `${res.data.results.metrics["confusion matrix"]["false pos"]} (FP)`,
                },
                {
                  title: "Negative",
                  positive: `${res.data.results.metrics["confusion matrix"]["false neg"]} (FN)`,
                  negative: `${res.data.results.metrics["confusion matrix"]["true neg"]} (TN)`,
                },
              ],
              intercept: res.data.results.model.intercept,
              coeffs: res.data.results.model.coeffs,
              features: res.data.results.model.features,
            };
            this.resultDialogVisible = true;
            break;
          }
          case 52: {
            this.resultDialogVisible = true;
            break;
          }
          case 53: {
            this.result = { ...res.data.results };
            this.resultDialogVisible = true;
            break;
          }
          default:
            break;
        }
      } catch (error) {
        this.$alert("Something went wrong, please try again later.", "Error");
        console.log(error);
      } finally {
        loading.close();
      }
    },
    async getResultDialog(row) {
      if (row.isPaid) {
        this.getResult(row);
      } else {
        this.$confirm(
          "Please transfer 10 vLAVITA tokens to unlock the study results.",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(() => {
            this.payForResearch(row);
          })
          .catch(() => {});
      }
    },
    switchToParameterPage(row) {
      this.$router.push({
        name: "FillStudyParameters",
        params: { studyInfo: row },
      });
    },
  },
};
</script>

<style scoped>
.table-container {
  background: #fff;
  padding: 30px;
  border-radius: 4px;
}
</style>
