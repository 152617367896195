<template>
  <AuthLayout>
    <div v-if="finishUpdate" class="nftmax-wc__form">
      <div
        class="nftmax-wc__form-inner nftmax-wc__form-password bg-mask-img"
        style="background-image: url('../../assets/img/mask-group-bg.png')"
      >
        <div class="nftmax-wc__heading">
          <h3 class="nftmax-wc__form-title">Password Updated</h3>
        </div>
        <!-- Sign in Form -->
        <form class="nftmax-wc__form-main" action="#" method="post">
          <div class="form-group">
            <div class="nftmax-wc__middle-update">
              <div class="nftmax-wc__update">
                <img src="../../assets/img/sign-icon.png" alt="#" />
              </div>
              <div class="nftmax-wc__update-contnet">
                <h2 class="nftmax-wc__update-contnet-title">Thanks You !</h2>
                <p class="nftmax-wc__update-contnet-text">
                  Your password has been updated
                </p>
              </div>
            </div>
            <div class="nftmax-wc__button nftmax-wc__button-forget">
              <button
                class="ntfmax-wc__btn"
                type="submit"
                @click="() => this.$router.push('login')"
              >
                Back to Login
              </button>
            </div>
          </div>
        </form>
        <!-- End Sign in Form -->
      </div>
    </div>
    <div v-else class="nftmax-wc__form">
      <div class="nftmax-wc__form-inner">
        <div class="nftmax-wc__heading">
          <h3
            class="nftmax-wc__form-title nftmax-wc__form-title__three"
            style="
              background-image: url('../../assets/img/heading-vector-3.png');
            "
          >
            Update Password
          </h3>
        </div>
        <!-- Sign in Form -->
        <form class="nftmax-wc__form-main" action="#" method="post">
          <div
            v-if="!$route.params.email && !$route.params.verificationCode"
            class="form-group"
          >
            <label class="nftmax-wc__form-label">Old Password</label>
            <div class="form-group__input">
              <el-input
                v-model="oldPassword"
                placeholder=""
                show-password
              ></el-input>
            </div>
          </div>
          <div class="form-group">
            <label class="nftmax-wc__form-label">New Password</label>
            <div class="form-group__input">
              <el-input
                v-model="password"
                placeholder=""
                show-password
              ></el-input>
            </div>
          </div>
          <div class="form-group">
            <label class="nftmax-wc__form-label">Re-enter Password</label>
            <div class="form-group__input">
              <el-input
                v-model="renterPassword"
                placeholder=""
                show-password
              ></el-input>
            </div>
          </div>
          <div class="form-group">
            <div class="nftmax-wc__button">
              <el-button
                class="ntfmax-wc__btn"
                type="submit"
                @click="finishUpdateHandler"
              >
                Continue
              </el-button>
            </div>
          </div>
          <div class="form-group">
            <div class="nftmax-wc__button">
              <button
                class="ntfmax-wc__btn ntfmax-wc__btn-grey"
                type="submit"
                @click="back"
              >
                Back
              </button>
            </div>
          </div>
        </form>
        <!-- End Sign in Form -->
      </div>
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from "@/components/Auth/AuthLayout";
import api from "../../http/api";

export default {
  components: { AuthLayout },
  data() {
    return {
      oldPassword: "",
      password: "",
      renterPassword: "",
      finishUpdate: false,
    };
  },
  methods: {
    async finishUpdateHandler() {
      try {
        if (this.password !== "" && this.renterPassword !== "") {
          if (this.password === this.renterPassword) {
            const passwordRegex =
              /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/;
            if (!passwordRegex.test(this.password)) {
              this.$vToastify.warning(
                "The password must contain at least 6 digits, including at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."
              );
              return;
            }
            // 根据this.$route.params中有没有值判断用户是在登录前后进入更新密码页面的
            if (
              this.$route.params &&
              this.$route.params.email &&
              this.$route.params.verificationCode
            ) {
              await api.forgotPassword({
                email: this.$route.params.email,
                verificationCode: this.$route.params.verificationCode,
                newPassword: this.password.split(""),
              });
              this.finishUpdate = !this.finishUpdate;
            } else {
              if (this.oldPassword === "") {
                this.$vToastify.error("Please fill all fields");
                return;
              }
              await api.resetPassword({
                username: this.$store.state.userInfo.username,
                password: this.oldPassword,
                newPassword: this.password.split(""),
              });
              this.finishUpdate = !this.finishUpdate;
            }
          } else {
            this.$vToastify.error("Password doesn't match");
          }
        } else {
          this.$vToastify.error("Please fill all fields");
        }
      } catch (error) {
        console.log(error);
      }
    },
    back() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped></style>
