<template>
  <div class="trending-action mg-top-40">
    <h2 class="trending-action__heading">Trending Action</h2>
    <div class="row">
      <div class="col-12">
        <div class="trending-action__slider position-relative">
          <button class="Prev slick-arrow" style="" @click="showNext()">
            <svg
              width="11"
              height="19"
              viewBox="0 0 11 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.09766 1.1499L1.13307 9.11449L9.09766 17.0791"
                stroke="url(#paint0_linear_220_23410)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <defs>
                <linearGradient
                  id="paint0_linear_220_23410"
                  x1="9.09766"
                  y1="1.1499"
                  x2="-4.2474"
                  y2="7.96749"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F539F8"></stop>
                  <stop offset="0.416763" stop-color="#C342F9"></stop>
                  <stop offset="1" stop-color="#34E393"></stop>
                </linearGradient>
              </defs>
            </svg>
          </button>
          <VueSlickCarousel ref="carousel" v-bind="settings">
            <template v-for="(product, index) in products">
              <ProductCardStyleOne :key="index" :datas="product" />
            </template>
          </VueSlickCarousel>
          <button class="Next slick-arrow" style="" @click="showPrev()">
            <svg
              width="11"
              height="19"
              viewBox="0 0 11 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.34766 17.0801L9.31224 9.11549L1.34766 1.15091"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import ProductCardStyleOne from "@/components/Helper/Cards/ProductCardStyleOne";
import products from "../../data/product_data.json";
export default {
  components: { ProductCardStyleOne, VueSlickCarousel },
  data() {
    return {
      settings: {
        autoplay: false,
        speed: 800,
        autoplaySpeed: 3500,
        slidesToShow: 4,
        pauseOnHover: true,
        dots: false,
        center: false,
        arrows: false,
        cssEase: "ease",
        draggable: true,
        responsive: [
          {
            breakpoint: 2000,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 812,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      products: products.datas,
    };
  },
  methods: {
    showNext() {
      this.$refs.carousel.next();
    },
    showPrev() {
      this.$refs.carousel.prev();
    },
  },
};
</script>

<style scoped></style>
