export function format(time) {
  time = new Date(time);
  const options = { year: "numeric", month: "short", day: "numeric" };
  return time.toLocaleDateString(undefined, options);
  // const values = {
  //   "y+": time.getFullYear(),
  //   "m+": time.getMonth() + 1,
  //   "d+": time.getDate(),
  //   "h+": time.getHours(),
  //   "M+": time.getMinutes(),
  //   "s+": time.getSeconds(),
  // };
  // console.log(values);
  // Object.entries(values).forEach((item) => {
  //   const reg = new RegExp(item[0]);
  //   f = f.replace(reg, (item[1] + "").padStart(2, "0"));
  // });
  // return f;
}

export function formatUsDate(inputDate) {
  const date = new Date(inputDate);
  const options = { year: "numeric", month: "short", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

export function isValidDate(dateString) {
  const dateObject = new Date(dateString);
  return !isNaN(dateObject.getTime());
}
