<template>
  <div class="nftmax-dsinner">
    <div class="nftmax-inner__heading mg-btm-20">
      <h2 class="nftmax-inner__page-title m-0">My Collection</h2>
    </div>

    <div class="row nftmax-gap-sq30">
      <template v-for="(collection, index) in collections">
        <div :key="index" class="col-lg-4 col-md-6 col-12">
          <CollectionCard :datas="collection" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CollectionCard from "@/components/Helper/Cards/CollectionCard";
import collectionsData from "../../data/Collectionplan_data.json";
export default {
  components: { CollectionCard },
  data() {
    return {
      collections: collectionsData.data,
    };
  },
};
</script>

<style scoped></style>
