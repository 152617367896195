<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <!-- Marketplace Single Item -->
        <div class="trending-action__single marketplace-details mg-top-30">
          <div class="row nftmax-flex__center nftmax-gap-30">
            <div class="col-lg-6 col-12 nftmax-sixth-one">
              <!-- Trending Head -->
              <div class="trending-action__head">
                <div
                  class="trending-action__badge trending-action__badge-detail"
                >
                  <span>On Sale</span>
                </div>
                <img src="../../assets/img/shop-single.png" alt="#" />
              </div>
            </div>
            <div class="col-lg-6 col-12 nftmax-sixth-two">
              <div class="nftmax-trendmeta">
                <div
                  class="nftmax-trendmeta__main nftmax-trendmeta__main_detail"
                >
                  <div
                    class="nftmax-trendmeta__author nftmax-trendmeta__detail"
                  >
                    <div
                      class="nftmax-trendmeta__img nftmax-trendmeta__img-detail"
                    >
                      <img src="../../assets/img/market-author-1.png" alt="#" />
                    </div>
                    <div
                      class="nftmax-trendmeta__content nftmax-trendmeta__content-detail"
                    >
                      <span class="nftmax-trendmeta__small">Owned by</span>
                      <h4 class="nftmax-trendmeta__title">Rrayak John</h4>
                    </div>
                  </div>
                  <div class="nftmax-trendmeta__author">
                    <div
                      class="nftmax-trendmeta__content nftmax-trendmeta__content-detail"
                    >
                      <span class="nftmax-trendmeta__small">Create by</span>
                      <h4 class="nftmax-trendmeta__title">Yuaisn Kha</h4>
                    </div>
                    <div
                      class="nftmax-trendmeta__img nftmax-trendmeta__img-detail"
                    >
                      <img src="../../assets/img/market-author-2.png" alt="#" />
                    </div>
                  </div>
                </div>
              </div>
              <!-- Trending Body -->
              <div class="trending-action__body trending-marketplace__body">
                <p class="trending-action__shop-id">ID : 2320382</p>
                <h2
                  class="trending-action__title trending-action__title-detail"
                >
                  Lock and Lob x Fiesta Spurs
                </h2>
                <p class="trending-action__title-text">
                  Why I say old chap that is spiffing starkers it’s your round
                  nancy you get the whole package that allows you to get
                  exclusive boy, knees up have it are you find to make it taking
                  the piss.
                </p>

                <div class="nftmax-published">
                  <span class="nftmax-published__title">Publish Date</span>
                  <h4 class="nftmax-published__date">
                    12 March 2021, 11:32 PM
                  </h4>
                </div>

                <div class="nftmax-currency nftmax-currency__shop-detail">
                  <div class="nftmax-currency__main">
                    <div class="nftmax-currency__icon">
                      <img src="../../assets/img/eth-icon.png" alt="#" />
                    </div>
                    <div class="nftmax-currency__content">
                      <h4 class="nftmax-currency__content-title">75,320 ETH</h4>
                      <p class="nftmax-currency__content-sub">(773.69 USD)</p>
                    </div>
                  </div>
                  <a href="#" class="nftmax-btn nftmax-btn__secondary radius"
                    >Publish for Sell</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Marketplace Item -->
        <SellHistoryAnalytics />
        <TrendingSection />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/Partials/Layout";
import SellHistoryAnalytics from "@/components/HomePage/SellHistoryAnalytics";
import TrendingSection from "@/components/HomePage/TrendingSection";
export default {
  components: { TrendingSection, SellHistoryAnalytics, Layout },
};
</script>

<style scoped></style>
